import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { Box, Button, Flex, IconButton, Modal } from "@chakra-ui/react";
import { FaFilePdf, FaTimes } from "react-icons/fa";

export function InvoicePDFViewer() {
    const { invoice, isPDFVisible, setIsPDFVisible } = useInvoiceDetails();
  
    return (
      <>
        {/* Botón Sticky */}
        <Box
          position="fixed"
          right={4}
          bottom={4}
          transform="translateY(-50%)"
          zIndex={1000}
        >
          <Button
            leftIcon={<FaFilePdf />}
            onClick={() => setIsPDFVisible(true)}
            colorScheme="blue"
            size="lg"
            boxShadow="lg"
            borderRadius="full"
            px={6}
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "xl",
            }}
            transition="all 0.2s"
          >
            Ver PDF
          </Button>
        </Box>
  
        {/* Modal del PDF */}
        <Modal isOpen={isPDFVisible} onClose={() => setIsPDFVisible(false)} size="full">
          <Box 
            position="fixed" 
            top="0" 
            left="0" 
            width="100vw" 
            height="100vh" 
            bg="rgba(0, 0, 0, 0.5)" 
            display="flex" 
            justifyContent="center" 
            zIndex={1000}
            alignItems="center"
            onClick={() => setIsPDFVisible(false)}
          >
            <Box 
              width="90%" 
              height="90%" 
              bg="white" 
              borderRadius="lg" 
              overflow="hidden"
              onClick={e => e.stopPropagation()}
              position="relative"
            >
              <Flex 
                position="absolute" 
                top={10} 
                right={4} 
                zIndex={2}
              >
                <IconButton
                  aria-label="Cerrar PDF"
                  icon={<FaTimes />}
                  onClick={() => setIsPDFVisible(false)}
                  colorScheme="gray"
                  size="lg"
                />
              </Flex>
              <iframe
                src={invoice?.pdf_url}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
                title="PDF Viewer"
              />
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
  