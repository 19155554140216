import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepData, Step } from '@/types/onboarding.types';
import { MdBusiness, MdCheck, MdSettings, MdPerson } from "react-icons/md";
import { BusinessInformation } from "@/components/onboarding/steps/BusinessInformation";
import { UserInformation } from "@/components/onboarding/steps/UserInformation";
import { ServicesConfiguration } from "@/components/onboarding/steps/ServicesConfiguration";
import { Congratulations } from "@/components/onboarding/steps/Congratulations";
import { useToast } from '@chakra-ui/react';
import { submitOnboardingData as submitOnboardingDataService } from '@/services/onboarding.services';

// Step Configuration
export const ONBOARDING_STEPS: Step[] = [
    {
        id: 'user-details',
        icon: MdPerson,
        title: "Usuario Administrador",
        description: "Configura tu cuenta",
        component: UserInformation,
        validationFn: (data) => {
            if (!data) return false;
            return (
                !!data.email &&
                !!data.userName &&
                !!data.password &&
                data.password.length >= 8 &&
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)
            );
        },
    },
    {
        id: 'business-details',
        icon: MdBusiness,
        title: "Datos de la Empresa",
        description: "Información empresarial y DIAN",
        component: BusinessInformation,
        validationFn: (data) => {
            if (!data) return false;
            return (
                !!data.companyName &&
                !!data.legalName &&
                !!data.taxId &&
                !!data.description &&
                !!data.idType &&
                !!data.legalRepresentativeId &&
                data.description.length >= 20 &&
                /^\d{9,11}$/.test(data.taxId) &&
                /^\d{6,11}$/.test(data.legalRepresentativeId)
            );
        },
    },
    {
        id: 'services-configuration',
        icon: MdSettings,
        title: "Configura tus servicios",
        description: "Configura tus servicios",
        component: ServicesConfiguration,
        validationFn: (data) => {
            if (!data || !data.system) return false;
            
            // Check that all required fields are present based on ERP type
            const credentials = data.credentials || {};
            
            if (data.system === 'siigo-contador') {
                return !!credentials.accountsFile && 
                       !!credentials.costCentersFile && 
                       !!credentials.invoiceTypesFile;
            } else if (data.system === 'siigo-no-contador') {
                return !!credentials.platformUsername && 
                       !!credentials.platformPassword && 
                       !!credentials.apiUsername && 
                       !!credentials.apiKey && 
                       !!credentials.accountsFile;
            } else if (data.system === 'helisa') {
                return !!credentials.accountsFile && 
                       !!credentials.costCentersFile && 
                       !!credentials.invoiceTypesFile && 
                       !!credentials.paymentMethodsFile; // Added payment methods file validation
            }
            
            return false;
        },
    },
    {
        id: 'congratulations',
        icon: MdCheck,
        title: "¡Felicidades!",
        description: "Has completado el onboarding",
        component: Congratulations,
    }
];

interface OnboardingContextType {
    currentStepIndex: number;
    completedSteps: string[];
    stepsData: StepData;
    currentStepValid: boolean;
    currentStep: Step;
    progress: number;
    isSubmitting: boolean;
    
    // Methods
    handleDataChange: (stepData: any) => void;
    handleContinue: () => void;
    handleStepClick: (stepIndex: number) => void;
    canNavigateToStep: (stepIndex: number) => boolean;
    resetOnboarding: () => void;
    submitOnboardingData: () => Promise<boolean>;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

interface OnboardingProviderProps {
    children: ReactNode;
}

export function OnboardingProvider({ children }: OnboardingProviderProps) {
    const navigate = useNavigate();
    const toast = useToast();
    // Add admin mode parameter - should be false in production
    const isAdminMode = import.meta.env.DEV;
    
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [completedSteps, setCompletedSteps] = useState<string[]>([]);
    const [stepsData, setStepsData] = useState<StepData>({});
    const [currentStepValid, setCurrentStepValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const currentStep = ONBOARDING_STEPS[currentStepIndex];
    const progress = (completedSteps.length / ONBOARDING_STEPS.length) * 100;

    const handleDataChange = (stepData: any) => {
        const updatedStepsData = {
            ...stepsData,
            [currentStep.id]: stepData,
        };
        setStepsData(updatedStepsData);

        // Log the state of the form when data changes
        console.log(`Step ${currentStep.id} data updated:`, stepData);

        // Set congratulations step as always valid
        if (currentStep.id === 'congratulations') {
            setCurrentStepValid(true);
            return;
        }

        // Validate current step data if validation function exists
        if (currentStep.validationFn) {
            setCurrentStepValid(currentStep.validationFn(stepData));
        } else {
            setCurrentStepValid(true);
        }
    };

    const handleContinue = () => {
        // Log the current step data before continuing
        console.log(`Completing step ${currentStep.id}:`, stepsData[currentStep.id]);
        
        // For the final "Congratulations" step, allow continuing without validation
        const isFinalStep = currentStepIndex === ONBOARDING_STEPS.length - 1;
        
        // Allow continuing in admin mode without validation or if it's the final step
        if (isAdminMode || (isFinalStep && currentStep.id === 'congratulations')) {
            if (currentStepIndex < ONBOARDING_STEPS.length - 1) {
                setCurrentStepIndex(currentStepIndex + 1);
            } else {
                handleOnboardingComplete(stepsData);
            }
            return;
        }

        // Mark current step as completed
        if (!completedSteps.includes(currentStep.id)) {
            setCompletedSteps([...completedSteps, currentStep.id]);
        }

        // Move to next step if not on last step
        if (currentStepIndex < ONBOARDING_STEPS.length - 1) {
            setCurrentStepIndex(currentStepIndex + 1);
            setCurrentStepValid(false); // Reset validation for new step
        } else {
            handleOnboardingComplete(stepsData);
        }
    };

    const handleOnboardingComplete = (finalData: StepData) => {
        console.log('Onboarding completed with data:', finalData);
        submitOnboardingData();
    };

    const submitOnboardingData = async (): Promise<boolean> => {
        setIsSubmitting(true);
        try {
            const response = await submitOnboardingDataService(stepsData);
            
            toast({
                title: '¡Registro exitoso!',
                description: 'Tu cuenta ha sido creada correctamente.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            
            // Navigate to the dashboard or wherever appropriate
            navigate('/dashboard');
            return true;
        } catch (error) {
            console.error('Error submitting onboarding data:', error);
            toast({
                title: 'Error en el registro',
                description: 'Hubo un problema al crear tu cuenta. Por favor intenta de nuevo.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return false;
        } finally {
            setIsSubmitting(false);
        }
    };

    const canNavigateToStep = (stepIndex: number): boolean => {
        // In admin mode, allow navigation to any step
        if (isAdminMode) return true;

        const targetStep = ONBOARDING_STEPS[stepIndex];

        // Can always go back
        if (stepIndex < currentStepIndex) return true;

        // Can't skip steps
        if (stepIndex > currentStepIndex + 1) return false;

        // Can move to next step if current is completed
        if (stepIndex === currentStepIndex + 1) {
            const currentStepId = ONBOARDING_STEPS[currentStepIndex].id;
            return completedSteps.includes(currentStepId);
        }

        return true;
    };

    const handleStepClick = (stepIndex: number) => {
        if (canNavigateToStep(stepIndex)) {
            // Log navigation between steps
            console.log(`Navigating from step ${currentStepIndex} (${currentStep.id}) to step ${stepIndex} (${ONBOARDING_STEPS[stepIndex].id})`);
            setCurrentStepIndex(stepIndex);
        }
    };

    const resetOnboarding = () => {
        console.log('Resetting onboarding process, previous data:', stepsData);
        setCurrentStepIndex(0);
        setCompletedSteps([]);
        setStepsData({});
        setCurrentStepValid(false);
    };

    const value = {
        currentStepIndex,
        completedSteps,
        stepsData,
        currentStepValid,
        currentStep,
        progress,
        isSubmitting,
        handleDataChange,
        handleContinue,
        handleStepClick,
        canNavigateToStep,
        resetOnboarding,
        submitOnboardingData
    };

    return (
        <OnboardingContext.Provider value={value}>
            {children}
        </OnboardingContext.Provider>
    );
}

export function useOnboarding() {
    const context = useContext(OnboardingContext);
    if (context === undefined) {
        throw new Error('useOnboarding must be used within an OnboardingProvider');
    }
    return context;
}
