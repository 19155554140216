import React from 'react';
import {
    Stack,
    Box,
    Text,
    Badge,
    HStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    useColorModeValue,
} from '@chakra-ui/react';
import { FileUploadSection } from '../FileUploadSection';
import { CredentialsSection } from '../steps/CredentialsSection';

// Define props interface
interface SiigoNoContadorFormProps {
    stepData: any;
    handleCredentialChange: (field: string, value: string) => void;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    videoURLs: Record<string, string>;
    videoLoadingStates: any;
}

export const SiigoNoContadorForm: React.FC<SiigoNoContadorFormProps> = ({
    stepData,
    handleCredentialChange,
    handleFileChange,
    videoURLs,
    videoLoadingStates,
}) => {
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const headerBg = useColorModeValue('gray.50', 'gray.700');

    return (
        <Stack spacing={4}>
            <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md" mb={3}>
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Credenciales Siigo</Text>
                            {(stepData?.credentials?.platformUsername || stepData?.credentials?.platformPassword) && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <CredentialsSection
                                title="Credenciales Siigo"
                                description="Estas credenciales nos permiten ayudarte para el soporte de integración con Siigo."
                                fields={[
                                    {
                                        label: 'Usuario Siigo Nube',
                                        key: 'platformUsername',
                                        placeholder: 'Usuario de la plataforma Siigo Nube',
                                        isRequired: true,
                                    },
                                    {
                                        label: 'Contraseña Siigo Nube',
                                        key: 'platformPassword',
                                        type: 'password',
                                        placeholder: 'Contraseña de la plataforma Siigo Nube',
                                        isRequired: true,
                                    },
                                ]}
                                values={stepData?.credentials || {}}
                                onChange={handleCredentialChange}
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md" mb={3}>
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Credenciales API Siigo</Text>
                            {(stepData?.credentials?.apiUsername && stepData?.credentials?.apiKey) && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <CredentialsSection
                                title="Credenciales API Siigo"
                                description="Estas credenciales permiten que nuestra IA se conecte de forma segura con tu cuenta de Siigo para subir automáticamente las facturas procesadas."
                                fields={[
                                    {
                                        label: 'Usuario API',
                                        key: 'apiUsername',
                                        placeholder: 'Usuario API de Siigo',
                                        isRequired: true,
                                    },
                                    {
                                        label: 'API Key',
                                        key: 'apiKey',
                                        placeholder: 'API Key de Siigo',
                                        isRequired: true,
                                    },
                                ]}
                                values={stepData?.credentials || {}}
                                onChange={handleCredentialChange}
                                tutorial={{
                                    title: '¿Cómo obtener las credenciales API?',
                                    link: 'https://siigonube.portaldeclientes.siigo.com/credenciales-api/',
                                    videoUrl: videoURLs.api,
                                    isLoading: videoLoadingStates.apiVideoLoading,
                                    onVideoLoaded: videoLoadingStates.setApiVideoLoaded,
                                }}
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md">
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Cuentas Contables</Text>
                            {stepData?.credentials?.accountsFile && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <FileUploadSection
                                title="Cuentas Contables"
                                description="Este archivo nos permite entrenar a nuestra IA con el contexto de tus cuentas contables para predecir automáticamente la cuenta apropiada para cada ítem."
                                inputId="accounts-file"
                                fileName={stepData?.credentials?.accountsFile}
                                onFileChange={handleFileChange}
                                tutorialTitle="¿Cómo obtener el listado de cuentas?"
                                videoUrl={videoURLs.accounts}
                                isVideoLoading={videoLoadingStates.accountsVideoLoading}
                                onVideoLoaded={videoLoadingStates.setAccountsVideoLoaded}
                                labelPrefix="Archivo de Cuentas Contables"
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Stack>
    );
}; 