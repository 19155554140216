import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
    VStack, 
    Heading, 
    Input, 
    Button, 
    Box, 
    Text, 
    useToast, 
    InputGroup, 
    InputLeftElement,
    Icon,
    FormControl,
    FormLabel,
    Flex,
    Grid,
    Image,
    Center,
    HStack,
    useColorModeValue,
} from "@chakra-ui/react";
import { MdEmail, MdLock } from "react-icons/md";
import { signInWithEmailAndPassword } from "firebase/auth";
import { motion } from "framer-motion";

import { auth } from "@/config/firebaseConfig";
import { FiltersSection } from "@/components/invoices/FiltersSection";

// Motion components
const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionText = motion(Text);
const MotionHStack = motion(HStack);

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    // Color mode values
    const bgGradient = useColorModeValue(
        "linear(to-br, blue.50, white, blue.50)", 
        "linear(to-br, gray.900, gray.800)"
    );
    const cardBg = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue("gray.200", "gray.700");
    const textColor = useColorModeValue("gray.700", "gray.300");
    const brandColor = useColorModeValue("blue.500", "blue.400");
    
    // Left panel gradient
    const leftPanelGradient = useColorModeValue(
        "linear(to-b, blue.600, blue.500)", 
        "linear(to-b, blue.900, blue.800)"
    );

    const validateInputs = () => {
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            toast({
                title: "Error de validación",
                description: "Por favor ingrese un correo válido",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return false;
        }
        if (!password || password.length < 6) {
            toast({
                title: "Error de validación",
                description: "La contraseña debe tener al menos 6 caracteres",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return false;
        }
        return true;
    };

    const handleLogin = async () => {
        if (!validateInputs()) return;

        setIsLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/");
        } catch {
            setError('Correo o contraseña incorrectos.');
            toast({
                title: "Error de autenticación",
                description: "Credenciales inválidas. Por favor intente nuevamente.",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <Grid 
            minH="100vh"
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            bgGradient={bgGradient}
        >
            {/* Left side - Branding & value proposition */}
            <MotionFlex
                display={{ base: "none", md: "flex" }}
                bgGradient={leftPanelGradient}
                color="white"
                p={10}
                direction="column"
                align="flex-start"
                justify="center"
                position="relative"
                borderRightRadius="xl"
                boxShadow="lg"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Box position="relative" zIndex={1} maxW="450px" pl={6}>
                    <MotionText
                        as="h1"
                        fontSize="4xl"
                        fontWeight="bold"
                        mb={6}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3, duration: 0.5 }}
                    >
                        🤖 Contabilidad potenciada por IA
                    </MotionText>
                    
                    <MotionText
                        fontSize="lg"
                        mb={8}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                    >
                        Optimiza tu trabajo contable, reduce errores y ahorra tiempo con nuestra plataforma impulsada por inteligencia artificial.
                    </MotionText>
                    
                    <MotionHStack
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5, duration: 0.5 }}
                        spacing={2}
                        align="center"
                    >
                        <Text fontSize="sm" fontWeight="medium" opacity={0.7}>
                            Impulsado por
                        </Text>
                        <Image 
                            src="https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Ycombinator%20Logo.png" 
                            alt="Y Combinator" 
                            height="20px"
                            opacity={0.9}
                        />
                    </MotionHStack>
                </Box>
            </MotionFlex>
            
            {/* Right side - Login form */}
            <Flex 
                direction="column" 
                align="center" 
                justify="center" 
                p={{ base: 4, md: 8 }}
                position="relative"
            >
                <MotionBox
                    maxW={{ base: "400px", lg: "450px" }}
                    w="full"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Box display={{ base: "block", md: "none" }} mb={8} textAlign="center">
                        <Heading as="h1" size="xl" color={brandColor}>
                            Cifrato
                        </Heading>
                    </Box>
                    
                    <Box 
                        bg={cardBg} 
                        p={8} 
                        borderRadius="xl"
                        boxShadow="lg"
                        border="1px solid"
                        borderColor={borderColor}
                    >
                        <VStack spacing={6} align="stretch">
                            <Box textAlign="center" mb={2}>
                                <Heading 
                                    as="h2" 
                                    size="lg" 
                                    color={textColor}
                                    fontWeight="600"
                                    mb={2}
                                >
                                    Bienvenido
                                </Heading>
                                <Text color="gray.500">
                                    Inicia sesión para acceder a tu cuenta
                                </Text>
                            </Box>

                            {error && (
                                <Box 
                                    bg="red.50" 
                                    color="red.500" 
                                    p={3} 
                                    borderRadius="md"
                                    fontSize="sm"
                                >
                                    {error}
                                </Box>
                            )}

                            <FormControl>
                                <FormLabel fontSize="sm" color="gray.600" fontWeight="medium">
                                    Correo electrónico
                                </FormLabel>
                                <InputGroup>
                                    <InputLeftElement h="100%" pointerEvents="none">
                                        <Center w="100%">
                                            <Icon as={MdEmail} color="gray.500" />
                                        </Center>
                                    </InputLeftElement>
                                    <Input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder="correo@ejemplo.com"
                                        bg={cardBg}
                                        borderColor={borderColor}
                                        size="lg"
                                        _hover={{ borderColor: "gray.400" }}
                                        _focus={{ 
                                            borderColor: brandColor,
                                            boxShadow: `0 0 0 1px ${brandColor}`
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <FormLabel fontSize="sm" color="gray.600" fontWeight="medium">
                                    Contraseña
                                </FormLabel>
                                <InputGroup>
                                    <InputLeftElement h="100%" pointerEvents="none">
                                        <Center w="100%">
                                            <Icon as={MdLock} color="gray.500" />
                                        </Center>
                                    </InputLeftElement>
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder="••••••••"
                                        bg={cardBg}
                                        borderColor={borderColor}
                                        size="lg"
                                        _hover={{ borderColor: "gray.400" }}
                                        _focus={{ 
                                            borderColor: brandColor,
                                            boxShadow: `0 0 0 1px ${brandColor}`
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>

                            <Button
                                colorScheme="blue"
                                width="full"
                                onClick={handleLogin}
                                isLoading={isLoading}
                                loadingText="Iniciando sesión..."
                                height="50px"
                                fontSize="md"
                                fontWeight="semibold"
                                _hover={{ 
                                    bg: "blue.600",
                                    transform: "translateY(-2px)",
                                    boxShadow: "md"
                                }}
                                _active={{ 
                                    transform: "translateY(0)",
                                    boxShadow: "sm"
                                }}
                                transition="all 0.2s"
                                mt={2}
                            >
                                Iniciar Sesión
                            </Button>
                        </VStack>
                    </Box>
                </MotionBox>
                
                {/* Footer text */}
                <Text 
                    fontSize="sm" 
                    color="gray.500" 
                    textAlign="center" 
                    position="absolute"
                    bottom={4}
                    opacity={0.7}
                >
                    Hecho con ❤️ desde 🇨🇴
                </Text>
            </Flex>
        </Grid>
    );
}