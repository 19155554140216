import { ErpHandler } from "@/services/erp/erpHandler.interface";
import { AccountOption, AccountData } from "@/types/invoiceDetail.types";
import { useState, useEffect } from "react";

export const useRetentionAccounts = (erpService: ErpHandler | null) => {
    // State for retention accounts
    const [retentionAccountOptions, setRetentionAccountOptions] = useState<AccountOption[]>([]);
    const [isLoading, setIsLoading] = useState(false);
  
    // Load accounts for retentions
    useEffect(() => {
      if (!erpService) return;
  
      const loadAccounts = async () => {
        setIsLoading(true);
  
        try {
            const retentionAccounts = await erpService.getAccounts('impuesto');
            
            const retentionOptions = Object.entries<AccountData>(retentionAccounts).map(([key, data]) => ({
              label: `${key} - ${data.name}`,
              value: key
            }));
  
            setRetentionAccountOptions(retentionOptions);
          
        } catch (error) {
          console.error('useRetentionAccounts: Error loading retention accounts:', error);
        } finally {
          setIsLoading(false);
        }
      };
  
      loadAccounts();
    }, [erpService]);
  
    return {
      retentionAccountOptions,
      isLoading
    };
  }; 