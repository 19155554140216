import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Text,
    VStack,
    Flex,
    Button,
    Spinner,
    Alert,
    AlertIcon,
    Input,
    InputGroup,
    InputLeftElement,
    Center,
    Icon,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from "@chakra-ui/icons";
import { FiPackage } from 'react-icons/fi';
import { PurchaseOrderModalProps, PurchaseOrder } from '@/types/purchaseOrder.types';
import { PurchaseOrderDetails } from './PurchaseOrderDetails';

export const PurchaseOrderModal: React.FC<PurchaseOrderModalProps> = ({
    isOpen,
    onClose,
    availablePOs,
    onSelectPO,
    isLoading,
    currentPO
}) => {
    const [expandedPOs, setExpandedPOs] = useState<{ [key: string]: boolean }>({});
    const [searchTerm, setSearchTerm] = useState('');

    const toggleExpand = (poId: string) => {
        setExpandedPOs(prev => ({
            ...prev,
            [poId]: !prev[poId]
        }));
    };

    const filteredPOs = availablePOs.filter(po => 
        po.transaction_id.toString().includes(searchTerm) ||
        po.observations?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        po.items?.[0]?.warehouse_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        po.items?.some(item => 
            item.item_description?.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const renderPurchaseOrder = (po: PurchaseOrder) => {
        const isSelected = currentPO?.id === po.id;
        return (
            <Box
                key={po.id}
                p={4}
                borderWidth="1px"
                borderRadius="lg"
                bg={isSelected ? 'blue.50' : 'white'}
                borderColor={isSelected ? 'blue.500' : 'gray.200'}
                _hover={{ borderColor: 'blue.500' }}
            >
                <VStack align="stretch" spacing={4}>
                    <Flex justify="space-between" align="center">
                        <Flex
                            flex="1"
                            cursor="pointer"
                            onClick={() => toggleExpand(po.id)}
                            _hover={{ bg: isSelected ? 'blue.100' : 'gray.50' }}
                            p={2}
                            borderRadius="md"
                        >
                            <Flex direction="column" flex="1">
                                <Flex align="center" gap={2}>
                                    <Text fontSize="sm" fontWeight="bold">
                                        #{po.transaction_id}
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">
                                        {new Date(po.transaction_date).toLocaleDateString()}
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">•</Text>
                                    <Text fontSize="sm" color="gray.500">
                                        {po.items?.[0]?.warehouse_name || 'Sin punto de venta'}
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">•</Text>
                                    <Text fontSize="sm" color="gray.500">
                                        {po.items?.length || 0} items
                                    </Text>
                                    {isSelected && (
                                        <>
                                            <Text fontSize="sm" color="gray.500">•</Text>
                                            <Text fontSize="sm" color="blue.600" fontWeight="medium">
                                                Seleccionada
                                            </Text>
                                        </>
                                    )}
                                </Flex>
                                {po.observations && (
                                    <Text fontSize="sm" color="gray.600" noOfLines={1} mt={1}>
                                        {po.observations}
                                    </Text>
                                )}
                            </Flex>
                            {expandedPOs[po.id] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        </Flex>
                        <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={() => {
                                onSelectPO(po);
                                onClose();
                            }}
                            variant={isSelected ? "outline" : "solid"}
                        >
                            {isSelected ? 'Seleccionada' : 'Seleccionar'}
                        </Button>
                    </Flex>

                    {expandedPOs[po.id] && <PurchaseOrderDetails purchaseOrder={po} />}
                </VStack>
            </Box>
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent maxW="900px">
                <ModalHeader>Seleccionar Orden de Compra</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    {availablePOs.length > 0 && (
                        <>
                            <Alert status="info" mb={4}>
                                <AlertIcon />
                                Solo se puede asociar una orden de compra por factura.
                            </Alert>
                            
                            <InputGroup mb={4}>
                                <InputLeftElement pointerEvents="none">
                                    <SearchIcon color="gray.300" />
                                </InputLeftElement>
                                <Input
                                    placeholder="Buscar por número de orden, observaciones o items..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </InputGroup>
                        </>
                    )}

                    {isLoading ? (
                        <Flex justify="center" align="center" h="200px">
                            <Spinner />
                        </Flex>
                    ) : availablePOs.length === 0 ? (
                        <Center py={10}>
                            <VStack spacing={4}>
                                <Icon as={FiPackage} w={12} h={12} color="gray.400" />
                                <Text fontSize="lg" fontWeight="medium" color="gray.600">
                                    No hay órdenes de compra disponibles
                                </Text>
                                <Text color="gray.500" textAlign="center" maxW="md">
                                    No se encontraron órdenes de compra para este proveedor. 
                                    Las órdenes de compra aparecerán aquí una vez que se generen en el sistema.
                                </Text>
                            </VStack>
                        </Center>
                    ) : filteredPOs.length === 0 ? (
                        <Center py={10}>
                            <VStack spacing={4}>
                                <Icon as={SearchIcon} w={12} h={12} color="gray.400" />
                                <Text fontSize="lg" fontWeight="medium" color="gray.600">
                                    No se encontraron resultados
                                </Text>
                                <Text color="gray.500" textAlign="center">
                                    No hay órdenes de compra que coincidan con tu búsqueda.
                                    Intenta con otros términos.
                                </Text>
                            </VStack>
                        </Center>
                    ) : (
                        <VStack spacing={4} align="stretch" maxH="600px" overflowY="auto">
                            {filteredPOs.map(renderPurchaseOrder)}
                        </VStack>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}; 