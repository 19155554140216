import React, { useEffect } from "react";
import {
    Tr,
    Td,
    Text,
    Select,
    Box,
    IconButton,
    Button,
    useDisclosure,
    Tooltip
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { Select as ChakraSelect, GroupBase, CreatableSelect, SingleValue } from "chakra-react-select";
import { InvoiceItem } from "@/types/invoice.types";
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { StatusBadge } from "../../StatusBadge";
import { formatCurrency, getMissingConfigs } from "../../utils";
import { useErpForm } from "@/context/shared/erpFormContext";
import { CostCenterOption } from "@/types/erpShared.types";
import { AccountOption } from "@/types/invoiceDetail.types";
import { useInvoicesHandler } from "@/context/shared/invoicesHandlerContext";
import { selectStyles } from "@/components/ui/SelectStyles";
import { ErpProductOption } from "@/types/erpProducts.types";
import { TaxesModal } from "./TaxesModal";

interface ERPTableRowProps {
    item: InvoiceItem;
    index: number;
    editableAccounts: { [key: string]: boolean };
    toggleAccountEditable: (accountKey: string) => void;
}

export const ERPTableRow: React.FC<ERPTableRowProps> = ({
    item,
    index,
    editableAccounts,
    toggleAccountEditable,
}) => {


    const {
        invoice,
        setInvoice,
        erpProductsOptions,
        erpTaxesOptions,
        handleTaxChange,
        handleTaxAmountChange,
        services,
    } = useInvoiceDetails();

    const {
        accountsHook,
        costCentersHook,
    } = useErpForm();

    const erpService = services?.erp;

    const { useCostCenterPerItem } = useInvoicesHandler();

    const taxes = Object.keys(item.taxes);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isHelisa = erpService?.toLowerCase() === 'helisa';

    // Check if the invoice is already uploaded to the ERP system
    // If the invoice is processing, we should allow editing
    const isProcessing = invoice?.uploads?.siigo?.consecutive === "Procesando";
    const isInvoiceUploaded = (invoice?.uploads?.siigo?.uploaded || invoice?.uploads?.helisa?.uploaded) && !isProcessing;

    useEffect(() => {
        if (erpTaxesOptions?.length && erpTaxesOptions.length > 0) {
            const newItems = [...invoice.items];
            let hasChanges = false;

            taxes.forEach((taxName) => {
                const currentTax = item.taxes[taxName];
                const matchingErpTax = erpTaxesOptions.find(opt => opt.value === currentTax?.tax_erp_id);

                // Ensure tax_erp_percentage is set from the matching tax option
                if (matchingErpTax && (!currentTax.tax_erp_percentage || currentTax.tax_erp_percentage !== matchingErpTax.percentage)) {
                    newItems[index].taxes[taxName] = {
                        ...currentTax,
                        tax_erp_percentage: matchingErpTax.percentage || 0
                    };
                    hasChanges = true;
                }

                // This code automatically sets use_as_cost_value to true for non-IVA taxes if:
                // 1. The matching ERP tax option has use_as_cost_value=true 
                // 2. The current tax in the invoice item doesn't have use_as_cost_value set yet
                // This ensures taxes that should be treated as cost values are properly marked
                if (matchingErpTax?.use_as_cost_value === true && !currentTax.use_as_cost_value) {
                    newItems[index].taxes[taxName] = {
                        ...newItems[index].taxes[taxName],
                        use_as_cost_value: true
                    };
                    hasChanges = true;
                }
            });

            if (hasChanges) {
                setInvoice({ ...invoice, items: newItems });
            }
        }
    }, [erpTaxesOptions, item.taxes, erpService]);

    useEffect(() => {
        if (erpService?.toLowerCase() === 'helisa' && item.erp_type !== 'account') {
            const newItems = [...invoice.items];
            newItems[index] = {
                ...item,
                erp_type: 'account',
                erp_product: undefined
            };
            setInvoice({ ...invoice, items: newItems });
        }
    }, [erpService, item.erp_type]);

    useEffect(() => {
        // map invoice item's taxes to erpTaxesOptions
        Object.keys(item.taxes).forEach(tax => {
            const matchedTax = erpTaxesOptions?.find(opt => opt.label === tax);
            if (matchedTax) item.taxes[tax].tax_erp_id = matchedTax.value;
        });
    }, [erpTaxesOptions, item.taxes]);

    const missingConfigs = getMissingConfigs(item, { erp: erpService });
    const isMissingERPType = missingConfigs.erp.includes('erp_type');
    const isMissingERPProduct = item.erp_type === 'product' && missingConfigs.erp.includes('erp_product');
    const isMissingERPAccount = missingConfigs.erp.includes('erp_account');
    const hasUnconfiguedTaxes = taxes.length > 0 && taxes.some(taxName =>
        !item.taxes[taxName]?.tax_erp_id || !item.taxes[taxName]?.tax_account);

    const handleApplyConfigClick = (index: number) => {
        const sourceItem = invoice.items[index];
        const newItems = invoice.items.map((item, idx) => {
            if (idx === index) return item; // Skip the source item
            return {
                ...item,
                erp_type: sourceItem.erp_type,
                erp_product: sourceItem.erp_product,
                erp_account: sourceItem.erp_account,
                cost_center: sourceItem.cost_center,
                taxes: Object.keys(sourceItem.taxes).reduce((acc, taxName) => {
                    if (item.taxes[taxName]) {
                        acc[taxName] = {
                            ...item.taxes[taxName],
                            tax_erp_id: sourceItem.taxes[taxName].tax_erp_id,
                            tax_account: sourceItem.taxes[taxName].tax_account,
                            tax_erp_percentage: sourceItem.taxes[taxName].tax_erp_percentage,
                            use_as_cost_value: sourceItem.taxes[taxName].use_as_cost_value
                        };
                    }
                    return acc;
                }, { ...item.taxes })
            };
        });
        setInvoice({ ...invoice, items: newItems });
    };

    return (
        <>
            <Tr
                _hover={{ bg: "gray.50" }}
                transition="background-color 0.2s"
            >
                <Td px={4} py={3}>
                    <Text fontSize="sm" color="gray.700">{item.description}</Text>
                </Td>
                {!isHelisa && (
                    <>
                        <Td px={4} py={3}>
                            <Box minWidth="150px" maxWidth="250px">
                                <ChakraSelect
                                    value={item.erp_type ? { label: item.erp_type === 'product' ? 'Producto' : 'Cuenta Contable', value: item.erp_type } : null}
                                    placeholder="Seleccione tipo"
                                    onChange={(selectedOption) => {
                                        const newItems = [...invoice.items];
                                        newItems[index] = {
                                            ...item,
                                            erp_type: selectedOption?.value as 'product' | 'account',
                                            erp_product: undefined,
                                            erp_account: undefined
                                        };
                                        setInvoice({ ...invoice, items: newItems });
                                    }}
                                    size="sm"
                                    options={[
                                        { value: 'product', label: 'Producto' },
                                        { value: 'account', label: 'Cuenta Contable' }
                                    ]}
                                    isDisabled={isInvoiceUploaded}
                                    isInvalid={!item.erp_type}
                                    errorBorderColor='red.100'
                                    menuPosition="fixed"
                                    chakraStyles={selectStyles}
                                />
                            </Box>
                        </Td>
                        {invoice.items.some(item => item.erp_type === 'product') && (
                            <Td px={4} py={3}>
                                {item.erp_type === 'product' && (
                                    <Box minWidth="150px" maxWidth="250px">
                                        <ChakraSelect
                                            value={item.erp_product ? {
                                                label: erpProductsOptions?.find(opt => opt.value === item.erp_product)?.label || '',
                                                value: item.erp_product
                                            } : null}
                                            options={erpProductsOptions}
                                            onChange={(selectedOption: SingleValue<ErpProductOption>) => {
                                                console.log('selectedOption', selectedOption);
                                                const newItems = [...invoice.items];
                                                newItems[index] = {
                                                    ...item,
                                                    erp_product: selectedOption?.value || '',
                                                    erp_type: 'product',
                                                    erp_account: selectedOption?.accountNumber || undefined
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                            placeholder="Seleccionar..."
                                            menuPosition="fixed"
                                            isLoading={!erpProductsOptions?.length}
                                            isDisabled={isInvoiceUploaded}
                                            size="sm"
                                            chakraStyles={selectStyles}
                                        />
                                    </Box>
                                )}
                            </Td>
                        )}
                    </>
                )}
                <Td px={4} py={3}>
                    <Box position="relative" minWidth="150px">
                        <CreatableSelect<AccountOption, false, GroupBase<AccountOption>>
                            value={item.erp_account ? {
                                label: accountsHook.accountOptions?.find(opt => opt.value === item.erp_account)?.label || '',
                                value: item.erp_account
                            } : null}
                            options={accountsHook.accountOptions}
                            onChange={(newValue) => {
                                const newItems = [...invoice.items];
                                const accountNumber = newValue?.value || '';

                                // get the full account data from the accountOptions
                                const selectedAccount = accountsHook.accountOptions?.find(opt => opt.value === accountNumber);
                                const accountData = selectedAccount?.data;

                                // update the account number
                                newItems[index] = {
                                    ...item,
                                    erp_account: accountNumber,
                                };

                                // Check if the account has taxes associated with it
                                if (accountData?.taxes && Object.keys(accountData.taxes).length > 0) {
                                    // Get the current taxes from the item
                                    const itemTaxes = { ...item.taxes };

                                    // For each tax in the item, check if there's a matching tax in the account
                                    Object.keys(itemTaxes).forEach(taxName => {
                                        if (accountData.taxes[taxName]) {
                                            // Use the tax data from the account
                                            itemTaxes[taxName] = {
                                                ...itemTaxes[taxName],
                                                tax_erp_id: accountData.taxes[taxName].tax_erp_id || itemTaxes[taxName].tax_erp_id,
                                                tax_account: accountData.taxes[taxName].tax_account || itemTaxes[taxName].tax_account,
                                                tax_erp_percentage: accountData.taxes[taxName].tax_erp_percentage || itemTaxes[taxName].tax_erp_percentage,
                                                use_as_cost_value: accountData.taxes[taxName].use_as_cost_value || itemTaxes[taxName].use_as_cost_value
                                            };
                                        }
                                    });

                                    // Update the taxes in the item
                                    newItems[index].taxes = itemTaxes;
                                }

                                setInvoice({ ...invoice, items: newItems });
                            }}
                            onCreateOption={(inputValue) => {
                                if (!/^\d+$/.test(inputValue)) {
                                    return;
                                }
                                const newItems = [...invoice.items];
                                newItems[index] = {
                                    ...item,
                                    erp_account: inputValue,
                                };
                                setInvoice({ ...invoice, items: newItems });
                            }}
                            placeholder="Seleccionar..."
                            menuPosition="fixed"
                            isLoading={!accountsHook.accountOptions?.length}
                            isDisabled={isInvoiceUploaded}
                            isSearchable
                            formatCreateLabel={(input: string) => `Usar cuenta: ${input}`}
                            size="sm"
                            chakraStyles={selectStyles}
                        />
                    </Box>
                </Td>
                {useCostCenterPerItem && (erpService === 'helisa' || invoice.invoice_type_name === 'CC') && (
                    <Td px={4} py={3} minWidth="200px" maxWidth="250px" maxHeight="32px">
                        <CreatableSelect<CostCenterOption, false, GroupBase<CostCenterOption>>
                            value={item.cost_center ? {
                                label: costCentersHook.costCenters?.find(opt => opt.value === Number(item.cost_center))?.label || '',
                                value: item.cost_center
                            } : null}
                            options={costCentersHook.costCenters}
                            onChange={(newValue) => {
                                const newItems = [...invoice.items];
                                newItems[index] = {
                                    ...item,
                                    cost_center: String(newValue?.value) || ''
                                };
                                setInvoice({ ...invoice, items: newItems });
                            }}
                            onCreateOption={(inputValue) => {
                                const newItems = [...invoice.items];
                                newItems[index] = {
                                    ...item,
                                    cost_center: inputValue
                                };
                                setInvoice({ ...invoice, items: newItems });
                            }}
                            placeholder="Seleccionar..."
                            menuPosition="fixed"
                            isDisabled={isInvoiceUploaded}
                            isInvalid={!item.cost_center}
                            isSearchable
                            formatCreateLabel={(input: string) => `Usar centro de costo: ${input}`}
                            size="sm"
                            errorBorderColor='red.100'
                            chakraStyles={selectStyles}
                        />
                    </Td>
                )}
                <Td px={4} py={3} isNumeric>
                    <Text fontSize="sm" color="gray.700">{item.quantity}</Text>
                </Td>
                <Td px={4} py={3} isNumeric>
                    <Text fontSize="sm" color="gray.700">{`$ ${formatCurrency(item.price)}`}</Text>
                </Td>
                <Td py={3}>
                    <Box display="flex" alignItems="center" gap={4}>
                        {taxes.length > 0 ? (
                            taxes.every(taxName =>
                                item.taxes[taxName]?.use_as_cost_value || (item.taxes[taxName]?.tax_erp_id && item.taxes[taxName]?.tax_account)
                            ) ? (
                                <Button
                                    onClick={onOpen}
                                    size="sm"
                                    colorScheme="green"
                                    variant="solid"
                                    _hover={{ transform: "translateY(-1px)", boxShadow: "sm" }}
                                    transition="all 0.2s"
                                >
                                    Ver
                                </Button>
                            ) : (
                                <Button
                                    onClick={onOpen}
                                    size="sm"
                                    colorScheme="red"
                                    variant="solid"
                                    isDisabled={isInvoiceUploaded}
                                    _hover={{ transform: "translateY(-1px)", boxShadow: "sm" }}
                                    transition="all 0.2s"
                                >
                                    Configurar
                                </Button>
                            )
                        ) : (
                            <Text fontSize="sm" color="gray.500">No hay impuestos</Text>
                        )}
                    </Box>
                </Td>
                <Td px={4} py={3}>
                    {!invoice.uploads?.siigo?.uploaded || isProcessing ? (
                        <StatusBadge
                            isReady={getMissingConfigs(item, { erp: erpService }).erp.length === 0}
                            pendingCount={getMissingConfigs(item, { erp: erpService }).erp.length}
                            pendingItems={getMissingConfigs(item, { erp: erpService }).erp}
                        />
                    ) : (
                        <Text fontSize="sm" color="gray.400"></Text>
                    )}
                </Td>
                <Td px={4} py={3}>
                    {invoice.items.length > 1 ? (
                        <Tooltip label="Copiar ajustes a otros items" aria-label="Copiar ">
                            <IconButton
                                aria-label="Aplicar configuración"
                                icon={<CopyIcon />}
                                size="sm"
                                variant="outline"
                                onClick={() => handleApplyConfigClick(index)}
                                isDisabled={isInvoiceUploaded}
                                colorScheme="blue"
                                _hover={{ transform: "translateY(-1px)", boxShadow: "sm" }}
                                transition="all 0.2s"
                            />
                        </Tooltip>
                    ) : (
                        <Text fontSize="sm" color="gray.400"></Text>
                    )}
                </Td>

            </Tr>

            <TaxesModal
                isOpen={isOpen}
                onClose={onClose}
                item={item}
                index={index}
                isInvoiceUploaded={isInvoiceUploaded}
            />
        </>
    );
}; 