import React from 'react';
import {
    Stack,
    Box,
    Text,
    Badge,
    HStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    useColorModeValue,
} from '@chakra-ui/react';
import { FileUploadSection } from '../FileUploadSection';

// Define props interface
interface WorldOfficeFormProps {
    stepData: any;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const WorldOfficeForm: React.FC<WorldOfficeFormProps> = ({
    stepData,
    handleFileChange,
}) => {
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const headerBg = useColorModeValue('gray.50', 'gray.700');

    return (
        <Stack spacing={4}>
            <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md" mb={3}>
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Cuentas Contables</Text>
                            {stepData?.credentials?.accountsFile && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <FileUploadSection
                                title="Cuentas Contables"
                                description="Este archivo nos permite entrenar a nuestra IA con el contexto de tus cuentas contables para predecir automáticamente la cuenta apropiada para cada ítem."
                                inputId="accounts-file"
                                fileName={stepData?.credentials?.accountsFile}
                                onFileChange={handleFileChange}
                                tutorialTitle="¿Cómo obtener el listado de cuentas en World Office? (Nota: Exportar como Excel)"
                                tutorialLink="https://worldoffice.com.co/ayuda/exportacion-plan-contable.html"
                                videoUrl=""
                                isVideoLoading={false}
                                onVideoLoaded={() => {}}
                                labelPrefix="Archivo de Cuentas Contables"
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md" mb={3}>
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Centros de Costo</Text>
                            {stepData?.credentials?.costCentersFile && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <FileUploadSection
                                title="Centros de Costo"
                                description="Este archivo nos permite entrenar a nuestra IA con el contexto de tus centros de costo para predecir automáticamente el centro de costo apropiado para cada factura."
                                inputId="cost-centers-file"
                                fileName={stepData?.credentials?.costCentersFile}
                                onFileChange={handleFileChange}
                                tutorialTitle="¿Cómo obtener el listado de centros de costo en World Office? (Nota: Puedes subir un archivo Excel o una captura de pantalla)"
                                tutorialLink="https://worldoffice.com.co/ayuda/centros-de-costo.html"
                                videoUrl=""
                                isVideoLoading={false}
                                onVideoLoaded={() => {}}
                                fileType="both"
                                labelPrefix="Archivo de Centros de Costo"
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md" mb={3}>
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Tipos de Documentos</Text>
                            {stepData?.credentials?.invoiceTypesFile && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <FileUploadSection
                                title="Tipos de documentos"
                                description="Este archivo nos permite entrenar a nuestra IA con el contexto de tus tipos de documentos para predecir automáticamente el tipo apropiado para cada factura."
                                inputId="invoice-types-file"
                                fileName={stepData?.credentials?.invoiceTypesFile}
                                onFileChange={handleFileChange}
                                tutorialTitle="¿Cómo obtener el listado de tipos de documentos en World Office?"
                                tutorialLink="https://worldoffice.com.co/ayuda/documentos-contables.html"
                                videoUrl=""
                                isVideoLoading={false}
                                onVideoLoaded={() => {}}
                                fileType="image"
                                labelPrefix="Archivo de Tipos de Documentos"
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md">
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Formas de Pago</Text>
                            {stepData?.credentials?.paymentMethodsFile && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <FileUploadSection
                                title="Formas de Pago"
                                description="Este archivo nos permite entrenar a nuestra IA con el contexto de tus formas de pago para procesar correctamente las transacciones."
                                inputId="payment-methods-file"
                                fileName={stepData?.credentials?.paymentMethodsFile}
                                onFileChange={handleFileChange}
                                tutorialTitle="¿Cómo obtener las formas de pago en World Office?"
                                tutorialLink="https://worldoffice.com.co/ayuda/formas-de-pago.html"
                                videoUrl=""
                                isVideoLoading={false}
                                onVideoLoaded={() => {}}
                                fileType="image"
                                labelPrefix="Archivo de Formas de Pago"
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Stack>
    );
}; 