import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { FAQSection } from '../FAQSection';

interface DefaultERPSelectionProps {
    videoURLs: {
        siigoContador: string;
        siigoNoContador: string;
    };
}

export const DefaultERPSelection: React.FC<DefaultERPSelectionProps> = ({ videoURLs }) => {
    return (
        <Box textAlign="center" color="gray.500" mt={8}>
            <Text fontSize="sm">Selecciona un sistema ERP para comenzar la configuración</Text>
            <FAQSection 
                videoUrls={{
                    siigoContador: videoURLs.siigoContador,
                    siigoNoContador: videoURLs.siigoNoContador
                }}
            />
        </Box>
    );
}; 