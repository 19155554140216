import { useState, useEffect } from 'react';
import {
    VStack,
    HStack,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    IconButton,
    Text,
    Box,
    Card,
    CardBody,
    FormHelperText,
    Icon,
    Flex,
    Select,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { FaMagic } from "react-icons/fa";


interface BusinessData {
    companyName: string;
    legalName: string;
    taxId: string;
    idType: string;
    legalRepresentativeId: string;
    description: string;
}

interface BusinessInformationProps {
    onDataChange: (data: BusinessData) => void;
    stepData: Partial<BusinessData>;
    isValid: boolean;
}

export const BusinessInformation: React.FC<BusinessInformationProps> = ({
    onDataChange,
    stepData,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState<Partial<BusinessData>>({});
    const [touchedFields, setTouchedFields] = useState<Set<keyof BusinessData>>(new Set());
    const [formData, setFormData] = useState<BusinessData>({
        companyName: stepData?.companyName || '',
        legalName: stepData?.legalName || '',
        taxId: stepData?.taxId || '',
        idType: stepData?.idType || '',
        legalRepresentativeId: stepData?.legalRepresentativeId || '',
        description: stepData?.description || '',
    });

    const validateField = (name: keyof BusinessData, value: string): string => {
        if (!value) return ''; // Don't validate empty fields

        switch (name) {
            case 'companyName':
            case 'legalName':
                return value.length < 3 ? 'Debe tener al menos 3 caracteres' : '';
            case 'taxId':
                return !/^\d{9,11}$/.test(value) ? 'NIT debe tener entre 9 y 11 dígitos' : '';
            case 'legalRepresentativeId':
                return !/^\d{6,11}$/.test(value) ? 'Número de identificación debe tener entre 6 y 11 dígitos' : '';
            case 'idType':
                return value === '' ? 'Debe seleccionar un tipo de identificación' : '';
            case 'description':
                return value.length < 20 ? 'La descripción debe tener al menos 20 caracteres' : '';
            default:
                return '';
        }
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const newFormData = {
            ...formData,
            [name]: value,
        };
        setFormData(newFormData);

        // Only validate if field has been touched
        if (touchedFields.has(name as keyof BusinessData)) {
            const error = validateField(name as keyof BusinessData, value);
            setErrors(prev => ({
                ...prev,
                [name]: error,
            }));
        }

        // Notify parent component
        onDataChange(newFormData);
    };

    const handleBlur = (name: keyof BusinessData) => {
        // Mark field as touched
        setTouchedFields(prev => new Set(prev).add(name));

        // Validate on blur
        const error = validateField(name, formData[name]);
        setErrors(prev => ({
            ...prev,
            [name]: error,
        }));
    };

    useEffect(() => {
        // Only validate fields that have initial values
        const newErrors: Partial<BusinessData> = {};
        Object.entries(formData).forEach(([key, value]) => {
            if (value) {
                const fieldName = key as keyof BusinessData;
                newErrors[fieldName] = validateField(fieldName, value);
                setTouchedFields(prev => new Set(prev).add(fieldName));
            }
        });
        setErrors(newErrors);
    }, []);

    const shouldShowError = (fieldName: keyof BusinessData) => {
        return touchedFields.has(fieldName) && !!errors[fieldName];
    };

    const inputStyles = {
        borderRadius: 'md',
        borderColor: 'gray.200',
        bg: 'white',
        h: '40px',
        _hover: {
            borderColor: 'gray.300',
        },
        _focus: {
            borderColor: 'blue.500',
            boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)',
        },
    };

    const textareaStyles = {
        ...inputStyles,
        h: 'auto',
        minH: '96px',
        py: 3,
        fontSize: 'sm',
    };

    return (
        <Box w="full" h="full" py={4}>
            <Card 
                h="full"
                maxW="1200px"
                mx="auto"
            >
                <CardBody 
                    py={8} 
                    px={6}
                    overflowY="auto"
                    maxH="70vh"
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            width: '6px',
                            background: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: 'gray.200',
                            borderRadius: '24px',
                        },
                    }}
                >
                    <Grid templateColumns="repeat(2, 1fr)" gap={20}>
                        {/* Información de la Empresa */}
                        <Box>
                            <Text
                                fontSize="lg"
                                fontWeight="bold"
                                color="gray.700"
                                mb={4}
                            >
                                Información de la Empresa
                            </Text>

                            <VStack align="stretch" spacing={6}>
                                <FormControl isInvalid={shouldShowError('companyName')}>
                                    <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="gray.700"
                                        mb={2}
                                    >
                                        Nombre de la Empresa
                                    </FormLabel>
                                    <Input
                                        name="companyName"
                                        value={formData.companyName}
                                        onChange={handleChange}
                                        onBlur={() => handleBlur('companyName')}
                                        placeholder="Ej: Cifrato"
                                        {...inputStyles}
                                    />
                                    <FormErrorMessage fontSize="xs">{errors.companyName}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={shouldShowError('legalName')}>
                                    <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="gray.700"
                                        mb={2}
                                    >
                                        Razón Social
                                    </FormLabel>
                                    <Input
                                        name="legalName"
                                        value={formData.legalName}
                                        onChange={handleChange}
                                        onBlur={() => handleBlur('legalName')}
                                        placeholder="Ej: Cifrato Soluciones Tecnológicas SAS"
                                        {...inputStyles}
                                    />
                                    <FormErrorMessage fontSize="xs">{errors.legalName}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={shouldShowError('description')}>
                                    <HStack spacing={2} mb={2}>
                                        <FormLabel
                                            fontSize="sm"
                                            fontWeight="medium"
                                            color="gray.700"
                                            m={0}
                                        >
                                            Contexto de la Empresa
                                        </FormLabel>
                                        <Icon as={FaMagic} color="purple.500" w={3} h={3} />
                                    </HStack>
                                    <Textarea
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        onBlur={() => handleBlur('description')}
                                        placeholder="Describe detalladamente a qué se dedica tu empresa, qué productos o servicios ofrece, quiénes son sus principales clientes. Esta información ayudará a nuestra IA a clasificar mejor tus facturas como compras o gastos."
                                        {...textareaStyles}
                                    />
                                    <FormErrorMessage fontSize="xs">{errors.description}</FormErrorMessage>
                                </FormControl>
                            </VStack>
                        </Box>

                        {/* Información de la DIAN */}
                        <Box>
                            <Text 
                                fontSize="lg" 
                                fontWeight="bold"
                                color="gray.700"
                                mb={2}
                            >
                                Información de la DIAN
                            </Text>
                            <Text 
                                fontSize="sm" 
                                color="gray.600"
                                mb={5}
                            >
                                Esta información será utilizada para sincronizar con las facturas electrónicas de la DIAN y facilitar la gestión de tus documentos tributarios.
                            </Text>

                            <VStack align="stretch" spacing={6}>
                                <FormControl 
                                    isInvalid={shouldShowError('taxId')}
                                    mb={6}
                                >
                                    <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="gray.700"
                                        mb={2}
                                    >
                                        NIT Empresa
                                    </FormLabel>
                                    <Input
                                        name="taxId"
                                        value={formData.taxId}
                                        onChange={handleChange}
                                        onBlur={() => handleBlur('taxId')}
                                        placeholder="Ej: 900123456"
                                        {...inputStyles}
                                    />
                                    <FormErrorMessage fontSize="xs">{errors.taxId}</FormErrorMessage>
                                </FormControl>

                                <Grid templateColumns="1fr 2fr" gap={6} mb={4}>
                                    <FormControl isInvalid={shouldShowError('idType')}>
                                        <FormLabel
                                            fontSize="sm"
                                            fontWeight="medium"
                                            color="gray.700"
                                            mb={2}
                                        >
                                            Tipo de Identificación
                                        </FormLabel>
                                        <Select
                                            name="idType"
                                            value={formData.idType}
                                            onChange={handleChange}
                                            onBlur={() => handleBlur('idType')}
                                            placeholder="Seleccionar"
                                            {...inputStyles}
                                        >
                                            <option value="10910094">Cédula de Ciudadanía</option>
                                            <option value="10910096">Cédula de Extranjería</option>
                                            <option value="10910095">Tarjeta de Extranjería</option>
                                            <option value="10910092">Registro Civil</option>
                                            <option value="10910093">Tarjeta de Identidad</option>
                                            <option value="10910036">Certificado Registraduría sin identificación</option>
                                            <option value="10910098">Pasaporte</option>
                                            <option value="10910097">NIT</option>
                                            <option value="10910402">Nit de otro país</option>
                                            <option value="10910394">Documento de identificación extranjero</option>
                                            <option value="10910366">Tipo de documento desconocido</option>
                                            <option value="10910403">NIUP</option>
                                            <option value="10930954">PEP</option>
                                            <option value="10930955">PPT</option>
                                        </Select>
                                        <FormErrorMessage fontSize="xs">{errors.idType}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={shouldShowError('legalRepresentativeId')}>
                                        <FormLabel
                                            fontSize="sm"
                                            fontWeight="medium"
                                            color="gray.700"
                                            mb={2}
                                        >
                                            NIT Representante Legal
                                        </FormLabel>
                                        <Input
                                            name="legalRepresentativeId"
                                            value={formData.legalRepresentativeId}
                                            onChange={handleChange}
                                            onBlur={() => handleBlur('legalRepresentativeId')}
                                            placeholder="Ej: 1020304050"
                                            {...inputStyles}
                                        />
                                        <FormErrorMessage fontSize="xs">{errors.legalRepresentativeId}</FormErrorMessage>
                                    </FormControl>
                                </Grid>
                            </VStack>
                        </Box>
                    </Grid>
                </CardBody>
            </Card>
        </Box>
    );
};
