import React, { useState } from "react";
import {
    Text,
    Input,
    InputGroup,
    InputRightAddon,
    Button,
    Box,
    VStack,
    Tr,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    ModalFooter,
} from "@chakra-ui/react";
import { Select as ChakraSelect } from "chakra-react-select";
import { InvoiceItem } from "@/types/invoice.types";
import { conversionExamples, measurementExamples } from "@/utils/conversionsExamples";
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { optionsTaxes } from "@/utils/options";
import { InventoryItem } from "@/types/inventory.types";
import { POSConversionModal } from "./POSConversionModal";

interface POSTableRowProps {
    item: InvoiceItem;
    index: number;
    itemsOptions: { label: string, value: string }[];
    inventoryItems: InventoryItem[];
    handleItemChange: (selectedOption: { label: string, value: string }, index: number) => void;
    formatCurrency: (value: number) => string;
}


export const POSTableRow: React.FC<POSTableRowProps> = ({
    item,
    index,
    itemsOptions,
    inventoryItems,
    handleItemChange,
    formatCurrency,
}) => {
    const [currentExampleIndex, setCurrentExampleIndex] = useState<{ [key: string]: number }>({});
    const taxes = Object.keys(item.taxes);
    const { isOpen: isConversionOpen, onOpen: onConversionOpen, onClose: onConversionClose } = useDisclosure();
    const { isOpen: isTaxesOpen, onOpen: onTaxesOpen, onClose: onTaxesClose } = useDisclosure();

    const { invoice, setInvoice, handleTaxChange, handleTaxAmountChange } = useInvoiceDetails();
    
    // Check if the invoice is already uploaded to the POS system
    const isInvoiceUploaded = invoice?.uploads?.intuipos?.uploaded;

    const handleExampleNavigation = (type: string, direction: 'next' | 'back') => {
        setCurrentExampleIndex(prev => {
            const currentIndex = prev[type] || 0;
            const examples = type === 'conversion' ? conversionExamples : measurementExamples;

            if (direction === 'next') {
                return { ...prev, [type]: (currentIndex + 1) % examples.length };
            } else {
                return { ...prev, [type]: currentIndex === 0 ? examples.length - 1 : currentIndex - 1 };
            }
        });
    };

    const TaxesModal = () => (
        <Modal isOpen={isTaxesOpen} onClose={onTaxesClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="lg">Configuración de Impuestos</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <VStack align="stretch" spacing={4}>
                        {taxes.length > 0 && item.taxes[taxes[0]] && (
                            <Box>
                                <Text fontWeight="bold" fontSize="md" mb={4}>Impuesto 1</Text>
                                <VStack align="stretch" spacing={2}>
                                    <Box minWidth="150px" maxWidth="350px" height="42px">
                                        <ChakraSelect
                                            defaultValue={{ label: taxes[0] || '', value: taxes[0] || '' }}
                                            options={optionsTaxes}
                                            isDisabled={true}
                                            onChange={(selectedOption) => {
                                                if (selectedOption && selectedOption.label && selectedOption.value) {
                                                    handleTaxChange(selectedOption as { label: string; value: string }, index, 0);
                                                }
                                            }}
                                            menuPosition="fixed"
                                            size="md"
                                        />
                                    </Box>
                                    <InputGroup size="md">
                                        <Input
                                            value={formatCurrency(item.taxes[taxes[0]]?.tax_amount)}
                                            type="text"
                                            disabled={true}
                                            onChange={(e) => handleTaxAmountChange(e.target.value, index, taxes[0])}
                                        />
                                        <InputRightAddon>COP</InputRightAddon>
                                    </InputGroup>
                                </VStack>
                            </Box>
                        )}
                        {taxes.length > 1 && item.taxes[taxes[1]] && (
                            <Box>
                                <Text fontWeight="bold" fontSize="md" mb={4}>Impuesto 2</Text>
                                <VStack align="stretch" spacing={2}>
                                    <Box minWidth="150px" maxWidth="350px" height="42px">
                                        <ChakraSelect
                                            defaultValue={{ label: taxes[1] || '', value: taxes[1] || '' }}
                                            options={optionsTaxes}
                                            isDisabled={true}
                                            onChange={(selectedOption) => {
                                                if (selectedOption && selectedOption.label && selectedOption.value) {
                                                    handleTaxChange(selectedOption as { label: string; value: string }, index, 1);
                                                }
                                            }}
                                            menuPosition="fixed"
                                            size="md"
                                        />
                                    </Box>
                                    <InputGroup size="md">
                                        <Input
                                            value={formatCurrency(item.taxes[taxes[1]]?.tax_amount)}
                                            type="text"
                                            disabled={true}
                                            onChange={(e) => handleTaxAmountChange(e.target.value, index, taxes[1])}
                                        />
                                        <InputRightAddon>COP</InputRightAddon>
                                    </InputGroup>
                                </VStack>
                            </Box>
                        )}
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onTaxesClose} size="md" isDisabled={isInvoiceUploaded}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );

    return (
        <>
            <Tr
                _hover={{ bg: "gray.50" }}
                transition="background-color 0.2s"
            >
                <Td px={4} py={3}>
                    <Text fontSize="sm" color="gray.700">{item.description}</Text>
                </Td>
                <Td px={4} py={3}>
                    <Box minWidth="150px" maxWidth="250px">
                        <ChakraSelect
                            value={item.inventory_id ? {
                                label: item.inventory_item_name || '',
                                value: item.inventory_id
                            } : null}
                            options={itemsOptions}
                            onChange={(selectedOption: any) => {
                                handleItemChange(selectedOption, index);
                            }}
                            placeholder="Seleccionar..."
                            menuPosition="fixed"
                            isLoading={!itemsOptions?.length}
                            isDisabled={isInvoiceUploaded}
                            size="sm"
                            chakraStyles={{
                                container: (provided) => ({
                                    ...provided,
                                    borderRadius: "md",
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    borderColor: !item.inventory_item_name ? "red.500" : "gray.300",
                                    _hover: { borderColor: !item.inventory_item_name ? "red.300" : "gray.400" },
                                    _focus: { borderColor: "blue.500", boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)" },
                                })
                            }}
                        />
                    </Box>
                </Td>
                <Td px={4} py={3} isNumeric>
                    <Input
                        value={item.quantity}
                        type="number"
                        disabled={true}
                        size="sm"
                        fontSize="sm"
                        textAlign="right"
                        bg="gray.50"
                        borderColor="gray.300"
                        _hover={{ borderColor: "gray.400" }}
                        onChange={(e) => {
                            const newItems = [...invoice.items];
                            newItems[index] = {
                                ...item,
                                quantity: Number(e.target.value)
                            };
                            setInvoice({ ...invoice, items: newItems });
                        }}
                    />
                </Td>
                <Td px={4} py={3} isNumeric>
                    <InputGroup size="sm">
                        <Input
                            value={formatCurrency(item.price_per_unit)}
                            type="text"
                            disabled={true}
                            fontSize="sm"
                            textAlign="right"
                            bg="gray.50"
                            borderColor="gray.300"
                            _hover={{ borderColor: "gray.400" }}
                            onChange={(e) => {
                                const newItems = [...invoice.items];
                                const numericValue = Number(e.target.value.replace(/[^0-9.-]+/g, ''));
                                newItems[index] = {
                                    ...item,
                                    price_per_unit: numericValue
                                };
                                setInvoice({ ...invoice, items: newItems });
                            }}
                        />
                        <InputRightAddon 
                            children="COP"
                            bg="gray.100"
                            borderColor="gray.300"
                            fontSize="xs"
                            color="gray.600"
                        />
                    </InputGroup>
                </Td>
                <Td px={4} py={3} isNumeric>
                    <InputGroup size="sm">
                        <Input
                            value={formatCurrency(item.price)}
                            type="text"
                            disabled={true}
                            fontSize="sm"
                            textAlign="right"
                            bg="gray.50"
                            borderColor="gray.300"
                            _hover={{ borderColor: "gray.400" }}
                            onChange={(e) => {
                                const newItems = [...invoice.items];
                                const numericValue = Number(e.target.value.replace(/[^0-9.-]+/g, ''));
                                newItems[index] = {
                                    ...item,
                                    price: numericValue
                                };
                                setInvoice({ ...invoice, items: newItems });
                            }}
                        />
                        <InputRightAddon 
                            children="COP"
                            bg="gray.100"
                            borderColor="gray.300"
                            fontSize="xs"
                            color="gray.600"
                        />
                    </InputGroup>
                </Td>
                    <Td px={4} py={3}>
                        {item.inventory_item_name ? (
                            <Button
                                onClick={onConversionOpen}
                                size="sm"
                                colorScheme={item.converted_quantity ? "green" : "red"}
                                variant="solid"
                                isDisabled={isInvoiceUploaded}
                                _hover={{ transform: "translateY(-1px)", boxShadow: "sm" }}
                                transition="all 0.2s"
                            >
                                Configurar
                            </Button>
                        ) : (
                            <Text fontSize="sm" color="gray.500">
                                Seleccione un producto
                            </Text>
                        )}
                    </Td>
                
                <Td px={4} py={3}>
                    {taxes.length > 0 ? (
                        <Button
                            onClick={onTaxesOpen}
                            size="sm"
                            colorScheme="blue"
                            variant="solid"
                            isDisabled={isInvoiceUploaded}
                            _hover={{ transform: "translateY(-1px)", boxShadow: "sm" }}
                            transition="all 0.2s"
                        >
                            Ver Impuestos ({taxes.length})
                        </Button>
                    ) : (
                        <Text fontSize="sm" color="gray.500">
                            No hay impuestos
                        </Text>
                    )}
                </Td>
            </Tr>
            <POSConversionModal 
                isOpen={isConversionOpen}
                onClose={onConversionClose}
                item={item}
                index={index}
                invoice={invoice}
                setInvoice={setInvoice}
                inventoryItems={inventoryItems}
                currentExampleIndex={currentExampleIndex}
                handleExampleNavigation={handleExampleNavigation}
                formatCurrency={formatCurrency}
            />
            <TaxesModal />
        </>
    );
}; 