import React from 'react';
import { 
  Box, 
  Button, 
  Container, 
  Stack, 
  Heading, 
  Text, 
  useColorModeValue 
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import HeroIllustration from './HeroIllustration';
import { containerVariants, itemVariants, floatingAnimation } from './animations';

// Create motion components from Chakra components
const MotionBox = motion(Box);
const MotionStack = motion(Stack);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionButton = motion(Button);

const HeroSection = () => {
  // Decorative elements animation
  const decorElements = [
    { top: "15%", left: "10%", delay: 0, size: "80px", opacity: 0.1 },
    { top: "60%", left: "5%", delay: 0.5, size: "50px", opacity: 0.08 },
    { top: "75%", right: "15%", delay: 0.8, size: "70px", opacity: 0.07 },
    { top: "25%", right: "10%", delay: 0.3, size: "60px", opacity: 0.06 },
  ];

  return (
    <Box 
      bg={useColorModeValue('blue.50', 'blue.900')} 
      pt={{ base: 20, md: 28 }} 
      pb={{ base: 16, md: 24 }}
      mt="60px"
      position="relative"
      overflow="hidden"
    >
      {/* Decorative elements */}
      {decorElements.map((elem, index) => (
        <MotionBox
          key={index}
          position="absolute"
          width={elem.size}
          height={elem.size}
          bg="blue.500"
          rounded="full"
          opacity={elem.opacity}
          filter="blur(40px)"
          style={{ top: elem.top, left: elem.left, right: elem.right }}
          initial={{ opacity: 0 }}
          animate={{ 
            opacity: elem.opacity,
            x: [0, 10, 0],
            y: [0, 15, 0],
          }}
          transition={{
            delay: elem.delay,
            duration: 6,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
      
      <Container maxW="container.xl" position="relative" zIndex={1}>
        <MotionStack
          direction={{ base: 'column', md: 'row' }}
          spacing={10}
          align="center"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <MotionStack spacing={6} maxW="lg" variants={containerVariants}>
            <MotionHeading 
              as="h1" 
              size="2xl" 
              fontWeight="bold"
              lineHeight="1.2"
              color={useColorModeValue('blue.600', 'blue.200')}
              variants={itemVariants}
            >
              Revoluciona tu Firma Contable con Inteligencia Artificial
            </MotionHeading>
            <MotionText 
              fontSize="xl" 
              color={useColorModeValue('gray.600', 'gray.400')}
              variants={itemVariants}
            >
              Cifrato transforma la contabilidad tradicional con IA avanzada, aumentando la eficiencia, 
              reduciendo costos y ayudándote a atraer más clientes.
            </MotionText>
            <MotionBox variants={itemVariants}>
              <Stack direction={{ base: 'column', sm: 'row' }} spacing={4}>
                <MotionButton 
                  size="lg" 
                  colorScheme="blue" 
                  px={8}
                  as={RouterLink}
                  to="/login"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Comenzar Ahora
                </MotionButton>
                <MotionButton 
                  size="lg" 
                  variant="outline" 
                  colorScheme="blue"
                  rightIcon={<FaArrowRight />}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    window.location.href = 'https://cal.com/juan-pisco/cifrato-demo';
                  }}
                >
                  Solicitar Demo
                </MotionButton>
              </Stack>
            </MotionBox>
          </MotionStack>
          
          <Box 
            w={{ base: 'full', md: '50%' }} 
            h={{ base: '300px', md: '400px' }}
            position="relative"
          >
            <HeroIllustration animate={floatingAnimation} />
          </Box>
        </MotionStack>
      </Container>
    </Box>
  );
};

export default HeroSection; 