import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { TabList, Tab, HStack, Image, Text } from "@chakra-ui/react";

export function InvoiceTabsHeader() {
  const { services } = useInvoiceDetails();

  return (
    <TabList
      mb="1.5em"
      gap={4}
      px={2}
      py={1}
      bg="gray.50"
      borderRadius="full"
      borderWidth="1px"
      borderColor="gray.200"
    >
      <>
        {services?.pos && (
          <Tab
            fontSize="md"
            fontWeight="semibold"
            _selected={{
              color: "blue.600",
              bg: "white",
              boxShadow: "md",
              transform: "translateY(-1px)",
              borderWidth: "1px",
              borderColor: "gray.200",
              _hover: { bg: "white" }
            }}
            _hover={{
              bg: "gray.100"
            }}
            transition="all 0.2s"
            borderRadius="full"
            px={6}
          >
            <HStack spacing={2} align="center">
              <Image src="/intuipos_icon.png" alt="Intuipos" boxSize="20px" />
              <Text>Intuipos</Text>
            </HStack>
          </Tab>
        )}
        {services?.erp && (
          <Tab
            fontSize="md"
            fontWeight="semibold"
            _selected={{
              color: "blue.600",
              bg: "white",
              boxShadow: "md",
              transform: "translateY(-1px)",
              borderWidth: "1px",
              borderColor: "gray.200",
              _hover: { bg: "white" }
            }}
            _hover={{
              bg: "gray.100"
            }}
            transition="all 0.2s"
            borderRadius="full"
            px={6}
          >
            <HStack spacing={2} align="center">
              <Image
                src={services.erp === 'helisa' ? "/helisa_logo.png" : "/siigo_blue_icon.png"}
                alt={services.erp}
                boxSize="20px"
              />
              <Text>{services.erp === 'helisa' ? 'Helisa' : 'Siigo'}</Text>
            </HStack>
          </Tab>
        )}
      </>
    </TabList>
  );
}
