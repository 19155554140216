import { useState, useEffect, useCallback } from 'react';
import { ErpHandler } from '@/services/erp/erpHandler.interface';
import { AccountData, AccountOption } from '@/types/invoiceDetail.types';
import { Invoice } from '@/types/invoice.types';

export const usePaymentAccounts = (
    erpHandler: ErpHandler | null,
    selectedInvoices: Invoice[],
    setSelectedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>,
) => {
    // State for accounts
    const [accountsData, setAccountsData] = useState<Record<string, AccountData>>({});
    const [accountOptions, setAccountOptions] = useState<AccountOption[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<AccountOption | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Load accounts for payment methods
    useEffect(() => {
        if (!erpHandler) return;

        const loadAccounts = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const paymentAccounts = await erpHandler.getAccounts();
                setAccountsData(paymentAccounts);

                const paymentOptions = Object.entries<AccountData>(paymentAccounts).map(([key, data]) => ({
                    label: `${key} - ${data.name}`,
                    value: key,
                    data: data
                }));

                setAccountOptions(paymentOptions);

                // Initialize account if it exists in the invoice
                if (selectedInvoices[0]?.account_number && paymentOptions.length > 0) {
                    const accountValue = String(selectedInvoices[0]?.account_number);

                    const account = paymentOptions.find((a: AccountOption) =>
                        a.value === accountValue ||
                        String(a.value) === accountValue
                    );

                    if (account) {
                        setSelectedAccount(account);
                    } 
                }
            } catch (error) {
                console.error('usePaymentAccounts: Error loading accounts:', error);
                setError('Error loading payment accounts');
            } finally {
                setIsLoading(false);
            }
        };

        loadAccounts();
    }, [erpHandler]);

    const updateSelectedInvoices = useCallback((account: AccountOption) => {
        if (selectedInvoices.length > 0) {
            setSelectedInvoices(prevInvoices => {
                const updatedInvoices = prevInvoices.map(function(invoice) {
                    if (erpHandler?.erp === 'helisa') {
                        return {
                            ...invoice,
                            account_number: account.value,
                            payment_method: {
                                id: account.value,
                                name: account.data?.name || ''
                            }
                        }
                    }
                    return {
                        ...invoice,
                        account_number: account.value,
                    }
                });
                return updatedInvoices;
            });
        }
    }, [selectedInvoices.length, setSelectedInvoices]);

    // Handle account change
    const handleAccountChange = useCallback((option: AccountOption) => {
        setSelectedAccount(option);

        // Call the callback to update the invoice if it is available
        updateSelectedInvoices(option);
    }, [updateSelectedInvoices]);

    // Modified version of setSelectedAccount that also notifies changes
    const updateSelectedAccount = useCallback((option: AccountOption | null) => {
        setSelectedAccount(option);

        // If there is a value and the callback exists, notify the change
        if (option) updateSelectedInvoices(option);
    }, [updateSelectedInvoices]);

    return {
        accountsData,
        accountOptions,
        selectedAccount,
        isLoading,
        error,
        handleAccountChange,
        setSelectedAccount: updateSelectedAccount
    };
};
