import {
    Box,
    Button,
    Flex,
    Icon,
    List,
    ListItem,
    Text,
    VStack,
    Image,
    IconProps,
    ComponentWithAs,
} from "@chakra-ui/react"
import { NavLink, useLocation } from "react-router-dom"
import { FaCog, FaCreditCard, FaFileInvoice, FaMoneyBill, FaUserCog } from "react-icons/fa";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { IconType } from "react-icons/lib";

interface NavButtonProps {
    to: string;
    icon: IconType | ComponentWithAs<"svg", IconProps>;
    children: React.ReactNode;
    disabled?: boolean;
}

const NavButton: React.FC<NavButtonProps> = ({ to, icon, children, disabled }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <NavLink to={disabled ? "#" : to} style={{ width: '100%' }}>
            <Button
                width="100%"
                height="40px"
                variant="ghost"
                justifyContent="flex-start"
                bg={isActive ? 'whiteAlpha.900' : 'transparent'}
                color={isActive ? 'blue.500' : disabled ? 'whiteAlpha.900' : 'whiteAlpha.900'}
                _hover={{
                    bg: isActive ? 'whiteAlpha.900' : disabled ? 'transparent' : 'blue.500',
                    transform: disabled ? 'none' : 'translateX(2px)',
                }}
                _active={{
                    bg: isActive ? 'whiteAlpha.900' : disabled ? 'transparent' : 'whiteAlpha.200',
                }}
                transition="all 0.15s ease"
                borderRadius="md"
                px={3}
                fontSize="sm"
                isDisabled={disabled}
            >
                <Flex align="center" width="100%">
                    <Icon
                        as={icon}
                        boxSize="16px"
                        mr={2.5}
                    />
                    <Text fontWeight={isActive ? "500" : "400"}>
                        {children}
                    </Text>
                </Flex>
            </Button>
        </NavLink>
    );
};

export default function Sidebar({ showExtra }: { showExtra: boolean }) {
    const navItems = [
        { to: "/dashboard", icon: FaFileInvoice, label: "Facturas" },
        ...(showExtra ? [
            { to: "/dashboard/purchase_sales", icon: ExternalLinkIcon, label: "Compras y Ventas" },
        ] : []),
        { to: "/dashboard/dian", icon: FaMoneyBill, label: "DIAN" },
        { to: "#", icon: FaCreditCard, label: "Pagos (Pronto)", disabled: true },
        { to: "#", icon: FaCog, label: "Suscripción (Pronto)", disabled: true },
        { to: "#", icon: FaUserCog, label: "Cuenta (Pronto)", disabled: true },
    ];

    return (
        <Box py={3}>
            <VStack spacing={6} align="start">
                <Flex align="center" justify="center">
                    <Box pl={5} mb={1}>
                        <Image
                            src="/logo_white.png"
                            alt="Cifrato"
                            width="40px"
                            height="40px"
                            mx="auto"
                        />
                    </Box>

                    <Box px={2}>
                        <Text
                            color="white"
                            fontSize="lg"
                            fontWeight="600"
                            letterSpacing="wide"
                        >
                            Cifrato
                        </Text>
                    </Box>
                </Flex>


                <List spacing={1}>
                    {navItems.map((item, index) => (
                        <ListItem key={index} px={3}>
                            <NavButton
                                to={item.to}
                                icon={item.icon}
                                disabled={item.disabled}
                            >
                                {item.label}
                            </NavButton>
                        </ListItem>
                    ))}
                </List>
            </VStack>
        </Box>
    )
}
