import React from 'react';
import {
    Box,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    VStack,
} from '@chakra-ui/react';

interface FAQSectionProps {
    videoUrls: {
        siigoContador: string;
        siigoNoContador: string;
    };
}

export const FAQSection: React.FC<FAQSectionProps> = ({ videoUrls }) => {
    return (
        <Box mt={6} maxW="600px" mx="auto" textAlign="left">
            <Text fontSize="md" fontWeight="medium" mb={3} color="gray.700">Preguntas Frecuentes</Text>
            <Accordion allowToggle>
                <AccordionItem borderWidth="1px" borderRadius="md" mb={2}>
                    <h2>
                        <AccordionButton py={2}>
                            <Box flex="1" textAlign="left" fontWeight="medium" fontSize="sm">
                                ¿Cómo puedo saber si tengo Siigo Contador o Siigo Emprendedor?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} pt={3} px={4} fontSize="sm">
                        <Text fontWeight="medium">
                            La forma más sencilla de verificar tu versión de Siigo es:
                        </Text>
                        <VStack align="stretch" mt={3} spacing={2.5}>
                            <Text>
                                1. Ve a <strong>Configuraciones</strong> en el menú principal
                            </Text>
                            <Text>
                                2. Selecciona <strong>Contrato siigo nube</strong>
                            </Text>
                            <Text>
                                3. Verifica el nombre de tu plan:
                                <Box pl={4} mt={1.5}>
                                    • Si aparece <strong>"Nube Contador"</strong>, debes seleccionar <strong>Siigo Nube (contador) </strong> en Cifrato
                                    <br />
                                    <Box mt={1}>
                                    • Si aparece <strong>"Siigo Premium"</strong>, <strong>"Siigo Emprendedor"</strong> o <strong>"Siigo Profesional Independiente"</strong>, 
                                    debes seleccionar <strong>Siigo Nube (Profesional Independiente | Emprendedor | Premium)</strong> en Cifrato
                                    </Box>
                                </Box>
                            </Text>
                        </VStack>
                        
                        <Box mt={5} mb={4}>
                            <Text fontWeight="medium" mb={2.5} fontSize="sm">Tutorial: Siigo Contador</Text>
                            <Box
                                position="relative" 
                                h="220px" 
                                borderRadius="md"
                                overflow="hidden"
                                boxShadow="sm"
                            >
                                <video 
                                    src={videoUrls.siigoContador}
                                    controls
                                    autoPlay
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover' }}
                                    preload="metadata"
                                />
                            </Box>
                        </Box>
                        
                        <Box mb={5}>
                            <Text fontWeight="medium" mb={2.5} fontSize="sm">Tutorial: Siigo Emprendedor ~ Siigo Premium ~ Siigo Profesional Independiente</Text>
                            <Box
                                position="relative" 
                                h="220px" 
                                borderRadius="md"
                                overflow="hidden"
                                boxShadow="sm"
                            >
                                <video 
                                    src={videoUrls.siigoNoContador}
                                    controls
                                    autoPlay
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover' }}
                                    preload="metadata"
                                />
                            </Box>
                        </Box>
                        
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    );
}; 