import React, { useState } from "react";
import { FaChevronDown, FaChevronRight, FaCheck, FaExclamationTriangle, FaRegClock } from "react-icons/fa";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Icon,
    Box,
    Collapse,
    HStack,
} from "@chakra-ui/react";
import { Invoice, InvoiceValidations, TaxxaValidation } from "@/types/invoice.types";
import { formatCurrency } from "@/utils/currencyFormat";
import { useInvoicesHandler } from "@/context/shared/invoicesHandlerContext";

interface InvoiceDetailsDianAcknowledgementModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const InvoiceRow: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
    const [isOpen, setIsOpen] = useState(false);

    const getTaxxaStatus = (validations?: InvoiceValidations) => {
        if (!validations?.taxxa) return 'pending';

        const taxxa = validations.taxxa;
        const processes = ['acuse_recibo', 'recibo_de_bienes', 'aceptacion_expresa'];
        const completedProcesses = processes.filter(p => taxxa[p as keyof TaxxaValidation]);

        if (completedProcesses.length === 0) return 'pending';
        if (completedProcesses.length === processes.length) return 'completed';
        return 'partial';
    };

    return (
        <>
            <Tr
                cursor="pointer"
                onClick={() => setIsOpen(!isOpen)}
                _hover={{ bg: "gray.50" }}
            >
                <Td width="40px">
                    <Icon
                        as={isOpen ? FaChevronDown : FaChevronRight}
                        color="gray.500"
                    />
                </Td>
                <Td>{invoice.invoice_id}</Td>
                <Td>{invoice.sender_party_name}</Td>
                <Td>{new Date(invoice.issue_date + 'T00:00:00').toLocaleDateString()}</Td>
                <Td>
                    <HStack spacing={2}>
                        <Icon
                            as={
                                getTaxxaStatus(invoice.validations) === 'completed' ? FaCheck :
                                    getTaxxaStatus(invoice.validations) === 'partial' ? FaExclamationTriangle :
                                        FaRegClock
                            }
                            color={
                                getTaxxaStatus(invoice.validations) === 'completed' ? "green.500" :
                                    getTaxxaStatus(invoice.validations) === 'partial' ? "orange.500" :
                                        "gray.500"
                            }
                        />
                        <Text fontSize="sm" color={
                            getTaxxaStatus(invoice.validations) === 'completed' ? "green.500" :
                                getTaxxaStatus(invoice.validations) === 'partial' ? "orange.500" :
                                    "gray.500"
                        }>
                            {getTaxxaStatus(invoice.validations) === 'completed' ? "Completado" :
                                getTaxxaStatus(invoice.validations) === 'partial' ? "Parcial" :
                                    "Pendiente"}
                        </Text>
                    </HStack>
                </Td>
            </Tr>
            <Tr>
                <Td colSpan={5} p={0}>
                    <Collapse in={isOpen} animateOpacity>
                        <Box
                            p={5}
                            bg="gray.50"
                            borderBottom="1px"
                            borderColor="gray.200"
                        >
                            <Table size="sm" variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Producto</Th>
                                        <Th isNumeric>Cantidad</Th>
                                        <Th isNumeric>Precio unitario</Th>
                                        <Th isNumeric>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {invoice.items.map((item, index) => (
                                        <Tr key={index}>
                                            <Td>{item.description}</Td>
                                            <Td isNumeric>{item.quantity} {item.unit}</Td>
                                            <Td isNumeric>{formatCurrency(item.price_per_unit.toString())}</Td>
                                            <Td isNumeric>{formatCurrency((item.quantity * item.price_per_unit).toString())}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                    </Collapse>
                </Td>
            </Tr>
        </>
    );
};

export const InvoiceDetailsDianAcknowledgementModal: React.FC<InvoiceDetailsDianAcknowledgementModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const { invoices: selectedInvoices } = useInvoicesHandler();

    const handleConfirm = async () => {
        setIsLoading(true);
        try {
            await onConfirm();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent maxWidth="80vw">
                <ModalHeader>Confirmar Acuse de Recibo DIAN</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={4}>
                        ¿Está seguro que desea generar el acuse de recibo para las siguientes facturas?
                    </Text>

                    <Box
                        p={4}
                        bg="yellow.50"
                        borderRadius="md"
                        mb={4}
                        borderLeft="4px"
                        borderColor="yellow.400"
                    >
                        <Text color="yellow.800">
                            <strong>Importante:</strong> El acuse de recibo solo funcionará para facturas a crédito. 
                            No aplicará para facturas de contado.
                        </Text>
                    </Box>

                    <Box
                        p={4}
                        bg="gray.50"
                        borderRadius="md"
                        mb={4}
                    >
                        <Text fontWeight="medium" mb={2}>Resumen:</Text>
                        <HStack spacing={4}>
                            <Text>Total facturas: {selectedInvoices.length}</Text>
                            <Text>
                                Pendientes: {
                                    selectedInvoices.filter(inv =>
                                        !inv.validations?.taxxa ||
                                        Object.keys(inv.validations.taxxa).length === 0
                                    ).length
                                }
                            </Text>
                            {/* <Text>
                                Parciales: {
                                    selectedInvoices.filter(inv => 
                                        inv.validations?.taxxa && 
                                        Object.keys(inv.validations.taxxa).length > 0 &&
                                        Object.keys(inv.validations.taxxa).length < 3
                                    ).length
                                }
                            </Text> */}
                            <Text>
                                Completadas: {
                                    selectedInvoices.filter(inv =>
                                        inv.validations?.taxxa &&
                                        Object.keys(inv.validations.taxxa).length === 3
                                    ).length
                                }
                            </Text>
                        </HStack>
                    </Box>

                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th width="40px"></Th>
                                <Th>ID Factura</Th>
                                <Th>Proveedor</Th>
                                <Th>Fecha</Th>
                                <Th>Estado</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {selectedInvoices.map((invoice) => (
                                <InvoiceRow
                                    key={invoice.id}
                                    invoice={invoice}
                                />
                            ))}
                        </Tbody>
                    </Table>
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                        Cancelar
                    </Button>
                    <Button 
                        colorScheme="blue" 
                        onClick={handleConfirm}
                        isLoading={isLoading}
                        loadingText="Confirmando"
                    >
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}; 