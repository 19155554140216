import * as ReactDOM from "react-dom/client"
import { ChakraProvider } from "@chakra-ui/react"
import App from "@/App"
import theme from "@/utils/theme";
import { ComponerProveedores } from "@/context/general";
import { DianContextProvider } from "@/context/dianContext";
import { InvoiceContextProvider } from "@/context/invoiceContext";
import { initializeSentry } from "@/config/sentry.config";

initializeSentry();

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');

ReactDOM.createRoot(container).render(
    <ComponerProveedores providers={[DianContextProvider, InvoiceContextProvider]} >
        <ChakraProvider theme={theme}>
            <App />
        </ChakraProvider>
    </ComponerProveedores>
)

