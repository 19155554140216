import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
} from "@chakra-ui/react";
import { InvoiceRow } from '@/components/invoices/uploadmodal/ConfirmationModalInvoiceRow';
import { useInvoicesHandler } from '@/context/shared/invoicesHandlerContext';
import { useErpForm } from '@/context/shared/erpFormContext';


// Componente de fila de factura con errores expansibles
const InvoiceRowWithErrors: React.FC<{
    invoice: any;
    hasErrors: boolean;
    errorItems: any[];
    errorType: string;
    uploadTarget: string;
    storeIds: Record<string, string>;
    storeSelection: string;
    onStoreSelect: (invoiceId: string, storeId: string) => void;
    showStoreSelect: boolean;
}> = ({ 
    invoice, 
    hasErrors, 
    errorItems, 
    errorType, 
    uploadTarget, 
    storeIds, 
    storeSelection, 
    onStoreSelect, 
    showStoreSelect 
}) => {
    // Inicializar como true para mostrar los errores por defecto cuando existen
    
    return (
            <InvoiceRow
                invoice={invoice}
                hasErrors={hasErrors}
                storeIds={storeIds}
                errorType={errorType}
                errorItems={errorItems}
                storeSelection={storeSelection}
                onStoreSelect={onStoreSelect}
                showStoreSelect={showStoreSelect}
                uploadTarget={uploadTarget}
            />
            
            
    );
};

export const InvoicesTable: React.FC = () => {
    const { uploadTarget, invoices, invoiceItemErrors } = useInvoicesHandler();
    const { storesHook } = useErpForm();
    const showStoreSelect = uploadTarget === 'intuipos' && Object.keys(storesHook.storeIds).length > 1;

    return (
        <Table variant="simple" size="sm">
            <Thead>
                <Tr>
                    <Th width="40px"></Th>
                    <Th>ID</Th>
                    <Th>Proveedor</Th>
                    <Th>Fecha</Th>
                    <Th>Estado</Th>
                    {showStoreSelect && <Th>Tienda</Th>}
                </Tr>
            </Thead>
            <Tbody>
                {invoices.map((invoice) => {
                    const invoiceError = invoiceItemErrors.find(e => e.invoiceId === invoice.invoice_id);
                    const hasErrors = !!invoiceError;
                    
                    return (
                        <InvoiceRowWithErrors
                            key={invoice.id}
                            invoice={invoice}
                            hasErrors={hasErrors}
                            errorItems={hasErrors ? invoiceError.items : []}
                            errorType={hasErrors ? invoiceError.errorType : ''}
                            uploadTarget={uploadTarget}
                            storeIds={storesHook.storeIds}
                            storeSelection={storesHook.storeSelections[invoice.id]}
                            onStoreSelect={storesHook.handleStoreSelection}
                            showStoreSelect={showStoreSelect}
                        />
                    );
                })}
            </Tbody>
        </Table>
    );
}; 