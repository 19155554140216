import React from "react";
import {
    Button,
    HStack,
    Image,
    Text,
    Flex,
} from "@chakra-ui/react";
import { UploadConfirmationModal } from "@/components/invoices/uploadmodal/UploadConfirmationModal";
import { DianAcknowledgmentModal } from "@/components/invoices/dianSyncModals/DianAcknowledgmentModal";
import { useInvoicesContext } from "@/context/invoices/invoicesContext";
import { useInvoicesHandler } from "@/context/shared/invoicesHandlerContext";

interface UploadControlsProps {
}

export const UploadControls: React.FC<UploadControlsProps> = () => {

    const {
        services,
        handleDianClick,
        handleConfirmDianAcknowledgment,
        isDianModalOpen,
        setIsDianModalOpen,
    } = useInvoicesContext(); 

    const {
        handleUploadClick
    } = useInvoicesHandler();

    return (
        <>
            <HStack spacing={3}>
                {services?.pos === 'intuipos' && (
                    <Button
                        size="sm"
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => handleUploadClick('intuipos')}
                    >
                        <Flex align="center" gap={2}>
                            <Image src="/intuipos_icon.png" alt="Intuipos" boxSize="16px" />
                            <Text>Subir a Intuipos</Text>
                        </Flex>
                    </Button>
                )}

                {services?.erp === 'siigo' && (
                    <Button
                        size="sm"
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => handleUploadClick('siigo')}
                    >
                        <Flex align="center" gap={2}>
                            <Image src="/siigo_blue_icon.png" alt="Siigo" boxSize="16px" />
                            <Text>Subir a Siigo</Text>
                        </Flex>
                    </Button>
                )}

                {services?.erp === 'helisa' && (
                    <Button
                        size="sm"
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => handleUploadClick('helisa')}
                    >
                        <Flex align="center" gap={2}>
                            <Image src="/helisa_logo.png" alt="Helisa" width="16px" />
                            <Text>Subir a Helisa</Text>
                        </Flex>
                    </Button>
                )}

                {services?.dian && (
                    <Button
                        size="sm"
                        colorScheme="blue"
                        variant="outline"
                        onClick={handleDianClick}
                    >
                        <Flex align="center" gap={2}>
                            <Image src="/dian_icon.png" alt="DIAN" boxSize="16px" />
                            <Text>Acuse de recibo</Text>
                        </Flex>
                    </Button>
                )}
            </HStack>

            <UploadConfirmationModal />

            <DianAcknowledgmentModal
                isOpen={isDianModalOpen}
                onClose={() => setIsDianModalOpen(false)}
                onConfirm={handleConfirmDianAcknowledgment}
            />
        </>
    );
}; 


