import { Flex, Skeleton, Tr } from "@chakra-ui/react";
import { Td } from "@chakra-ui/react";
import { UseInvocesTableContext } from "@/context/invoices/invoicesTableContext";


export function InvoicesTableSkeleton() {
    const { services } = UseInvocesTableContext();
    const showPurchaseOrdersColumn = services?.pos == "intuipos";

    return (
        <>
            {[...Array(5)].map((_, index) => (
                <Tr key={`skeleton-${index}`}>
                    <Td px={2}><Skeleton height="20px" width="20px" /></Td>
                    <Td><Flex justifyContent="center"><Skeleton height="20px" width="100px" /></Flex></Td>
                    <Td><Flex justifyContent="center"><Skeleton height="20px" width="80px" /></Flex></Td>
                    <Td><Flex justifyContent="center"><Skeleton height="20px" width="150px" /></Flex></Td>
                    {showPurchaseOrdersColumn && <Td><Flex justifyContent="center"><Skeleton height="20px" width="20px" /></Flex></Td>}
                    <Td><Flex justifyContent="center"><Skeleton height="20px" width="50px" /></Flex></Td>
                    <Td><Flex justifyContent="center"><Skeleton height="20px" width="20px" /></Flex></Td>
                    <Td><Flex justifyContent="center"><Skeleton height="20px" width="100px" /></Flex></Td>
                </Tr>
            ))}
        </>
    )
}