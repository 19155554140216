import config from "@/config/env.config";
import { getToken } from "@/utils/auth";
import { DianComparisonResponse, DianTokenResponse, DianSyncResponse, DianSyncRequest } from "@/types/dian.types";


export const fetchComparison = async (dianFile: File): Promise<DianComparisonResponse | undefined> => {
    if (!dianFile) {
        console.error('File is not available');
        return;
    }

    const newDianFile = new File([dianFile], "dian_" + dianFile.name, { type: dianFile.type });

    const formData = new FormData();
    formData.append('dian_file', newDianFile);

    try {
        const token = await getToken();
        console.log("token", token)
        const response = await fetch(`${config.apiUrl}/v1/dian/comparison`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData
        });
        const data = await response.json();
        return data.results;
    } catch (error) {
        console.error('Failed to fetch invoices:', error);
    }
};


export const fetchDianToken = async (): Promise<DianTokenResponse | undefined> => {
    const token = await getToken();
    const response = await fetch(`${config.apiUrl}/v1/dian/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const data = await response.json();
    return data;
};

export const fetchDianSync = async (url: string, startDate?: string | null, endDate?: string | null, type: "sent" | "received" = "received"): Promise<DianSyncResponse | undefined> => {
    try {
        const token = await getToken();

        // Create the request body according to what the backend expects
        const requestBody: DianSyncRequest = {
            url,
            type
        };

        // Add dates only if they are defined (explicit format for the backend)
        if (startDate) {
            requestBody.start_date = startDate;
        }

        if (endDate) {
            requestBody.end_date = endDate;
        }

        console.log("Request Body:", JSON.stringify(requestBody));

        const response = await fetch(`${config.apiUrl}/v1/dian/sync`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody)
        });

        // If the response is not ok, throw an error to catch it in the catch
        if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            console.error(`Error ${response.status}: ${response.statusText}`, errorData);
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error: any) {
        console.error('Failed to fetch invoices:', error);
        // Return an object with status: error so the frontend can handle it
        return { status: "error", message: error.message || "Unknown error" };
    }
};

