import { Invoice, InvoiceError } from '@/types/invoice.types';
import { UploadTargetType } from '@/types/erpShared.types';
import { isValidUploadTarget } from './utils';
/**
 * Checks if all selected invoices have been uploaded successfully
 */
export const checkSuccessfullyUploaded = (
  selectedInvoices: Invoice[],
  uploadTarget: UploadTargetType
): boolean => {
  if (selectedInvoices.length === 0 || !isValidUploadTarget(uploadTarget)) return false;

  // Verify that all selected invoices are uploaded
  return selectedInvoices.every(invoice =>
    Boolean(
      invoice.uploads?.[uploadTarget]?.uploaded &&
      !invoice.uploads?.[uploadTarget]?.failed
    )
  );
};

/**
 * Calculate missing form fields for all selected invoices
 */
export const calculateMissingFormFields = (
  selectedInvoices: Invoice[],
  uploadTarget: UploadTargetType,
  useCostCenterPerItem: boolean,
  availableStores: number | undefined
): string[] => {
  if (selectedInvoices.length === 0 || !isValidUploadTarget(uploadTarget)) {
    return [];
  }

  // Combine missing fields from all invoices
  const missingFields: string[] = [];

  // Check each selected invoice for missing fields
  selectedInvoices.forEach(invoice => {
    if (uploadTarget === 'siigo' || uploadTarget === 'helisa') {
      if (!invoice.invoice_type) {
        if (!missingFields.includes('Tipo de factura')) {
          missingFields.push('Tipo de factura');
        }
      }
    }
    if (uploadTarget === 'siigo') {

      if (invoice.invoice_type_name === 'FC' && invoice.payment_method === undefined) {
        console.log('invoice.invoice_type_name', invoice.invoice_type_name);
        console.log('invoice.payment_method', invoice.payment_method);
        if (!missingFields.includes('Método de pago')) {
          missingFields.push('Método de pago');
        }
      }

      if (invoice.invoice_type_name === 'CC' && !invoice.account_number) {
        if (!missingFields.includes('Cuenta contable del método de pago')) {
          missingFields.push('Cuenta contable del método de pago');
        }
      }

      // Check if selected retentions have an account ONLY if type is CC
      if (invoice.retentions && invoice.retentions.length > 0 && invoice.invoice_type_name === 'CC') {
        invoice.retentions.forEach((retention: any) => {
          if (!retention.account) {
            const fieldName = `Cuenta contable de ${retention.label}`;
            if (!missingFields.includes(fieldName)) {
              missingFields.push(fieldName);
            }
          }
        });
      }

      // The cost center is completely optional for Siigo
    } else if (uploadTarget === 'helisa') {
      // Validate required fields for Helisa

      if (!invoice.account_number) {
        if (!missingFields.includes('Cuenta contable del método de pago')) {
          missingFields.push('Cuenta contable del método de pago');
        }
      }

      if (!useCostCenterPerItem && invoice.cost_center === undefined) {
        if (!missingFields.includes('Centro de costo')) {
          missingFields.push('Centro de costo');
        }
      }
    }
    else if (uploadTarget === 'intuipos') {
      if (invoice.items.some((item) => !item.inventory_item_name)) {
        if (!missingFields.includes('Nombre de inventario')) {
          missingFields.push('Nombre de inventario');
        }
      }
      // Only require store_id if there are multiple stores available and storesHook is initialized
      if (!invoice.store_id && availableStores && availableStores > 1) {
        if (!missingFields.includes('Tienda')) {
          missingFields.push('Tienda');
        }
      }
    }
  });

  return missingFields;
};

/**
 * Calculate item errors for all selected invoices
 */
export const calculateInvoiceItemErrors = (
  selectedInvoices: Invoice[],
  uploadTarget: UploadTargetType,
  useCostCenterPerItem: boolean
): InvoiceError[] => {
  if (selectedInvoices.length === 0 || !isValidUploadTarget(uploadTarget)) return [];

  const errors: InvoiceError[] = [];

  // Process all selected invoices
  for (const invoice of selectedInvoices) {
    // Review specific errors for each service
    if (uploadTarget === 'siigo') {
      // For Siigo, review errors related to ERP
      let errorType = 'erp_config';
      const itemsWithErrors = invoice.items
        .map((item: any, index: number) => ({ ...item, index }))
        .filter((item: any) => {
          // Review if the ERP type and selection are correctly configured
          if (!item.erp_type || (item.erp_type === 'product' && !item.erp_product)) {
            return true;
          }
          if (useCostCenterPerItem && !item.cost_center) {
            errorType = 'cost_center_per_item';
            return true;
          }
          // Review if the tax accounts are correctly configured for each tax
          const taxes = Object.values(item.taxes || {});
          if (taxes.some((tax: any) => !tax.use_as_cost_value && !tax.tax_erp_id)) {
            return true;
          }

          return false;
        });

      if (itemsWithErrors.length > 0) {
        errors.push({
          invoiceId: invoice.invoice_id,
          items: itemsWithErrors,
          errorType: errorType
        });
      }
    } else if (uploadTarget === 'helisa') {
      // For Helisa, review account numbers
      let errorType = 'account_numbers';
      const itemsWithErrors = invoice.items
        .map((item: any, index: number) => ({ ...item, index }))
        .filter((item: any) => {
          // Review if the account number is configured
          if (!item.erp_account) {
            return true;
            // Review if the tax accounts are configured
          }
          const taxes = Object.values(item.taxes || {});
          if (taxes.some((tax: any) => !tax.use_as_cost_value && !tax.tax_account)) {
            return true;
          }
          if (useCostCenterPerItem && !item.cost_center) {
            errorType = 'cost_center_per_item';
            return true;
          }

          return false;
        });
      if (itemsWithErrors.length > 0) {
        errors.push({
          invoiceId: invoice.invoice_id,
          items: itemsWithErrors,
          errorType: errorType
        });
      }
    } else if (uploadTarget === 'intuipos') {
      const itemsWithErrors = invoice.items
        .map((item: any, index: number) => ({ ...item, index }))
        .filter((item: any) => {
          return !item.inventory_item_name;
        });
      if (itemsWithErrors.length > 0) {
        errors.push({
          invoiceId: invoice.invoice_id,
          items: itemsWithErrors,
          errorType: 'inventory_item_name'
        });
      }
    }
  }

  return errors;
}; 