import { Badge } from "@chakra-ui/react";




export const InvoiceTypeBadge = ({ type, isReturn, isGeneralPurchase }: { 
    type: string;
    isReturn: boolean;
    isGeneralPurchase: boolean;
}) => {
    let color = "blue";
    let label = type;
    
    if (isReturn) {
        color = "red";
        label = "Nota Crédito";
    } else if (isGeneralPurchase) {
        color = "purple";
        label = "Gasto";
    } else {
        color = "green";
        label = "Compra";
    }
    
    return (
        <Badge
            colorScheme={color}
            variant="subtle"
            px={2}
            py={1}
            borderRadius="full"
            textTransform="none"
            fontSize="xs"
            fontWeight="medium"
        >
            {label}
        </Badge>
    );
};
