import { Box, Flex } from "@chakra-ui/react"
import { VStack, Text } from "@chakra-ui/react";
import UploadDianFile from "./UploadDianFile";
import { InfoIcon } from "@chakra-ui/icons";

export function UploadFileView() {

    return (
        <Box pt={10}>
            <Flex
                justify="center"
                align="center"
                bg="white"
                p={4}
                borderRadius="lg"
                boxShadow="sm"
                border="1px"
                borderColor="gray.200"
            >
                <Flex align="center" direction="column" >
                    <InfoIcon boxSize={5} color="blue.500" mb={2} />
                    <Text fontSize="lg" color="gray.700" textAlign="center">
                        Aquí puedes revisar qué facturas de la DIAN están registradas en Cifrato y en Siigo
                    </Text>
                    <Text fontSize="lg" color="gray.500" textAlign="center">
                        Carga tu archivo de facturas de la DIAN que obtienes en el siguiente <a href="https://ayuda.alegra.com/colombia/descargar-listados-en-la-dian" target="_blank" style={{ color: "blue", cursor: "pointer" }} >link</a>
                    </Text>
                </Flex>
            </Flex>

            <VStack py={10} justifyContent={"center"} spacing={10}>
                <UploadDianFile />
            </VStack>
        </Box>
    )
}