import { Invoice } from "@/types/invoice.types";

export const updateIntuiposInvoice = (inv: Invoice, storeId: string): Invoice => {
    return {
        ...inv,
        store_id: storeId
    };
};



