import { useState } from 'react';

interface VideoLoadingStates {
    apiVideoLoading: boolean;
    accountsVideoLoading: boolean;
    costCentersVideoLoading: boolean;
    invoiceTypesVideoLoading: boolean;
    setApiVideoLoaded: () => void;
    setAccountsVideoLoaded: () => void;
    setCostCentersVideoLoaded: () => void;
    setInvoiceTypesVideoLoaded: () => void;
}

export const useVideoLoading = (): VideoLoadingStates => {
    const [apiVideoLoading, setApiVideoLoading] = useState(true);
    const [accountsVideoLoading, setAccountsVideoLoading] = useState(true);
    const [costCentersVideoLoading, setCostCentersVideoLoading] = useState(true);
    const [invoiceTypesVideoLoading, setInvoiceTypesVideoLoading] = useState(true);

    return {
        apiVideoLoading,
        accountsVideoLoading,
        costCentersVideoLoading,
        invoiceTypesVideoLoading,
        setApiVideoLoaded: () => setApiVideoLoading(false),
        setAccountsVideoLoaded: () => setAccountsVideoLoading(false),
        setCostCentersVideoLoaded: () => setCostCentersVideoLoading(false),
        setInvoiceTypesVideoLoaded: () => setInvoiceTypesVideoLoading(false),
    };
}; 