import React from 'react';
import { 
  Box, 
  Container, 
  Heading, 
  Text, 
  SimpleGrid, 
  Stack, 
  Icon,
  Flex,
  Grid,
  GridItem,
  useColorModeValue,
  useBreakpointValue,
  IconProps
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaRobot, FaChartLine, FaMoneyBillWave, FaUsers } from 'react-icons/fa';

// Motion components
const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

// Define interface for the AnimatedFeature props
interface AnimatedFeatureProps {
  icon: React.ReactElement<IconProps>;
  title: string;
  text: string;
  index: number;
  badge?: string;
}

// Custom animated feature card instead of using the Feature component
const AnimatedFeature: React.FC<AnimatedFeatureProps> = ({ icon, title, text, index, badge }) => {
  // Add a few complementary colors while keeping blue dominant
  const colors = ["blue.500", "teal.500", "blue.600", "purple.500"];
  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const delay = index * 0.1;

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-50px" }}
      transition={{ duration: 0.5, delay }}
      whileHover={{ y: -5, transition: { duration: 0.2 } }}
    >
      <Box 
        bg={cardBg} 
        p={6} 
        rounded="xl" 
        shadow="lg" 
        height="full"
        borderTop="4px solid"
        borderColor={colors[index % colors.length]}
        position="relative"
      >
        {badge && (
          <Box 
            position="absolute"
            top="-10px"
            right="-10px"
            bg="blue.50"
            color="blue.800"
            fontSize="xs"
            fontWeight="bold"
            px={2}
            py={1}
            rounded="full"
            borderWidth="1px"
            borderColor="blue.200"
          >
            {badge}
          </Box>
        )}
        <MotionFlex
          alignItems="center" 
          justifyContent="center"
          w={12} 
          h={12} 
          rounded="full" 
          bg={colors[index % colors.length]} 
          color="white"
          mb={4}
          whileHover={{ scale: 1.1, rotate: 5 }}
        >
          {icon}
        </MotionFlex>
        <Heading size="md" fontWeight="bold" mb={3} mt={2}>
          {title}
        </Heading>
        <Text color={textColor}>
          {text}
        </Text>
      </Box>
    </MotionBox>
  );
};

const BenefitsSection = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const mainBg = useColorModeValue('gray.50', 'gray.800');
  const secondaryColor = useColorModeValue('blue.600', 'blue.400');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  
  const benefits = [
    {
      icon: <Icon as={FaRobot} w={6} h={6} />,
      title: "Automatización Inteligente",
      text: "Automatiza tareas repetitivas como la clasificación de documentos, extracción de datos y conciliaciones con la DIAN con precisión superior al 98%."
    },
    {
      icon: <Icon as={FaChartLine} w={6} h={6} />,
      title: "Mayor Eficiencia",
      text: "Reduce el tiempo de procesamiento contable hasta en un 70%, permitiéndote enfocarte en servicios de mayor valor para tus clientes."
    },
    {
      icon: <Icon as={FaMoneyBillWave} w={6} h={6} />,
      title: "Reducción de Costos",
      text: "Disminuye los costos operativos hasta en un 40% mientras elevas la calidad y precisión de tu trabajo contable."
    },
    {
      icon: <Icon as={FaUsers} w={6} h={6} />,
      title: "Atrae Más Clientes",
      text: "Ofrece servicios innovadores impulsados por IA que te diferencian de la competencia y atraen a clientes que buscan soluciones modernas."
    },
    {
      icon: <Icon as={FaChartLine} w={6} h={6} />,
      title: "Cumplimiento Garantizado",
      text: "Mantente al día con las normativas fiscales y contables gracias a nuestras actualizaciones automáticas y análisis inteligente de compliance."
    },
    {
      icon: <Icon as={FaRobot} w={6} h={6} />,
      title: "Análisis Predictivo",
      text: "Anticipa problemas de flujo de caja, identifica oportunidades de ahorro fiscal y proporciona asesoramiento estratégico a tus clientes.",
      badge: "Próximamente"
    }
  ];

  return (
    <Box bg={mainBg} py={{ base: 16, md: 24 }} id="beneficios">
      <Container maxW="container.xl">
        <Stack spacing={16} align="center">
          <Stack textAlign="center" maxW="3xl" mx="auto" spacing={5}>
            <MotionBox
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <Heading 
                as="h2" 
                size="xl" 
                bgGradient="linear(to-r, blue.500, teal.500)" 
                bgClip="text"
              >
                ¿Por qué elegir Cifrato para tu firma contable?
              </Heading>
            </MotionBox>
            <MotionBox
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Text fontSize="lg" color={textColor}>
                Nuestra plataforma impulsada por IA está diseñada específicamente para firmas contables que buscan crecer y optimizar sus operaciones.
              </Text>
            </MotionBox>
          </Stack>
          
          {isDesktop ? (
            <Grid 
              templateColumns="repeat(3, 1fr)" 
              gap={8} 
              w="full"
              px={4}
            >
              {benefits.map((benefit, index) => (
                <GridItem key={index}>
                  <AnimatedFeature
                    icon={benefit.icon}
                    title={benefit.title}
                    text={benefit.text}
                    index={index}
                    badge={benefit.badge}
                  />
                </GridItem>
              ))}
            </Grid>
          ) : (
            <Stack spacing={6} w="full">
              {benefits.map((benefit, index) => (
                <AnimatedFeature
                  key={index}
                  icon={benefit.icon}
                  title={benefit.title}
                  text={benefit.text}
                  index={index}
                  badge={benefit.badge}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default BenefitsSection; 