import { Invoice } from '@/types/invoice.types';
import { InventoryItem } from '@/types/inventory.types';

export const useInvoiceItemHandlers = (
  invoice: Invoice | null,
  setInvoice: (invoice: Invoice) => void,
  inventoryItems: InventoryItem[]
) => {
  const handleItemChange = (selectedOption: any, index: number) => {
    if (!invoice) return;
    
    const item = inventoryItems.find(
      (item) => item.item_name === selectedOption.value
    );
    
    if (item) {
      const updatedItems = [...invoice.items];
      updatedItems[index] = {
        ...updatedItems[index],
        unit: item.item_unit,
        inventory_item_name: item.item_name,
        inventory_id: item.id,
        item_role_id: item.item_role_id,
        item_role_name: item.item_role_name,
      };
      setInvoice({ ...invoice, items: updatedItems });
    }
  };

  const handleTaxChange = (
    selectedOption: any,
    index: number,
    taxIndex: number
  ) => {
    if (!invoice) return;
    
    const updatedItems = [...invoice.items];
    const taxName = selectedOption.value;
    const updatedTaxes = { ...updatedItems[index].taxes };
    const oldTaxName = Object.keys(updatedTaxes)[taxIndex];
    const oldTaxAmount = updatedTaxes[oldTaxName]?.tax_amount || 0;
    
    delete updatedTaxes[oldTaxName];
    updatedTaxes[taxName] = { tax_amount: oldTaxAmount };
    
    updatedItems[index] = {
      ...updatedItems[index],
      taxes: updatedTaxes,
    };
    
    setInvoice({ ...invoice, items: updatedItems });
  };

  const handleTaxAmountChange = (
    value: any,
    index: number,
    taxName: string
  ) => {
    if (!invoice) return;
    
    const updatedItems = [...invoice.items];
    const updatedTaxes = { ...updatedItems[index].taxes };
    
    updatedTaxes[taxName] = { tax_amount: Number(value) };
    
    updatedItems[index] = {
      ...updatedItems[index],
      taxes: updatedTaxes,
    };
    
    setInvoice({ ...invoice, items: updatedItems });
  };

  return {
    handleItemChange,
    handleTaxChange,
    handleTaxAmountChange
  };
}; 