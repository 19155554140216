import { Tr } from "@chakra-ui/react";


export const ClickableRow = ({ children, onClick }: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
}) => (
    <Tr
        height="40px"
        cursor="pointer"
        _hover={{ bg: 'gray.50' }}
        onClick={onClick}
        transition="background-color 0.2s"
    >
        {children}
    </Tr>
);
