import React, { useState } from 'react';
import {
    VStack,
    Box,
    FormControl,
    FormLabel,
    Select,
    Text,
    Stack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    HStack,
    Icon,
    Badge,
    Tooltip,
    Progress,
    useColorModeValue,
} from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { StepComponentProps } from '@/types/onboarding.types';
import { ERPSystem, AVAILABLE_ERPS, COMING_SOON_ERPS } from '@/types/erp.types';
import { useVideoLoading } from '@/hooks/useVideoLoading';
import { FileUploadSection } from '../FileUploadSection';
import { FAQSection } from '../FAQSection';
import {
    SiigoContadorForm,
    SiigoNoContadorForm,
    SiigoPymeForm,
    HelisaForm,
    WorldOfficeForm,
    DefaultERPSelection
} from '../services';

const VIDEO_URLS = {
    api: 'https://storage.googleapis.com/onboarding-videos-cifrato/siigoapi.mp4',
    accounts: 'https://storage.googleapis.com/onboarding-videos-cifrato/cuentascontables.mp4',
    costCenters: 'https://storage.googleapis.com/onboarding-videos-cifrato/centros_costo.mp4',
    invoiceTypes: 'https://storage.googleapis.com/onboarding-videos-cifrato/invoice_types.mp4',
    siigoContador: 'https://storage.googleapis.com/onboarding-videos-cifrato/siigo_contador.mp4',
    siigoNoContador: 'https://storage.googleapis.com/onboarding-videos-cifrato/siigo_no_contador.mp4',
};

export const ServicesConfiguration: React.FC<StepComponentProps> = ({ onDataChange, stepData }) => {
    const [selectedERP, setSelectedERP] = useState<ERPSystem>(
        (stepData?.system as ERPSystem) || ''
    );
    
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const headerBg = useColorModeValue('gray.50', 'gray.700');

    const videoLoadingStates = useVideoLoading();

    // Calculate progress based on completed fields
    const calculateProgress = () => {
        if (!selectedERP) return 0;
        
        // Different ERPs have different required fields
        // Add +1 to each for the system selection step
        const totalSteps = selectedERP === 'siigo-contador' || selectedERP === 'siigo-pyme' ? 4 : 
                          selectedERP === 'siigo-no-contador' ? 4 : 5;
        
        let completedSteps = 0;
        const credentials = stepData?.credentials || {};
        
        // Count system selection as first step
        if (selectedERP) completedSteps++;
        
        // Count credentials based on ERP type
        if (selectedERP === 'siigo-contador' || selectedERP === 'siigo-pyme') {
            if (credentials.accountsFile) completedSteps++;
            if (credentials.costCentersFile) completedSteps++;
            if (credentials.invoiceTypesFile) completedSteps++;
        } else if (selectedERP === 'siigo-no-contador') {
            if (credentials.platformUsername && credentials.platformPassword) completedSteps++;
            if (credentials.apiUsername && credentials.apiKey) completedSteps++;
            if (credentials.accountsFile) completedSteps++;
        } else if (selectedERP === 'helisa' || selectedERP === 'world-office') {
            if (credentials.accountsFile) completedSteps++;
            if (credentials.costCentersFile) completedSteps++;
            if (credentials.invoiceTypesFile) completedSteps++;
            if (credentials.paymentMethodsFile) completedSteps++;
        }
        
        // Ensure we don't exceed 100%
        const percentage = (completedSteps / totalSteps) * 100;
        return Math.min(percentage, 100);
    };

    const handleERPChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const system = e.target.value as ERPSystem;
        setSelectedERP(system);
        
        // When ERP system changes, update the selected services array
        const selectedServices = system ? [system] : [];
        
        onDataChange({
            system,
            credentials: {},
            selectedServices, // Add selected services array
            configurations: {
                // Include the appropriate configurations based on ERP system
                ...(system === 'siigo-contador' || system === 'siigo-no-contador' || system === 'siigo-pyme' ? { siigo: {} } : {}),
                ...(system === 'helisa' ? { helisa: {} } : {}),
                ...(system === 'world-office' ? { world_office: {} } : {})
            }
        });
    };

    const handleCredentialChange = (field: string, value: string) => {
        const updatedCredentials = {
            ...(stepData?.credentials || {}),
            [field]: value,
        };
        
        // Ensure we preserve the selected services when credentials change
        const selectedServices = selectedERP ? [selectedERP] : stepData?.selectedServices || [];
        
        // Update the configurations based on the selected ERP and credentials
        const configurations = {
            ...(stepData?.configurations || {}),
            ...(selectedERP === 'siigo-contador' || selectedERP === 'siigo-no-contador' ? { 
                siigo: { 
                    api_key: updatedCredentials.apiKey || '',
                    api_username: updatedCredentials.apiUsername || '',
                    type: selectedERP === 'siigo-contador' ? 'contador' : 'no-contador'
                } 
            } : {}),
            ...(selectedERP === 'siigo-pyme' ? { 
                siigo: { 
                    api_key: updatedCredentials.apiKey || '',
                    api_username: updatedCredentials.apiUsername || '',
                    type: 'pyme'
                } 
            } : {}),
            ...(selectedERP === 'helisa' ? { 
                helisa: { 
                    account_subgroups: {} // We'll populate this from uploaded files later
                } 
            } : {}),
            ...(selectedERP === 'world-office' ? { 
                world_office: { 
                    account_subgroups: {} // We'll populate this from uploaded files later
                } 
            } : {})
        };
        
        onDataChange({
            system: selectedERP,
            credentials: updatedCredentials,
            selectedServices, // Include selected services
            configurations // Include service configurations
        });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            // Log comprehensive file information
            console.log("File selected:", {
                name: file.name,
                size: file.size,
                type: file.type,
                lastModified: file.lastModified,
                isFile: file instanceof File,
                extension: file.name.slice(file.name.lastIndexOf('.')),
            });
            
            // Validate file type based on expected formats
            const inputId = event.target.id;
            const fileType = inputId.includes('accounts-file') 
                ? 'excel'
                : inputId.includes('cost-centers-file') 
                    ? 'both' 
                    : inputId.includes('invoice-types-file') || inputId.includes('payment-methods-file')
                        ? 'image'
                        : 'unknown';
                
            // Check if file type matches expected format
            if (fileType === 'excel' && !file.type.includes('sheet') && !file.type.includes('excel') && 
                !file.name.endsWith('.xls') && !file.name.endsWith('.xlsx')) {
                console.warn(`File ${file.name} might not be a valid Excel file. Type: ${file.type}`);
            }
            
            const credentialKey = {
                'accounts-file': 'accountsFile',
                'cost-centers-file': 'costCentersFile',
                'invoice-types-file': 'invoiceTypesFile',
                'payment-methods-file': 'paymentMethodsFile'
            }[inputId];

            if (credentialKey) {
                // Update credentials with file name for display
                const updatedCredentials = {
                    ...(stepData?.credentials || {}),
                    [credentialKey]: file.name,
                };
                
                // Store files separately for actual file upload
                const updatedFiles = {
                    ...(stepData?.files || {}),
                };
                
                // Ensure we create a proper file structure
                let fileServiceKey = '';
                
                // Organize files by ERP system
                if (selectedERP === 'siigo-contador' || selectedERP === 'siigo-no-contador' || selectedERP === 'siigo-pyme') {
                    fileServiceKey = 'siigo';
                    updatedFiles.siigo = {
                        ...(updatedFiles.siigo || {}),
                    };
                    // Explicitly set the file, don't just reference it
                    updatedFiles.siigo[credentialKey] = file;
                    console.log(`Stored file ${file.name} (${file.size} bytes, type: ${file.type}) for siigo under key ${credentialKey}`);
                } else if (selectedERP === 'helisa') {
                    fileServiceKey = 'helisa';
                    updatedFiles.helisa = {
                        ...(updatedFiles.helisa || {}),
                    };
                    updatedFiles.helisa[credentialKey] = file;
                    console.log(`Stored file ${file.name} (${file.size} bytes, type: ${file.type}) for helisa under key ${credentialKey}`);
                } else if (selectedERP === 'world-office') {
                    fileServiceKey = 'world_office';
                    updatedFiles.world_office = {
                        ...(updatedFiles.world_office || {}),
                    };
                    updatedFiles.world_office[credentialKey] = file;
                    console.log(`Stored file ${file.name} (${file.size} bytes, type: ${file.type}) for world_office under key ${credentialKey}`);
                }
                
                // Ensure we preserve the selected services
                const selectedServices = selectedERP ? [selectedERP] : stepData?.selectedServices || [];

                // Confirm file storage worked
                if (fileServiceKey && updatedFiles[fileServiceKey]) {
                    const storedFile = updatedFiles[fileServiceKey][credentialKey];
                    console.log(`File storage check for ${fileServiceKey}.${credentialKey}:`, {
                        isFile: storedFile instanceof File,
                        name: storedFile instanceof File ? storedFile.name : 'NOT A FILE',
                        size: storedFile instanceof File ? storedFile.size : 'UNKNOWN',
                        type: storedFile instanceof File ? storedFile.type : 'UNKNOWN'
                    });
                }
                
                // Update data including the file references
                onDataChange({
                    ...stepData,
                    system: selectedERP,
                    credentials: updatedCredentials,
                    files: updatedFiles,
                    selectedServices,
                    configurations: stepData?.configurations || {}
                });
            }
        }
    };

    const renderERPForm = () => {
        switch (selectedERP) {
            case 'siigo-contador':
                return (
                    <SiigoContadorForm
                        stepData={stepData}
                        handleCredentialChange={handleCredentialChange}
                        handleFileChange={handleFileChange}
                        videoURLs={VIDEO_URLS}
                        videoLoadingStates={videoLoadingStates}
                    />
                );
            case 'siigo-no-contador':
                return (
                    <SiigoNoContadorForm
                        stepData={stepData}
                        handleCredentialChange={handleCredentialChange}
                        handleFileChange={handleFileChange}
                        videoURLs={VIDEO_URLS}
                        videoLoadingStates={videoLoadingStates}
                    />
                );
            case 'siigo-pyme':
                return (
                    <SiigoPymeForm
                        stepData={stepData}
                        handleCredentialChange={handleCredentialChange}
                        handleFileChange={handleFileChange}
                        videoURLs={VIDEO_URLS}
                        videoLoadingStates={videoLoadingStates}
                    />
                );
            case 'helisa':
                return (
                    <HelisaForm
                        stepData={stepData}
                        handleFileChange={handleFileChange}
                    />
                );
            case 'world-office':
                return (
                    <WorldOfficeForm
                        stepData={stepData}
                        handleFileChange={handleFileChange}
                    />
                );
            default:
                return (
                    <DefaultERPSelection
                        videoURLs={{
                            siigoContador: VIDEO_URLS.siigoContador,
                            siigoNoContador: VIDEO_URLS.siigoNoContador
                        }}
                    />
                );
        }
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" bg={bgColor}>
            <Box p={4} pb={3} borderBottom="1px" borderColor={borderColor}>
                <HStack justify="space-between" align="center">
                    <Text fontSize="md" fontWeight="bold">
                        Configuración del Sistema ERP
                    </Text>
                    <Tooltip label="Tu progreso en la configuración">
                        <HStack spacing={2} align="center">
                                <Text fontSize="xs" color="gray.500">{Math.round(calculateProgress())}% completado</Text>
                            <Progress 
                                value={calculateProgress()} 
                                size="sm" 
                                width="100px" 
                                colorScheme="blue" 
                                borderRadius="full"
                            />
                        </HStack>
                    </Tooltip>
                </HStack>
            </Box>

            <Box flex="1" overflowY="auto" px={4} py={3}>
                <VStack spacing={4} align="stretch">
                    <Box 
                        bg="white" 
                        p={3} 
                        borderRadius="md" 
                        boxShadow="sm" 
                        border="1px" 
                        borderColor={borderColor}
                    >
                        <FormControl isRequired>
                            <FormLabel fontSize="sm" mb={1}>Selecciona tu Sistema Contable</FormLabel>
                            <Select
                                value={selectedERP}
                                onChange={handleERPChange}
                                placeholder="Selecciona un sistema contable"
                                size="sm"
                            >
                                <optgroup label="Disponibles">
                                    {AVAILABLE_ERPS.map((erp) => (
                                        <option key={erp} value={erp}>
                                            {erp === 'siigo-contador'
                                                ? 'Siigo Nube (contador)'
                                                : erp === 'siigo-no-contador'
                                                ? 'Siigo Nube (Profesional Independiente | Emprendedor | Premium)'
                                                : erp === 'world-office'
                                                ? 'World Office'
                                                : erp === 'siigo-pyme'
                                                ? 'Siigo Pyme'
                                                : 'Helisa'}
                                        </option>
                                    ))}
                                </optgroup>
                                <optgroup label="Próximamente" style={{ color: 'gray.400' }}>
                                    {COMING_SOON_ERPS.map((erp) => (
                                        <option key={erp} value={erp} disabled style={{ color: 'gray.400' }}>
                                            {erp === 'allegra' ? 'Allegra' : 'Siigo Pyme'}
                                        </option>
                                    ))}
                                </optgroup>
                            </Select>
                        </FormControl>
                    </Box>

                    {selectedERP && (
                        <HStack spacing={1} px={1}>
                            <Icon as={FiInfo} color="blue.400" fontSize="sm" />
                            <Text fontSize="xs" color="gray.600">
                                Expande cada sección para completar la configuración. Los campos obligatorios están marcados con *.
                            </Text>
                        </HStack>
                    )}

                    {renderERPForm()}
                </VStack>
            </Box>
        </Box>
    );
};
