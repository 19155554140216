import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { fetchInventoryItems } from '@/services/invoices.services';
import { InventoryItem } from '@/types/inventory.types';

export const useInventoryItems = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [itemsOptions, setItemsOptions] = useState<{ label: string, value: string }[]>([]);

  useEffect(() => {
    const loadInventoryItems = async () => {
      try {
        const inventoryData = await fetchInventoryItems();
        
        if (inventoryData) {
          setInventoryItems(inventoryData.inventory_items);
          
          const items = inventoryData.inventory_items.map((item: InventoryItem) => ({
            label: item.item_name,
            value: item.item_name,
          }));
          
          setItemsOptions(items);
        }
      } catch (error) {
        console.error("Error loading inventory items:", error);
        toast({
          title: "Error al cargar artículos de inventario",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    loadInventoryItems();
  }, []);

  return {
    loading,
    inventoryItems,
    itemsOptions
  };
}; 