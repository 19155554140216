import React, { useEffect } from "react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Badge, Button, Flex, Heading, HStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { InvoceDetailsUploadControls } from "./InvoceDetailsUploadControls";
import { useInvoicesHandler } from "@/context/shared/invoicesHandlerContext";

export function InvoiceDetailsHeaderSection() {
  const navigate = useNavigate();
  const { isSaving, handleSaveInvoice } = useInvoiceDetails();
  const { invoices: selectedInvoices, services } = useInvoicesHandler();

  const invoice = selectedInvoices[0];

  return (
    <Flex
      justify="space-between"
      align="center"
      py={4}
      mb={6}
      px={14}
      position="sticky"
      top={0}
      bg="blue.500"
      zIndex={1}
      boxShadow="md"
    >
      <Flex align="center" justify="space-between" gap={3}>
        <Button
          leftIcon={<ArrowBackIcon boxSize={6} color="white" />} // Aumentar el tamaño del ícono
          variant="ghost"
          color="white"
          fontWeight="normal"
          _hover={{ color: "white" }}
          onClick={() => navigate(-1)}
          fontSize="lg" // Aumentar el tamaño del texto
        >
        </Button>
        <Heading size="md" color="white" fontWeight="bold">Factura</Heading>
        <Badge borderRadius="full" px={2} py={1} colorScheme="blue" fontSize="sm">
          {invoice?.invoice_id}
        </Badge>
      </Flex>
      <HStack spacing={3}>
        {invoice && (
          <InvoceDetailsUploadControls
            isDetailView={true}
            currentInvoice={invoice}
          />
        )}
        <Button
          bg="white"
          size="sm"
          color="blue.500"
          onClick={handleSaveInvoice}
          isLoading={isSaving}
          loadingText="Guardando..."
        >
          Guardar cambios
        </Button>
      </HStack>
    </Flex>
  );
} 