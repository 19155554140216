import { createContext, useContext, useState } from "react";
import { Invoice } from "@/types/invoice.types";

const InvoiceContext = createContext<{
    invoice: Invoice | null;
    setInvoice: (value: Invoice | null) => void;
} | null>(null)

export const InvoiceContextProvider = ({ children }: { children: React.ReactNode; }) => {
    const [invoice, setInvoice] = useState<Invoice | null>(null)
    
    return (
        <InvoiceContext.Provider value={{ invoice, setInvoice }} >
            {children}
        </InvoiceContext.Provider>
    )
}

export const useInvoiceContext = () => {
    const context = useContext(InvoiceContext)

    if (!context) {
        throw new Error("InvoiceContext must be used inside a InvoiceContextProvider")
    }

    return context
} 