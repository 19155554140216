import React from 'react';
import {
    Stack,
    Box,
    Text,
    Badge,
    HStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    useColorModeValue,
} from '@chakra-ui/react';
import { FileUploadSection } from '../FileUploadSection';
import { CredentialsSection } from '../steps/CredentialsSection';

// Define props interface
interface SiigoContadorFormProps {
    stepData: any;
    handleCredentialChange: (field: string, value: string) => void;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    videoURLs: Record<string, string>;
    videoLoadingStates: any;
}

export const SiigoContadorForm: React.FC<SiigoContadorFormProps> = ({
    stepData,
    handleCredentialChange,
    handleFileChange,
    videoURLs,
    videoLoadingStates,
}) => {
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const headerBg = useColorModeValue('gray.50', 'gray.700');

    return (
        <Stack spacing={4}>
            <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md" mb={3}>
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Credenciales Siigo</Text>
                            {(stepData?.credentials?.platformUsername || stepData?.credentials?.platformPassword) && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <CredentialsSection
                                title="Credenciales Siigo"
                                description="Estas credenciales nos permiten ayudarte para el soporte de integración con Siigo."
                                fields={[
                                    {
                                        label: 'Usuario Siigo Nube',
                                        key: 'platformUsername',
                                        placeholder: 'Usuario de la plataforma Siigo Nube',
                                        isRequired: true,
                                    },
                                    {
                                        label: 'Contraseña Siigo Nube',
                                        key: 'platformPassword',
                                        type: 'password',
                                        placeholder: 'Contraseña de la plataforma Siigo Nube',
                                        isRequired: true,
                                    },
                                ]}
                                values={stepData?.credentials || {}}
                                onChange={handleCredentialChange}
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md" mb={3}>
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Cuentas Contables</Text>
                            {stepData?.credentials?.accountsFile && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <FileUploadSection
                                title="Cuentas Contables"
                                description="Este archivo nos permite entrenar a nuestra IA con el contexto de tus cuentas contables para predecir automáticamente la cuenta apropiada para cada ítem."
                                inputId="accounts-file"
                                fileName={stepData?.credentials?.accountsFile}
                                onFileChange={handleFileChange}
                                tutorialTitle="¿Cómo obtener el listado de cuentas?"
                                videoUrl={videoURLs.accounts}
                                isVideoLoading={videoLoadingStates.accountsVideoLoading}
                                onVideoLoaded={videoLoadingStates.setAccountsVideoLoaded}
                                labelPrefix="Archivo de Cuentas Contables"
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md" mb={3}>
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Centros de Costo</Text>
                            {stepData?.credentials?.costCentersFile && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <FileUploadSection
                                title="Centros de Costo"
                                description="Este archivo nos permite entrenar a nuestra IA con el contexto de tus centros de costo para predecir automáticamente el centro de costo apropiado para cada factura."
                                inputId="cost-centers-file"
                                fileName={stepData?.credentials?.costCentersFile}
                                onFileChange={handleFileChange}
                                tutorialTitle="¿Cómo obtener el listado de centros de costo?"
                                videoUrl={videoURLs.costCenters}
                                isVideoLoading={videoLoadingStates.costCentersVideoLoading}
                                onVideoLoaded={videoLoadingStates.setCostCentersVideoLoaded}
                                labelPrefix="Archivo de Centros de Costo"
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="1px" borderColor={borderColor} borderRadius="md">
                    <AccordionButton bg={headerBg} _hover={{ bg: 'gray.100' }} py={3} borderTopRadius="md">
                        <HStack flex="1" textAlign="left" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium">Tipos de Comprobantes</Text>
                            {stepData?.credentials?.invoiceTypesFile && 
                                <Badge colorScheme="green" fontSize="xs">Completado</Badge>}
                        </HStack>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} pt={2}>
                        <Box fontSize="sm">
                            <FileUploadSection
                                title="Tipos de Comprobantes"
                                description="Este archivo nos permite entrenar a nuestra IA con el contexto de tus tipos de comprobantes para predecir automáticamente el tipo apropiado para cada factura."
                                inputId="invoice-types-file"
                                fileName={stepData?.credentials?.invoiceTypesFile}
                                onFileChange={handleFileChange}
                                tutorialTitle="¿Cómo obtener el listado de tipos de comprobantes?"
                                videoUrl={videoURLs.invoiceTypes}
                                isVideoLoading={videoLoadingStates.invoiceTypesVideoLoading}
                                onVideoLoaded={videoLoadingStates.setInvoiceTypesVideoLoaded}
                                labelPrefix="Archivo de Tipos de Comprobantes"
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Stack>
    );
}; 