import { Box, Flex, VStack, Skeleton, Table, Thead, Tr, Th, Tbody, Td, SimpleGrid, HStack } from "@chakra-ui/react";
import { InvoiceTabsSkeleton } from "./tabs/InvoiceTabsSkeleton";

export const InvoiceDetailsSkeleton = () => {
  return (
    <Box py={4} px={12}>
      <Flex direction="column" gap={6}>
        {/* Header Section - invoice_detailsHeaderSection */}
        <Flex
          justify="space-between"
          align="center"
          py={4}
          mb={6}
          px={14}
          position="sticky"
          top={0}
          bg="blue.500"
          zIndex={1}
          boxShadow="md"
        >
          <Flex align="center" justify="space-between" gap={3}>
            <Skeleton height="40px" width="40px" borderRadius="md" />
            <Skeleton height="24px" width="80px" />
            <Skeleton height="20px" width="60px" borderRadius="full" />
          </Flex>
          <HStack spacing={3}>
            <Skeleton height="36px" width="120px" borderRadius="md" />
            <Skeleton height="36px" width="120px" borderRadius="md" />
          </HStack>
        </Flex>

        <VStack spacing={6} align="stretch" overflowX="auto">
          {/* Upload Errors Section */}
          <Box 
            bg="white" 
            p={6} 
            borderRadius="xl" 
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <Skeleton height="24px" width="180px" mb={4} />
            <Skeleton height="60px" width="100%" borderRadius="md" />
          </Box>

          {/* Invoice Details Section */}
          <Box 
            bg="white" 
            p={6} 
            borderRadius="xl" 
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <Flex justify="space-between" align="center" mb={4}>
              <Skeleton height="24px" width="200px" />
              <Skeleton height="40px" width="120px" borderRadius="md" />
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
              {[...Array(6)].map((_, i) => (
                <Box key={i}>
                  <Skeleton height="16px" width="100px" mb={2} />
                  <Skeleton height="20px" width="100%" />
                </Box>
              ))}
            </SimpleGrid>
          </Box>

          <InvoiceTabsSkeleton />

          {/* Purchase Order Section */}
          <Box 
            bg="white" 
            p={6} 
            borderRadius="xl" 
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <Skeleton height="24px" width="180px" mb={4} />
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              {[...Array(4)].map((_, i) => (
                <Box key={i}>
                  <Skeleton height="16px" width="120px" mb={2} />
                  <Skeleton height="20px" width="100%" />
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </VStack>
      </Flex>
    </Box>
  );
}; 