import { useState, useEffect } from 'react';
import { useToast, useDisclosure } from '@chakra-ui/react';
import { getPurchaseOrderMatches, getPurchaseOrdersBySupplier, updatePurchaseOrderMatches } from '@/services/invoices.services';
import { PurchaseOrder } from '@/types/purchaseOrder.types';

interface UsePurchaseOrderProps {
    invoiceCufe: string;
    supplierNit: string;
    invoicePo: string[];
}

export const usePurchaseOrder = ({ invoiceCufe, supplierNit, invoicePo }: UsePurchaseOrderProps) => {
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder | null>(null);
    const [availablePOs, setAvailablePOs] = useState<PurchaseOrder[]>([]);
    const [isLoadingPOs, setIsLoadingPOs] = useState(false);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetchCurrentPO = async () => {
            try {
                const data = await getPurchaseOrderMatches(invoiceCufe);
                if (data && data.length > 0) {
                    setPurchaseOrder(data[0]);
                } else {
                    setPurchaseOrder(null);
                }
            } catch (error) {
                console.error('Error fetching current PO:', error);
                toast({
                    title: "Error al cargar orden de compra actual",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        if (invoicePo.length > 0) {
            fetchCurrentPO();
        }
    }, []);

    useEffect(() => {
        const fetchAvailablePOs = async () => {
            if (!supplierNit) return;
            
            setIsLoadingPOs(true);
            try {
                const data = await getPurchaseOrdersBySupplier(supplierNit);
                if (data && Array.isArray(data)) {
                    setAvailablePOs(data);
                }
            } catch (error) {
                console.error('Error fetching available POs:', error);
                toast({
                    title: "Error al cargar órdenes de compra disponibles",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsLoadingPOs(false);
            }
        };

        fetchAvailablePOs();
    }, []);

    const handleAddPO = async (selectedPO: PurchaseOrder) => {
        if (selectedPO) {
            setPurchaseOrder(selectedPO);
            try {
                await updatePurchaseOrderMatches(invoiceCufe, [selectedPO.id]);
                toast({
                    title: "Orden de compra actualizada",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } catch (error) {
                console.error('Error updating purchase order matches:', error);
                toast({
                    title: "Error al actualizar orden de compra",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleRemovePO = async () => {
        setPurchaseOrder(null);
        try {
            await updatePurchaseOrderMatches(invoiceCufe, []);
            toast({
                title: "Orden de compra removida",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error removing purchase order matches:', error);
            toast({
                title: "Error al remover orden de compra",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return {
        purchaseOrder,
        availablePOs,
        isLoadingPOs,
        isOpen,
        onOpen,
        onClose,
        handleAddPO,
        handleRemovePO,
    };
}; 