import { Button, Flex, Icon } from "@chakra-ui/react";
import { MdSync } from "react-icons/md";
import { UploadControls } from "./UploadControls";
import { useInvoicesContext } from "@/context/invoices/invoicesContext";

export function InvoicesHeaderButtons() {

    const { handleGetDianToken, tabIndex } = useInvoicesContext();
    return (
        <Flex gap={3}>
            <Button
                colorScheme="blue"
                onClick={handleGetDianToken}
                size="sm"
                leftIcon={<Icon as={MdSync} boxSize="1.2em" />}
                px={4}
                py={2}
                borderRadius="md"
                fontWeight="500"
                _hover={{ transform: 'translateY(-2px)', boxShadow: 'md' }}
                transition="all 0.2s"
                _active={{
                    transform: 'translateY(0)',
                    boxShadow: 'sm'
                }}
            >
                {tabIndex === 0 ? 'Sincronizar compras con DIAN' : 'Sincronizar ventas con DIAN'}
            </Button>

            <UploadControls />
        </Flex>
    )
}

