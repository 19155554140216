import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { InvoiceItem } from "@/types/invoice.types";

export function InvoiceDetailsModal() {
  const { isOpen, modalData, handleModalCancel, handleModalConfirm } =
    useInvoiceDetails();
  return (
    <Modal isOpen={isOpen} onClose={handleModalCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>¡Alerta!</ModalHeader>
        <ModalBody>
          {modalData &&
            "Hemos detectado ítems cuyas cantidades podrían ser erróneas. Por favor revisa cuidadosamente los detalles antes de confirmar:"}
          {modalData?.suspiciousItems
            .map(
              (item: any) =>
                `• ${item.inventory_item_name} (${item.converted_quantity} ${item.unit})`
            )
            .join("\n")}
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalCancel} mr={3}>
            Cancelar
          </Button>
          <Button colorScheme="blue" onClick={handleModalConfirm}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
