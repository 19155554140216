import React from "react";
import {
    Button,
    HStack,
    Image,
    Text,
    Flex,
    useToast,
} from "@chakra-ui/react";
import { Invoice } from "@/types/invoice.types";
import { BizServices } from "@/types/invoice.types";
import { UploadErpModal } from "./modals/UploadERPModal";
import { UploadPOSModal } from "./modals/UploadPOSModal";
import { InvoiceDetailsDianAcknowledgementModal } from "./InvoiceDetailsDianAcknowledgementModal";
import { useInvoicesHandler } from "@/context/shared/invoicesHandlerContext";
import { useErpForm } from "@/context/shared/erpFormContext";
import { confirmDianAcknowledgment } from "@/utils/invoiceDetails/dianHandlers";

interface InvoceDetailsUploadControlsProps {
    isDetailView?: boolean;
    currentInvoice?: Invoice | null;
}

export const InvoceDetailsUploadControls: React.FC<InvoceDetailsUploadControlsProps> = ({
    isDetailView = false,
    currentInvoice = null,
}) => {
    const {
        services,
        isUploadModalOpen,
        setIsUploadModalOpen,
        isPOSModalOpen,
        setIsPOSModalOpen,
        isDianModalOpen,
        setIsDianModalOpen,
        isErpLoading,
        uploadTarget,
        handleUploadClick,
        handleDianClick,
    } = useInvoicesHandler();

    const {
        handleUploadInvoices,
    } = useErpForm();

    const toast = useToast();

    return (
        <>
            <HStack spacing={3}>
                {services?.pos === 'intuipos' && (
                    <Button
                        size="sm"
                        bg="white"
                        color="gray.800"
                        borderColor="gray.200"
                        variant="outline"
                        _hover={{ bg: 'gray.50' }}
                        onClick={() => handleUploadClick('intuipos')}
                    >
                        <Flex align="center" gap={2}>
                            <Image src="/intuipos_icon.png" alt="Intuipos" boxSize="16px" />
                            <Text>Subir a Intuipos</Text>
                        </Flex>
                    </Button>
                )}

                {services?.erp === 'siigo' && (
                    <Button
                        size="sm"
                        bg="white"
                        color="gray.800"
                        borderColor="gray.200"
                        variant="outline"
                        _hover={{ bg: 'gray.50' }}
                        onClick={() => handleUploadClick('siigo')}
                    >
                        <Flex align="center" gap={2}>
                            <Image src="/siigo_blue_icon.png" alt="Siigo" boxSize="16px" />
                            <Text>Subir a Siigo</Text>
                        </Flex>
                    </Button>
                )}

                {services?.erp === 'helisa' && (
                    <Button
                        size="sm"
                        bg="white"
                        color="gray.800"
                        borderColor="gray.200"
                        variant="outline"
                        _hover={{ bg: 'gray.50' }}
                        onClick={() => handleUploadClick('helisa')}
                    >
                        <Flex align="center" gap={2}>
                            <Image src="/helisa_logo.png" alt="Helisa" width="16px" />
                            <Text>Subir a Helisa</Text>
                        </Flex>
                    </Button>
                )}

                {services?.dian && (
                    <Button
                        size="sm"
                        bg="white"
                        color="gray.800"
                        borderColor="gray.200"
                        variant="outline"
                        _hover={{ bg: 'gray.50' }}
                        onClick={handleDianClick}
                    >
                        <Flex align="center" gap={2}>
                            <Image src="/dian_icon.png" alt="DIAN" boxSize="16px" />
                            <Text>Acuse de recibo</Text>
                        </Flex>
                    </Button>
                )}
            </HStack>

            <UploadErpModal
                isOpen={isUploadModalOpen}
                onClose={() => {
                    setIsUploadModalOpen(false);
                }}
                onConfirm={() => handleUploadInvoices()}
                invoice={currentInvoice}
                uploadTarget={uploadTarget as 'siigo' | 'helisa'}
                isLoading={isErpLoading}
            />

            <UploadPOSModal
                isOpen={isPOSModalOpen}
                onClose={() => setIsPOSModalOpen(false)}
                onConfirm={async (storeSelections) => {
                    if (storeSelections) {
                        await handleUploadInvoices();
                    }
                }}
            />

            <InvoiceDetailsDianAcknowledgementModal
                isOpen={isDianModalOpen}
                onClose={() => setIsDianModalOpen(false)}
                onConfirm={async () => {
                    await confirmDianAcknowledgment(toast, () => setIsDianModalOpen(false));
                    await handleUploadInvoices();
                }}
            />
        </>
    );
}; 