import {
  Box,
  Flex,
  VStack
} from "@chakra-ui/react";
import { InvoicePDFViewer } from "@/components/invoiceDetails/InvoicePDFViewer";
import { InvoiceDetailsModal } from "@/components/invoiceDetails/InvoiceDetailsModal";
import { InvoiceDetailsHeaderSection } from "@/components/invoiceDetails/header/InvoiceDetailsHeaderSection";
import { UploadErrorsSection } from "@/components/invoiceDetails/UploadErrorsSection";
import { InvoiceDetailsSection } from "@/components/invoiceDetails/InvoiceDetailsSection";
import { InvoiceTabsContainer } from "@/components/invoiceDetails/tabs/InvoiceTabsContainer";
import { PurchaseOrderSection } from "@/components/invoiceDetails/purchaseOrders/PurchaseOrderSection";


export default function InvoiceDetail() {
  return (
    <>
      <InvoiceDetailsHeaderSection />
      <Box pb={4} px={12}>
        <Flex direction="column" gap={6}>
          {/* Header */}
          {/* Left Column - Invoice Details & Products */}
          <InvoiceDetailsView />

        </Flex>
        <InvoiceDetailsModal />
      </Box>
      <InvoicePDFViewer />
      <Box height={150} />
    </>
  );
}

function InvoiceDetailsView() {
  return (
    <VStack spacing={6} align="stretch" overflowX="auto">
      {/* Upload Errors Section */}
      <UploadErrorsSection />
      {/* Invoice Details */}
      <InvoiceDetailsSection />
      <InvoiceTabsContainer />
      {/* Purchase Order Section */}
      <PurchaseOrderSection />
    </VStack>
  );
}



