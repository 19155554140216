import { createContext, useContext } from "react";
import { Invoice, BizServices } from "@/types/invoice.types";

export const InvocesTableContext = createContext<{
    filteredInvoices: Invoice[];
    selectedInvoices: string[];
    handleSelectInvoice: (invoice: Invoice) => void;
    debouncedProviderFilter: string;
    isLoading: boolean;
    services: BizServices | undefined;
} | null>(null)


export const UseInvocesTableContext = () => {
    const context = useContext(InvocesTableContext);

    if (!context) {
        throw new Error("Invoices Table must be used inside a InvocesTableProvider");
    }

    return context;
};
