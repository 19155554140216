import { useInvoicesContext } from "@/context/invoices/invoicesContext";
import { Button, Center, FormControl, FormLabel, Heading, HStack, Icon, Input, ModalBody, ModalContent, ModalOverlay, VStack, Text, Select, AlertDescription, Alert, Box, AlertTitle, AlertIcon, Badge, useToast } from "@chakra-ui/react"

import { Modal } from "@chakra-ui/react"

import { MdCheckCircle, MdError, MdSync } from "react-icons/md"
import { useEffect, useState } from "react";

export function DianModal() {

    const { tabIndex, isSuccessOpen, onSuccessClose, isImporting, importStatus, urlToken, setUrlToken, handleImportInformation, selectedDaysPeriod, setSelectedDaysPeriod, errorMessage, importInvoicesResult } = useInvoicesContext();
    const toast = useToast();

    // Show toast when syncedInvoices changes and there is a successful import
    useEffect(() => {
        if (importInvoicesResult?.summary?.successful === 0 && importInvoicesResult?.summary?.failed === 0 && importInvoicesResult?.summary?.skipped === 0) {
            toast({
                title: "No se encontraron facturas para sincronizar, aumenta el período de sincronización o verifica que el token sea correcto",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });

            onSuccessClose();
            return;
        }

        if (importStatus === "success" && importInvoicesResult?.updated_cufes?.length) {
            
            // If all invoices were skipped (already existed), show a specific message
            if (importInvoicesResult?.summary?.successful === 0 && importInvoicesResult?.summary?.failed === 0 && importInvoicesResult?.summary?.skipped > 0) {
                toast({
                    title: "Todo está sincronizado",
                    description: "Todas las facturas ya están sincronizadas en el sistema.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                });
                
                onSuccessClose();
                return;
            }

            

            toast({
                title: "Sincronización completada",
                description: (
                    <Box maxH="200px" overflowY="auto">
                        <VStack align="stretch" spacing={2}>
                            <Box>
                                {(importInvoicesResult?.summary?.failed ?? 0) > 0 && (
                                    <Badge colorScheme="red" fontSize="sm">
                                        Errores: {importInvoicesResult?.summary?.failed}
                                    </Badge>
                                )}
                            </Box>
                            
                            {(importInvoicesResult?.summary?.successful ?? 0) > 0 && (
                                <Box>
                                    <Text fontWeight="medium" mb={1}> {importInvoicesResult?.summary?.successful} facturas sincronizadas</Text>
                                    {importInvoicesResult?.updated_cufes?.filter(inv => inv.status === "success").map((invoice, index) => (
                                        <Box
                                            key={`success-${invoice.cufe}-${index}`}
                                            bg="white"
                                            p={2}
                                            mb={1}
                                            borderRadius="md"
                                            border="1px"
                                            borderColor="green.100"
                                        >
                                            <Text fontSize="xs" color="gray.700" fontFamily="monospace">
                                                {invoice.cufe.substring(0, 20)}...
                                            </Text>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            
                            {(importInvoicesResult?.summary?.failed ?? 0) > 0 && (
                                <Box>
                                    <Text fontWeight="medium" mb={1}>Facturas con error</Text>
                                    {importInvoicesResult?.updated_cufes?.filter(inv => inv.status === "error").map((invoice, index) => (
                                        <Box
                                            key={`error-${invoice.cufe}-${index}`}
                                            bg="white"
                                            p={2}
                                            mb={1}
                                            borderRadius="md"
                                            border="1px"
                                            borderColor="red.100"
                                        >
                                            <Text fontSize="xs" color="gray.700" fontFamily="monospace">
                                                {invoice.cufe.substring(0, 20)}...
                                            </Text>
                                            <Text fontSize="xs" color="red.500">
                                                {invoice.reason}
                                            </Text>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </VStack>
                    </Box>
                ),
                status: "success",
                duration: 19000,
                isClosable: true,
                position: "top-right",
            });
            
            // Close modal after a short delay
            onSuccessClose();
            
        } else if (importStatus === "success" && importInvoicesResult?.updated_cufes?.length === 0) {
            toast({
                title: "Sincronización completada",
                description: "¡No se encontraron facturas para sincronizar!",
                status: "info",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });
            
            // Close modal after a short delay
            onSuccessClose();
        }
    }, [importStatus, importInvoicesResult, toast, onSuccessClose]);

    const renderImportButton = () => {
        if (importStatus === "loading") {
            return (
                <Button
                    colorScheme="blue"
                    w="full"
                    isLoading={true}
                    loadingText="Importando información..."
                >
                    Importar información
                </Button>
            );
        }

        if (importStatus === "success") {
            return (
                <Button
                    colorScheme="blue"
                    w="full"
                    leftIcon={<Icon as={MdCheckCircle} color="green.500" boxSize="1.2em" />}
                >
                    Importación exitosa
                </Button>
            );
        }

        if (importStatus === "error") {
            return (
                <Button
                    colorScheme="blue"
                    w="full"
                    leftIcon={<Icon as={MdError} color="red.500" boxSize="1.2em" />}
                >
                    Error en la importación
                </Button>
            );
        }

        return (
            <Button
                colorScheme="blue"
                w="full"
                isDisabled={!urlToken}
                onClick={handleImportInformation}
            >
                Importar información
            </Button>
        );
    };



    return (
        <Modal isOpen={isSuccessOpen} onClose={onSuccessClose} closeOnOverlayClick={!isImporting} isCentered size="md">
            <ModalOverlay />
            <ModalContent maxW="500px" p={5} borderRadius="md">
                <ModalBody>
                    <VStack spacing={5} align="stretch">
                        {/* Logos */}
                        <HStack justify="center" spacing={4}>
                            <Center bg="white" w="60px" h="60px" borderRadius="md" boxShadow="sm">
                                <Icon as={MdSync} color="blue.500" boxSize="2em" />
                            </Center>
                            <Text fontSize="xl" fontWeight="bold" color="blue.500">
                                DIAN
                            </Text>
                        </HStack>

                        {/* Título */}
                        <Heading size="md" textAlign="center">
                            {`Sincronizar facturas de ${tabIndex === 0 ? "compra" : "venta"} de la DIAN`}
                        </Heading>

                        {/* Mensaje informativo */}
                        <Text textAlign="center" color="gray.700">
                            Se ha enviado el token de acceso al correo registrado en el RUT de la persona natural que se autenticó en el sistema. <Text as="span" fontWeight="bold">El acceso estará disponible por 60 minutos.</Text>
                        </Text>

                        {/* Instrucciones */}
                        <VStack align="start" spacing={2}>
                            <Text fontWeight="bold">Cómo obtener el token de acceso</Text>
                            <VStack align="start" spacing={1} pl={4}>
                                <Text>1. Ingresa en el correo electrónico.</Text>
                                <Text>2. Haz clic derecho en el botón "Ingrese aquí" y seleccione "Copiar la dirección del enlace".</Text>
                                <Text>3. Pegue en el siguiente campo la URL del token de acceso.</Text>
                            </VStack>
                        </VStack>

                        {/* Campo de token */}
                        <FormControl>
                            <FormLabel fontWeight="bold">Token de acceso</FormLabel>
                            <Input
                                placeholder="Pega aquí la URL del token"
                                value={urlToken}
                                onChange={(e) => setUrlToken(e.target.value)}
                                isDisabled={isImporting}
                            />
                        </FormControl>



                        {/* Selector de período */}
                        <FormControl>
                            <FormLabel fontWeight="bold">Importar facturas desde</FormLabel>
                            <Select
                                value={selectedDaysPeriod}
                                onChange={(e) => setSelectedDaysPeriod(e.target.value)}
                                isDisabled={isImporting}
                            >
                                <option value="1">Hace 1 día</option>
                                <option value="5">Últimos 5 días</option>
                                <option value="15">Últimos 15 días</option>
                                <option value="30">Últimos 30 días</option>
                                <option value="custom" disabled>Personalizado</option>
                            </Select>
                        </FormControl>

                        {/* Mensajes de error */}
                        {errorMessage && (
                            <Alert status="error" borderRadius="md">
                                <AlertIcon />
                                <Box>
                                    <AlertTitle>Error</AlertTitle>
                                    <AlertDescription>
                                        {errorMessage}
                                    </AlertDescription>
                                </Box>
                            </Alert>
                        )}

                        {/* Estado de importación */}
                        {importStatus === 'loading' && (
                            <VStack>
                                <Text>Esto puede tardar unos minutos...</Text>
                                <Text fontSize={'xs'} color={'gray.600'}>Por favor, no cierre esta ventana.</Text>
                            </VStack>
                        )}

                        {/* Botones */}
                        <VStack spacing={3}>
                            {renderImportButton()}
                        </VStack>

                        {/* Texto de ayuda */}
                        <Text textAlign="center" fontSize="sm" color="gray.600">
                            ¿Problemas conectándote? Escríbenos, te ayudaremos.
                        </Text>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
