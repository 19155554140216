import { useState, useEffect } from 'react';
import { fetchSuppliers } from '@/services/invoices.services';
import { SingleValue } from 'chakra-react-select';

export const useInvoiceFilters = (setSearchParams: (params: URLSearchParams) => void, searchParams: URLSearchParams) => {
  const [searchSuppliers, setSearchSuppliers] = useState<{ label: string; value: string }[]>([]);

  // Load suppliers for the filter
  useEffect(() => {
    fetchSuppliers().then((data) => {
      const options = data.map((supplier) => ({
        label: supplier["name"],
        value: supplier["nit"],
      }));

      options.unshift({
        label: "Todos",
        value: "all",
      });

      setSearchSuppliers(options);
    });
  }, []);

  // Filter handlers
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value || null;
    const newParams = new URLSearchParams(searchParams);
    
    if (date) {
      newParams.set('startDate', date);
    } else {
      newParams.delete('startDate');
    }
    
    newParams.set('page', '1');
    setSearchParams(newParams);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value || null;
    const newParams = new URLSearchParams(searchParams);
    
    if (date) {
      newParams.set('endDate', date);
    } else {
      newParams.delete('endDate');
    }
    
    newParams.set('page', '1');
    setSearchParams(newParams);
  };

  const handleUploadStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newUploadStatus = e.target.value;
    const newParams = new URLSearchParams(searchParams);
    
    newParams.set('uploadStatus', newUploadStatus);
    newParams.set('page', '1');
    setSearchParams(newParams);
  };

  const handleProviderFilterChange = (
    obj: SingleValue<{ label: string; value: string }>
  ) => {
    const newParams = new URLSearchParams(searchParams);
    
    if (!obj || obj.value === "all") {
      newParams.delete('providerInputValue');
    } else {
      newParams.set('providerInputValue', obj.value);
    }
    
    newParams.set('page', '1');
    setSearchParams(newParams);
  };

  return {
    searchSuppliers,
    handleStartDateChange,
    handleEndDateChange,
    handleUploadStatusChange, 
    handleProviderFilterChange
  };
}; 