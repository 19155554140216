import React from 'react';
import { 
  Box, 
  Container, 
  Text, 
  Stack, 
  Link,
  useColorModeValue 
} from '@chakra-ui/react';

const FooterSection = () => {
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.900')} py={10}>
      <Container maxW="container.xl">
        <Stack direction={{ base: 'column', md: 'row' }} justify="space-between" align="center" spacing={8}>
          <Text>&copy; {new Date().getFullYear()} Cifrato. Todos los derechos reservados.</Text>
          <Stack direction="row" spacing={6}>
            <Link href="#">Términos</Link>
            <Link href="#">Privacidad</Link>
            <Link href="#">Contacto</Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default FooterSection; 