import config from "@/config/env.config";
import { getToken } from "@/utils/auth";

export const fetchBizName = async (): Promise<string | undefined> => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/name`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json()
        return data.biz_name;
    } catch (error) {
        console.error('Failed to fetch protected data:', error);
        return undefined;
    }
};