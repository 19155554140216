import { createErpHandler } from "@/services/erp/erpHandler.interface";
import { BizServices, Invoice, InvoiceUploadsState } from "@/types/invoice.types";
import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useInvoicesHandler } from "./invoicesHandlerContext";

import { usePaymentAccounts } from "@/hooks/erpForm/useAccounts";
import { useCostCenters } from "@/hooks/erpForm/useCostCenters";
import { useInvoiceTypes } from "@/hooks/erpForm/useInvoiceTypes";
import { usePaymentMethods } from "@/hooks/erpForm/usePaymentMethods";
import { useRetentionAccounts } from "@/hooks/erpForm/useRetentionAccounts";
import { useRetentions } from "@/hooks/erpForm/useRetentions";
import { useSellers } from "@/hooks/erpForm/useSellers";
import { useStores } from "@/hooks/posForm/useStores";
import { updateInvoice } from "@/services/invoices.services";
import { UploadTargetType } from "@/types/erpShared.types";
import { handleTargetResponse } from "../../utils/erpForm/handleResponseUtils";
import { updateIntuiposInvoice } from "../../utils/erpForm/updateInvoiceUtils";
import { uploadInvoicesToTarget } from "../../utils/erpForm/uploadInvoicesUtils";
import { reloadInvoices } from "../../utils/invoiceDetails/reloadInvoices";

interface ErpFormContextType {
    // State
    services: BizServices | undefined;
    isAllCreditNotes: boolean;
    allInvoicesUploaded: boolean;
    someInvoicesUploaded: boolean;
    // Invoice types
    invoiceTypesHook: ReturnType<typeof useInvoiceTypes>;
    // Payment methods
    paymentMethodsHook: ReturnType<typeof usePaymentMethods>;
    // Cost centers
    costCentersHook: ReturnType<typeof useCostCenters>;
    // Account
    accountsHook: ReturnType<typeof usePaymentAccounts>;
    // Common retentions
    retentionsHook: ReturnType<typeof useRetentions>;
    retentionAccountsHook: ReturnType<typeof useRetentionAccounts>;
    // Sales (Siigo)
    sellersHook: ReturnType<typeof useSellers>;
    // Stores
    storesHook: ReturnType<typeof useStores>;
    // Uploads  
    hasFailedUploads: boolean;

    handleUploadInvoices: () => Promise<void>;
}

export const ErpFormContext = createContext<ErpFormContextType>({} as ErpFormContextType);

export const useErpForm = () => {
    return useContext(ErpFormContext);
};

export const ErpFormProvider = ({ children }: { children: React.ReactNode }) => {
    const toast = useToast();

    const {
        services,
        invoices: selectedInvoices,
        setInvoices: setSelectedInvoices,
        uploadTarget,
        setIsErpLoading,
        setIsUploadModalOpen,
        setIsPOSModalOpen,
        setAvailableStores
    } = useInvoicesHandler();

    // Get ERP service based on type (Strategy Pattern)
    const erpHandler = useMemo(() => {
        try {
            return services?.erp ? createErpHandler(services.erp) : null;
        } catch (error) {
            console.error('Error creating ERP service:', error);
            return null;
        }
    }, [services?.erp]);

    // State for invoice uploads
    const allInvoicesUploaded = useMemo(() => {
        if (selectedInvoices.length === 0 || !services?.erp) return false;
        return selectedInvoices.every(invoice =>
            (invoice?.uploads?.[services?.erp as keyof InvoiceUploadsState]?.uploaded || false) &&
            invoice?.uploads?.siigo?.consecutive !== "Procesando"
        );
    }, [selectedInvoices, services?.erp]);

    const someInvoicesUploaded = useMemo(() => {
        if (selectedInvoices.length === 0 || !services?.erp) return false;
        return selectedInvoices.some(invoice =>
            (invoice?.uploads?.[services?.erp as keyof InvoiceUploadsState]?.uploaded || false) &&
            invoice?.uploads?.siigo?.consecutive !== "Procesando"
        );
    }, [selectedInvoices, services?.erp]);

    const hasFailedUploads = selectedInvoices?.some(invoice => invoice?.uploads?.[uploadTarget as keyof InvoiceUploadsState]?.failed);

    const storesHook = useStores(services, selectedInvoices, setSelectedInvoices, setAvailableStores);
    const invoiceTypesHook = useInvoiceTypes(erpHandler, selectedInvoices, setSelectedInvoices);
    const accountsHook = usePaymentAccounts(erpHandler, selectedInvoices, setSelectedInvoices);
    const paymentMethodsHook = usePaymentMethods(erpHandler, accountsHook.selectedAccount, invoiceTypesHook.selectedInvoiceType?.type || '', selectedInvoices, setSelectedInvoices);
    const costCentersHook = useCostCenters(erpHandler, invoiceTypesHook.selectedInvoiceType?.cost_center_default, selectedInvoices, setSelectedInvoices);
    const retentionsHook = useRetentions(erpHandler, selectedInvoices, setSelectedInvoices);
    const retentionAccountsHook = useRetentionAccounts(erpHandler);
    const sellersHook = useSellers(erpHandler, selectedInvoices, setSelectedInvoices);

    // Invoice state
    const [isAllCreditNotes, setIsAllCreditNotes] = useState(false);

    // Effect to detect if it is a credit note
    useEffect(() => {
        if (selectedInvoices.length > 0) {
            // Verify if all invoices are credit notes
            const allAreReturns = selectedInvoices.every(invoice => invoice.is_return);
            setIsAllCreditNotes(allAreReturns);
        } else {
            setIsAllCreditNotes(false);
        }
    }, [selectedInvoices]);

    const updateInvoices = async (nonUploadedInvoices: Invoice[]) => {
        if (nonUploadedInvoices.length === 0) return;

        const updatedInvoices = [];
        for (const invoice of nonUploadedInvoices) {
            let updatedInvoice = { ...invoice };
            if (storesHook.storeSelections && storesHook.storeSelections[invoice.id]) {
                updatedInvoice = updateIntuiposInvoice(invoice, storesHook.storeSelections[invoice.id]);
            }
    
            // Update the invoice in the database
            updatedInvoices.push(updatedInvoice);
            await updateInvoice({ invoice: updatedInvoice });
        }

        setSelectedInvoices(updatedInvoices);
    }

    const getNonUploadedInvoices = () => {
        // Filter already uploaded invoices
        return selectedInvoices.filter(invoice => {
            switch (uploadTarget) {
                case 'intuipos':
                    return !invoice.uploads?.intuipos?.uploaded;
                case 'siigo':
                    return !invoice.uploads?.siigo?.uploaded || invoice.uploads?.siigo?.consecutive === "Procesando";
                case 'helisa':
                    return !invoice.uploads?.helisa?.uploaded;
                default:
                    return true;
            }
        });
    }



    // Handle the upload
    const handleUploadInvoices = async (
    ): Promise<void> => {
        if (!uploadTarget || uploadTarget === 'dian') return;

        setIsErpLoading(true);
        try {

            const nonUploadedInvoices = getNonUploadedInvoices();
            await updateInvoices(nonUploadedInvoices);
        
            const invoicesToUpload = nonUploadedInvoices.map(invoice => invoice.id);

            const data = await uploadInvoicesToTarget(invoicesToUpload, uploadTarget);
            await handleTargetResponse(data, uploadTarget, toast);

            await reloadInvoices(selectedInvoices, setSelectedInvoices);

            setIsUploadModalOpen(false);
            setIsPOSModalOpen(false);

        } catch (error) {
            console.error('Error durante la carga:', error);
            toast({
                position: "top-right",
                title: "Error en el proceso",
                description: "Ha ocurrido un error al procesar la solicitud",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });

        }
        setIsErpLoading(false);
    };

    // Provide the context
    return (
        <ErpFormContext.Provider
            value={{
                // State
                services,
                isAllCreditNotes,
                allInvoicesUploaded,
                someInvoicesUploaded,
                // Uploads
                hasFailedUploads,
                // Invoice types
                invoiceTypesHook,
                // Payment methods
                paymentMethodsHook,
                // Cost centers
                costCentersHook,
                // Account
                accountsHook,
                // Retentions
                retentionsHook,
                retentionAccountsHook,
                // Sales
                sellersHook,
                // Stores
                storesHook,
                // Upload
                handleUploadInvoices,
            }}
        >
            {children}
        </ErpFormContext.Provider>
    );
};
