import { InfoIcon } from "@chakra-ui/icons";
import { Th, Tooltip } from "@chakra-ui/react";


export const POSTableHeader: React.FC = () => (
    <>
        <Th
            px={4}
            py={3}
            fontSize="xs"
            color="gray.600"
            borderBottomWidth="2px"
            borderBottomColor="gray.200"
        >
            Descripción
        </Th>
        <Th
            px={4}
            py={3}
            fontSize="xs"
            color="gray.600"
            borderBottomWidth="2px"
            borderBottomColor="gray.200"
        >
            Producto Intuipos
        </Th>
        <Th
            px={4}
            py={3}
            fontSize="xs"
            color="gray.600"
            borderBottomWidth="2px"
            borderBottomColor="gray.200"
            isNumeric
        >
            Cantidad
        </Th>
        <Th
            px={4}
            py={3}
            fontSize="xs"
            color="gray.600"
            borderBottomWidth="2px"
            borderBottomColor="gray.200"
            isNumeric
        >
            Precio Unitario
        </Th>
        <Th
            px={4}
            py={3}
            fontSize="xs"
            color="gray.600"
            borderBottomWidth="2px"
            borderBottomColor="gray.200"
            isNumeric
        >
            Total
        </Th>
        <Th
            px={4}
            py={3}
            fontSize="xs"
            color="gray.600"
            borderBottomWidth="2px"
            borderBottomColor="gray.200"
            display="flex"
            gap={1}
        >
            Conversión
            <Tooltip
                label="Ajusta la conversión de cantidades y precios de la factura al POS, por ejemplo, de cajas a unidades."
                hasArrow
                placement="right"
                maxW="400px"
            >
                <InfoIcon color="blue.500" cursor="help" />
            </Tooltip>
        </Th>

        <Th
            px={4}
            py={3}
            fontSize="xs"
            color="gray.600"
            borderBottomWidth="2px"
            borderBottomColor="gray.200"
        >
            Impuestos
        </Th>
    </>
);
