import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { BizServices, Invoice } from "@/types/invoice.types";
import {
  fetchBizServices,
} from "@/services/invoices.services";
import { useSearchParams } from "react-router-dom";
import { SingleValue } from "chakra-react-select";
import { useInvoicesHandler } from "../shared/invoicesHandlerContext";
import { useTabManagement } from "@/hooks/invoices/useTabManagement";
import { useInvoicesList } from "@/hooks/invoices/useInvoicesList";
import { useInvoiceFilters } from "@/hooks/invoices/useInvoiceFilters";
import { useDianOperations } from "@/hooks/invoices/useDianOperations";
import { DianSyncResponse } from "@/types/dian.types";

interface InvoicesContextProps {
  selectedInvoices: Invoice[];
  setSelectedInvoices: (invoices: Invoice[]) => void;
  handleSelectInvoice: (invoice: Invoice) => void;
  handleUploadComplete: () => void;
  handlePageChange: (newPage: number) => void;
  handlePageSizeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  services: BizServices | undefined;
  searchSuppliers: { label: string; value: string }[];
  filteredInvoices: Invoice[];
  totalInvoices: number;
  hasMore: boolean;
  currentPage: number;
  pageSize: number;
  startDate: string | null;
  endDate: string | null;
  uploadStatus: string;
  providerInputValue: string;
  isLoading: boolean;
  handleStartDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEndDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUploadStatusChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleProviderFilterChange: (
    obj: SingleValue<{ label: string; value: string }>
  ) => void;
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  // ************** Invoice HEADER | DIAN TOKEN **************
  isImporting: boolean;
  importStatus: "idle" | "loading" | "success" | "error";
  importProgress: number;
  handleGetDianToken: () => Promise<void>;
  handleImportInformation: () => Promise<void>;
  urlToken: string;
  setUrlToken: (urlToken: string) => void;
  isLoadingOpen: boolean;
  isSuccessOpen: boolean;
  onLoadingClose: () => void;
  onSuccessClose: () => void;
  selectedDaysPeriod: string;
  setSelectedDaysPeriod: (period: string) => void;
  importInvoicesResult: DianSyncResponse | undefined;
  errorMessage: string;
  tabIndex: number;
  setTabIndex: (index: number) => void;
  handleTabIndexChange: (index: number) => void;
  // ************** Upload Controls **************
  handleDianClick: () => void;
  handleConfirmDianAcknowledgment: () => Promise<void>;
  isDianModalOpen: boolean;
  setIsDianModalOpen: (isOpen: boolean) => void;
}

const InvoicesContext = createContext<InvoicesContextProps | undefined>(
  undefined
);

export const InvoicesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Use the shared invoice data context
  const {
    invoices: selectedInvoices,
    setInvoices: setSelectedInvoices,
    services,
    setServices,
    isUploadModalOpen,
  } = useInvoicesHandler();

  // Parse URL params
  const currentPage = parseInt(searchParams.get("page") || "1");
  const pageSize = parseInt(searchParams.get("pageSize") || "10");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const uploadStatus = searchParams.get("uploadStatus") || "all";
  const providerInputValue = searchParams.get("providerInputValue") || "";

  // Use custom hooks
  const {
    tabIndex,
    handleTabIndexChange
  } = useTabManagement(searchParams, setSearchParams, () => {
    // Reset the selected invoices when changing tabs
    setSelectedInvoices([]);
  });

  const {
    filteredInvoices,
    isLoading,
    totalInvoices,
    hasMore,
    loadInvoices,
    handleSelectInvoice: selectInvoice,
    handleUploadComplete,
    handlePageChange: pageChange,
    handlePageSizeChange: pageSizeChange
  } = useInvoicesList(
    currentPage,
    pageSize,
    startDate,
    endDate,
	searchParams.get("include_credit_notes") === "true" ? true : null,
	searchParams.get("include_general_purchases") === "true" ? true : null,
	searchParams.get("include_purchases") === "true" ? true : null,
	searchParams.get("tabIndex") === "1" ? true : null,
    uploadStatus,
    providerInputValue,
    tabIndex,
    setSelectedInvoices
  );

  const {
    searchSuppliers,
    handleStartDateChange,
    handleEndDateChange,
    handleUploadStatusChange,
    handleProviderFilterChange
  } = useInvoiceFilters(setSearchParams, searchParams);

  const {
    isImporting,
    importStatus,
    importProgress,
    urlToken,
    setUrlToken,
    selectedDaysPeriod,
    setSelectedDaysPeriod,
    importInvoicesResult,
    errorMessage,
    isDianModalOpen,
    setIsDianModalOpen,
    isLoadingOpen,
    isSuccessOpen,
    onLoadingClose,
    onSuccessClose,
    handleDianClick,
    handleConfirmDianAcknowledgment,
    handleGetDianToken,
    handleImportInformation
  } = useDianOperations(selectedInvoices, handleUploadComplete);

  // Track previous state of isUploadModalOpen
  const prevIsUploadModalOpenRef = useRef(isUploadModalOpen);
  
  // Load services
  useEffect(() => {
    fetchBizServices().then((data) => {
      setServices(data?.services);
    });
  }, []);
  
  // Load invoices when search params change
  useEffect(() => {
    loadInvoices();
  }, [searchParams]);

  // Load invoices only when modal closes (changes from true to false) (update state when uploadcompleted)
  useEffect(() => {
    // Check if we need to reload based on isUploadModalOpen change (true → false)
    const shouldReloadOnModalChange = 
      prevIsUploadModalOpenRef.current === true && isUploadModalOpen === false;
    
    if (shouldReloadOnModalChange) {
      loadInvoices();
    }
    
    // Update ref for next check
    prevIsUploadModalOpenRef.current = isUploadModalOpen;
  }, [isUploadModalOpen]);

  useEffect(() => {
    console.log('invoices', selectedInvoices);
  }, [isUploadModalOpen]);

  // useEffect(() => {
  //   console.log('selectedInvoices', selectedInvoices);
  // }, [selectedInvoices]);

  // Ensure search params are in sync with URL
  useEffect(() => {
    if (searchParams.toString() !== window.location.search.substring(1)) {
      setSearchParams(new URLSearchParams(window.location.search));
    }
  }, [searchParams, setSearchParams]);

  // Create wrapper functions with proper parameters
  const handleSelectInvoice = (invoice: Invoice) => {
    selectInvoice(invoice, selectedInvoices);
  };

  const handlePageChange = (newPage: number) => {
    pageChange(newPage, setSearchParams, searchParams);
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    pageSizeChange(e, setSearchParams, searchParams);
  };

  return (
    <InvoicesContext.Provider
      value={{
        selectedInvoices,
        setSelectedInvoices,
        handleSelectInvoice,
        handleUploadComplete,
        handlePageChange,
        handlePageSizeChange,
        services,
        searchSuppliers,
        filteredInvoices,
        totalInvoices,
        hasMore,
        currentPage,
        pageSize,
        startDate,
        endDate,
        uploadStatus,
        providerInputValue,
        isLoading,
        handleStartDateChange,
        handleEndDateChange,
        handleUploadStatusChange,
        handleProviderFilterChange,
        searchParams,
        setSearchParams,
        isImporting,
        importStatus,
        importProgress,
        handleGetDianToken,
        handleImportInformation,
        urlToken,
        setUrlToken,
        isLoadingOpen,
        isSuccessOpen,
        onLoadingClose,
        onSuccessClose,
        selectedDaysPeriod,
        setSelectedDaysPeriod,
        importInvoicesResult,
        errorMessage,
        tabIndex,
        setTabIndex: (index: number) => handleTabIndexChange(index),
        handleTabIndexChange,
        // Upload Controls
        handleDianClick,
        handleConfirmDianAcknowledgment,
        isDianModalOpen,
        setIsDianModalOpen,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};

export const useInvoicesContext = () => {
  const context = useContext(InvoicesContext);
  if (!context) {
    throw new Error(
      "useInvoicesContext debe ser usado dentro de un InvoicesProvider"
    );
  }
  return context;
};

