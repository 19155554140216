import { useState, useEffect } from 'react';

export const useTabManagement = (
  searchParams: URLSearchParams,
  setSearchParams: (params: URLSearchParams) => void,
  onTabChange?: () => void
) => {
  // Initialize from query parameters if available, otherwise use 0 by default
  const [tabIndex, setTabIndex] = useState(() => {
    return parseInt(searchParams.get("tabIndex") || "0");
  });

  // Function to update tabIndex and save it in the query parameters
  const handleTabIndexChange = (index: number) => {
    setTabIndex(index);
    
    const newParams = new URLSearchParams(searchParams);
    newParams.set('tabIndex', index.toString());
    setSearchParams(newParams);
    
    if (onTabChange) {
      onTabChange();
    }
  };

  // Update tab index when searchParams changes
  useEffect(() => {
    const tabFromParams = parseInt(searchParams.get("tabIndex") || "0");
    if (tabIndex !== tabFromParams) {
      setTabIndex(tabFromParams);
    }
  }, [searchParams, tabIndex]);

  return {
    tabIndex,
    setTabIndex,
    handleTabIndexChange
  };
}; 