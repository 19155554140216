import React from 'react';
import { 
  Box, 
  Button, 
  Container, 
  Heading, 
  Text, 
  Stack, 
  AspectRatio,
  Icon,
  useColorModeValue 
} from '@chakra-ui/react';
import { FaPlayCircle, FaCalendarAlt, FaSignInAlt } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';

// Motion components
const MotionBox = motion(Box);
const MotionStack = motion(Stack);

const DemoVideoSection = () => {
  const mainBg = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const videoBorderColor = useColorModeValue('blue.300', 'blue.500');
  
  return (
    <Box py={{ base: 16, md: 24 }} bg={mainBg} id="demo-video">
      <Container maxW="container.xl">
        <Stack spacing={16} align="center">
          <MotionStack 
            textAlign="center" 
            maxW="3xl" 
            mx="auto" 
            spacing={5}
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <Heading 
              as="h2" 
              size="xl" 
              bgGradient="linear(to-r, blue.500, teal.500)" 
              bgClip="text"
            >
              Cifrato en acción
            </Heading>
            <Text fontSize="lg" color={textColor}>
              Mira cómo nuestra IA revoluciona el manejo de la contabilidad en tu firma
            </Text>
          </MotionStack>
          
          <MotionBox 
            w="full" 
            maxW="4xl" 
            mx="auto" 
            rounded="xl" 
            overflow="hidden" 
            shadow="2xl"
            borderWidth="1px"
            borderColor={videoBorderColor}
            position="relative"
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
            whileHover={{ 
              transform: "translateY(-8px)",
              boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)"
            }}
          >
            {/* Video decoration elements */}
            <Box 
              position="absolute" 
              top="0" 
              left="0" 
              right="0" 
              h="8px" 
              bgGradient="linear(to-r, blue.400, teal.400)" 
              zIndex="1"
            />
            
            <AspectRatio ratio={1628/1080}>
              <video
                controls
                autoPlay
                loop
                muted
                poster="https://via.placeholder.com/1280x720?text=Cifrato+Demo+Video"
              >
                <source 
                  src="https://storage.googleapis.com/onboarding-videos-cifrato/demohd.mp4" 
                  type="video/mp4" 
                />
                Tu navegador no soporta videos HTML5.
              </video>
            </AspectRatio>
          </MotionBox>
          
          <MotionStack 
            direction={{ base: 'column', sm: 'row' }} 
            spacing={6} 
            pt={4}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Button 
              size="lg" 
              colorScheme="blue" 
              leftIcon={<FaCalendarAlt />}
              as="a"
              href="https://cal.com/juan-pisco/cifrato-demo"
              px={8}
              py={7}
              shadow="md"
              _hover={{
                transform: "translateY(-2px)",
                shadow: "lg",
              }}
              transition="all 0.2s"
            >
              Agendar mi demo
            </Button>
            <Button 
              size="lg" 
              variant="outline" 
              colorScheme="blue"
              as={RouterLink}
              to="/login"
              leftIcon={<FaSignInAlt />}
              px={8}
              py={7}
              _hover={{
                transform: "translateY(-2px)",
                shadow: "md",
                bg: useColorModeValue('blue.50', 'rgba(66, 153, 225, 0.08)'),
              }}
              transition="all 0.2s"
            >
              Ir a la plataforma
            </Button>
          </MotionStack>
        </Stack>
      </Container>
    </Box>
  );
};

export default DemoVideoSection; 