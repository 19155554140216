import { Box, Table, Thead, Tr, Th, Tbody, Td, Skeleton } from "@chakra-ui/react";

export function InvoiceTabsSkeleton() {
    return (
        <Box
            bg="white"
            borderRadius="xl"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
            overflow="hidden"
        >
            <Table variant="simple" size="sm">
                <Thead bg="gray.50">
                    <Tr>
                        <Th px={4} py={3} borderBottomWidth="2px" borderBottomColor="gray.200">
                            <Skeleton height="16px" width="100px" />
                        </Th>
                        <Th px={4} py={3} borderBottomWidth="2px" borderBottomColor="gray.200">
                            <Skeleton height="16px" width="80px" />
                        </Th>
                        <Th px={4} py={3} borderBottomWidth="2px" borderBottomColor="gray.200">
                            <Skeleton height="16px" width="100px" />
                        </Th>
                        <Th px={4} py={3} borderBottomWidth="2px" borderBottomColor="gray.200">
                            <Skeleton height="16px" width="80px" />
                        </Th>
                        <Th px={4} py={3} borderBottomWidth="2px" borderBottomColor="gray.200">
                            <Skeleton height="16px" width="100px" />
                        </Th>
                        <Th px={4} py={3} borderBottomWidth="2px" borderBottomColor="gray.200">
                            <Skeleton height="16px" width="80px" />
                        </Th>
                        <Th px={4} py={3} borderBottomWidth="2px" borderBottomColor="gray.200">
                            <Skeleton height="16px" width="80px" />
                        </Th>
                        <Th px={4} py={3} borderBottomWidth="2px" borderBottomColor="gray.200">
                            <Skeleton height="16px" width="80px" />
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {[...Array(5)].map((_, index) => (
                        <Tr key={index} _hover={{ bg: "gray.50" }}>
                            <Td px={4} py={3}>
                                <Skeleton height="20px" width="150px" />
                            </Td>
                            <Td px={4} py={3}>
                                <Skeleton height="20px" width="100px" />
                            </Td>
                            <Td px={4} py={3}>
                                <Skeleton height="20px" width="150px" />
                            </Td>
                            <Td px={4} py={3}>
                                <Skeleton height="20px" width="120px" />
                            </Td>
                            <Td px={4} py={3}>
                                <Skeleton height="20px" width="100px" />
                            </Td>
                            <Td px={4} py={3}>
                                <Skeleton height="20px" width="100px" />
                            </Td>
                            <Td px={4} py={3}>
                                <Skeleton height="20px" width="80px" />
                            </Td>
                            <Td px={4} py={3}>
                                <Skeleton height="32px" width="32px" borderRadius="md" />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}
