import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { Invoice, InvoiceItem } from '@/types/invoice.types';
import { updateInvoice } from '@/services/invoices.services';
import { calculateConversions } from '@/utils/invoices';
import { validateConvertedQuantity } from "@/utils/conversionFactors";
import { ErpTaxOption } from '@/types/erpProducts.types';
import { ModalData } from '@/types/invoiceDetail.types';


export const useInvoiceOperations = (
  invoice: Invoice | null, 
  setInvoice: (invoice: Invoice) => void,
  erpTaxesOptions?: ErpTaxOption[]
) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState<ModalData>(null);
  const [isSaving, setIsSaving] = useState(false);

  const processUpdateInvoice = async (updatedInvoice: Invoice) => {
    try {
      const data = await updateInvoice({ invoice: updatedInvoice });
      toast({
        title:
          data.status === "success"
            ? "Factura actualizada"
            : data.detail || "Error al actualizar la factura",
        status: data.status === "success" ? "success" : "error",
        duration: 3000,
        isClosable: true,
      });
    } catch {
      toast({
        title: "Error al guardar",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleModalConfirm = async () => {
    if (invoice) {
      await processUpdateInvoice(invoice);
    }
    setIsSaving(false);
    onClose();
    setModalData(null);
  };

  const handleModalCancel = () => {
    onClose();
    setModalData(null);
  };

  const handleSaveInvoice = async () => {
    if (!invoice) return;

    setIsSaving(true);
    try {
      // Calculate any missing conversions
      const updatedInvoice = calculateConversions(invoice);
      
      // Ensure tax_erp_percentage is preserved after conversions
      if (updatedInvoice.items && updatedInvoice.items.length > 0) {
        updatedInvoice.items.forEach((item, idx) => {
          const taxNames = Object.keys(item.taxes || {});
          taxNames.forEach(taxName => {
            const tax = item.taxes[taxName];
            if (tax.tax_erp_id) {
              const matchingTax = erpTaxesOptions?.find(opt => opt.value === tax.tax_erp_id);
              if (matchingTax && matchingTax.percentage) {
                updatedInvoice.items[idx].taxes[taxName].tax_erp_percentage = matchingTax.percentage;
              }
            }
          });
        });
      }

      setInvoice(updatedInvoice);

      // Check for suspicious items by validating each item's quantity and unit
      const suspiciousItems =
        updatedInvoice.items?.filter((item: any) => {
          if (!item.converted_quantity) {
            return false;
          }
          return !validateConvertedQuantity(item.converted_quantity, item.unit);
        }) || [];

      // If suspicious items exist, open confirmation modal
      if (suspiciousItems.length > 0) {
        setModalData({ updatedInvoice, suspiciousItems });
        onOpen();
        return;
      }

      // Otherwise, proceed immediately
      await processUpdateInvoice(updatedInvoice);
    } catch {
      toast({
        title: "Error al guardar",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return {
    isOpen,
    modalData,
    setModalData,
    isSaving,
    setIsSaving,
    handleModalCancel,
    handleModalConfirm,
    handleSaveInvoice,
    processUpdateInvoice
  };
}; 