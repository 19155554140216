import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Alert,
    AlertIcon,
    AlertDescription,
    Box,
    Flex,
    Spinner,
} from "@chakra-ui/react";
import { Invoice } from "@/types/invoice.types";
import { fetchStoreIds } from "@/services/invoices.services";
import { useInvoicesHandler } from "@/context/shared/invoicesHandlerContext";
interface UploadPOSModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (storeSelections?: Record<string, string>) => void;
}

export const UploadPOSModal: React.FC<UploadPOSModalProps> = ({
    isOpen,
    onClose,
    onConfirm,

}) => {
    const [storeIds, setStoreIds] = useState<Record<string, string>>({});
    const [storeSelections, setStoreSelections] = useState<Record<string, string>>({});
    const [isLoadingStores, setIsLoadingStores] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [invalidItems, setInvalidItems] = useState<{invoiceId: string, items: any[]}[]>([]);

    const { invoices: selectedInvoices } = useInvoicesHandler();

    useEffect(() => {
        if (!isOpen) return;

        const loadData = async () => {
            setIsLoadingStores(true);
            try {
                // Obtener IDs de tiendas
                const storeIdsResponse = await fetchStoreIds();
                setStoreIds(storeIdsResponse.store_ids);

                // Inicializar selecciones de tienda directamente desde cada invoice
                const initialSelections: Record<string, string> = {};

                // Para cada factura, usar su store_id si existe
                selectedInvoices.forEach(invoice => {
                    if (invoice.store_id) {
                        initialSelections[invoice.id] = invoice.store_id;
                    } else if (Object.values(storeIdsResponse.store_ids).length === 1) {
                        // Si solo hay una tienda y la factura no tiene store_id, autoseleccionarla
                        const onlyStoreName = Object.values(storeIdsResponse.store_ids)[0];
                        initialSelections[invoice.id] = onlyStoreName;
                    }
                });
                
                setStoreSelections(initialSelections);
                
                // Verify invalid items (without inventory_item_name)
                const invalidItemsList = selectedInvoices
                    .map(invoice => {
                        const invalidInvoiceItems = invoice.items
                            .filter(item => !item.inventory_item_name)
                            .map(item => ({
                                description: item.description,
                                quantity: item.quantity,
                                unit: item.unit
                            }));
                        
                        return invalidInvoiceItems.length > 0 
                            ? { invoiceId: invoice.id, items: invalidInvoiceItems } 
                            : null;
                    })
                    .filter(Boolean) as {invoiceId: string, items: any[]}[];
                
                setInvalidItems(invalidItemsList);
            } catch (error) {
                console.error('Error al cargar datos iniciales:', error);
            } finally {
                setIsLoadingStores(false);
            }
        };

        loadData();
    }, [isOpen]);

    const allInvoicesUploaded = selectedInvoices.every(invoice =>
        invoice.uploads?.intuipos?.uploaded
    );

    const someInvoicesUploaded = selectedInvoices.some(invoice =>
        invoice.uploads?.intuipos?.uploaded
    ) && !allInvoicesUploaded;

    const hasErrors = invalidItems.length > 0;

    // Verificar si hay facturas de gasto
    const hasGeneralPurchases = selectedInvoices.some(invoice => 
        invoice.is_general_purchase
    );

    // Verificar si hay facturas sin tienda seleccionada
    const invoicesWithoutStore = selectedInvoices.filter(invoice => {
        return !storeSelections[invoice.id] && !invoice.store_id;
    });
    
    const hasMissingStores = invoicesWithoutStore.length > 0;

    const canConfirm = (() => {
        if (allInvoicesUploaded) return false;
        if (hasErrors) return false;
        if (hasGeneralPurchases) return false;
        if (hasMissingStores) return false;

        // Verificar que todas las facturas tengan una tienda asignada
        return selectedInvoices.every(invoice => {
            return storeSelections[invoice.id] || 
                (invoice.store_id && Object.values(storeIds).includes(invoice.store_id));
        });
    })();

    const handleConfirm = async () => {
        setIsConfirmLoading(true);
        try {
            // Preparar storeSelections finales combinando las selecciones del modal
            // con los store_id de cada invoice
            const finalStoreSelections: Record<string, string> = {};
            
            selectedInvoices.forEach(invoice => {
                // Priorizar la selección del modal si existe
                if (storeSelections[invoice.id]) {
                    finalStoreSelections[invoice.id] = storeSelections[invoice.id];
                } 
                // Si no hay selección del modal pero hay store_id en el invoice, usarlo
                else if (invoice.store_id) {
                    finalStoreSelections[invoice.id] = invoice.store_id;
                }
            });
            
            // Llamar al callback de confirmación con las selecciones finales de tiendas
            await onConfirm(finalStoreSelections);
        } catch (error) {
            console.error('Error al cargar a Intuipos:', error);
        } finally {
            setIsConfirmLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent maxWidth="80vw">
                <ModalHeader>
                    Cargar a Intuipos
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {hasGeneralPurchases ? (
                        <Alert
                            status="warning"
                            mb={4}
                        >
                            <AlertIcon />
                            <AlertDescription>
                                Las facturas de gasto no aplican para POS.
                            </AlertDescription>
                        </Alert>
                    ) : isLoadingStores ? (
                        <Flex justify="center" align="center" h="100px">
                            <Spinner size="md" color="blue.500" />
                            <Text ml={3}>Cargando datos...</Text>
                        </Flex>
                    ) : (
                        <>
                            {allInvoicesUploaded && (
                                <Alert
                                    status="warning"
                                    mb={4}
                                    borderRadius="lg"
                                    boxShadow="sm"
                                >
                                    <AlertIcon />
                                    <AlertDescription>
                                        Todas las facturas seleccionadas ya han sido cargadas a Intuipos.
                                        No es posible volver a cargarlas.
                                    </AlertDescription>
                                </Alert>
                            )}

                            {someInvoicesUploaded && (
                                <Alert
                                    status="info"
                                    mb={4}
                                    borderRadius="lg"
                                    boxShadow="sm"
                                >
                                    <AlertIcon />
                                    <AlertDescription>
                                        Algunas facturas seleccionadas ya han sido cargadas a Intuipos.
                                        Solo se cargarán las facturas que no han sido cargadas previamente.
                                    </AlertDescription>
                                </Alert>
                            )}
                            
                            {hasErrors && (
                                <Alert
                                    status="error"
                                    mb={4}
                                    borderRadius="lg"
                                    boxShadow="sm"
                                >
                                    <AlertIcon />
                                    <AlertDescription>
                                        Existen productos sin nombre de inventario asignado.
                                        Para cargar a Intuipos, todos los productos deben tener un nombre de inventario.
                                    </AlertDescription>
                                </Alert>
                            )}

                            {hasErrors && (
                                <Box mt={4} mb={4}>
                                    <Text fontWeight="bold" mb={2}>Productos con errores:</Text>
                                    {invalidItems.map((invoiceError, index) => {
                                        const currentInvoice = selectedInvoices.find(inv => inv.id === invoiceError.invoiceId);
                                        return (
                                            <Box key={index} mb={4} p={3} borderWidth="1px" borderRadius="md" borderColor="red.300" bg="red.50">
                                                <Text fontWeight="semibold">
                                                    Factura: {currentInvoice?.invoice_id} - {currentInvoice?.sender_party_name}
                                                </Text>
                                                <Table variant="simple" size="sm" mt={2}>
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Producto</Th>
                                                            <Th isNumeric>Cantidad</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {invoiceError.items.map((item, idx) => (
                                                            <Tr key={idx}>
                                                                <Td>{item.description}</Td>
                                                                <Td isNumeric>{item.quantity} {item.unit}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            )}
                            
                            {hasMissingStores && (
                                <Alert
                                    status="warning"
                                    mb={4}
                                    borderRadius="lg"
                                    boxShadow="sm"
                                >
                                    <AlertIcon />
                                    <AlertDescription>
                                        {invoicesWithoutStore.length === 1
                                            ? "Hay una factura sin tienda seleccionada. Por favor seleccione una tienda."
                                            : `Hay ${invoicesWithoutStore.length} facturas sin tienda seleccionada. Por favor seleccione una tienda para cada factura.`}
                                    </AlertDescription>
                                </Alert>
                            )}
                            
                            {!hasErrors && !hasMissingStores && !allInvoicesUploaded && (
                                <Alert
                                    status="success"
                                    mb={4}
                                    borderRadius="lg"
                                    boxShadow="sm"
                                >
                                    <AlertIcon />
                                    <AlertDescription>
                                        {selectedInvoices.length === 1
                                            ? "La factura está lista para ser cargada a Intuipos."
                                            : `${selectedInvoices.length} facturas están listas para ser cargadas a Intuipos.`}
                                    </AlertDescription>
                                </Alert>
                            )}
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={handleConfirm}
                        isDisabled={!canConfirm || allInvoicesUploaded || hasErrors || hasGeneralPurchases}
                        isLoading={isConfirmLoading || isLoadingStores}
                    >
                        Cargar a Intuipos
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}; 

