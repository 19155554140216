export const optionsTaxes = [
    { label: "IVA 0%", value: "IVA 0%" },
    { label: "IVA 16%", value: "IVA 16%" },
    { label: "IVA 5%", value: "IVA 5%" },
    { label: "INC (COMPRAS)", value: "INC (COMPRAS)" },
    { label: "IVA (VAR)%", value: "IVA (VAR)%" },
    { label: "IVA 19%", value: "IVA 19%" },
    { label: "Bolsas", value: "Bolsas" },
    { label: "IC Porcentual", value: "IC Porcentual" },
    { label: "INC 9%", value: "INC 9%" },
    { label: "IBUA < 6gr/100 ml", value: "IBUA < 6gr/100 ml" },
    {
      label: "IBUA >= 6gr < 10gr / 100 ml",
      value: "IBUA >= 6gr < 10gr / 100 ml",
    },
    { label: "IBUA >= 10gr / 100 ml", value: "IBUA >= 10gr / 100 ml" },
    { label: "ICUI", value: "ICUI" },
    { label: "I.G.V 18", value: "I.G.V 18" },
  ];
  
  export const optionsConversionMeasurements = [
    { label: "un", value: "un" },
    { label: "kg", value: "kg" },
    { label: "lb", value: "lb" },
    { label: "gr", value: "gr" },
    { label: "mg", value: "mg" },
    { label: "ml", value: "ml" },
    { label: "l", value: "l" },
    { label: "oz", value: "oz" },
  ];