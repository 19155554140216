import { 
  Box, 
  Button, 
  Container, 
  Heading, 
  Text, 
  Stack, 
  useColorModeValue 
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const CTASection = () => {
  return (
    <Box bg={useColorModeValue('blue.600', 'blue.900')} py={{ base: 12, md: 16 }}>
      <Container maxW="container.xl">
        <Stack direction={{ base: 'column', md: 'row' }} justify="space-between" align="center" spacing={8}>
          <Stack spacing={4} maxW="2xl" color="white">
            <Heading as="h2" size="xl">
              Impulsa el crecimiento de tu firma contable hoy
            </Heading>
            <Text fontSize="lg">
              Únete a las firmas contables que están liderando la revolución digital en el sector. Comienza tu prueba gratuita de 30 días sin compromiso.
            </Text>
          </Stack>
          <Button 
            size="lg" 
            colorScheme="whiteAlpha" 
            px={8}
            as={RouterLink}
            to="https://cal.com/juan-pisco/cifrato-demo"
          >
            Comenzar Ahora
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default CTASection; 