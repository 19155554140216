interface IConfig {
    apiUrl: string | undefined;
    firebaseConfig: {
        apiKey: string | undefined;
        authDomain: string | undefined;
        projectId: string | undefined;
        storageBucket: string | undefined;
        messagingSenderId: string | undefined;
        appId: string | undefined;
        measurementId: string | undefined;
    };
}

const {
    VITE_APP_API_URL,
    VITE_APP_FIREBASE_API_KEY,
    VITE_APP_FIREBASE_AUTH_DOMAIN,
    VITE_APP_FIREBASE_PROJECT_ID,
    VITE_APP_FIREBASE_STORAGE_BUCKET,
    VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
    VITE_APP_FIREBASE_APP_ID,
    VITE_APP_FIREBASE_MEASUREMENT_ID,
} = import.meta.env;


const config: IConfig = {
    apiUrl: VITE_APP_API_URL,
    firebaseConfig: {
        apiKey: VITE_APP_FIREBASE_API_KEY,
        authDomain: VITE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: VITE_APP_FIREBASE_PROJECT_ID,
        storageBucket: VITE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: VITE_APP_FIREBASE_APP_ID,
        measurementId: VITE_APP_FIREBASE_MEASUREMENT_ID,
    }
};

export default config;