import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { fetchInvoiceByCufe } from '@/services/invoices.services';
import { Invoice } from '@/types/invoice.types';

export const useInvoiceById = (
  id: string | undefined, 
  invoice: Invoice | undefined, 
  setInvoice: (invoice: Invoice) => void
) => {
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchInvoice = async () => {
      setLoading(true);
      try {
        const response = await fetchInvoiceByCufe(id!);
        if (response) {
          setInvoice(response);
        }
      } catch (error) {
        console.error("Error fetching invoice:", error);
        toast({
          title: "Error fetching invoice",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (id && !invoice) {
      fetchInvoice();
    } else {
      setLoading(false);
    }
  }, [id, invoice, setInvoice, toast]);

  return { loading };
}; 