import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { fetchBizErp, getBizErpProducts } from '@/services/invoices.services';
import { ErpProductOption } from '@/types/erpProducts.types';

export const useErpProducts = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [erpProductsOptions, setErpProductsOptions] = useState<ErpProductOption[] | undefined>([]);

  useEffect(() => {
    const loadErpProducts = async () => {
      try {
        // First fetch bizErp to ensure it's available // TODO: Use erpHandler to avoid fetching bizErp multiple times
        const bizErp = await fetchBizErp();

        // Then fetch and map products // TODO: Use erpHandler to avoid fetching products multiple times
        const productsData = await getBizErpProducts();

        const options: ErpProductOption[] | undefined = productsData?.products
          .filter((product) => product.active)
          .map((product) => ({
            label: `${product.name} - ${product.code}`,
            value: product.code,
          }));

        if (bizErp?.products && options) {
          for (const key in bizErp.products) {
            if (bizErp.products.hasOwnProperty(key)) {
              const value = bizErp.products[key];
              const option: ErpProductOption | undefined = options.find(
                (option) => option.value === key
              );
              if (option) {
                option.accountNumber = value;
                option.set = true;
              }
            }
          }
        }
        
        setErpProductsOptions(options);
      } catch (error) {
        console.error("Error loading ERP products:", error);
        toast({
          title: "Error al cargar productos ERP",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    loadErpProducts();
  }, []);

  return {
    loading,
    erpProductsOptions
  };
}; 