import { Box, Container, Heading, Text, Flex, useColorModeValue, keyframes, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FC } from 'react';

// Sample company logos - replace these with actual client logos
const companyLogos = [
  { id: 1, name: 'Empresa 1', logoUrl: 'https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Media%20Library%20Image%207.png' },
  { id: 2, name: 'Empresa 2', logoUrl: 'https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Media%20Library%20Image%206-1.png' },
  { id: 3, name: 'Empresa 3', logoUrl: 'https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Media%20Library%205-1.png' },
  { id: 4, name: 'Empresa 4', logoUrl: 'https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Media%20Library%204-1.png' },
  { id: 5, name: 'Empresa 5', logoUrl: 'https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Media%20Library%203-1.png' },
  { id: 6, name: 'Empresa 6', logoUrl: 'https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Media%20Library%202-1.png' },
  { id: 7, name: 'Empresa 7', logoUrl: 'https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Media%20Library%201-1.png' },
  // { id: 8, name: 'Empresa 8', logoUrl: 'https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Google%20Logo%20PNG.png' },
];

// Animation for the infinite scrolling effect
const scrollAnimation = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
`;

const MotionFlex = motion(Flex);
const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const ClientsSection: FC = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const gradientStart = useColorModeValue('rgba(247, 250, 252, 1)', 'rgba(26, 32, 44, 1)');
  const gradientEnd = useColorModeValue('rgba(247, 250, 252, 0)', 'rgba(26, 32, 44, 0)');
  
  // Create a doubled array of logos to create seamless infinite scroll
  const doubledLogos = [...companyLogos, ...companyLogos];
  
  return (
    <Box as="section" py={{ base: 16, md: 24 }} bg={bgColor} width="100%">
      <Container maxW="container.xl" centerContent>
        <MotionBox
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          textAlign="center"
          mb={6}
        >
          <MotionHeading 
            as="h2" 
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }} 
            bgGradient="linear(to-r, blue.500, teal.500)" 
            bgClip="text"
            mb={4}
          >
            50+ empresas ya utilizan nuestra IA para mejorar su contabilidad
          </MotionHeading>
        </MotionBox>
        
        <MotionBox
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
          mb={12} 
          textAlign="center"
        >
          <Text 
            fontSize={{ base: 'md', md: 'lg' }} 
            color={textColor}
            maxW="800px"
          >
            Únete a las empresas líderes que están transformando su gestión contable con nuestra tecnología
          </Text>
        </MotionBox>
        
        <MotionBox 
          position="relative" 
          width="100%" 
          overflow="hidden"
          py={8}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.4 }}
          sx={{
            '&::before, &::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              width: '15%',
              height: '100%',
              zIndex: 2,
              pointerEvents: 'none'
            },
            '&::before': {
              left: 0,
              background: `linear-gradient(to right, ${gradientStart}, ${gradientEnd})`
            },
            '&::after': {
              right: 0,
              background: `linear-gradient(to left, ${gradientStart}, ${gradientEnd})`
            }
          }}
        >
          <MotionFlex
            animation={`${scrollAnimation} 25s linear infinite`}
            width="fit-content"
            alignItems="center"
            gap={10}
            px={6}
          >
            {doubledLogos.map((company, index) => (
              <MotionBox 
                key={`${company.id}-${index}`} 
                minWidth="200px" 
                height="120px" 
                position="relative"
                filter="grayscale(0.7) opacity(0.8)"
                transition="all 0.3s"
                _hover={{ 
                  filter: "grayscale(0) opacity(1)",
                  transform: "scale(1.05)" 
                }}
                whileHover={{ y: -5 }}
              >
                <Flex 
                  justifyContent="center" 
                  alignItems="center" 
                  height="100%"
                  px={4}
                >
                  <Image 
                    src={company.logoUrl} 
                    alt={company.name} 
                    objectFit="contain" 
                    width="100%"
                    height="100%"
                  />
                </Flex>
              </MotionBox>
            ))}
          </MotionFlex>
        </MotionBox>
      </Container>
    </Box>
  );
};

export default ClientsSection; 