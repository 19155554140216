import { Button, InputGroup, ModalFooter, Text } from "@chakra-ui/react";
import { Select as ChakraSelect, GroupBase, CreatableSelect, SingleValue } from "chakra-react-select";

import { Box, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Modal } from "@chakra-ui/react";
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { formatCurrency } from "@/utils/currencyFormat";
import { selectStyles } from "@/components/ui/SelectStyles";
import { InvoiceItem } from "@/types/invoice.types";
import { useErpForm } from "@/context/shared/erpFormContext";
import { ErpTaxOption } from "@/types/erpProducts.types";
import { AccountOption } from "@/types/invoiceDetail.types";


interface TaxesModalProps {
    isOpen: boolean;
    onClose: () => void;
    item: InvoiceItem;
    index: number;
    isInvoiceUploaded: boolean;
}


export const TaxesModal: React.FC<TaxesModalProps> = ({ isOpen, onClose, item, index, isInvoiceUploaded }) => {
    const {
        invoice,
        setInvoice,
        services,
        erpTaxesOptions,
    } = useInvoiceDetails();

    const {
        accountsHook,
    } = useErpForm();

    const erpService = services?.erp;

    const taxes = Object.keys(item.taxes);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="lg">Administrar Impuestos</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box mb={4}>
                        <label style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <input
                                type="checkbox"
                                checked={item.exclude_withholding || false}
                                disabled={isInvoiceUploaded}
                                onChange={(e) => {
                                    const newItems = [...invoice.items];
                                    newItems[index] = {
                                        ...item,
                                        exclude_withholding: e.target.checked
                                    };
                                    setInvoice({ ...invoice, items: newItems });
                                }}
                            />
                            <Text fontSize="sm">Excluir de Retefuente</Text>
                        </label>
                    </Box>
                    {taxes.map((taxName, taxIndex) => (
                        <Box key={taxIndex} mb={6}>
                            <Text fontWeight="bold" fontSize="sm">{`${taxName}:`}</Text>
                            <Box mt={4}>
                                <label style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <input
                                        type="checkbox"
                                        checked={item.taxes[taxName]?.use_as_cost_value || false}
                                        disabled={isInvoiceUploaded}
                                        onChange={(e) => {
                                            const newItems = [...invoice.items];
                                            newItems[index].taxes[taxName] = {
                                                ...item.taxes[taxName],
                                                use_as_cost_value: e.target.checked
                                            };
                                            setInvoice({ ...invoice, items: newItems });
                                        }}
                                    />
                                    <Text fontSize="sm">Usar como mayor valor del costo:</Text>
                                </label>
                            </Box>
                            {!item.taxes[taxName]?.use_as_cost_value && (
                                <>
                                    <Box my={5}>
                                        <Text fontSize="sm">Impuesto en ERP:</Text>
                                        <ChakraSelect
                                            value={item.taxes[taxName]?.tax_erp_id ? {
                                                label: erpTaxesOptions?.find(opt => opt.value === item.taxes[taxName]?.tax_erp_id)?.label || '',
                                                value: item.taxes[taxName]?.tax_erp_id
                                            } : null}
                                            options={erpTaxesOptions}
                                            onChange={(selectedOption: SingleValue<ErpTaxOption>) => {
                                                const newItems = [...invoice.items];
                                                const selectedTaxOption = erpTaxesOptions?.find(opt => opt.value === selectedOption?.value);
                                                newItems[index].taxes[taxName] = {
                                                    ...item.taxes[taxName],
                                                    tax_erp_id: selectedOption?.value || '',
                                                    tax_account: selectedOption?.account || '',
                                                    tax_erp_percentage: selectedTaxOption?.percentage || 0
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                            placeholder="Seleccionar impuesto..."
                                            menuPosition="fixed"
                                            isLoading={!erpTaxesOptions?.length}
                                            isDisabled={isInvoiceUploaded}
                                            size="md"
                                            chakraStyles={selectStyles}
                                        />
                                    </Box>
                                    {(erpService?.toLowerCase() === 'helisa' || item.taxes[taxName]?.tax_erp_id) && (
                                        <Box my={5}>
                                            <Text fontSize="sm">Cuenta contable:</Text>
                                            <ChakraSelect
                                                value={item.taxes[taxName]?.tax_account ? {
                                                    label: accountsHook.accountOptions?.find(opt => opt.value === item.taxes[taxName]?.tax_account)?.label || '',
                                                    value: item.taxes[taxName]?.tax_account
                                                } : null}
                                                options={accountsHook.accountOptions}
                                                onChange={(selectedOption: SingleValue<AccountOption>) => {
                                                    const newItems = [...invoice.items];
                                                    newItems[index].taxes[taxName] = {
                                                        ...item.taxes[taxName],
                                                        tax_account: selectedOption?.value || ''
                                                    };
                                                    setInvoice({ ...invoice, items: newItems });
                                                }}
                                                placeholder="Seleccionar cuenta..."
                                                menuPosition="fixed"
                                                isLoading={!accountsHook.accountOptions?.length}
                                                isDisabled={isInvoiceUploaded}
                                                size="md"
                                                isClearable
                                                isSearchable
                                                chakraStyles={selectStyles}
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                            <InputGroup my={5}>
                                <Text fontSize="md">Total:</Text>
                                <Text fontSize="md" ml={2} fontWeight="bold">{`${formatCurrency(String(item.taxes[taxName]?.tax_amount || 0))}`}</Text>
                            </InputGroup>
                        </Box>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onClose} isDisabled={isInvoiceUploaded}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};