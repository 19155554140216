import { Flex, HStack, IconButton, Text, Select } from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useInvoicesContext } from "@/context/invoices/invoicesContext";

export function PaginationSection() {
  const {
    currentPage,
    pageSize,
    totalInvoices,
    hasMore,
    handlePageChange,
    handlePageSizeChange,
  } = useInvoicesContext();
  return (
    <Flex justify="space-between" align="center" mt={4}>
      <HStack>
        <Text>Facturas por página:</Text>
        <Select value={pageSize} onChange={handlePageSizeChange} w="70px">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </Select>
      </HStack>
      <HStack>
        <Text>{`${(currentPage - 1) * pageSize + 1}-${Math.min(
          currentPage * pageSize,
          totalInvoices
        )} de ${totalInvoices}`}</Text>
        <IconButton
          icon={<FaChevronLeft />}
          aria-label="Previous page"
          isDisabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        <IconButton
          icon={<FaChevronRight />}
          aria-label="Next page"
          isDisabled={!hasMore}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </HStack>
    </Flex>
  );
}
