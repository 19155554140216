import { useNavigate } from "react-router-dom";
import { Td, Checkbox, HStack, Tooltip, Box, Flex, Image, useToast, Spinner } from "@chakra-ui/react";

import { Invoice, BizServices } from "@/types/invoice.types";
import { checkERPReadiness, checkPOSReadiness, getTaxxaStatusLabel } from "@/utils/invoices";
import { UseInvocesTableContext } from "@/context/invoices/invoicesTableContext";
import { ClickableRow } from "./ClickableRow";
import { InvoiceTypeBadge } from "./InvoicesTypeBadge";
import { InvoicesTableSkeleton } from "./InvoicesTableSkeleton";
import { InvoicesTableEmpty } from "./InvoicesTableEmpty";
import { useInvoicesContext } from "@/context/invoices/invoicesContext";


export function InvoicesTable() {
    const { filteredInvoices, selectedInvoices, handleSelectInvoice, services } = UseInvocesTableContext()
    const { tabIndex } = useInvoicesContext();
    const navigate = useNavigate();
    const toast = useToast();

    const handleCopyToClipboard = (text: string, description: string) => {
        navigator.clipboard.writeText(text).then(() => {
            toast({
                title: `${description} copiado al portapapeles`,
                status: 'info',
                duration: 3500,
                isClosable: true,
            });
        });
    };

    const showPOSStatus = services?.pos == "intuipos";
    const showDianStatus = services?.dian == "dian";

    return (
        <>
            {
                filteredInvoices.map((invoice) => {
                    return (
                        <ClickableRow
                            key={invoice.id}
                            onClick={(event) => {
                                if (event.metaKey || event.ctrlKey) {
                                    window.open(`/invoice/${invoice.id}`, '_blank');
                                } else {
                                    navigate(`/invoice/${invoice.id}`);
                                }
                            }}
                        >
                            <Td px={2} onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                    size="md"
                                    isChecked={selectedInvoices.includes(invoice.id)}
                                    onChange={() => handleSelectInvoice(invoice)}
                                />
                            </Td>
                            <Td textAlign="center">{invoice.invoice_id}</Td>
                            <Td textAlign="center">{new Date(invoice.issue_date + 'T00:00:00').toLocaleDateString()}</Td>
                            {tabIndex === 0 && (
                            <Td textAlign="center">{invoice.sender_party_name}</Td>
                            )}
                            {showPOSStatus  && (
                            	<Td>
									<Flex justifyContent="center" gap={2}>
										<Tooltip label={invoice.purchase_order_matches && invoice.purchase_order_matches.length > 0 ? "Orden de compra encontrada" : "No se encontró orden de compra"}>
											<Box opacity={invoice.purchase_order_matches && invoice.purchase_order_matches.length > 0 ? "100%" : "30%"}>
												<Image
													src="/intuipos_icon.png"
													alt="Intuipos"
													boxSize="18px"
												/>
											</Box>
										</Tooltip>
									</Flex>
                            	</Td>
                            )}
							{tabIndex === 0 && (
                            <Td textAlign="center">
                                <InvoiceTypeBadge 
                                    type={invoice.is_return ? "NC" : invoice.is_general_purchase ? "General" : "FC"}
                                    isReturn={invoice.is_return ?? false}
                                    isGeneralPurchase={invoice.is_general_purchase ?? false}
                                />
                            </Td>
							)}
                            <Td onClick={(e) => e.stopPropagation()}>
								<Flex justifyContent="center">
									<HStack spacing={2}>
										{showDianStatus && (<Tooltip
											label={getTaxxaStatusLabel(invoice.validations)}
											placement="top"
											hasArrow
											whiteSpace="pre-wrap"
											>
											<Box opacity={
												invoice.validations?.taxxa ?
													Object.values(invoice.validations.taxxa).some(v => v) ? "100%" : "30%"
													: "30%"
											}>
												<Image
													src="/dian_icon.png"
													alt="DIAN"
													boxSize="20px"
													/>
											</Box>
										</Tooltip>)}
										{invoice.uploads?.intuipos?.uploaded && (
											<Tooltip label={invoice.uploads.intuipos.order_id === "Procesando"
												? "La orden está siendo procesada en IntuiPOS. El OrderId aparecerá cuando termine el proceso."
												: `OrderId: ${invoice.uploads.intuipos.order_id}`}>
												<Box cursor={invoice.uploads.intuipos.order_id === "Procesando" ? "default" : "pointer"}
													onClick={() => {
														if (invoice.uploads?.intuipos?.order_id !== "Procesando") {
															handleCopyToClipboard(
																invoice.uploads?.intuipos?.order_id || '',
																'OrderId'
															)
														}
													}}>
													{invoice.uploads.intuipos.order_id === "Procesando" ? (
														<Spinner size="sm" color="blue.500" speed="1s" />
													) : (
														<Image
															src="/intuipos_icon.png"
															alt="Intuipos"
															boxSize="18px"
														/>
													)}
												</Box>
											</Tooltip>
										)}
										{invoice.uploads?.siigo?.uploaded && (
											<Tooltip label={invoice.uploads?.siigo?.consecutive === "Procesando"
												? "La factura está siendo procesada en Siigo. El consecutivo aparecerá cuando termine el proceso."
												: invoice.uploads?.siigo?.url
													? "Click para copiar URL"
													: `Consecutivo: ${invoice.uploads?.siigo?.consecutive}`}>
												<Box cursor={invoice.uploads?.siigo?.consecutive === "Procesando" ? "default" : "pointer"}
													onClick={() => {
														if (invoice.uploads?.siigo?.consecutive !== "Procesando") {
															handleCopyToClipboard(
																invoice.uploads?.siigo?.url || invoice.uploads?.siigo?.consecutive || '',
																invoice.uploads?.siigo?.url ? 'URL' : 'Consecutivo'
															)
														}
													}}>
													{invoice.uploads?.siigo?.consecutive === "Procesando" ? (
														<Spinner size="sm" color="blue.500" speed="1s" />
													) : (
														<Image
															src="/siigo_blue_icon.png"
															alt="Siigo"
															boxSize="17px"
															opacity="0.7"
														/>
													)}
												</Box>
											</Tooltip>
										)}
										{invoice.uploads?.helisa?.uploaded && (
											<Tooltip label="Click para copiar URL">
												<Box cursor="pointer" onClick={() => handleCopyToClipboard(
													invoice.uploads?.helisa?.url || '',
													'URL'
												)}>
													<Image
														src="/helisa_logo.png"
														alt="Helisa"
														boxSize="17px"
														opacity="0.7"
													/>
												</Box>
											</Tooltip>
										)}
									</HStack>
								</Flex>
                            </Td>
                            <Td>
								<Flex justifyContent="center">
									<HStack spacing={2}>
										{showPOSStatus && (
											<Tooltip label={
												checkPOSReadiness(invoice.items)
													? "Listo para cargar al POS"
													: `Faltan ${invoice.items.filter(item => !item.inventory_item_name).length} producto(s) por asignar nombre de inventario`
											}>
												<Box>
													<Image
														src="/intuipos_icon.png"
														alt="IntuiPOS"
														boxSize="17px"
														opacity={checkPOSReadiness(invoice.items) ? "0.7" : "0.25"}
													/>
												</Box>
											</Tooltip>
										)}

										<Tooltip label={
											checkERPReadiness(invoice.items, services?.erp)
												? "Listo para cargar al ERP"
												: services?.erp?.toLowerCase() === 'helisa'
													? `Faltan ${invoice.items.filter(item => !item.erp_account).length} producto(s) por asignar cuenta contable${invoice.items.some(item => item.taxes) ? ` y ${invoice.items.filter(item => item.taxes && Object.values(item.taxes).some(tax => !tax.tax_account)).length} producto(s) con impuestos sin cuenta asignada` : ''}`
													: `Faltan ${invoice.items.filter(item => !item.erp_type || !item.erp_account).length} producto(s) por asignar cuenta contable${invoice.items.some(item => item.taxes) ? ` y ${invoice.items.filter(item => item.taxes && Object.values(item.taxes).some(tax => !tax.tax_account || !tax.tax_erp_id)).length} producto(s) con impuestos sin cuenta ERP asignada` : ''}`
										}>
											<Box>
												<Image
													src={services?.erp?.toLowerCase() === 'helisa' ? "/helisa_logo.png" : "/siigo_blue_icon.png"}
													alt={services?.erp || "ERP"}
													boxSize={services?.erp?.toLowerCase() === 'helisa' ? "17px" : "17px"}
													opacity={checkERPReadiness(invoice.items, services?.erp) ? "0.7" : "0.25"}
												/>
											</Box>
										</Tooltip>
									</HStack>
								</Flex>
                            </Td>
                        </ClickableRow>
                    );
                })
            }
        </>
    )
}



export function ShowInvoicesTable() {
    const { isLoading, filteredInvoices } = UseInvocesTableContext()

    if (isLoading) {
        return <InvoicesTableSkeleton />
    }

    if (filteredInvoices.length === 0) {
        return <InvoicesTableEmpty />
    }

    return <InvoicesTable />
}


