import { getToken } from "@/utils/auth";
import config from "@/config/env.config";
import { InventoryItemsResponse } from "@/types/inventory.types";
import { AccountResponse, BizServices, BizServicesResponse, CostCenterResponse, Invoice, InvoiceTypeResponse, PaginatedInvoicesResponse, PaymentMethod, PaymentMethodResponse, RetentionsBySupplierResponse, StoreIdsResponse, Supplier } from "@/types/invoice.types";
import { BizErpResponse } from "@/types/bizErp.types";
import { ERPProductsResponse } from "@/types/erpProducts.types";
import { ERPTaxesResponse } from "@/types/erpTaxes.types";


export const fetchStoreIds = async (): Promise<StoreIdsResponse> => {
    const token = await getToken();
    const response = await fetch(`${config.apiUrl}/v1/business/stores`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return await response.json() as StoreIdsResponse;
};

export const fetchInvoices = async (
    page: number = 1,
    pageSize: number = 10,
    startDate?: string,
    endDate?: string,
    uploadStatus?: string,
    providerFilter?: string,
    includeCreditNotes: boolean | null = null,
    includeGeneralPurchases: boolean | null = null,
    includePurchases: boolean | null = null,
    sales: boolean | null = null
): Promise<PaginatedInvoicesResponse> => {
    try {
        const token = await getToken();
        let url = `${config.apiUrl}/v1/invoices?page=${page}&page_size=${pageSize}`;

        if (startDate) url += `&start_date=${startDate}`;
        if (endDate) url += `&end_date=${endDate}`;
        if (uploadStatus && uploadStatus !== 'all') url += `&upload_status=${uploadStatus}`;
        if (providerFilter) url += `&provider_nit=${encodeURIComponent(providerFilter)}`;
        if (includeCreditNotes !== null) url += `&include_credit_notes=${includeCreditNotes}`;
        if (includeGeneralPurchases !== null) url += `&include_general_purchases=${includeGeneralPurchases}`;
        if (includePurchases !== null) url += `&include_purchases=${includePurchases}`;
        if (sales !== null) url += `&sales=${sales}`;
        const response = await fetch(url, { headers: { Authorization: `Bearer ${token}` } });
        const data = await response.json() as PaginatedInvoicesResponse;
        return data;
    } catch (error) {
        console.error('Failed to fetch invoices:', error);
        throw error;
    }
};

export const deleteInvoiceById = async (invoiceId: string) => {
    try {
        const token = await getToken();
        await fetch(`${config.apiUrl}/v1/invoices/${invoiceId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error('Failed to delete invoice:', error);
    }
};

export const uploadInvoicesIntuipos = async (invoicesList: string[]) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/upload/intuipos`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ invoices: invoicesList }),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to upload invoices:', error);
    }
};

export const uploadInvoicesSiigo = async (invoicesList: string[]) => {
    try {
        console.log('uploadInvoicesSiigo', { invoicesList })
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/upload/siigo`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ invoices: invoicesList }),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to upload invoices:', error);
    }
};

export const getPurchaseOrderMatches = async (invoiceCufe: string) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/purchase_orders/${invoiceCufe}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to get purchase order matches:', error);
    }
}

export const uploadInvoicesHelisa = async (
    invoicesList: string[],
) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/upload/helisa`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ invoices: invoicesList }),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to upload invoices:', error);
    }
}

export const fetchSuppliers = async (): Promise<Supplier[]> => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/suppliers`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        const suppliers = await response.json()
        return suppliers.suppliers as Supplier[];
    } catch (error) {
        console.error('Failed to fetch business suppliers:', error);
        return [];
    }
};

export const fetchBizServices = async (): Promise<BizServicesResponse | undefined> => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/services`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as BizServicesResponse;
    } catch (error) {
        console.error('Failed to fetch business services:', error);
        return undefined;
    }
};

export const getBizErpType = async (): Promise<{ type: string }> => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/erp/type`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as { type: string };
    } catch (error) {
        console.error('Failed to fetch business erp type:', error);
        return { type: '' };
    }
};


export const fetchInvoiceByCufe = async (cufe: string) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/${cufe}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as Invoice;
    } catch (error) {
        console.error('Failed to fetch invoice by cufe:', error);
    }
};

export const fetchInventoryItems = async () => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/inventory`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as InventoryItemsResponse;
    } catch (error) {
        console.error('Failed to fetch inventory items:', error);
    }
};

export const fetchBizErp = async () => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/erp`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as BizErpResponse;
    } catch (error) {
        console.error('Failed to fetch Biz ERP :', error);
    }
}

export const getBizErpProducts = async () => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/erp/products`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as ERPProductsResponse;
    } catch (error) {
        console.error('Failed to fetch ERP products:', error);
    }
};

export const getBusinessErpTaxes = async (retentions: boolean = false) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/erp/taxes?retentions=${retentions}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as ERPTaxesResponse;
    } catch (error) {
        console.error('Failed to fetch ERP taxes:', error);
    }
};

export const updateInvoice = async ({ invoice }: { invoice: Invoice }) => {
    try {
        console.log("DEBUG - updateInvoice:", invoice);

        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/items`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ invoice: invoice }),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to update invoice:', error);
    }
};

export const updatePurchaseOrderMatches = async (invoiceId: string, purchaseOrderIds: string[]) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/${invoiceId}/purchase_orders`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(purchaseOrderIds),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to update purchase order matches:', error);
        throw error;
    }
};

// TODO: Add type for the response (returns {})
export const getDianAcknowledgment = async (invoicesList: any) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/dian/acknowledgment`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ invoices: invoicesList }),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to get DIAN acknowledgment:', error);
    }
};

// TODO: Add type for the response
export const updateInvoiceStoreId = async (invoiceId: string, storeId: string, storeName: string) => {
    const token = await getToken();
    const response = await fetch(`${config.apiUrl}/v1/invoices/${invoiceId}/store_id`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            store_id: storeId,
            store_name: storeName,
        }),
    });
    return await response.json();
};


export const getBizErpInvoiceTypes = async (): Promise<InvoiceTypeResponse> => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/erp/invoice_types`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as InvoiceTypeResponse;
    } catch (error) {
        console.error('Failed to fetch ERP invoice types:', error);
        return { invoice_types: [] };
    }
};

export const getBizErpPaymentMethods = async (documentType: string | undefined = "FC"): Promise<PaymentMethodResponse> => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/erp/payment_methods${documentType ? `?document_type=${documentType}` : ''}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as PaymentMethodResponse;
    } catch (error) {
        console.error('Failed to fetch ERP payment methods:', error);
        return { payment_methods: [] };
    }
};

export const getBizErpCostCenters = async (): Promise<CostCenterResponse> => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/erp/cost_centers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as CostCenterResponse;
    } catch (error) {
        console.error('Failed to fetch ERP cost centers:', error);
        return { cost_centers: [] };
    }
};

export const getAccounts = async (): Promise<AccountResponse> => {
    try {
        const token = await getToken();
        const url = `${config.apiUrl}/v1/business/accounts`;
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json()
        return data as AccountResponse;
    } catch (error) {
        console.error(`Failed to fetch accounts:`, error);
        return { accounts: [] };
    }
};

// TODO: Add type for the response (the api returns {} for Birria & Guera)
export const getBizCostCenters = async () => {
    try {
        const token = await getToken();
        const url = `${config.apiUrl}/v1/business/cost_centers`;
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.error(`Failed to fetch cost centers:`, error);
    }
};

// TODO: Add type for the response (the api returns {} for Birria & Guera)
export const getBizRetentions = async () => {
    try {
        const token = await getToken();
        const url = `${config.apiUrl}/v1/business/retentions`;
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.error(`Failed to fetch retentions:`, error);
    }
};

// TODO: Add type for the response (need an suppplier nit)
export const fetchSupplierInvoices = async (supplierNit: string) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/supplier_invoices/${supplierNit}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('API raw response:', { status: response.status, data });

        if (!Array.isArray(data)) {
            throw new Error('Invalid response format: expected array');
        }

        return data;
    } catch (error) {
        console.error('Error fetching supplier invoices:', error);
        throw error;
    }
};

export const getPurchaseOrdersBySupplier = async (supplierNit: string) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/purchase_orders/supplier/${supplierNit}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Failed to get purchase orders by supplier:', error);
        throw error;
    }
};

export const deleteInvoiceFromSiigo = async (invoiceCufe: string) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/invoices/delete/siigo/${invoiceCufe}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to delete invoice from Siigo:', error);
        throw error;
    }
};


export const getRetentionsBySupplier = async (supplierNit: string): Promise<RetentionsBySupplierResponse> => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/retentions?supplier_nit=${supplierNit}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as RetentionsBySupplierResponse;
    } catch (error) {
        console.error('Failed to fetch retentions by supplier:', error);
        return { retentions: {} };
    }
};

export const getHelisaPaymentMethods = async (): Promise<{ payment_methods: Record<string, string> }> => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/v1/business/payment_methods`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as { payment_methods: Record<string, string> };
    } catch (error) {
        console.error('Failed to fetch Helisa payment methods:', error);
        return { payment_methods: {} };
    }
};
