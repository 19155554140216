import { Box, Icon } from "@chakra-ui/react";

import { HStack } from "@chakra-ui/react";
import { MdCheck } from "react-icons/md";
import { Text } from "@chakra-ui/react";
import { StepIndicatorProps } from "@/types/onboarding.types";

export const StepIndicator: React.FC<StepIndicatorProps> = ({ 
    step, 
    isActive, 
    isCompleted, 
    onClick, 
    canNavigate 
}) => (
    <HStack 
        spacing={3} 
        w="full" 
        p={3}
        bg={isActive ? "white" : "transparent"}
        borderRadius="lg"
        border="1px solid"
        borderColor={isActive ? "gray.200" : "transparent"}
        cursor={canNavigate ? "pointer" : "default"}
        onClick={canNavigate ? onClick : undefined}
        opacity={canNavigate ? 1 : 0.7}
        _hover={canNavigate ? { bg: isActive ? "white" : "gray.50" } : undefined}
        transition="all 0.2s"
        role="button"
        aria-current={isActive ? "step" : undefined}
    >
        <Icon 
            as={isCompleted ? MdCheck : step.icon} 
            boxSize={5} 
            color={isCompleted ? "green.500" : isActive ? "blue.500" : "gray.400"} 
        />
        <Box>
            <Text 
                fontWeight="medium" 
                fontSize="sm"
                color={isActive ? "gray.900" : "gray.600"}
            >
                {step.title}
            </Text>
            <Text 
                fontSize="xs" 
                color="gray.500"
            >
                {step.description}
            </Text>
        </Box>
    </HStack>
);