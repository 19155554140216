import React, { useEffect } from 'react';
import {
    ModalFooter,
    Button,
    Text,
    Flex,
} from "@chakra-ui/react";
import { useInvoicesHandler } from '@/context/shared/invoicesHandlerContext';
import { useErpForm } from '@/context/shared/erpFormContext';

interface ModalFooterActionsProps { }

export const ModalFooterActions: React.FC<ModalFooterActionsProps> = ({

}) => {
    const { isAllCreditNotes, storesHook, allInvoicesUploaded, handleUploadInvoices } = useErpForm();
    const { setIsUploadModalOpen, isUploadModalOpen, isErpLoading, invoices, uploadTarget, hasErrors, hasMissingFields, missingFormFields, areSuccessfullyUploaded } = useInvoicesHandler();


    return (
        <ModalFooter flexDirection="column" alignItems="flex-end">
            {!isAllCreditNotes && uploadTarget === 'intuipos' && Object.keys(storesHook.storeIds).length > 1 && !allInvoicesUploaded && (
                invoices.some(invoice =>
                    !storesHook.storeSelections[invoice.id] &&
                    !(invoice.store_id && Object.values(storesHook.storeIds).includes(invoice.store_id))
                ) && (
                    <Text fontSize="sm" color="red.500" mb={2}>
                        Seleccione una tienda para las facturas sin tienda asignada
                    </Text>
                )
            )}
            <Flex>
                {/* <Button variant="ghost" mr={3} onClick={() => setIsUploadModalOpen(false)}>
                    Cancelar
                </Button> // LOL. I quit this because its not closing the modal. The state is not updating. Idk why*/}
                <Button
                    colorScheme="blue"
                    onClick={() => handleUploadInvoices()}
                    isDisabled={allInvoicesUploaded || hasErrors || hasMissingFields || areSuccessfullyUploaded}
                    isLoading={isErpLoading || storesHook.isLoadingStores}
                >
                    Confirmar
                </Button>
            </Flex>
        </ModalFooter>
    );
}; 