import { getToken } from "@/utils/auth";
import config from "@/config/env.config";
import { StepData } from "@/types/onboarding.types";
import { OnboardingPayload } from "@/types/onboarding.types";

/**
 * Submits onboarding data to the server
 * @param stepsData The collected data from all onboarding steps
 * @returns A promise that resolves to the server response
 */
export const submitOnboardingData = async (stepsData: StepData): Promise<any> => {
    try {
        // Extract and organize the data from stepsData
        const userData = stepsData['user-details'] || {};
        const businessData = stepsData['business-details'] || {};
        const servicesData = stepsData['services-configuration'] || {};
        
        // Log the state of the form at this step
        console.log('Submitting onboarding data:', {
            userDetails: userData,
            businessDetails: businessData,
            servicesConfiguration: servicesData,
            allData: stepsData
        });
        
        // Create FormData to handle file uploads
        const formData = new FormData();
        
        // Map the service configurations to the backend expected structure
        const serviceConfigs: any = {};
        
        // Extract configurations for selected services
        if (servicesData.configurations) {
            // Handle siigo configuration (check this first since it's what's being used in the error example)
            if (servicesData.configurations.siigo) {
                serviceConfigs.siigo = servicesData.configurations.siigo;
                
                // Add Siigo platform credentials if they exist
                if (servicesData.credentials?.platformUsername) {
                    serviceConfigs.siigo.platform_username = servicesData.credentials.platformUsername;
                }
                
                if (servicesData.credentials?.platformPassword) {
                    serviceConfigs.siigo.platform_password = servicesData.credentials.platformPassword;
                }
                
                // Handle siigo files if any
                if (servicesData.files?.siigo) {
                    console.log('Adding siigo files:', Object.keys(servicesData.files.siigo));
                    // Debug the actual file objects
                    for (const [fileKey, file] of Object.entries(servicesData.files.siigo)) {
                        console.log(`Siigo file '${fileKey}':`, {
                            isFile: file instanceof File,
                            name: file instanceof File ? (file as File).name : 'NOT A FILE',
                            size: file instanceof File ? (file as File).size : 'N/A',
                            type: file instanceof File ? (file as File).type : 'N/A'
                        });
                        
                        // Only add if it's actually a File
                        if (file instanceof File) {
                            const formKey = `file_siigo_${fileKey}`;
                            console.log(`Adding form key: ${formKey}`);
                            formData.append(formKey, file as File);
                        } else {
                            console.error(`Not a valid File object for key ${fileKey}:`, file);
                        }
                    }
                } else {
                    console.log('No siigo files found in servicesData');
                }
            }
            
            // Handle intuipos configuration
            if (servicesData.configurations.intuipos) {
                serviceConfigs.intuipos = servicesData.configurations.intuipos;
                
                // Handle intuipos files if any
                if (servicesData.files?.intuipos) {
                    console.log('Adding intuipos files:', Object.keys(servicesData.files.intuipos));
                    for (const [fileKey, file] of Object.entries(servicesData.files.intuipos)) {
                        if (file instanceof File) {
                            formData.append(`file_intuipos_${fileKey}`, file as File);
                        } else {
                            console.error(`Not a valid File object for intuipos key ${fileKey}:`, file);
                        }
                    }
                }
            }
            
            // Handle helisa configuration
            if (servicesData.configurations.helisa) {
                serviceConfigs.helisa = servicesData.configurations.helisa;
                
                // Handle helisa files if any
                if (servicesData.files?.helisa) {
                    console.log('Adding helisa files:', Object.keys(servicesData.files.helisa));
                    for (const [fileKey, file] of Object.entries(servicesData.files.helisa)) {
                        if (file instanceof File) {
                            formData.append(`file_helisa_${fileKey}`, file as File);
                        } else {
                            console.error(`Not a valid File object for helisa key ${fileKey}:`, file);
                        }
                    }
                }
            }
            
            // Handle taxxa profile configuration
            if (servicesData.configurations.taxxa_profile) {
                serviceConfigs.taxxa_profile = servicesData.configurations.taxxa_profile;
                
                // Handle taxxa_profile files if any
                if (servicesData.files?.taxxa_profile) {
                    console.log('Adding taxxa_profile files:', Object.keys(servicesData.files.taxxa_profile));
                    for (const [fileKey, file] of Object.entries(servicesData.files.taxxa_profile)) {
                        formData.append(`file_taxxa_profile_${fileKey}`, file as File);
                    }
                }
            }
            
            // Handle taxxa validator configuration
            if (servicesData.configurations.taxxa_validator) {
                serviceConfigs.taxxa_validator = servicesData.configurations.taxxa_validator;
                
                // Handle taxxa_validator files if any
                if (servicesData.files?.taxxa_validator) {
                    console.log('Adding taxxa_validator files:', Object.keys(servicesData.files.taxxa_validator));
                    for (const [fileKey, file] of Object.entries(servicesData.files.taxxa_validator)) {
                        formData.append(`file_taxxa_validator_${fileKey}`, file as File);
                    }
                }
            }
            
            // Handle invoice credentials
            if (servicesData.configurations.invoice_credentials) {
                serviceConfigs.invoice_credentials = servicesData.configurations.invoice_credentials;
                
                // Handle invoice_credentials files if any
                if (servicesData.files?.invoice_credentials) {
                    console.log('Adding invoice_credentials files:', Object.keys(servicesData.files.invoice_credentials));
                    for (const [fileKey, file] of Object.entries(servicesData.files.invoice_credentials)) {
                        formData.append(`file_invoice_credentials_${fileKey}`, file as File);
                    }
                }
            }
        }
        
        // Prepare payload for API
        const payload: OnboardingPayload & any = {
            user: {
                email: userData.email,
                username: userData.userName,
                password: userData.password,
            },
            business: {
                companyName: businessData.companyName,
                legalName: businessData.legalName,
                taxId: businessData.taxId,
                idType: businessData.idType,
                legalRepresentativeId: businessData.legalRepresentativeId,
                description: businessData.description,
            },
            services: Array.isArray(servicesData.selectedServices) ? servicesData.selectedServices : 
                     (typeof servicesData.selectedServices === 'object' ? Object.keys(servicesData.selectedServices) : []),
            ...serviceConfigs // Spread the service configurations at the top level
        };
        
        console.log('Final payload being sent to server:', payload);
        
        // Add the JSON payload to FormData
        formData.append('data', JSON.stringify(payload));
        
        // Log all keys in FormData for debugging
        const formDataKeys: string[] = [];
        formData.forEach((value, key) => {
            formDataKeys.push(key);
            if (key !== 'data') {
                console.log(`FormData key: ${key}, type: ${value instanceof File ? 'File' : typeof value}`);
                if (value instanceof File) {
                    console.log(`File name: ${value.name}, size: ${value.size} bytes, type: ${value.type}`);
                }
            }
        });
        console.log('FormData keys:', formDataKeys);
        
        const response = await fetch(`${config.apiUrl}/v1/admin/create-business`, {
            method: 'POST',
            body: formData,
        });
        
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Server error response:', errorText);
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error submitting onboarding data:', error);
        throw error;
    }
};
