import {
    Box,
    Spinner,
    Flex,
    Text
} from "@chakra-ui/react";
import { ResultsView } from "@/components/dian/ResultsView";
import { UploadFileView } from "@/components/dian/UploadFileView";
import { useDianContext } from "@/context/dianContext";

export default function Dian() {
    const { showResults, isLoading } = useDianContext()

    return (
        <Box height={"100%"} pb={200}>
            <Box height={"100%"}>
                {!showResults ? <UploadFileView /> : null}
                {isLoading ? (
                    <Flex justifyContent="center" direction="column" alignItems="center" height="100%" mt={10}>
                        <Spinner size="xl" />
                        <Text mt={2}>Procesando, esto puede tardar unos segundos...</Text>
                    </Flex>
                ) : showResults ? (
                    <ResultsView />
                ) : null}
            </Box>
        </Box>
    );
}
