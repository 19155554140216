import React from 'react';
import {
    Box,
    Text,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormHelperText,
    Stack,
} from '@chakra-ui/react';
import { MdFileUpload } from 'react-icons/md';
import { VideoTutorial } from './VideoTutorial';

interface FileUploadSectionProps {
    title: string;
    description: string;
    inputId: string;
    fileName?: string;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    tutorialTitle: string;
    tutorialLink?: string;
    videoUrl: string;
    isVideoLoading: boolean;
    onVideoLoaded: () => void;
    isRequired?: boolean;
    fileType?: 'excel' | 'image' | 'both';
    labelPrefix?: string;
}

export const FileUploadSection: React.FC<FileUploadSectionProps> = ({
    title,
    description,
    inputId,
    fileName,
    onFileChange,
    tutorialTitle,
    tutorialLink,
    videoUrl,
    isVideoLoading,
    onVideoLoaded,
    isRequired = true,
    fileType = 'excel',
    labelPrefix,
}) => {
    const getAcceptValue = () => {
        switch (fileType) {
            case 'excel':
                return '.xlsx,.xls';
            case 'image':
                return '.png,.jpg,.jpeg,.gif,.bmp';
            case 'both':
                return '.xlsx,.xls,.png,.jpg,.jpeg,.gif,.bmp';
            default:
                return '.xlsx,.xls';
        }
    };

    const getButtonText = () => {
        if (fileName) return fileName;
        
        switch (fileType) {
            case 'excel':
                return 'Subir archivo Excel';
            case 'image':
                return 'Subir captura de pantalla';
            case 'both':
                return 'Subir archivo Excel o captura de pantalla';
            default:
                return 'Subir archivo';
        }
    };

    const getHelperText = () => {
        switch (fileType) {
            case 'excel':
                return `Archivo Excel con el listado de ${title.toLowerCase()}`;
            case 'image':
                return `Captura de pantalla con el listado de ${title.toLowerCase()}`;
            case 'both':
                return `Archivo Excel o captura de pantalla con el listado de ${title.toLowerCase()}`;
            default:
                return `Archivo con el listado de ${title.toLowerCase()}`;
        }
    };

    const getFormLabel = () => {
        if (labelPrefix) {
            return labelPrefix;
        }
        return `Archivo de ${title}`;
    };

    return (
        <Box bg="gray.50" p={6} borderRadius="lg">
            <Text fontSize="sm" color="gray.600" mb={4}>
                {description}
            </Text>
            <Stack spacing={4}>
                <FormControl isRequired={isRequired}>
                    <FormLabel>{getFormLabel()}</FormLabel>
                    <Input
                        type="file"
                        accept={getAcceptValue()}
                        onChange={onFileChange}
                        display="none"
                        id={inputId}
                    />
                    <Button
                        as="label"
                        htmlFor={inputId}
                        leftIcon={<MdFileUpload />}
                        width="full"
                        cursor="pointer"
                    >
                        {getButtonText()}
                    </Button>
                    <FormHelperText>
                        {getHelperText()}
                    </FormHelperText>
                </FormControl>

                <VideoTutorial
                    title={tutorialTitle}
                    tutorialLink={tutorialLink}
                    videoUrl={videoUrl}
                    isLoading={isVideoLoading}
                    onVideoLoaded={onVideoLoaded}
                />
            </Stack>
        </Box>
    );
}; 