import { Invoice } from "@/types/invoice.types";
import { AccountData } from "@/types/invoiceDetail.types";
import { 
  InvoiceTypeOption, 
  PaymentMethodOption, 
  CostCenterOption, 
  RetentionsData, 
  SellerOption,
  ErpType
} from "@/types/erpShared.types";
import { HelisaHandler } from "./helisaHandler";
import { SiigoHandler } from "./siigoHandler";

export interface ErpHandler {
  erp: ErpType;
  getInvoiceTypes(invoice?: Invoice): Promise<InvoiceTypeOption[]>;
  getPaymentMethods(invoiceType?: string): Promise<PaymentMethodOption[]>;
  getCostCenters(): Promise<CostCenterOption[]>;
  getRetentions(supplierId?: string): Promise<RetentionsData>;
  getSellers(): Promise<SellerOption[]>;
  getAccounts(type?: "pago" | "inventario" | "impuesto" | string): Promise<Record<string, AccountData>>;
}

export const createErpHandler = (erpType?: string): ErpHandler => {
  switch (erpType) {
    case 'siigo':
      return new SiigoHandler();
    case 'helisa':
      return new HelisaHandler();
    default:
      throw new Error(`ERP type "${erpType}" not supported`);
  }
};