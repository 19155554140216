import { Invoice } from "@/types/invoice.types";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, Box } from "@chakra-ui/react";

interface DeleteInvoiceModalProps {
    isDeleteSiigoModalOpen: boolean;
    setIsDeleteSiigoModalOpen: (isOpen: boolean) => void;
    invoice: Invoice;
    isErpLoading: boolean;
    handleDeleteFromSiigo: () => void;
}

export function DeleteInvoiceModal({ isDeleteSiigoModalOpen, setIsDeleteSiigoModalOpen, invoice, isErpLoading, handleDeleteFromSiigo }: DeleteInvoiceModalProps) {
    return (
        <Modal isOpen={isDeleteSiigoModalOpen} onClose={() => setIsDeleteSiigoModalOpen(false)} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirmar eliminación de Siigo</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={4}>
                        ¿Está seguro que desea eliminar esta factura de Siigo?
                    </Text>

                    {invoice?.uploads?.siigo?.consecutive && (
                        <Box
                            p={3}
                            bg="gray.50"
                            borderRadius="md"
                            mb={4}
                        >
                            <Text fontWeight="medium">
                                Consecutivo: <Text as="span" fontWeight="bold">{invoice.uploads.siigo.consecutive}</Text>
                            </Text>
                        </Box>
                    )}

                    <Box
                        p={4}
                        bg="red.50"
                        borderRadius="md"
                        mb={4}
                        borderLeft="4px"
                        borderColor="red.400"
                    >
                        <Text color="red.800">
                            <strong>Importante:</strong> Esta acción no se puede deshacer y eliminará la factura del sistema Siigo.
                        </Text>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" mr={3} onClick={() => setIsDeleteSiigoModalOpen(false)}>
                        Cancelar
                    </Button>
                    <Button
                        colorScheme="red"
                        onClick={handleDeleteFromSiigo}
                        isLoading={isErpLoading}
                        loadingText="Eliminando..."
                    >
                        Eliminar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}