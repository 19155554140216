import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Dian from "@/pages/Dian";
import Login from "@/pages/Login";
import Invoices from "@/pages/Invoices";
import SiigoUpload from "@/pages/SiigoUpload";
import RootLayout from "@/layouts/RootLayout";
import InvoiceDetail from "@/pages/InvoiceDetail";
import PrivateRoute from "@/components/utils/PrivateRoute";
import ClientOnboarding from "./pages/ClientOnboarding";
import Landing from "@/pages/Landing";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/config/firebaseConfig";
import { Navigate } from "react-router-dom";
import { Spinner, Flex, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { OnboardingProvider } from "@/context/onboardingContext";
import { InvoicesProvider } from "./context/invoices/invoicesContext";
import { InvoicesHandlerProvider } from "./context/shared/invoicesHandlerContext";
import { ErpFormProvider } from "./context/shared/erpFormContext";
import { InvoiceDetailsProvider } from "./context/invoiceDetails/invoiceDetailsContext";
// Component to check auth status and redirect accordingly
const AuthCheck = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const spinnerColor = useColorModeValue("blue.500", "blue.400");
    const textColor = useColorModeValue("gray.600", "gray.300");

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });

        return () => unsubscribe();
    }, []);

    // Show loading while checking auth status
    if (isAuthenticated === null) {
        return (
            <Flex width="100%" height="100vh" alignItems="center" justifyContent="center">
                <VStack spacing={4}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color={spinnerColor}
                        size="xl"
                    />
                    <Text color={textColor} fontSize="md" fontWeight="medium">
                        Cargando...
                    </Text>
                </VStack>
            </Flex>
        );
    }

    // Redirect to dashboard if authenticated, otherwise show landing
    return isAuthenticated ? <Navigate to="/dashboard" replace /> : <Landing />;
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<AuthCheck />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<RootLayout />}>
                <Route index element={<PrivateRoute><InvoicesHandlerProvider><ErpFormProvider><InvoicesProvider><Invoices /></InvoicesProvider></ErpFormProvider></InvoicesHandlerProvider></PrivateRoute>} />
                <Route path="dian" element={<PrivateRoute><Dian /></PrivateRoute>} />
                <Route path="purchase_sales" element={<PrivateRoute><SiigoUpload /></PrivateRoute>} />
            </Route>
            <Route path="/invoice/:id" element={<PrivateRoute><InvoicesHandlerProvider><ErpFormProvider><InvoiceDetailsProvider><InvoiceDetail /></InvoiceDetailsProvider></ErpFormProvider></InvoicesHandlerProvider></PrivateRoute>} />
            <Route path="/onboarding" element={
                <OnboardingProvider>
                    <ClientOnboarding />
                </OnboardingProvider>
            } />
        </>
    )
);

export default function App() {
    return (
        <RouterProvider router={router} />
    );
}