import { TabPanels, TabPanel, Alert, AlertIcon, VStack, Text, Box, Flex, Button, Image } from "@chakra-ui/react";
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { ErpFormProvider } from "@/context/shared/erpFormContext";
import { InvoiceDetailsPOSForm } from "../InvoiceDetailsPOSForm";
import { InvoiceDetailsERPForm } from "../../shared/erpForm/ERPForm";
import { ProductsTable } from "../productsTable/ProductsTable";

interface InvoiceTabBodyProps {
    handleDeleteFromSiigoClick: () => void;
}

export function InvoiceTabBody({ handleDeleteFromSiigoClick }: InvoiceTabBodyProps) {
    const { invoice, services } = useInvoiceDetails();

    if (!invoice.uploads) return null;

    const erps = ['siigo', 'helisa', 'intuipos'] as const;
    const uploads = invoice.uploads;
    const hasErrors = erps.some(erp =>
        uploads[erp]?.failed &&
        (uploads[erp]?.errors || uploads[erp]?.failed_reason)
    );

    return (<TabPanels>
        {services?.pos && (
            <TabPanel px={0}>
                {invoice.is_return ? (
                    <Alert status="info" borderRadius="lg" boxShadow="sm">
                        <AlertIcon />
                        <Text fontSize="sm">Nota Crédito no aplica POS</Text>
                    </Alert>
                ) : (
                    <>
                        {invoice.uploads?.intuipos?.uploaded ? (
                            <Alert status="success" borderRadius="lg" boxShadow="sm" mb={4}>
                                <AlertIcon alignSelf="flex-start" mt={1} />
                                <VStack align="start" spacing={2}>
                                <VStack align="start" spacing={1} w="100%">
                                    <Text fontSize="sm">
                                        Esta factura ya fue subida a Intuipos
                                        {invoice.uploads?.intuipos?.order_id && invoice.uploads?.intuipos?.order_id !== "Procesando"
                                            ? ` - Orden: ${invoice.uploads?.intuipos?.order_id}`
                                            : " (Procesando)"}
                                    </Text>
                                    {invoice.store_name && (
                                        <Text fontSize="sm">Tienda: {invoice.store_name}</Text>
                                    )}
                                    {invoice.uploads?.intuipos?.uploaded_date && (
                                        <Text fontSize="sm">Fecha de subida: {new Date(invoice.uploads.intuipos.uploaded_date).toLocaleString()}</Text>
                                    )}
                                </VStack>
                            </VStack>
                        </Alert>
                    ) : <></>}
                        <Box>
                            <InvoiceDetailsPOSForm />
                            <ProductsTable activeTab="pos" />
                        </Box>
                    </>
                )}
            </TabPanel>
        )}
        {services?.erp && (
            <TabPanel px={0}>
                {(((services?.erp === 'siigo' && invoice?.uploads?.siigo?.uploaded) ||
                    (services?.erp === 'helisa' && invoice?.uploads?.helisa?.uploaded)) && !hasErrors) ? (
                    <>
                        <Box
                            bg="green.50"
                            borderRadius="md"
                            boxShadow="0 1px 3px rgba(0,0,0,0.05)"
                            borderLeft="4px solid"
                            borderColor="green.500"
                            overflow="hidden"
                            mb={4}
                        >
                            <Box px={5} py={4}>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" gap={4}>
                                    <Flex align="flex-start" gap={3}>
                                        <Box color="green.500" mt={0.5}>
                                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="2" fill="currentColor" fillOpacity="0.2" />
                                                <path d="M8 12L11 15L16 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </Box>
                                        <Box>
                                            <Text fontSize="sm" fontWeight="medium" color="gray.800">
                                                Esta factura ya fue subida a {services?.erp.charAt(0).toUpperCase() + services?.erp.slice(1)}
                                            </Text>
                                            <Text fontSize="xs" color="gray.600" mt={0.5}>
                                                La factura ha sido procesada correctamente en el sistema.
                                            </Text>
                                        </Box>
                                    </Flex>
                                    {services?.erp === 'siigo' &&
                                        invoice?.uploads?.siigo?.uploaded &&
                                        invoice?.uploads?.siigo?.consecutive &&
                                        invoice?.uploads?.siigo?.consecutive !== "Procesando" &&
                                        !invoice?.is_return && invoice?.invoice_type_name === "FC" && (
                                            <Flex direction="column" align="flex-end">
                                                <Flex align="center" gap={3}>
                                                    <Button
                                                        size="sm"
                                                        bg="white"
                                                        color="gray.700"
                                                        border="1px solid"
                                                        borderColor="gray.200"
                                                        boxShadow="0 1px 2px rgba(0, 0, 0, 0.05)"
                                                        borderRadius="md"
                                                        px={3}
                                                        py={1}
                                                        height="auto"
                                                        fontSize="xs"
                                                        fontWeight="medium"
                                                        _hover={{ bg: 'white', color: 'red.500', borderColor: 'red.300' }}
                                                        _active={{ bg: 'white', transform: 'scale(0.98)' }}
                                                        onClick={handleDeleteFromSiigoClick}
                                                        leftIcon={<Image src="/siigo_blue_icon.png" alt="Siigo" boxSize="14px" mr={1} />}
                                                    >
                                                        Borrar en Siigo
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        )}
                                </Flex>
                            </Box>
                        </Box>

                        <Box>
                            <ErpFormProvider>
                                <InvoiceDetailsERPForm
                                />
                                <ProductsTable activeTab="erp" />
                            </ErpFormProvider>
                        </Box>
                    </>
                ) : (
                    <Box>
                        <ErpFormProvider>
                            <InvoiceDetailsERPForm
                            />
                        </ErpFormProvider>
                        <ProductsTable activeTab="erp" />
                    </Box>
                )}
            </TabPanel>
        )}
    </TabPanels>);
}
