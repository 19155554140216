import { useInvoicesContext } from "@/context/invoices/invoicesContext";
import {
	Box,
	Flex,
	FormControl,
	Input,
	Select,
	Text,
} from "@chakra-ui/react";
import { Select as ChakraSelect } from "chakra-react-select";

export function FiltersSection() {
  const {
    startDate,
    endDate,
    uploadStatus,
    handleStartDateChange,
    providerInputValue,
    handleEndDateChange,
    handleUploadStatusChange,
    handleProviderFilterChange,
    searchParams,
    setSearchParams,
    tabIndex
  } = useInvoicesContext();

  let selectedInvoiceType = "all";
  if (searchParams.get("include_credit_notes") === "true") selectedInvoiceType = "credit-note";
  if (searchParams.get("include_general_purchases") === "true") selectedInvoiceType = "general-purchases";
  if (searchParams.get("include_purchases") === "true") selectedInvoiceType = "purchase";

  const { searchSuppliers } = useInvoicesContext();
  return (
    <Box
      bg="white"
      p={4}
    >
      <Flex gap={4} wrap="wrap">
        <FormControl flex="1" minW="200px">
          <Text fontSize="sm" mb={2} color="gray.600">
            Fecha inicial
          </Text>
          <Input
            type="date"
            bg="white"
            color="gray.800"
            size="md"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            _focus={{
              borderColor: "blue.400",
              boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)",
            }}
            value={startDate || ""}
            onChange={handleStartDateChange}
          />
        </FormControl>
        <FormControl flex="1" minW="200px">
          <Text fontSize="sm" mb={2} color="gray.600">
            Fecha final
          </Text>
          <Input
            type="date"
            bg="white"
            color="gray.800"
            size="md"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            _focus={{
              borderColor: "blue.400",
              boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)",
            }}
            value={endDate || ""}
            onChange={handleEndDateChange}
          />
        </FormControl>
        <FormControl flex="1" minW="200px">
          <Text fontSize="sm" mb={2} color="gray.600">
            Estado de carga
          </Text>
          <Select
            bg="white"
            color="gray.800"
            size="md"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            _focus={{
              borderColor: "blue.400",
              boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)",
            }}
            onChange={handleUploadStatusChange}
            value={uploadStatus}
          >
            <option value="all">Todas</option>
            <option value="uploaded">Cargadas</option>
            <option value="not-uploaded">No cargadas</option>
            <option value="processing">Procesando</option>
            <option value="ready-to-upload-pos">Listas para subir a POS</option>
            <option value="ready-to-upload-erp">Listas para subir a ERP</option>
          </Select>
        </FormControl>
        {tabIndex === 0 && (
        <FormControl flex="1" minW="200px">
          <Text fontSize="sm" mb={2} color="gray.600">
            Tipos
          </Text>
          <Select
            bg="white"
            color="gray.800"
            size="md"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            _focus={{
              borderColor: "blue.400",
              boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)",
            }}
            onChange={(e) => {
				const newType = e.target.value;
				selectedInvoiceType = newType;
				const params = new URLSearchParams(searchParams);
				switch (newType) {
					case "credit-note":
						params.set('include_credit_notes', "true");
						if (params.get('include_general_purchases')) params.delete('include_general_purchases');
						if (params.get('include_purchases')) params.delete('include_purchases');
						break;
					case "general-purchases":
						params.set('include_general_purchases', "true");
						if (params.get('include_credit_notes')) params.delete('include_credit_notes');
						if (params.get('include_purchases')) params.delete('include_purchases');
						break;
					case "purchase":
						params.set('include_purchases', "true");
						if (params.get('include_credit_notes')) params.delete('include_credit_notes');
						if (params.get('include_general_purchases')) params.delete('include_general_purchases');
						break;
					default:
						if (params.get('include_credit_notes')) params.delete('include_credit_notes');
						if (params.get('include_general_purchases')) params.delete('include_general_purchases');
						if (params.get('include_purchases')) params.delete('include_purchases');
						break;
				}
				params.set('page', '1');
				setSearchParams(params);
			}}
            value={selectedInvoiceType}
          >
            <option value="all">Todas</option>
            <option value="purchase">Facturas de Compra</option>
            <option value="credit-note">Notas Crédito</option>
            <option value="general-purchases">Compras Generales</option>
          </Select>
        </FormControl>
        )}
        {tabIndex === 0 && (
        <FormControl flex="1" minW="200px">
          <Text fontSize="sm" mb={2} color="gray.600">
            Buscar proveedor
          </Text>
          <ChakraSelect
            options={searchSuppliers}
            placeholder="Nombre del proveedor"
            onChange={(obj) => handleProviderFilterChange(obj)}
            value={
              searchSuppliers.find(
                (supplier) => supplier.value === (providerInputValue || "all")
              ) || {
                label: "Todos",
                value: "all",
              }
            }
          />
        </FormControl>
        )}
      </Flex>
    </Box>
  );
}
