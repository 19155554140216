import { Box, Tabs } from "@chakra-ui/react";
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { InvoiceTabBody } from "./InvoiceTabBody";
import { InvoiceTabsHeader } from "./InvoiceTabsHeader";
import { InvoiceTabsSkeleton } from "./InvoiceTabsSkeleton";
import { DeleteInvoiceModal } from "../DeleteInvoiceModal";

export function InvoiceTabsContainer() {
  const { invoice, services, setActiveTab, isDeleteSiigoModalOpen, setIsDeleteSiigoModalOpen, handleDeleteFromSiigoClick, handleDeleteFromSiigo, isDeleteErpInvoiceLoading } = useInvoiceDetails();

  return services ? (
    <Box
      bg="white"
      borderRadius="xl"
      boxShadow="sm"
      p={6}
      borderWidth="1px"
      borderColor="gray.200"
      _hover={{
        borderColor: "gray.300",
      }}
      transition="all 0.2s"
    >
      <Tabs
        isFitted
        variant="soft-rounded"
        colorScheme="blue"
        mt={2}
        defaultIndex={services?.pos && services?.erp ? 1 : 0}
        onChange={(index) => setActiveTab(index)}
      >
        <InvoiceTabsHeader />
        <InvoiceTabBody handleDeleteFromSiigoClick={handleDeleteFromSiigoClick} />
      </Tabs>
      <DeleteInvoiceModal isDeleteSiigoModalOpen={isDeleteSiigoModalOpen} setIsDeleteSiigoModalOpen={setIsDeleteSiigoModalOpen} invoice={invoice} isErpLoading={isDeleteErpInvoiceLoading} handleDeleteFromSiigo={handleDeleteFromSiigo} /> {/* Refactor this  */}
    </Box>
  ) : (
    <InvoiceTabsSkeleton />
  );
}