import React, { useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Text,
  Checkbox,
  Box,
  Badge,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { QuestionIcon } from '@chakra-ui/icons';
import { CreatableSelect, SingleValue } from "chakra-react-select";
import { useErpForm } from '@/context/shared/erpFormContext';
import { AccountOption } from '@/types/invoiceDetail.types';
import { InvoiceTypeOption, PaymentMethodOption, CostCenterOption, RetentionOption, SellerOption } from '@/types/erpShared.types';
import { useInvoicesHandler } from '@/context/shared/invoicesHandlerContext';
import { selectStyles } from '@/components/ui/SelectStyles';
import { HiSparkles } from "react-icons/hi";



export const SiigoForm: React.FC = () => {

  const {
    // Retentions
    retentionsHook,
    retentionAccountsHook,
    // Invoice types
    invoiceTypesHook,
    // Payment methods
    paymentMethodsHook,
    // Payment account
    accountsHook,
    // Cost center
    costCentersHook,
    // Sellers
    sellersHook,
    // Uploads
    isAllCreditNotes,
    allInvoicesUploaded,
  } = useErpForm();

  const { useCostCenterPerItem, handleUseCostCenterPerItem } = useInvoicesHandler();

  const { invoices, setInvoices } = useInvoicesHandler();

  const isSale = (invoices?.length > 0 && invoices?.every(inv => inv.is_sale));

  // Access bizErpType from invoiceTypesHook
  const { bizErpType } = invoiceTypesHook;

  return (
    <Flex direction="row" gap={4} width={"auto"}>
      <Flex direction="column" gap={4} width={"300px"}>
        <Flex direction="column" gap={2}>
          <Text whiteSpace="nowrap" fontWeight="medium">Tipo de factura:</Text>
          <CreatableSelect<InvoiceTypeOption>
            value={invoiceTypesHook.selectedInvoiceType}
            options={invoiceTypesHook.invoiceTypesOptions.filter(option => {
              if (invoices.every(inv => inv.is_sale)) {
                return option.type === 'FV';
              } else {
                if (isAllCreditNotes) {
                  return option.type === 'CC';
                }
                return option.type !== 'FV';
              }
            })}
            onChange={(selectedOption: SingleValue<InvoiceTypeOption>) => {
              if (selectedOption) {
                invoiceTypesHook.handleInvoiceTypeChange(selectedOption);
              }
            }}
            placeholder="Seleccione el tipo de factura"
            noOptionsMessage={() => "No hay tipos de factura disponibles"}
            isLoading={invoiceTypesHook.isLoading}
            loadingMessage={() => "Cargando tipos de factura..."}
            isDisabled={invoiceTypesHook.isLoading || allInvoicesUploaded}
            isInvalid={!invoiceTypesHook.selectedInvoiceType}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />
        </Flex>
        {invoiceTypesHook.selectedInvoiceType && (
          <Flex direction="column" gap={4}>
            {(!isAllCreditNotes && invoiceTypesHook.invoiceTypesOptions.find(invoiceType =>
				invoiceType.id === invoiceTypesHook.selectedInvoiceType?.value)?.type !== 'CC'
			 || bizErpType === 'pyme') && (
              <Flex direction="column" gap={2}>
                <Text whiteSpace="nowrap" fontWeight="medium">
                  {bizErpType === 'pyme' ? 'Forma de pago:' : 'Método de pago:'}
                </Text>
                <CreatableSelect<PaymentMethodOption>
                  value={paymentMethodsHook.paymentForm}
                  options={paymentMethodsHook.paymentMethods}
                  onChange={(option: SingleValue<PaymentMethodOption>) => {
                    if (option) {
                      paymentMethodsHook.handlePaymentMethodChange(option);
                    }
                  }}
                  placeholder="Seleccione el método de pago"
                  noOptionsMessage={() => "No hay métodos de pago disponibles"}
                  isLoading={paymentMethodsHook.isLoading}
                  loadingMessage={() => "Cargando métodos de pago..."}
                  isDisabled={paymentMethodsHook.isLoading || allInvoicesUploaded}
                  size="sm"
                  isInvalid={!paymentMethodsHook.paymentForm}
                  errorBorderColor='red.100'
                  chakraStyles={selectStyles}
                />
              </Flex>
            )}
            {(invoiceTypesHook.invoiceTypesOptions.find(invoiceType =>
				invoiceType.id === invoiceTypesHook.selectedInvoiceType?.value)?.type !== 'FC'
			 || ['pyme', 'contador'].includes(bizErpType)) && (
              <FormControl isInvalid={!accountsHook.selectedAccount}>
                <FormLabel>Cuenta Contable del método de pago:</FormLabel>
                <CreatableSelect<AccountOption>
                  value={accountsHook.selectedAccount ? {
                    label: accountsHook.selectedAccount.label,
                    value: accountsHook.selectedAccount.value
                  } : null}
                  options={accountsHook.accountOptions}
                  onChange={(option: SingleValue<AccountOption>) => {
                    if (option) {
                      accountsHook.handleAccountChange(option);
                    } else {
                      accountsHook.setSelectedAccount(null as any);
                    }
                  }}
                  onCreateOption={(inputValue) => {
                    accountsHook.handleAccountChange({
                      label: inputValue,
                      value: inputValue
                    });
                  }}
                  placeholder="Seleccionar..."
                  isLoading={accountsHook.isLoading}
                  isClearable
                  isSearchable
                  formatCreateLabel={(input: string) => `Usar cuenta: ${input}`}
                  isDisabled={allInvoicesUploaded}
                  isInvalid={!accountsHook.selectedAccount}
                  chakraStyles={selectStyles}
                  size="sm"
                  errorBorderColor='red.100'
                />
                {accountsHook.error && (
                  <FormErrorMessage>{accountsHook.error}</FormErrorMessage>
                )}
              </FormControl>
            )}
            {isSale && (
              <Flex
                direction="column"
                gap={4}
                width={"300px"}
                minWidth="250px"
              >
                <Flex direction="column" gap={2}>
                  <Text fontWeight="medium">Vendedor:</Text>
                  <FormControl isInvalid={!sellersHook.selectedSeller}>
                    <CreatableSelect
                      value={sellersHook.selectedSeller}
                      options={sellersHook.sellers}
                      onChange={(option: SingleValue<SellerOption>) => option && sellersHook.handleSellerChange(option)}
                      placeholder="Seleccione el vendedor"
                      noOptionsMessage={() => "No hay vendedores disponibles"}
                      isLoading={sellersHook.isLoading}
                      loadingMessage={() => "Cargando vendedores..."}
                      isDisabled={sellersHook.isLoading || allInvoicesUploaded}
                      isInvalid={!sellersHook.selectedSeller}
                      chakraStyles={selectStyles}
                      size="sm"
                      errorBorderColor='red.100'
                    />
                  </FormControl>
                </Flex>
              </Flex>
            )}
            {((costCentersHook.costCenters?.length > 0) || invoices.every(inv => inv.is_sale)) && (
              <Flex direction="column" gap={2}>
                <Text whiteSpace="nowrap" fontWeight="medium">Centro de costo:</Text>
                <CreatableSelect<CostCenterOption>
                  value={costCentersHook.selectedCostCenter}
                  options={costCentersHook.costCenters}
                  onChange={(option: SingleValue<CostCenterOption>) => {
                    if (option) {
                      costCentersHook.handleCostCenterChange(option);
                    }
                  }}
                  placeholder="Seleccione el centro de costo"
                  noOptionsMessage={() => "No hay centros de costo disponibles"}
                  isLoading={costCentersHook.isLoading}
                  loadingMessage={() => "Cargando centros de costo..."}
                  isDisabled={allInvoicesUploaded || (costCentersHook.isLoading || useCostCenterPerItem || !!invoiceTypesHook.selectedInvoiceType?.cost_center_default) && invoiceTypesHook.selectedInvoiceTypeName === "CC"}
                  chakraStyles={selectStyles}
                  size="sm"
                  errorBorderColor='red.100'
                />
              </Flex>
            )}
            {(invoiceTypesHook.selectedInvoiceType?.type === 'CC' && invoices.length === 1) &&
              <FormControl display="flex" alignItems="center" mb={2}>
                <Checkbox
                  isChecked={useCostCenterPerItem}
                  onChange={(e) => {
                    handleUseCostCenterPerItem();
                  }}
                  size="sm"
                >
                  <Text fontSize="sm">Usar centro de costo por ítem</Text>
                </Checkbox>
              </FormControl>
            }
          </Flex>
        )}

      </Flex>

      <Flex direction="column" gap={4} width={"300px"}>
        <FormControl>
          <FormLabel>
            ReteICA:
            {retentionsHook.retentionsData?.suggested?.reteICA &&
              retentionsHook.selectedReteICA?.id === retentionsHook.retentionsData.suggested.reteICA.id && (
                <Tooltip label="Nuestra IA ha detectado que esta retención se ha usado en facturas previas con el mismo proveedor" hasArrow>
                  <Badge ml={2} colorScheme="purple" fontSize="xs" borderRadius="full" color="purple.700">Autoseleccionado
                    <Box
                      as="span"
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      ml={1}
                    >
                      <HiSparkles style={{
                        display: 'inline',
                        fontSize: '1em',
                        color: '#553C9A',
                        cursor: 'help'
                      }} />
                    </Box>
                  </Badge>
                </Tooltip>

              )}
          </FormLabel>
          <CreatableSelect<RetentionOption>
            value={retentionsHook.selectedReteICA}
            options={retentionsHook.reteICAOptions}
            onChange={(option: SingleValue<RetentionOption>) => retentionsHook.handleSingleRetentionChange(option, 'ReteICA')}
            menuPosition="fixed"
            placeholder="Seleccione ReteICA"
            noOptionsMessage={() => "No hay retenciones disponibles"}
            isLoading={retentionsHook.isLoading}
            isClearable
            isDisabled={allInvoicesUploaded}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />

        </FormControl>

        <FormControl>
          <FormLabel>
            ReteIVA:
            {retentionsHook.retentionsData?.suggested?.reteIVA &&
              retentionsHook.selectedReteIVA?.id === retentionsHook.retentionsData.suggested.reteIVA.id && (
                <Tooltip label="Nuestra IA ha detectado que esta retención se ha usado en facturas previas con el mismo proveedor" hasArrow>
                  <Badge ml={2} colorScheme="purple" fontSize="xs" borderRadius="full" color="purple.700">Autoseleccionado
                    <Box
                      as="span"
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      ml={1}
                    >
                      <HiSparkles style={{
                        display: 'inline',
                        fontSize: '1em',
                        color: '#553C9A',
                        cursor: 'help'
                      }} />
                    </Box>
                  </Badge>
                </Tooltip>
              )}
          </FormLabel>
          <CreatableSelect<RetentionOption>
            value={retentionsHook.selectedReteIVA}
            options={retentionsHook.reteIVAOptions}
            onChange={(option: SingleValue<RetentionOption>) => retentionsHook.handleSingleRetentionChange(option, 'ReteIVA')}
            menuPosition="fixed"
            placeholder="Seleccione ReteIVA"
            noOptionsMessage={() => "No hay retenciones disponibles"}
            isLoading={retentionsHook.isLoading}
            isClearable
            isDisabled={allInvoicesUploaded}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />

        </FormControl>

        <FormControl>
          <FormLabel>
            Retefuente:
            {retentionsHook.retentionsData?.suggested?.retefuente &&
              retentionsHook.selectedRetefuente?.id === retentionsHook.retentionsData.suggested.retefuente.id && (
                <Tooltip label="Nuestra IA ha detectado que esta retención se ha usado en facturas previas con el mismo proveedor" hasArrow>
                  <Badge ml={2} colorScheme="purple" fontSize="xs" borderRadius="full" color="purple.700">Autoseleccionado
                    <Box
                      as="span"
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      ml={1}
                    >
                      <HiSparkles style={{
                        display: 'inline',
                        fontSize: '1em',
                        color: '#553C9A',
                        cursor: 'help'
                      }} />
                    </Box>
                  </Badge>
                </Tooltip>
              )}
          </FormLabel>
          <CreatableSelect<RetentionOption>
            value={retentionsHook.selectedRetefuente}
            options={retentionsHook.retefuenteOptions}
            onChange={(option: SingleValue<RetentionOption>) => retentionsHook.handleSingleRetentionChange(option, 'Retefuente')}
            menuPosition="fixed"
            placeholder="Seleccione Retefuente"
            noOptionsMessage={() => "No hay retenciones disponibles"}
            isLoading={retentionsHook.isLoading}
            isClearable
            isDisabled={allInvoicesUploaded}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />

        </FormControl>
      </Flex>

      {invoiceTypesHook.showRetentionAccountInputs && (retentionsHook.selectedReteICA || retentionsHook.selectedReteIVA || retentionsHook.selectedRetefuente) && (
        <Flex direction="column" gap={4} width={"300px"}>
          <Box height="64px">
            {retentionsHook.selectedReteICA && (
              <FormControl isInvalid={!!retentionsHook.error}>
                <FormLabel>Cuenta Contable ReteICA:</FormLabel>
                <CreatableSelect<AccountOption>
                  value={retentionAccountsHook.retentionAccountOptions.find(option => option.value === retentionsHook.reteICAAccount)}
                  options={retentionAccountsHook.retentionAccountOptions}
                  onChange={(newValue: SingleValue<AccountOption>) => {
                    retentionsHook.setReteICAAccount(String(newValue?.value || ''));
                    retentionsHook.setReteICAAccountError('');
                  }}
                  onCreateOption={(inputValue: string) => {
                    if (!/^\d+$/.test(inputValue)) {
                      retentionsHook.setReteICAAccountError('Solo se permiten números');
                      return;
                    }
                    retentionsHook.setReteICAAccount(inputValue);
                    retentionsHook.setReteICAAccountError('');
                  }}
                  placeholder="Seleccionar..."
                  isLoading={retentionsHook.isLoading}
                  isClearable
                  isSearchable
                  formatCreateLabel={(input: string) => `Usar cuenta: ${input}`}
                  isDisabled={allInvoicesUploaded}
                  isInvalid={!retentionsHook.reteICAAccount}
                  chakraStyles={selectStyles}
                  size="sm"
                  errorBorderColor='red.100'
                />
                {retentionsHook.error && (
                  <FormErrorMessage>{retentionsHook.error}</FormErrorMessage>
                )}
              </FormControl>
            )}
          </Box>
          <Box height="64px">

            {retentionsHook.selectedReteIVA && (
              <FormControl isInvalid={!!retentionsHook.error}>
                <FormLabel>Cuenta Contable ReteIVA:</FormLabel>
                <CreatableSelect<AccountOption>
                  value={retentionAccountsHook.retentionAccountOptions.find(option => option.value === retentionsHook.reteIVAAccount)}
                  options={retentionAccountsHook.retentionAccountOptions}
                  onChange={(newValue: SingleValue<AccountOption>) => {
                    retentionsHook.setReteIVAAccount(String(newValue?.value || ''));
                    retentionsHook.setReteIVAAccountError('');
                  }}
                  onCreateOption={(inputValue: string) => {
                    if (!/^\d+$/.test(inputValue)) {
                      retentionsHook.setReteIVAAccountError('Solo se permiten números');
                      return;
                    }
                    retentionsHook.setReteIVAAccount(inputValue);
                    retentionsHook.setReteIVAAccountError('');
                  }}
                  placeholder="Seleccionar..."
                  isLoading={retentionsHook.isLoading}
                  isClearable
                  isSearchable
                  formatCreateLabel={(input: string) => `Usar cuenta: ${input}`}
                  isDisabled={allInvoicesUploaded}
                  isInvalid={!retentionsHook.reteIVAAccount}
                  chakraStyles={selectStyles}
                  size="sm"
                  errorBorderColor='red.100'
                />
                {retentionsHook.error && (
                  <FormErrorMessage>{retentionsHook.error}</FormErrorMessage>
                )}
              </FormControl>
            )}
          </Box>

          <Box height="64px">
            {retentionsHook.selectedRetefuente && (
              <FormControl isInvalid={!!retentionsHook.error}>
                <FormLabel>Cuenta Contable Retefuente:</FormLabel>
                <CreatableSelect<AccountOption>
                  value={retentionAccountsHook.retentionAccountOptions.find(option => option.value === retentionsHook.retefuenteAccount)}
                  options={retentionAccountsHook.retentionAccountOptions}
                  onChange={(newValue: SingleValue<AccountOption>) => {
                    retentionsHook.setRetefuenteAccount(String(newValue?.value || ''));
                    retentionsHook.setRetefuenteAccountError('');
                  }}
                  onCreateOption={(inputValue: string) => {
                    if (!/^\d+$/.test(inputValue)) {
                      retentionsHook.setRetefuenteAccountError('Solo se permiten números');
                      return;
                    }
                    retentionsHook.setRetefuenteAccount(inputValue);
                    retentionsHook.setRetefuenteAccountError('');
                  }}
                  placeholder="Seleccionar..."
                  isLoading={retentionsHook.isLoading}
                  isClearable
                  isSearchable
                  formatCreateLabel={(input: string) => `Usar cuenta: ${input}`}
                  isDisabled={allInvoicesUploaded}
                  isInvalid={!retentionsHook.retefuenteAccount}
                  chakraStyles={selectStyles}
                  size="sm"
                  errorBorderColor='red.100'
                />
                {retentionsHook.error && (
                  <FormErrorMessage>{retentionsHook.error}</FormErrorMessage>
                )}
              </FormControl>
            )}
          </Box>

        </Flex>


      )}

    </Flex>
  );
};

