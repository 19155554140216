import React from 'react';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Center,
  Flex,
} from "@chakra-ui/react";
import { useErpForm } from '@/context/shared/erpFormContext';
import { InvoiceUploadsState } from "@/types/invoice.types";
import { SiigoForm } from './forms/SiigoForm';
import { HelisaForm } from './forms/HelisaForm';
import { useInvoicesHandler } from '@/context/shared/invoicesHandlerContext';



export const InvoiceDetailsERPForm: React.FC = (): JSX.Element => {
  const {
    services,
    invoices: selectedInvoices
  } = useInvoicesHandler();

  const {
    allInvoicesUploaded,
    hasFailedUploads,
  } = useErpForm();

  // All this logic should be moved to the InvoiceDataContext
  const uploadTarget = services?.erp;

 


  return (
    <Center>
      <Box pb={4}>
        {allInvoicesUploaded && hasFailedUploads && (
          <Alert
            status="warning"
            mb={4}
            borderRadius="md"
          >
            <AlertIcon />
            <AlertDescription>
              {selectedInvoices.length > 1 
                ? "Hubo un error al subir algunas facturas. Por favor, revise las facturas y vuelva a subirlas." 
                : "Hubo un error al subir la factura. Por favor, revise la factura y vuelva a subirla."}
            </AlertDescription>
          </Alert>
        )}

        <Flex align="flex-start" gap={4} mb={4}>
          {uploadTarget === 'siigo' && (
            <SiigoForm/>
          )}

          {uploadTarget === 'helisa' && (
            <HelisaForm/>
          )}


        </Flex>
      </Box>
    </Center>
  );
};