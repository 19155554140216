import React, { useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Center,
    Flex,
    useToast
} from "@chakra-ui/react";
import { useInvoicesHandler } from '@/context/shared/invoicesHandlerContext';
import { InvoiceDetailsERPForm } from '../../shared/erpForm/ERPForm';
import { AlertsSection } from './AlertsSection';
import { InvoicesTable } from './InvoicesTable';
import { ModalFooterActions } from './ModalFooterActions';

interface UploadConfirmationModalProps { }

export const UploadConfirmationModal: React.FC<UploadConfirmationModalProps> = (): JSX.Element => {
    const { isUploadModalOpen, setIsUploadModalOpen, isPOSModalOpen, setIsPOSModalOpen } = useInvoicesHandler();
    const {
        uploadTarget,
        invoices
    } = useInvoicesHandler();
    

    // Obtain the formatted name of the upload service
    const uploadTargetName = uploadTarget
        ? uploadTarget.charAt(0).toUpperCase() + uploadTarget.slice(1)
        : '';
        
    // Verify if there are general purchases
    const hasGeneralPurchases = invoices.some(invoice => 
        invoice.is_general_purchase
    );

    return (
        <Modal isOpen={isUploadModalOpen || isPOSModalOpen} onClose={() => {
            setIsUploadModalOpen(false);
            setIsPOSModalOpen(false);
        }} size="xl">
            <ModalOverlay />
            <ModalContent maxWidth="80vw">
                <ModalHeader>
                    Confirmar carga a {uploadTargetName}
                </ModalHeader>
                <ModalCloseButton />
                
                <ModalBody>
                    <AlertsSection />
                    {(uploadTarget === 'siigo' || uploadTarget === 'helisa') && (
                        <InvoiceDetailsERPForm />
                    )}
                    {((!hasGeneralPurchases && uploadTarget === 'intuipos') || (uploadTarget !== 'intuipos')) && (
                        <>
                            <Text my={4}>Se cargarán las siguientes facturas:</Text>
                            <InvoicesTable />
                        </>
                    )}
                </ModalBody>
                {((!hasGeneralPurchases && uploadTarget === 'intuipos') || (uploadTarget !== 'intuipos')) && <ModalFooterActions />}
            </ModalContent>
        </Modal>
    );
};