import { useState } from 'react';
import { useToast, useDisclosure } from '@chakra-ui/react';
import { fetchDianSync, fetchDianToken } from '@/services/dian.services';
import { getDianAcknowledgment } from '@/services/invoices.services';
import { Invoice } from '@/types/invoice.types';
import { DianSyncResponse } from '@/types/dian.types';

export const useDianOperations = (
  selectedInvoices: Invoice[],
  onUploadComplete: () => void
) => {
  // DIAN Token and Sync State
  const [isImporting, setIsImporting] = useState(false);
  const [importStatus, setImportStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
  const [importProgress, setImportProgress] = useState(0);
  const [urlToken, setUrlToken] = useState("");
  const [selectedDaysPeriod, setSelectedDaysPeriod] = useState<string>("1");
  const [importInvoicesResult, setImportInvoicesResult] = useState<DianSyncResponse>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  
  // DIAN modal state
  const [isDianModalOpen, setIsDianModalOpen] = useState(false);
  
  // Chakra UI disclosure hooks
  const { isOpen: isLoadingOpen, onOpen: onLoadingOpen, onClose: onLoadingClose } = useDisclosure();
  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure();
  
  const toast = useToast();

  // Method to handle the click on the DIAN button
  const handleDianClick = () => {
    if (selectedInvoices.length === 0) {
      toast({
        title: "No hay facturas seleccionadas",
        description: "Por favor, seleccione al menos una factura para generar el acuse de recibo",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
      return;
    }
    setIsDianModalOpen(true);
  };

  // Method to confirm the DIAN acknowledgment
  const handleConfirmDianAcknowledgment = async () => {
    try {
      const data = await getDianAcknowledgment(selectedInvoices);

      // Customize message based on number of invoices
      let title = selectedInvoices.length === 1
        ? "Acuse de recibo DIAN"
        : "Acuses de recibo DIAN";

      let description;
      if (data.status === 'success') {
        description = selectedInvoices.length === 1
          ? "El acuse de recibo ha sido efectuado correctamente"
          : `Se han procesado ${selectedInvoices.length} acuses de recibo correctamente`;
      } else {
        description = selectedInvoices.length === 1
          ? "Ha ocurrido un error al procesar el acuse de recibo"
          : "Ha ocurrido un error al procesar los acuses de recibo";
      }

      toast({
        position: "top-right",
        title,
        description,
        status: data.status,
        duration: 5000,
        isClosable: true,
      });

      onUploadComplete();

    } catch {
      toast({
        title: "Error al procesar",
        description: "Ha ocurrido un error al procesar la solicitud",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsDianModalOpen(false);
  };

  // Get DIAN token
  const handleGetDianToken = async () => {
    try {
      onLoadingOpen();
      setErrorMessage("");

      const response = await fetchDianToken();

      if (response?.status === "success") {
        onSuccessOpen();
        onLoadingClose();
      } else {
        console.error("Error al obtener el token de acceso");
        onLoadingClose();
        toast({
          title: "Error con el token DIAN",
          description: "No se pudo obtener el token. Verifique que su configuración sea correcta o contacte a soporte.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
      }
    } catch (error) {
      console.error("Error al obtener el token de acceso:", error);
      onLoadingClose();
      toast({
        title: "Error con el token DIAN",
        description: "Error al conectar con DIAN. Verifique que su configuración sea correcta o contacte a soporte.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right"
      });
    }
  };

  // Import information from DIAN
  const handleImportInformation = async () => {
    try {
      setIsImporting(true);
      setImportStatus("loading");
      setErrorMessage("");

      if (!urlToken) {
        setErrorMessage("Por favor, ingresa el token de acceso antes de continuar.");
        setIsImporting(false);
        setImportStatus("error");
        return;
      }
      const startDate =  new Date(new Date().setDate(new Date().getDate() - Number(selectedDaysPeriod))).toISOString().split('T')[0];
      const endDate = new Date().toISOString().split('T')[0];
      const response = await fetchDianSync(urlToken, startDate, endDate);

      if (response?.status === "success") {
        setImportStatus("success");
        setImportInvoicesResult(response);
        setIsImporting(false);
      } else {
        console.log(response);
        setErrorMessage("No se pudo sincronizar con la DIAN. Por favor, verifica el token e intenta nuevamente.");
        setImportStatus("error");
        setIsImporting(false);
      }
    } catch (error) {
      setErrorMessage("Error de conexión. Por favor, verifica tu conexión a internet e intenta nuevamente.");
      setImportStatus("error");
      setIsImporting(false);
      console.error("Error importing information:", error);
    }
  };

  return {
    isImporting,
    importStatus,
    importProgress,
    urlToken,
    setUrlToken,
    selectedDaysPeriod,
    setSelectedDaysPeriod,
    importInvoicesResult,
    errorMessage,
    isDianModalOpen,
    setIsDianModalOpen,
    isLoadingOpen,
    isSuccessOpen,
    onLoadingClose,
    onSuccessClose,
    handleDianClick,
    handleConfirmDianAcknowledgment,
    handleGetDianToken,
    handleImportInformation
  };
}; 