import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { CreatableSelect } from "chakra-react-select";

export function ReferenceInvoiceSelect() {
  const { invoice, supplierInvoices, isLoadingSupplierInvoices, setInvoice } =
    useInvoiceDetails();

  return (
    <FormControl>
      <FormLabel fontWeight="bold" mb={1}>
        Factura de Referencia: (Opcional)
      </FormLabel>
      <CreatableSelect
        value={
          invoice.is_return_of
            ? {
                value: invoice.is_return_of,
                label: supplierInvoices.find(
                  (inv) => inv.invoice_id === invoice.is_return_of
                )
                  ? `${invoice.is_return_of} ${new Date(
                      supplierInvoices.find(
                        (inv) => inv.invoice_id === invoice.is_return_of
                      )!.issue_date + "T00:00:00"
                    ).toLocaleDateString()} $${
                      supplierInvoices
                        .find((inv) => inv.invoice_id === invoice.is_return_of)
                        ?.total_amount?.toLocaleString() || 0
                    }`
                  : invoice.is_return_of,
              }
            : null
        }
        onChange={(newValue: any) => {
          setInvoice({
            ...invoice,
            is_return_of: newValue?.value || "",
          });
        }}
        options={supplierInvoices
          .filter(
            (inv) => !inv.is_return && inv.invoice_id !== invoice.invoice_id
          )
          .map((inv) => ({
            value: inv.invoice_id,
            label: `${inv.invoice_id} ${new Date(
              inv.issue_date + "T00:00:00"
            ).toLocaleDateString()} $${
              inv.total_amount?.toLocaleString() || 0
            }`,
          }))}
        placeholder="Buscar o ingresar número de factura..."
        chakraStyles={{
          control: (base) => ({
            ...base,
            minHeight: '32px',
            maxWidth: '350px',
          }),
          valueContainer: (base) => ({
            ...base,
            fontSize: '0.875rem',
          }),
          menu: (base) => ({
            ...base,
            fontSize: '0.875rem',
            maxWidth: '350px',
          }),
          container: (provided) => ({
            ...provided,
            maxWidth: '350px',
          }),
        }}
        isDisabled={isLoadingSupplierInvoices}
        isLoading={isLoadingSupplierInvoices}
        isClearable
        formatCreateLabel={(inputValue: string) =>
          `Usar factura: ${inputValue}`
        }
      />
    </FormControl>
  );
}
