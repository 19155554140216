import { Invoice } from '@/types/invoice.types';
import { UploadTargetType } from '@/types/erpShared.types';

/**
 * Verifies if the target is a valid ERP/POS system
 */
export const isValidUploadTarget = (target: UploadTargetType): boolean => {
  return target === 'intuipos' || target === 'siigo' || target === 'helisa';
};

/**
 * Converts cost_center to number
 */
export const getCostCenterAsNumber = (invoice: Invoice): number | undefined => {
  if (invoice.cost_center === undefined) {
    return undefined;
  }
  
  if (typeof invoice.cost_center === 'number') {
    return invoice.cost_center;
  }
  
  // If it's a string, try to convert to number
  const parsed = Number(invoice.cost_center);
  return isNaN(parsed) ? undefined : parsed;
};

/**
 * Generate status message for toast notifications
 */
export const getStatusMessage = (successCount: number, errorCount: number, type: 'upload' | 'process' = 'upload') => {
  if (successCount === 0 && errorCount === 0) {
    return {
      description: "No se han seleccionado facturas para subir",
      status: 'warning' as const
    };
  }
  if (errorCount === 0) {
    return {
      description: successCount === 1
        ? `La factura ha sido ${type === 'upload' ? 'cargada' : 'procesada'} correctamente`
        : `${successCount} facturas han sido ${type === 'upload' ? 'cargadas' : 'procesadas'} correctamente`,
      status: 'success' as const
    };
  }
  if (successCount === 0) {
    return {
      description: errorCount === 1
        ? `La factura no se ha podido ${type === 'upload' ? 'cargar' : 'procesar'}`
        : `${errorCount} facturas no se han podido ${type === 'upload' ? 'cargar' : 'procesar'}`,
      status: 'error' as const
    };
  }
  return {
    description: `${successCount} factura${successCount === 1 ? '' : 's'} ${type === 'upload' ? 'cargada' : 'procesada'}${successCount === 1 ? '' : 's'} correctamente\n${errorCount} factura${errorCount === 1 ? '' : 's'} con error`,
    status: 'warning' as const
  };
}; 