import {
  Box,
  Flex,
  VStack,
  Alert,
  AlertIcon,
  Text,
  List,
  ListItem,
  Collapse,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { UploadError } from "@/types/invoice.types";

const ALLOWED_PARAM_KEYWORDS = [
	'supplier.identification',
	'items[0].cost_center',
	'items[1].tax',
	'payments[0].id',
	'payments',
	'retentions[0].id',
	'cost_center',
	'account',
	'code',
] as const;

const ERROR_MESSAGES: Record<typeof ALLOWED_PARAM_KEYWORDS[number], string> = {
  'supplier.identification': "Este proveedor no existe en el sistema",
  'items[0].cost_center': "Debe indicar un centro de costo activo",
  'items[1].tax': "La cuenta seleccionada debe tener un impuesto asociado",
  'payments[0].id': "El ID del método de pago no se encuentra activo",
  'payments': "El método de pago escogido no es válido",
  'retentions[0].id': "El ID de la retención no se encuentra activo",
  'cost_center': "El centro de costos no existe",
  'account': "La cuenta contable especificada no es válida",
  'code': "El código ingresado no es válido",
};

const CATEGORY_MESSAGES: Record<string, string> = {
	'customer_settings': 'Configuración de cliente',
	'delete_not_allowed': 'No se puede eliminar',
	'blocked_transactions': 'Transacción bloqueada',
	'disabled_functionality': 'Funcionalidad deshabilitada',
	'documents_service': 'Servicio de documentos',
	'document_settings': 'Configuración de documento',
	'parameter_required': 'Parámetro requerido',
	'invalid_cost_center': 'Centro de costos inválido',
	'invalid_reference': 'Referencia inválida',
	'invalid_payment': 'Método de pago inválido',
	'tax_settings': 'Configuración de impuesto',
	'not_found': 'Recurso no encontrado',
	'parameter_inactive': 'Parámetro inactivo',
	'invalid_account': 'Cuenta contable inválida',
	'product_settings': 'Configuración de producto',
	'service_unavailable': 'Servicio no disponible',
	'general_service_error': 'Servicio no disponible',
};

function getCategoryMessage(category: string): string {
  return CATEGORY_MESSAGES[category] || category;
}

function hasRelevantParams(error: UploadError): boolean {
  return error.params.some(param => 
    ALLOWED_PARAM_KEYWORDS.includes(param as typeof ALLOWED_PARAM_KEYWORDS[number]) ||
    ALLOWED_PARAM_KEYWORDS.some(keyword => param.includes(keyword))
  );
}

function getCustomErrorMessage(error: UploadError): string {
  // First try exact match
  const exactMatch = error.params.find(param => 
    ALLOWED_PARAM_KEYWORDS.includes(param as typeof ALLOWED_PARAM_KEYWORDS[number])
  );
  
  if (exactMatch) {
    return ERROR_MESSAGES[exactMatch as keyof typeof ERROR_MESSAGES];
  }

  // If no exact match, try to find a key contained within the param
  for (const param of error.params) {
    for (const keyword of ALLOWED_PARAM_KEYWORDS) {
      if (param.includes(keyword)) {
        return ERROR_MESSAGES[keyword];
      }
    }
  }
  
  return error.message;
}

export function UploadErrorsSection() {
  const { invoice } = useInvoiceDetails();
  const { isOpen, onToggle } = useDisclosure();

  if (!invoice.uploads) return null;

  const erps = ['siigo', 'helisa', 'intuipos'] as const;
  const uploads = invoice.uploads;
  const hasErrors = erps.some(erp => 
    uploads[erp]?.failed && 
    (uploads[erp]?.errors || uploads[erp]?.failed_reason)
  );

  if (!hasErrors) return null;

  return (
    <Box 
      bg="white" 
      p={4} 
      borderRadius="lg"
      boxShadow="sm"
      border="1px"
      borderColor="gray.200"
      overflow="hidden"
    >
      <Alert 
        status="error"
        variant="subtle"
        bg="transparent"
        p={0}
      >
        <Flex 
          justify="space-between" 
          align="center" 
          mb={isOpen ? 4 : 0}
          width="100%"
        >
          <Flex align="center" flex={1}>
            <AlertIcon color="red.500" />
            <Box ml={3}>
              <Text fontWeight="medium" color="gray.900">
                Errores de Carga
              </Text>
              <Text color="gray.600" fontSize="sm">
                Se encontraron errores al cargar la factura en uno o más sistemas.
              </Text>
            </Box>
          </Flex>
          <Button
            size="sm"
            onClick={onToggle}
            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            variant="ghost"
            color="gray.600"
            fontSize="sm"
            fontWeight="medium"
            _hover={{ bg: 'gray.50' }}
            _active={{ bg: 'gray.100' }}
          >
            {isOpen ? "Ocultar" : "Ver"} Detalles
          </Button>
        </Flex>
      </Alert>

      <Collapse in={isOpen} animateOpacity>
        <VStack spacing={3} align="stretch">
          {erps.map(erp => {
            const uploadInfo = uploads[erp];
            if (!uploadInfo?.failed) return null;

            const hasRelevantErrors = uploadInfo.errors && 
              Object.values(uploadInfo.errors).some(errors => 
                Array.isArray(errors) && errors.some(hasRelevantParams)
              );

            if (!hasRelevantErrors) {
              return (
                <Box 
                  key={erp} 
                  p={4} 
                  bg="gray.50" 
                  borderRadius="md"
                  border="1px"
                  borderColor="gray.200"
                >
                  <Text 
                    fontWeight="bold" 
                    color="gray.900"
                    mb={2}
                    textTransform="capitalize"
                    fontSize="sm"
                  >
                    {erp}
                  </Text>
                  <Box 
                    p={3}
                    bg="white"
                    borderRadius="md"
                    border="1px"
                    borderColor="gray.200"
                  >
                    <Text 
                      fontWeight="medium" 
                      color="gray.800" 
                      mb={2}
                      fontSize="sm"
                    >
                      {getCategoryMessage('general_service_error')}:
                    </Text>
                    <List spacing={2}>
                      <ListItem 
                        color="gray.900"
                        fontSize="sm"
                        display="flex"
                        alignItems="flex-start"
                        fontWeight="medium"
                      >
                        <Text as="span" mr={2} color="red.500">•</Text>
                        <Text>Se ha presentado un inconveniente con Siigo. Subiremos tu factura manualmente y te notificaremos cuando esté procesada.</Text>
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              );
            }

            return (
              <Box 
                key={erp} 
                p={4} 
                bg="gray.50" 
                borderRadius="md"
                border="1px"
                borderColor="gray.200"
              >
                <Text 
                  fontWeight="bold" 
                  color="gray.900" 
                  mb={2}
                  textTransform="capitalize"
                  fontSize="sm"
                >
                  {erp}
                </Text>
                {uploadInfo.errors && Object.entries(uploadInfo.errors).map(([category, errors]) => {
                  if (!Array.isArray(errors)) return null;
                  
                  const relevantErrors = errors.filter(hasRelevantParams);
                  
                  // Skip categories with no relevant errors
                  if (relevantErrors.length === 0) return null;

                  return (
                    <Box 
                      key={category} 
                      mb={2}
                      p={3}
                      bg="white"
                      borderRadius="md"
                      border="1px"
                      borderColor="gray.200"
                    >
                      <Text 
                        fontWeight="medium" 
                        color="gray.800" 
                        mb={2}
                        fontSize="sm"
                      >
                        {getCategoryMessage(category)}:
                      </Text>
                      <List spacing={2}>
                        {relevantErrors.map((error, idx) => (
                          <ListItem 
                            key={`relevant-${idx}`}
                            color="gray.900"
                            fontSize="sm"
                            display="flex"
                            alignItems="flex-start"
                            fontWeight="medium"
                          >
                            <Text as="span" mr={2} color="red.500">•</Text>
                            <Text>{getCustomErrorMessage(error)}</Text>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </VStack>
      </Collapse>
    </Box>
  );
} 