import React, { ComponentType, ReactElement } from "react";

interface ComponerProveedoresProps {
    providers: ComponentType<any>[];
    children: ReactElement;
}

export const ComponerProveedores: React.FC<ComponerProveedoresProps> = ({ providers, children }) => {
    return providers.reduceRight((child, Provider) => {
        return <Provider>{child}</Provider>;
    }, children);
};