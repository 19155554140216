import { useState, useEffect, useCallback } from 'react';
import { ErpHandler } from '@/services/erp/erpHandler.interface';
import { PaymentMethodOption } from '@/types/erpShared.types';
import { Invoice } from '@/types/invoice.types';
import { AccountOption } from '@/types/invoiceDetail.types';

export const usePaymentMethods = (
  erpHandler: ErpHandler | null,
  selectedAccount: AccountOption | null,
  invoiceType: string,
  selectedInvoices: Invoice[],
  setSelectedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>,
) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodOption[]>([]);
  const [paymentForm, setPaymentForm] = useState<PaymentMethodOption | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  // Load payment methods
  useEffect(() => {
    const loadPaymentMethods = async () => {
      if (!erpHandler) return;

      setIsLoading(true);
      setError(null);

      try {
        const methods = await erpHandler.getPaymentMethods(invoiceType);
        setPaymentMethods(methods);

        // Initialize payment method if it exists in the invoice
        if (selectedInvoices[0]?.payment_form && methods.length > 0) { // This is for Helisa
          const paymentForm = methods.find((m: PaymentMethodOption) => m.id === selectedInvoices[0].payment_form);
          if (paymentForm) setPaymentForm(paymentForm);
        } else if (selectedInvoices[0]?.payment_method && methods.length > 0) { // This is for Siigo
          const paymentMethod = methods.find((m: PaymentMethodOption) => m.id === selectedInvoices[0].payment_method);
          if (paymentMethod) setPaymentForm(paymentMethod);
        }
      } catch (err) {
        console.error('Error loading payment methods:', err);
        setError('Error loading payment methods');
      } finally {
        setIsLoading(false);
      }
    };

    loadPaymentMethods();
  }, [erpHandler]);

  const updateSelectedInvoices = useCallback((paymentMethod: PaymentMethodOption) => {
    if (selectedInvoices.length > 0) {
      
      setSelectedInvoices(prevInvoices => {
        const updatedInvoices = prevInvoices.map(invoice => {
          if (erpHandler?.erp === 'helisa') {
            return {
              ...invoice,
              payment_form: String(paymentMethod.id) || '',
            };
          }
          else if (erpHandler?.erp === 'siigo') {
            return {
              ...invoice,
              payment_method: Number(paymentMethod.id),
            };
          }
          return invoice;
        });
        return updatedInvoices;
      });
    }
  }, [selectedAccount, selectedInvoices.length, setSelectedInvoices]);

  const handlePaymentMethodChange = useCallback((option: PaymentMethodOption) => {
    setPaymentForm(option);
    updateSelectedInvoices(option);
  }, [updateSelectedInvoices]);


  // Modified version to also notify changes
  const updateSelectedPaymentMethod = useCallback((option: PaymentMethodOption | null) => {
    setPaymentForm(option);
    if (option) updateSelectedInvoices(option);
  }, [updateSelectedInvoices]);

  return {
    paymentMethods,
    paymentForm,
    isLoading,
    error,
    handlePaymentMethodChange,
    setSelectedPaymentMethod: updateSelectedPaymentMethod,
  };
};
