import { UploadTargetType } from "@/types/erpShared.types";
import { getStatusMessage } from "../invoiceDetails/utils";
import { useToast, UseToastOptions } from "@chakra-ui/react";

const handleHelisaResponse = (data: any, toast: (options: UseToastOptions) => void) => {
    if (data?.url !== null && data?.url !== undefined) {
        window.open(data?.url, '_blank');
        toast({
            position: "bottom",
            title: "Archivo plano generado",
            description: "El archivo plano se ha generado correctamente",
            status: 'success',
            duration: 12000,
            isClosable: true,
        });
    } else {
        toast({
            position: "top-right",
            title: "Error al generar archivo",
            description: "No se ha podido generar el archivo plano correctamente",
            status: 'error',
            duration: 12000,
            isClosable: true,
        });
    }
};

const handleSiigoResponse = (data: any, toast: (options: UseToastOptions) => void) => {
    if (data?.url !== null && data?.url !== undefined) {
        toast({
            position: "bottom",
            title: "Archivo Excel generado",
            description: "El archivo Excel se ha generado correctamente",
            status: 'success',
            duration: 12000,
            isClosable: true,
        });
        window.open(data?.url, '_blank');
    }

    // Show additional processing status for Siigo
    if (data) {
        const { description, status } = getStatusMessage(
            data.successfully_uploaded?.length || 0,
            data.error_uploaded?.length || 0,
            'process'
        );
        toast({
            position: "top-right",
            title: "Procesamiento en Siigo",
            description,
            status,
            duration: 12000,
            isClosable: true,
        });
    }
};

const handleIntuiposResponse = (data: any, toast: (options: UseToastOptions) => void) => {
    const { description, status } = data?.status === "success"
        ? getStatusMessage(data.successfully_uploaded?.length || 0, data.error_uploaded?.length || 0, 'upload')
        : {
            description: "Ha ocurrido un error, por favor intente nuevamente",
            status: 'error' as const
        };

    toast({
        position: "top-right",
        title: "Carga a Intuipos",
        description,
        status,
        duration: 12000,
        isClosable: true,
    });
};

export const handleTargetResponse = async (data: any, uploadTarget: UploadTargetType, toast: (options: UseToastOptions) => void) => {
    if (uploadTarget === 'helisa') {
        handleHelisaResponse(data, toast);
    } else if (uploadTarget === 'siigo') {
        handleSiigoResponse(data, toast);
    } else {
        handleIntuiposResponse(data, toast);
    }
}
