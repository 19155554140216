import { InvoiceItem } from "@/types/invoice.types";

export const getMissingConfigs = (item: InvoiceItem, service?: { erp?: string }) => {
    const missing = {
        pos: [] as string[],
        erp: [] as string[]
    };

    // Check POS configurations
    if (!item.inventory_item_name) {
        missing.pos.push('Producto POS');
    }

    // Check ERP configurations
    if (!item.erp_type && service?.erp?.toLowerCase() !== 'helisa') {
        missing.erp.push('Tipo ERP');
    } else {
        if (item.erp_type === 'product' && !item.erp_product && service?.erp?.toLowerCase() !== 'helisa') {
            missing.erp.push('Producto ERP');
        }
        if (!item.erp_account) {
            missing.erp.push('Cuenta ERP');
        }
    }

    // Check tax configurations (part of ERP)
    Object.entries(item.taxes).forEach(([taxName, taxInfo]) => {
        // Skip validation if use_as_cost_value is true
        if (taxInfo.use_as_cost_value) {
            return;
        }
        // Only validate tax_erp_id if not Helisa
        if (!taxInfo.tax_erp_id && service?.erp?.toLowerCase() !== 'helisa') {
            missing.erp.push(`Impuesto ERP (${taxName})`);
        }
        if (!taxInfo.tax_account) {
            missing.erp.push(`Cuenta de impuesto (${taxName})`);
        }
    });

    return missing;
};

export const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('es-CO', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
}; 