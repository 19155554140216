// TODO: Modularize this hook
// Move accounts

import { useState, useEffect, useCallback } from 'react';
import { ErpHandler } from '@/services/erp/erpHandler.interface';
import { RetentionOption, RetentionsData } from '@/types/erpShared.types';
import { Invoice, BaseUploadState } from '@/types/invoice.types';

export type RetentionType = 'ReteICA' | 'ReteIVA' | 'Retefuente';

export const useRetentions = (
    erpService: ErpHandler | null,
    selectedInvoices: Invoice[],
    setSelectedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>
) => {
    const [retentions, setRetentions] = useState<RetentionOption[]>([]);
    const [selectedRetentions, setSelectedRetentions] = useState<RetentionOption[]>([]);
    const [retentionsData, setRetentionsData] = useState<RetentionsData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Specific states for each type of retention
    const [reteICAOptions, setReteICAOptions] = useState<RetentionOption[]>([]);
    const [reteIVAOptions, setReteIVAOptions] = useState<RetentionOption[]>([]);
    const [retefuenteOptions, setRetefuenteOptions] = useState<RetentionOption[]>([]);
    const [selectedReteICA, setSelectedReteICA] = useState<RetentionOption | null>(null);
    const [selectedReteIVA, setSelectedReteIVA] = useState<RetentionOption | null>(null);
    const [selectedRetefuente, setSelectedRetefuente] = useState<RetentionOption | null>(null);

    // States for retention accounts
    const [reteICAAccount, setReteICAAccount] = useState<string>('');
    const [reteIVAAccount, setReteIVAAccount] = useState<string>('');
    const [retefuenteAccount, setRetefuenteAccount] = useState<string>('');
    const [reteICAAccountError, setReteICAAccountError] = useState<string>('');
    const [reteIVAAccountError, setReteIVAAccountError] = useState<string>('');
    const [retefuenteAccountError, setRetefuenteAccountError] = useState<string>('');

    // Load available retentions
    useEffect(() => {
        const loadRetentions = async () => {
            if (!erpService) return;

            setIsLoading(true);
            setError(null);

            try {
                // Get supplier ID from the invoice if it exists
                const supplierId = selectedInvoices[0]?.sender_party || '';

                // Load available retentions // This should not load if all the retentions are already set
                const data = await erpService.getRetentions(supplierId);
                setRetentionsData(data);

                // Configure retention options by type
                if (data.reteICA) setReteICAOptions(data.reteICA);
                if (data.reteIVA) setReteIVAOptions(data.reteIVA);
                if (data.retefuente) setRetefuenteOptions(data.retefuente);

                let currentRetentions = selectedInvoices[0]?.retentions || [];

                const currentReteICA = selectedInvoices[0]?.retentions?.find(r => r.type === 'ReteICA');
                const currentReteIVA = selectedInvoices[0]?.retentions?.find(r => r.type === 'ReteIVA');
                const currentRetefuente = selectedInvoices[0]?.retentions?.find(r => r.type === 'Retefuente');

				const invoiceAlreadyUploaded = Object.values(selectedInvoices[0]?.uploads || {}).some(r => r.uploaded);
				
                // Update the specific selectors
				if (currentRetentions.length > 0 || invoiceAlreadyUploaded) {
					setSelectedReteICA(currentReteICA || null);
					setReteICAAccount(currentReteICA?.account || '');
					setSelectedReteIVA(currentReteIVA || null);
                    setReteIVAAccount(currentReteIVA?.account || '');
					setSelectedRetefuente(currentRetefuente || null);
                    setRetefuenteAccount(currentRetefuente?.account || '');
					setSelectedRetentions(currentRetentions);
				} else {
					if (data.suggested?.reteICA) {
						setSelectedReteICA(data.suggested.reteICA);
						setReteICAAccount(data.suggested.reteICA.account || '');
						currentRetentions = [...currentRetentions, data.suggested.reteICA];
					}
					
					if (data.suggested?.reteIVA) {
						setSelectedReteIVA(data.suggested.reteIVA);
						setReteIVAAccount(data.suggested.reteIVA.account || '');
						currentRetentions = [...currentRetentions, data.suggested.reteIVA];
					}
					
					if (data.suggested?.retefuente) {
						setSelectedRetefuente(data.suggested.retefuente);
						setRetefuenteAccount(data.suggested.retefuente.account || '');
						currentRetentions = [...currentRetentions, data.suggested.retefuente];
					}
					setSelectedRetentions(currentRetentions);
				}
				updateSelectedInvoices(currentRetentions);
            } catch (err) {
                console.error('Error loading retentions:', err);
                setError('Error loading retentions');
            } finally {
                setIsLoading(false);
            }
        };

        loadRetentions();
    }, [erpService]);

    const updateSelectedInvoices = useCallback((retentions: RetentionOption[]) => {
        setSelectedInvoices(prevInvoices => {
            if (prevInvoices.length > 0) {
                const updatedInvoices = prevInvoices.map(invoice => ({
                    ...invoice,
                    retentions
                }));
                return updatedInvoices;
            }
            return prevInvoices;
        });
    }, [selectedInvoices.length, setSelectedInvoices]);

    // Handle retention selection
    const handleRetentionChange = useCallback((selectedOptions: RetentionOption[]) => {
        setSelectedRetentions(selectedOptions);
        updateSelectedInvoices(selectedOptions);
    }, [selectedRetentions, updateSelectedInvoices]);

    // Handle change in a specific type of retention
    const handleSingleRetentionChange = useCallback((option: RetentionOption | null, type: RetentionType) => {
        // Add the new retention
        const newRetentions = selectedRetentions.filter(r => r.type?.toLowerCase() !== type.toLowerCase());
        if (option) newRetentions.push(option);
        updateSelectedInvoices(newRetentions);
        setSelectedRetentions(newRetentions);

        // Update the specific account and the selector state
        switch (type) {
            case 'ReteICA':
                setSelectedReteICA(option);
                setReteICAAccount(option?.account || '');
                setReteICAAccountError('');
                break;
            case 'ReteIVA':
                setSelectedReteIVA(option);
                setReteIVAAccount(option?.account || '');
                setReteIVAAccountError('');
                break;
            case 'Retefuente':
                setSelectedRetefuente(option);
                setRetefuenteAccount(option?.account || '');
                setRetefuenteAccountError('');
                break;
        }
    }, [selectedRetentions, selectedReteICA, selectedReteIVA, selectedRetefuente, updateSelectedInvoices]);

    // Modified versions of the account setters to update the invoice
    const updateReteICAAccount = useCallback((value: string) => {        
        // If there is a callback and a selected retention, update the invoice
        if (selectedReteICA) {
            const currentRetentions = selectedInvoices[0]?.retentions || [];
            const newRetentions = currentRetentions.map(r => r.type === 'ReteICA' ? {...r, account: value || ''} : r);
			setReteICAAccount(value);
			setSelectedRetentions(newRetentions);
            updateSelectedInvoices(newRetentions);
        }
    }, [selectedRetentions, selectedReteICA, updateSelectedInvoices]);

    const updateReteIVAAccount = useCallback((value: string) => {
        // If there is a callback and a selected retention, update the invoice
        if (selectedReteIVA) {
            const currentRetentions = selectedInvoices[0]?.retentions || [];
            const newRetentions = currentRetentions.map(r => r.type === 'ReteIVA' ? {...r, account: value || ''} : r);
			setReteIVAAccount(value);
			setSelectedRetentions(newRetentions);
            updateSelectedInvoices(newRetentions);
        }
    }, [selectedRetentions, selectedReteIVA, updateSelectedInvoices]);

    const updateRetefuenteAccount = useCallback((value: string) => {
        // If there is a callback and a selected retention, update the invoice
        if (selectedRetefuente) {
            const currentRetentions = selectedInvoices[0]?.retentions || [];
            const newRetentions = currentRetentions.map(r => r.type === 'Retefuente' ? {...r, account: value || ''} : r);
			setRetefuenteAccount(value);
			setSelectedRetentions(newRetentions);
            updateSelectedInvoices(newRetentions);
        }

        return true;
    }, [selectedRetentions, selectedRetefuente, updateSelectedInvoices]);

    return {
        retentions,
        selectedRetentions,
        retentionsData,
        isLoading,
        error,
        handleRetentionChange,

        // Specific retentions
        reteICAOptions,
        reteIVAOptions,
        retefuenteOptions,
        selectedReteICA,
        selectedReteIVA,
        selectedRetefuente,

        // Accounting accounts
        reteICAAccount,
        reteIVAAccount,
        retefuenteAccount,
        reteICAAccountError,
        reteIVAAccountError,
        retefuenteAccountError,

        // Specific methods
        handleSingleRetentionChange,
        setReteICAAccount: updateReteICAAccount,
        setReteIVAAccount: updateReteIVAAccount,
        setRetefuenteAccount: updateRetefuenteAccount,
        setReteICAAccountError,
        setReteIVAAccountError,
        setRetefuenteAccountError,
    };
}; 