import { InventoryItem } from "@/types/inventory.types";
import { InvoiceItem, Invoice } from "@/types/invoice.types";
import { getConvertedQuantity, unitConversionFactors } from "@/utils/conversionFactors";
import { conversionExamples, measurementExamples } from "@/utils/conversionsExamples";
import { optionsConversionMeasurements } from "@/utils/options";
import { InfoIcon } from "@chakra-ui/icons";
import { Modal, Select, ModalOverlay, ModalContent, ModalHeader, HStack, ModalCloseButton, ModalBody, VStack, Box, Popover, PopoverTrigger, Button, PopoverContent, PopoverBody, IconButton, Input, Tooltip, InputGroup, InputRightAddon, ModalFooter, Text } from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

interface POSConversionModalProps {
    isOpen: boolean;
    onClose: () => void;
    item: InvoiceItem;
    index: number;
    invoice: Invoice;
    setInvoice: (invoice: Invoice) => void;
    inventoryItems: InventoryItem[];
    currentExampleIndex: { [key: string]: number };
    handleExampleNavigation: (type: string, direction: 'next' | 'back') => void;
    formatCurrency: (value: number) => string;
}

export const POSConversionModal = ({
    isOpen,
    onClose,
    item,
    index,
    invoice,
    setInvoice,
    inventoryItems,
    currentExampleIndex,
    handleExampleNavigation,
    formatCurrency
}: POSConversionModalProps) => {
    // Check if the invoice is already uploaded to the POS system
    const isInvoiceUploaded = invoice?.uploads?.intuipos?.uploaded;
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="lg">
                    <HStack>
                        <Text>Configuración de Conversión</Text>
                    </HStack>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <VStack align="stretch" spacing={4}>
                        <Box>
                            <HStack mb={4}>
                                <Text fontWeight="bold" fontSize="md">Unidades por empaque:</Text>
                                <Popover>
                                    <PopoverTrigger>
                                        <Button size="sm" variant="ghost" colorScheme="blue" rightIcon={<InfoIcon />}>
                                            Ver ejemplo
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent p={4}>
                                        <PopoverBody>
                                            <HStack spacing={4} align="center">
                                                <IconButton
                                                    aria-label="Anterior ejemplo"
                                                    icon={<FaArrowLeft />}
                                                    onClick={() => handleExampleNavigation('conversion', 'back')}
                                                    size="sm"
                                                    variant="ghost"
                                                    colorScheme="blue"
                                                />
                                                <Box flex="1">
                                                    <VStack align="left" spacing={2}>
                                                        <Text fontSize="md"><b>Producto:</b> <i>{conversionExamples[currentExampleIndex['conversion'] || 0].product}</i></Text>
                                                        <Text fontSize="md"><b>Unidades por empaque:</b> <i>{conversionExamples[currentExampleIndex['conversion'] || 0].unitsPerPack}</i></Text>
                                                    </VStack>
                                                </Box>
                                                <IconButton
                                                    aria-label="Siguiente ejemplo"
                                                    icon={<FaArrowRight />}
                                                    onClick={() => handleExampleNavigation('conversion', 'next')}
                                                    size="sm"
                                                    variant="ghost"
                                                    colorScheme="blue"
                                                />
                                            </HStack>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </HStack>
                            <Input
                                value={item.conversion?.units || 1}
                                type="number"
                                size="md"
                                isDisabled={isInvoiceUploaded}
                                borderColor={!item.conversion?.units ? "red.500" : "gray.200"}
                                _hover={{ borderColor: !item.conversion?.units ? "red.300" : "gray.300" }}
                                onChange={(e) => {
                                    const newItems = [...invoice.items];
                                    const conversion = {
                                        units: Number(e.target.value),
                                        measurement: item.conversion?.measurement || 'un',
                                        units_measurement: item.conversion?.units_measurement || 0
                                    }
                                    const converted_quantity = getConvertedQuantity(item.quantity, conversion, item.unit || 'un')
                                    const converted_price = getConvertedQuantity(item.price_per_unit, conversion, item.unit || 'un', true)
                                    newItems[index] = {
                                        ...item,
                                        conversion: conversion,
                                        converted_quantity: converted_quantity,
                                        converted_price: converted_price,
                                    };
                                    setInvoice({ ...invoice, items: newItems });
                                }}
                            />
                        </Box>

                        {item.unit && !unitConversionFactors.includes(item.unit) && (
                            <Box>
                                <HStack mb={4}>
                                    <Text fontWeight="bold" fontSize="md">Medidas de cada unidad:</Text>
                                    <Popover>
                                        <PopoverTrigger>
                                            <Button size="sm" variant="ghost" colorScheme="blue" rightIcon={<InfoIcon />}>
                                                Ver ejemplo
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent p={4}>
                                            <PopoverBody>
                                                <HStack spacing={4} align="center">
                                                    <IconButton
                                                        aria-label="Anterior ejemplo"
                                                        icon={<FaArrowLeft />}
                                                        onClick={() => handleExampleNavigation('measurement', 'back')}
                                                        size="sm"
                                                        variant="ghost"
                                                        colorScheme="blue"
                                                    />
                                                    <Box flex="1">
                                                        <VStack align="left" spacing={2}>
                                                            <Text fontSize="md">Producto: <i>{measurementExamples[currentExampleIndex['measurement'] || 0].product}</i></Text>
                                                            <Text fontSize="md">Medidas: <i>{measurementExamples[currentExampleIndex['measurement'] || 0].measurement} | {measurementExamples[currentExampleIndex['measurement'] || 0].unit}</i></Text>
                                                        </VStack>
                                                    </Box>
                                                    <IconButton
                                                        aria-label="Siguiente ejemplo"
                                                        icon={<FaArrowRight />}
                                                        onClick={() => handleExampleNavigation('measurement', 'next')}
                                                        size="sm"
                                                        variant="ghost"
                                                        colorScheme="blue"
                                                    />
                                                </HStack>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </HStack>
                                <HStack>
                                    <Input
                                        value={item.conversion?.units_measurement || 1}
                                        type="number"
                                        size="md"
                                        isDisabled={isInvoiceUploaded}
                                        borderColor={!item.conversion?.units_measurement ? "red.500" : "gray.200"}
                                        _hover={{ borderColor: !item.conversion?.units_measurement ? "red.300" : "gray.300" }}
                                        onChange={(e) => {
                                            const newItems = [...invoice.items];
                                            const conversion = {
                                                units: item.conversion?.units || 1,
                                                measurement: item.conversion?.measurement || 'un',
                                                units_measurement: Number(e.target.value)
                                            }
                                            const converted_quantity = getConvertedQuantity(item.quantity, conversion, item.unit || 'un')
                                            const converted_price = getConvertedQuantity(item.price_per_unit, conversion, item.unit || 'un', true)
                                            newItems[index] = {
                                                ...item,
                                                converted_quantity: converted_quantity,
                                                converted_price: converted_price,
                                                conversion: conversion
                                            };
                                            setInvoice({ ...invoice, items: newItems });
                                        }}
                                    />
                                    <Select
                                        value={item.conversion?.measurement || 'un'}
                                        size="md"
                                        isDisabled={isInvoiceUploaded}
                                        borderColor={!item.conversion?.measurement ? "red.500" : "gray.200"}
                                        _hover={{ borderColor: !item.conversion?.measurement ? "red.300" : "gray.300" }}
                                        onChange={(e) => {
                                            const newItems = [...invoice.items];
                                            const conversion = {
                                                units: item.conversion?.units || 1,
                                                measurement: e.target.value,
                                                units_measurement: item.conversion?.units_measurement || 0
                                            }
                                            const converted_quantity = getConvertedQuantity(item.quantity, conversion, item.unit || 'un')
                                            const converted_price = getConvertedQuantity(item.price_per_unit, conversion, item.unit || 'un', true)
                                            newItems[index] = {
                                                ...item,
                                                converted_quantity: converted_quantity,
                                                converted_price: converted_price,
                                                conversion: conversion
                                            };
                                            setInvoice({ ...invoice, items: newItems });
                                        }}
                                    >
                                        {optionsConversionMeasurements.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                </HStack>
                            </Box>
                        )}

                        <Box>
                            <Text fontWeight="bold" fontSize="md" mb={4}>Resultados de la conversión:</Text>
                            <VStack align="stretch" spacing={4}>
                                <HStack>
                                    <Text fontSize="md">Cantidad POS:</Text>
                                    <Tooltip label="La unidad mostrada es la que requiere el item del POS" hasArrow>
                                        <InfoIcon color="gray.500" />
                                    </Tooltip>
                                    <InputGroup size="md">
                                        <Input
                                            value={item.converted_quantity || (item.quantity && item.conversion && item.unit ? getConvertedQuantity(item.quantity, item.conversion, item.unit, false) : item.quantity)}
                                            type="number"
                                            disabled={true}
                                        />
                                        <InputRightAddon>{inventoryItems.find(invItem => invItem.item_name === item.inventory_item_name)?.item_unit || ''}</InputRightAddon>
                                    </InputGroup>
                                </HStack>

                                <HStack>
                                    <Text fontSize="md">Precio POS:</Text>
                                    <Tooltip label="El precio mostrado es el que se sube al POS" hasArrow>
                                        <InfoIcon color="gray.500" />
                                    </Tooltip>
                                    <InputGroup size="md">
                                        <Input
                                            value={formatCurrency(item.converted_price || (item.price_per_unit && item.conversion && item.unit ?
                                                getConvertedQuantity(item.price_per_unit, item.conversion, item.unit, true) :
                                                item.price_per_unit))}
                                            type="text"
                                            disabled={true}
                                        />
                                        <InputRightAddon>COP x {inventoryItems.find(invItem => invItem.item_name === item.inventory_item_name)?.item_unit}</InputRightAddon>
                                    </InputGroup>
                                </HStack>
                            </VStack>
                        </Box>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onClose} size="md">
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
