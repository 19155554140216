import React from 'react';
import { 
  Box, 
  Container, 
  Heading, 
  Text, 
  Flex,
  Stack, 
  Circle,
  Icon,
  useColorModeValue,
  Divider,
  HStack,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaFileAlt, FaClipboardList, FaSearchDollar, FaChartLine } from 'react-icons/fa';

// Wrap with motion for animations
const MotionBox = motion(Box);
const MotionCircle = motion(Circle);

const HowItWorksSection = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const mainBg = useColorModeValue('gray.50', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');
  const accentColor = useColorModeValue('blue.500', 'blue.300');
  const secondaryColor = useColorModeValue('blue.600', 'blue.400');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const dividerColor = useColorModeValue('blue.300', 'blue.500');
  
  const steps = [
    {
      number: "1",
      title: "Digitalización Inteligente",
      description: "Nuestra IA extrae toda la información relevante de tus documentos de la DIAN automáticamente en segundos.",
      icon: FaFileAlt,
      color: "blue.500"
    },
    {
      number: "2",
      title: "Clasificación y Organización",
      description: "La plataforma clasifica y organiza los datos según las categorías contables adecuadas, eliminando la necesidad de hacerlo manualmente.",
      icon: FaClipboardList,
      color: "teal.500"
    },
    {
      number: "3",
      title: "Análisis y Validación",
      description: "La IA analiza los datos en tiempo real para detectar inconsistencias, validar cumplimiento normativo y prevenir errores antes de que ocurran.",
      icon: FaSearchDollar,
      color: "purple.500"
    },
    {
      number: "4",
      title: "Reportes y Dashboards",
      description: "Genera informes personalizables con visualizaciones claras para compartir con tus clientes, aumentando el valor percibido de tus servicios.",
      icon: FaChartLine,
      color: "blue.600",
      badge: "Próximamente"
    }
  ];

  return (
    <Box bg={mainBg} py={{ base: 16, md: 24 }} id="como-funciona">
      <Container maxW="container.xl">
        <Stack spacing={16} align="center">
          <Stack textAlign="center" maxW="3xl" mx="auto" spacing={5}>
            <Heading 
              as="h2" 
              size="xl" 
              bgGradient="linear(to-r, blue.500, teal.500)" 
              bgClip="text"
            >
              ¿Cómo Funciona?
            </Heading>
            <Text fontSize="lg" color={textColor}>
              Cifrato se integra perfectamente en tu flujo de trabajo actual, mejorándolo sin complicaciones.
              Descubre nuestro proceso paso a paso.
            </Text>
          </Stack>
          
          {/* Desktop Version - Horizontal Timeline */}
          {isDesktop ? (
            <Flex w="full" justify="space-between" position="relative" px={8}>
              {/* Connecting Line */}
              <Box 
                position="absolute" 
                top="50%" 
                left="50px" 
                right="50px" 
                height="4px" 
                bg={dividerColor} 
                zIndex={0} 
                transform="translateY(-50%)"
              />
              
              {steps.map((step, index) => (
                <VStack key={index} width={`${100/steps.length}%`} position="relative" zIndex={1}>
                  <MotionCircle 
                    size="70px" 
                    bg={step.color} 
                    color="white" 
                    mb={4}
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <Icon as={step.icon} boxSize={8} />
                  </MotionCircle>
                  <MotionBox
                    bg={cardBg}
                    p={6}
                    rounded="lg"
                    shadow="lg"
                    width="full"
                    maxW="280px"
                    textAlign="center"
                    whileHover={{ y: -5 }}
                    transition={{ type: "spring", stiffness: 300 }}
                    position="relative"
                    borderTop="4px solid"
                    borderColor={step.color}
                  >
                    {step.badge && (
                      <Box 
                        position="absolute"
                        top="-10px"
                        right="-10px"
                        bg="blue.50"
                        color="blue.800"
                        fontSize="xs"
                        fontWeight="bold"
                        px={2}
                        py={1}
                        rounded="full"
                        borderWidth="1px"
                        borderColor="blue.200"
                      >
                        {step.badge}
                      </Box>
                    )}
                    <Text fontSize="2xl" fontWeight="bold" mb={2}>
                      {step.number}
                    </Text>
                    <Text fontWeight="bold" fontSize="lg" mb={2} color={secondaryColor}>
                      {step.title}
                    </Text>
                    <Text fontSize="sm" color={textColor}>
                      {step.description}
                    </Text>
                  </MotionBox>
                </VStack>
              ))}
            </Flex>
          ) : (
            /* Mobile Version - Vertical Timeline */
            <VStack spacing={8} w="full" position="relative">
              {/* Vertical connecting line */}
              <Box
                position="absolute"
                top="40px"
                bottom="40px"
                left="32px"
                width="4px"
                bgGradient="linear(to-b, blue.400, teal.400)"
                zIndex={0}
              />
              
              {steps.map((step, index) => (
                <HStack key={index} w="full" spacing={6} position="relative" zIndex={1} align="start">
                  <MotionCircle
                    size="64px"
                    bg={step.color}
                    color="white"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Icon as={step.icon} boxSize={6} />
                  </MotionCircle>
                  <MotionBox
                    flex={1}
                    bg={cardBg}
                    p={5}
                    rounded="lg"
                    shadow="md"
                    borderLeft="4px solid"
                    borderColor={step.color}
                    whileHover={{ x: 5 }}
                    position="relative"
                  >
                    {step.badge && (
                      <Box 
                        position="absolute"
                        top="-10px"
                        right="-10px"
                        bg="blue.50"
                        color="blue.800"
                        fontSize="xs"
                        fontWeight="bold"
                        px={2}
                        py={1}
                        rounded="full"
                        borderWidth="1px"
                        borderColor="blue.200"
                      >
                        {step.badge}
                      </Box>
                    )}
                    <Text fontWeight="bold" fontSize="lg" mb={1} color={secondaryColor}>
                      {step.number}. {step.title}
                    </Text>
                    <Text fontSize="sm" color={textColor}>
                      {step.description}
                    </Text>
                  </MotionBox>
                </HStack>
              ))}
            </VStack>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default HowItWorksSection; 