import { Box } from '@chakra-ui/react';
import LandingNav from '@/components/landing/LandingNav';
import HeroSection from '@/components/landing/HeroSection';
import BenefitsSection from '@/components/landing/BenefitsSection';
import HowItWorksSection from '@/components/landing/HowItWorksSection';
import DemoVideoSection from '@/components/landing/DemoVideoSection';
import TestimonialsSection from '@/components/landing/TestimonialsSection';
import PricingSection from '@/components/landing/PricingSection';
import CTASection from '@/components/landing/CTASection';
import FooterSection from '@/components/landing/FooterSection';
import ClientsSection from '@/components/landing/ClientsSection';
import BackersSection from '@/components/landing/BackersSection';

const Landing = () => {
  return (
    <Box>
      <LandingNav />
      <HeroSection />
      <BenefitsSection />
      <ClientsSection />
      <HowItWorksSection />
      <DemoVideoSection />
      {/* <TestimonialsSection /> */}
      {/* <PricingSection /> */}
      <BackersSection />
      <CTASection />
      <FooterSection />
    </Box>
  );
};

export default Landing; 