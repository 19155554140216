import { Invoice, InvoiceItem, InvoiceValidations, TaxxaValidation } from "../types/invoice.types";
import { getConvertedQuantity } from "./conversionFactors";

export const calculateConversions = (invoiceToUpdate: Invoice): Invoice => {
    const updatedInvoice = { ...invoiceToUpdate };

    updatedInvoice.items = updatedInvoice.items.map(item => {
        // Only calculate if we have the necessary information AND the values aren't already set
        if (item.inventory_item_name &&
            item.quantity &&
            item.conversion &&
            item.unit &&
            (!item.converted_quantity || !item.converted_price)) {

            return {
                ...item,
                converted_quantity: item.converted_quantity || getConvertedQuantity(
                    item.quantity,
                    item.conversion,
                    item.unit,
                    false
                ),
                converted_price: item.converted_price || getConvertedQuantity(
                    item.price_per_unit,
                    item.conversion,
                    item.unit,
                    true
                )
            };
        }
        return item;
    });

    return updatedInvoice;
};

export const getTaxxaStatusLabel = (validations?: InvoiceValidations): string => {
    if (!validations?.taxxa) return 'Pendiente de acuse de recibo';

    const taxxa = validations.taxxa;
    const processes = {
        'acuse_recibo': 'Acuse de recibo',
        'recibo_de_bienes': 'Recibo de bienes',
        'aceptacion_expresa': 'Aceptación expresa'
    };

    const completed = Object.entries(processes)
        .filter(([key]) => taxxa[key as keyof TaxxaValidation])
        .map(([, label]) => label);

    const pending = Object.entries(processes)
        .filter(([key]) => !taxxa[key as keyof TaxxaValidation])
        .map(([, label]) => label);

    if (completed.length === 0) return 'Pendiente de acuse de recibo';
    if (pending.length === 0) return 'Acuse de recibo completado';

    return `Completado: ${completed.join(', ')}\nPendiente: ${pending.join(', ')}`;
};

export const checkPOSReadiness = (items: InvoiceItem[]): boolean => {
    return items.every(item => item.inventory_item_name);
};

export const checkERPReadiness = (items: InvoiceItem[], service?: string): boolean => {
    if (service?.toLowerCase() === 'helisa') {
        return items.every(item =>
            item.erp_account &&
            (item.taxes ? Object.values(item.taxes).every(tax => tax.use_as_cost_value || tax.tax_account) : true)
        );
    }

    return items.every(item =>
        item.erp_type &&
        item.erp_account &&
        ((item.erp_type === 'product' && item.erp_product) || item.erp_type === 'account') &&
        (item.taxes ? Object.values(item.taxes).every(tax => tax.use_as_cost_value || (tax.tax_erp_id && tax.tax_account)) : true)
    );
};