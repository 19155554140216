import * as Sentry from "@sentry/react";

const SENTRY_DSN = "https://2521e85544997f15b83c2ef99b2d4d4d@o4508847394258944.ingest.us.sentry.io/4508864375488512";

const feedbackConfig = {
  triggerLabel: "Reportar un error",
  formTitle: "Reportar un error",
  nameLabel: "Nombre",
  namePlaceholder: "Tu nombre",
  showEmail: false,
  showBranding: false,
  messageLabel: "Mensaje",
  messagePlaceholder: "Describe el error que estás experimentando",
  isRequiredLabel: "Requerido",
  addScreenshotButtonLabel: "Agregar captura de pantalla",
  removeScreenshotButtonLabel: "Quitar captura de pantalla",
  submitButtonLabel: "Enviar",
  cancelButtonLabel: "Cancelar",
  successMessageText: "Gracias por tu reporte! Vamos a revisar el problema y te contactaremos pronto.",
};

export const initializeSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      // Sentry.feedbackIntegration(feedbackConfig), // TODO: Is this better than intercom or similar?
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}; 