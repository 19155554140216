import { Box, Table, Thead, Tr, Th, Checkbox, Tbody, Tooltip, Icon } from "@chakra-ui/react";
import { ShowInvoicesTable } from "./InvoicesTable";
import { InvocesTableContext } from "@/context/invoices/invoicesTableContext";
import { useInvoicesContext } from "@/context/invoices/invoicesContext";
import { HiSparkles } from "react-icons/hi";
import { QuestionIcon } from '@chakra-ui/icons';

export function TableSection() {


    const { filteredInvoices, selectedInvoices, setSelectedInvoices, providerInputValue, handleSelectInvoice, isLoading, services } = useInvoicesContext();
    const { tabIndex } = useInvoicesContext();
    return (<Box
        bg="white"
        overflowX="auto"
        mt={6}
        ml={2}

    >
        <Table variant="simple" size="sm">
            <Thead>
                <Tr>
                    <Th width="40px" px={2}>
                        <Checkbox
                            size="md"
                            isDisabled={isLoading}
                            isChecked={selectedInvoices.length > 0 && selectedInvoices.length === filteredInvoices.length}
                            isIndeterminate={selectedInvoices.length > 0 && selectedInvoices.length < filteredInvoices.length}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedInvoices(filteredInvoices);
                                } else {
                                    setSelectedInvoices([]);
                                }
                            }}
                        />
                    </Th>
                    <Th textAlign="center">ID</Th>
                    <Th textAlign="center">Fecha</Th>
                    {tabIndex === 0 && (
                        <Th textAlign="center">Proveedor</Th>
                    )}
                    {services?.pos == "intuipos" && <Th textAlign="center">Orden de Compra</Th>}
                    {tabIndex === 0 && (
                        <Th textAlign="center">
                            <Box as="span" display="inline-flex" alignItems="center" gap={1}>
                                Tipo
                                <Tooltip label="Nuestra IA analiza y clasifica tu factura: compra, gasto o nota de crédito." placement="top" hasArrow>
                                    <Box
                                        as="span"
                                        display="inline-flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        bg="purple.50"
                                        p="1"
                                        borderRadius="md"
                                        transition="all 0.2s"
                                        _hover={{ bg: 'purple.100' }}
                                    >
                                        <HiSparkles style={{
                                            display: 'inline',
                                            fontSize: '1em',
                                            color: '#553C9A',
                                            cursor: 'help'
                                        }} />
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Th>
                    )}
                    <Th textAlign="center">Estados de carga</Th>
                    <Th textAlign="center">
                        <Box as="span" display="inline-flex" alignItems="center" gap={1}>
                            Listo
                            <Tooltip label="Los productos o servicios ya están listos. Completa la información adicional (tipo de factura, centro de costo, etc.) para enviarla al sistema contable." placement="top" hasArrow>
                                <Icon as={QuestionIcon} ml={1} boxSize={4} color="blue.500" />
                            </Tooltip>
                        </Box>
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                <InvocesTableContext.Provider value={{ debouncedProviderFilter: providerInputValue, filteredInvoices, handleSelectInvoice, isLoading, selectedInvoices: selectedInvoices.map(inv => inv.id), services }} >
                    <ShowInvoicesTable />
                </InvocesTableContext.Provider>
            </Tbody>
        </Table>
    </Box>
    )
}

