import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    VStack,
    HStack,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    List,
    ListItem,
} from '@chakra-ui/react';
import { Invoice } from '@/types/invoice.types';
import { useInvoicesHandler } from '@/context/shared/invoicesHandlerContext';

interface UploadErpModalProps {
    isOpen: boolean;
    onClose: () => void;
    invoice: Invoice | null;
    isLoading: boolean;
    onConfirm: () => void; // Simplificada, ya que usaremos el contexto
    uploadTarget?: 'siigo' | 'helisa';
}

export const UploadErpModal: React.FC<UploadErpModalProps> = ({
    isOpen,
    onClose,
    isLoading,
    onConfirm,
    uploadTarget = 'siigo'
}) => {
    const { 
        invoices: selectedInvoices, 
        invoicesWithErrors, 
        missingFormFields, 
        hasErrors, 
        hasMissingFields, 
        areSuccessfullyUploaded ,
        useCostCenterPerItem
    } = useInvoicesHandler();

    const invoice = selectedInvoices[0];

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {uploadTarget === 'siigo' ? 'Confirmar subida a Siigo' : 
                     uploadTarget === 'helisa' ? 'Confirmar subida a Helisa' : 
                     'Confirmar subida al sistema contable'}
                </ModalHeader>
                <ModalBody>
                    <VStack spacing={4} align="stretch">
                        {areSuccessfullyUploaded ? (
                            <Alert
                                status="info"
                                flexDirection="column"
                                alignItems="flex-start"
                            >
                                <AlertIcon />
                                <AlertTitle mb={2}>
                                    Factura ya subida
                                </AlertTitle>
                                <AlertDescription width="100%">
                                    <Text>
                                        Esta factura ya ha sido subida correctamente a {
                                            uploadTarget === 'siigo' ? 'Siigo' : 
                                            uploadTarget === 'helisa' ? 'Helisa' : 
                                            'el sistema contable'
                                        }. No es posible subirla nuevamente.
                                    </Text>
                                </AlertDescription>
                            </Alert>
                        ) : hasErrors ? (
                            <Alert
                                status="error"
                                flexDirection="column"
                                alignItems="flex-start"
                            >
                                <AlertIcon />
                                <AlertTitle mb={2}>
                                    Se encontraron problemas en la factura
                                </AlertTitle>
                                <AlertDescription width="100%">
                                    <Text mb={2}>
                                        {uploadTarget === 'siigo' ? (
                                            'La factura tiene productos sin configuración completa en Siigo:'
                                        ) : uploadTarget === 'helisa' ? (
                                            'La factura tiene productos sin números de cuenta configurados en Helisa:'
                                        ) : (
                                            'La factura tiene problemas de configuración:'
                                        )}
                                    </Text>
                                    <List spacing={2}>
                                        {invoicesWithErrors.map(({ invoiceId, items, errorType }) => (
                                            <ListItem key={invoiceId}>
                                                {errorType === 'return_without_reference' ? (
                                                    <Text pl={4} color="red.500">
                                                        Esta factura es una devolución pero no tiene factura de referencia
                                                    </Text>
                                                ) : (
                                                    <List pl={4} styleType="disc">
                                                        {items.map((item, idx) => (
                                                            <ListItem key={`${item.description}-${idx}`}>
                                                                <Text>
                                                                    Producto "{item.description}" (ítem {idx + 1}):
                                                                    {useCostCenterPerItem ? (
                                                                        <Text as="span" color="red.500">
                                                                            {!item.cost_center && " falta centro de costo"}
                                                                        </Text>
                                                                    ) : null}
                                                                    {uploadTarget === 'siigo' ? (   
                                                                        <Text as="span" color="red.500">
                                                                            {!item.erp_type && " falta tipo en Siigo"}
                                                                            {item.erp_type === 'product' && !item.erp_product && " falta producto en Siigo"}
                                                                            {Object.values(item.taxes || {}).some((tax: any) => !tax.use_as_cost_value && !tax.tax_erp_id) && " falta cuenta de impuesto"}
                                                                        </Text>
                                                                    ) : uploadTarget === 'helisa' ? (
                                                                        <Text as="span" color="red.500">
                                                                            {!item.erp_account && " falta cuenta contable en Helisa"}
                                                                            {Object.values(item.taxes || {}).some((tax: any) => !tax.use_as_cost_value && !tax.tax_account) && " falta cuenta de impuesto"}
                                                                        </Text>
                                                                    ) : null}
                                                                </Text>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>
                                </AlertDescription>
                            </Alert>
                        ) : hasMissingFields ? (
                            <Alert
                                status="error"
                                flexDirection="column"
                                alignItems="flex-start"
                            >
                                <AlertIcon />
                                <AlertTitle mb={2}>
                                    Formulario incompleto
                                </AlertTitle>
                                <AlertDescription width="100%">
                                    <Text mb={2}>
                                        Por favor complete los siguientes campos requeridos en el formulario antes de subir la factura:
                                    </Text>
                                    <List spacing={2} styleType="disc" pl={4}>
                                        {missingFormFields.map((field, index) => (
                                            <ListItem key={index}>
                                                <Text color="red.500">{field}</Text>
                                            </ListItem>
                                        ))}
                                    </List>
                                </AlertDescription>
                            </Alert>
                        ) : (
                            <>
                                <Text>
                                    ¿Estás seguro que deseas subir la siguiente factura a {
                                        uploadTarget === 'siigo' ? 'Siigo' : 
                                        uploadTarget === 'helisa' ? 'Helisa' : 
                                        'el sistema contable'
                                    }?
                                </Text>
                                <HStack
                                    p={4}
                                    bg="gray.50"
                                    borderRadius="md"
                                    justify="center"
                                >
                                    <Text fontWeight="bold">ID</Text>
                                    <Text>{invoice?.invoice_id}</Text>
                                </HStack>
                            </>
                        )}
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button
                        variant="ghost"
                        mr={3}
                        onClick={onClose}
                        isDisabled={isLoading}
                    >
                        Cancelar
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={onConfirm}
                        isLoading={isLoading}
                        loadingText="Subiendo..."
                        isDisabled={hasErrors || hasMissingFields || areSuccessfullyUploaded}
                    >
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
