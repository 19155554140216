import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    VStack,
    Checkbox,
    Text,
    Divider,
} from '@chakra-ui/react';
import { InvoiceItem } from '@/types/invoice.types';

interface ApplyConfigModalProps {
    isOpen: boolean;
    onClose: () => void;
    items: InvoiceItem[];
    sourceItemIndex: number;
    selectedItems: number[];
    setSelectedItems: (items: number[]) => void;
    onApply: () => void;
}

export const ApplyConfigModal: React.FC<ApplyConfigModalProps> = ({
    isOpen,
    onClose,
    items,
    sourceItemIndex,
    selectedItems,
    setSelectedItems,
    onApply,
}) => {
    const availableItems = items
        .map((_, index) => index)
        .filter(index => index !== sourceItemIndex);

    const handleCheckboxChange = (index: number) => {
        if (selectedItems.includes(index)) {
            setSelectedItems(selectedItems.filter(i => i !== index));
        } else {
            setSelectedItems([...selectedItems, index]);
        }
    };

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            setSelectedItems(availableItems);
        } else {
            setSelectedItems([]);
        }
    };

    const isAllSelected = availableItems.length === selectedItems.length;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Aplicar configuración a otros items</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={4}>
                        Seleccione los items a los que desea aplicar la misma configuración del item "{items[sourceItemIndex]?.description}":
                    </Text>
                    <VStack align="stretch" spacing={3}>
                        <Checkbox
                            isChecked={isAllSelected}
                            onChange={(e) => handleSelectAll(e.target.checked)}
                            fontWeight="bold"
                        >
                            Seleccionar todos
                        </Checkbox>
                        <Divider />
                        {items.map((item, index) => (
                            index !== sourceItemIndex && (
                                <Checkbox
                                    key={index}
                                    isChecked={selectedItems.includes(index)}
                                    onChange={() => handleCheckboxChange(index)}
                                >
                                    {item.description}
                                </Checkbox>
                            )
                        ))}
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button 
                        colorScheme="blue" 
                        onClick={onApply}
                        isDisabled={selectedItems.length === 0}
                    >
                        Aplicar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}; 