import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { fetchSupplierInvoices } from '@/services/invoices.services';
import { Invoice } from '@/types/invoice.types';

export const useSupplierInvoices = (invoice: Invoice | null) => {
  const toast = useToast();
  const [supplierInvoices, setSupplierInvoices] = useState<Array<Invoice & { total_amount: number }>>([]);
  const [isLoadingSupplierInvoices, setIsLoadingSupplierInvoices] = useState(false);

  useEffect(() => {
    const loadSupplierInvoices = async () => {
      if (!invoice?.is_return || !invoice.sender_party) return;

      setIsLoadingSupplierInvoices(true);
      try {
        const data = await fetchSupplierInvoices(invoice.sender_party);
        if (data && Array.isArray(data)) {
          setSupplierInvoices(data);
        } else {
          console.error("Invalid response format:", data);
        }
      } catch (error) {
        console.error("Error loading supplier invoices:", error);
        toast({
          title: "Error al cargar facturas del proveedor",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingSupplierInvoices(false);
      }
    };

    loadSupplierInvoices();
  }, []);

  return {
    supplierInvoices,
    isLoadingSupplierInvoices
  };
}; 