import { useState, useEffect, useCallback } from 'react';
import { ErpHandler } from '@/services/erp/erpHandler.interface';
import { CostCenterOption, InvoiceTypeOption } from '@/types/erpShared.types';
import { Invoice } from '@/types/invoice.types';

export const useCostCenters = (
  erpHandler: ErpHandler | null,
  cost_center_default: number | string | undefined,
  selectedInvoices: Invoice[],
  setSelectedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>,
) => {
  const [costCenters, setCostCenters] = useState<CostCenterOption[]>([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState<CostCenterOption | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Generic function to find a cost center by ID or value
  const findCostCenter = useCallback((
    centers: CostCenterOption[],
    costCenterId: number | string | undefined
  ): CostCenterOption | undefined => {
    if (!costCenterId || centers.length === 0) return undefined;

    const numericId = typeof costCenterId === 'number' ? costCenterId : Number(costCenterId);

    return centers.find((c: CostCenterOption) =>
      c.id === numericId ||
      c.value === numericId ||
      c.value === costCenterId ||
      String(c.value) === String(costCenterId)
    );
  }, []);

  // Load cost centers from API - only when erpHandler changes
  useEffect(() => {
    const loadCostCenters = async () => {
      if (!erpHandler) return;

      setIsLoading(true);
      setError(null);

      try {
        const centers = await erpHandler.getCostCenters();
        setCostCenters(centers);
      } catch (err) {
        console.error('Error loading cost centers:', err);
        setError('Error loading cost centers');
      } finally {
        setIsLoading(false);
      }
    };

    loadCostCenters();
  }, [erpHandler]); // Only depends on erpHandler

  // Separate effect to handle cost center selection when data changes
  useEffect(() => {
    if (costCenters.length === 0) return;

    // Priority 1: Use cost center from invoice if available
    if (selectedInvoices[0]?.cost_center) {
      const center = findCostCenter(costCenters, selectedInvoices[0].cost_center);
      if (center) {
        setSelectedCostCenter(center);
        return;
      }
    }

    // Priority 2: Use default cost center from invoice type
    if (cost_center_default) {
      const center = findCostCenter(costCenters, cost_center_default);
      if (center) {
        setSelectedCostCenter(center);
      }
    }
  }, [costCenters, selectedInvoices, cost_center_default, findCostCenter]);

  // Update all selected invoices with the given cost center
  const updateSelectedInvoices = useCallback((costCenter: CostCenterOption) => {
    if (selectedInvoices.length === 0) return;

    // Update all the selected invoices
    setSelectedInvoices(prevInvoices => {
      const updatedInvoices = prevInvoices.map(invoice => ({
        ...invoice,
        cost_center: typeof invoice.cost_center === 'number'
          ? Number(costCenter.value)
          : costCenter.value.toString()
      }));
      return updatedInvoices;
    });
  }, [selectedInvoices.length, setSelectedInvoices]);

  // Handle cost center change
  const handleCostCenterChange = useCallback((option: CostCenterOption) => {
    setSelectedCostCenter(option);
    updateSelectedInvoices(option);
  }, [updateSelectedInvoices]);

  // Set selected cost center and notify changes if needed
  const updateSelectedCostCenter = useCallback((option: CostCenterOption | null) => {
    setSelectedCostCenter(option);
    if (option) {
      updateSelectedInvoices(option);
    }
  }, [updateSelectedInvoices]);

  return {
    costCenters,
    selectedCostCenter,
    isLoading,
    error,
    handleCostCenterChange,
    setSelectedCostCenter: updateSelectedCostCenter
  };
}; 