import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { fetchInvoices } from '@/services/invoices.services';
import { Invoice } from '@/types/invoice.types';

export const useInvoicesList = (
  currentPage: number,
  pageSize: number,
  startDate: string | null,
  endDate: string | null,
  includeCreditNotes: boolean | null,
  includeGeneralPurchases: boolean | null,
  includePurchases: boolean | null,
  sales: boolean | null,
  uploadStatus: string,
  providerInputValue: string,
  tabIndex: number,
  setSelectedInvoices: (invoices: Invoice[]) => void
) => {
  const [filteredInvoices, setFilteredInvoices] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const toast = useToast();

  const loadInvoices = async () => {
    setIsLoading(true);
    try {
      const data = await fetchInvoices(
        currentPage,
        pageSize,
        startDate || undefined,
        endDate || undefined,
        uploadStatus,
        providerInputValue || undefined,
        includeCreditNotes,
        includeGeneralPurchases,
        includePurchases,
        sales,
      );
      
      let invoicesWithProducts = data.invoices.filter(
        (invoice) => invoice.items && invoice.items.length > 0
      );
      
      // Filter based on the invoice type (sale or purchase) based on the tabIndex
      if (tabIndex === 0) {
        // Tab of Sale Invoices: show only sale invoices
        invoicesWithProducts = invoicesWithProducts.filter(invoice => !invoice.is_sale);
      } else {
        // Tab of Purchase Invoices: show only purchase invoices
        invoicesWithProducts = invoicesWithProducts.filter(invoice => invoice.is_sale);
      }
      
      setFilteredInvoices(invoicesWithProducts);
      setTotalInvoices(data.total);
      setHasMore(data.has_more);
    } catch (error) {
      console.error("Error fetching invoices:", error);
      toast({
        title: "Error fetching invoices",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle selection of invoices
  const handleSelectInvoice = (invoice: Invoice, selectedInvoices: Invoice[]) => {
    let newSelectedInvoices: Invoice[];

    if (selectedInvoices.map(inv => inv.id).includes(invoice.id)) {
      // Remove from selection
      newSelectedInvoices = selectedInvoices.filter((inv) => inv.id !== invoice.id);
    } else {
      // Add to selection
      newSelectedInvoices = [...selectedInvoices, invoice];
    }

    setSelectedInvoices(newSelectedInvoices);
  };

  // Handle upload completion
  const handleUploadComplete = () => {
    loadInvoices(); // Refresh the invoices
    setSelectedInvoices([]); // Clear selection after upload
  };

  // Handle page change
  const handlePageChange = (newPage: number, setSearchParams: Function, searchParams: URLSearchParams) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', newPage.toString());
    setSearchParams(newParams);
    
    // Reset selected invoices when changing pages
    setSelectedInvoices([]);
  };

  // Handle page size change
  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>, setSearchParams: Function, searchParams: URLSearchParams) => {
    const newPageSize = parseInt(e.target.value);
    const newParams = new URLSearchParams(searchParams);
    
    newParams.set('pageSize', newPageSize.toString());
    newParams.set('page', '1');
    setSearchParams(newParams);
    
    // Reset selected invoices when changing page size
    setSelectedInvoices([]);
  };

  return {
    filteredInvoices,
    isLoading,
    totalInvoices,
    hasMore,
    loadInvoices,
    handleSelectInvoice,
    handleUploadComplete,
    handlePageChange,
    handlePageSizeChange
  };
}; 