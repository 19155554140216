import { useNavigate } from "react-router-dom";
import {
    VStack,
    Heading,
    Text,
    Box,
    Button,
    Progress,
    Flex,
} from "@chakra-ui/react";
import { MdArrowForward } from "react-icons/md";
import { StepIndicator } from "@/components/onboarding/StepIndicator";
import { useOnboarding, ONBOARDING_STEPS } from "@/context/onboardingContext";

// Main Component
export default function ClientOnboarding() {
    const navigate = useNavigate();
    const {
        currentStepIndex,
        completedSteps,
        stepsData,
        currentStepValid,
        currentStep,
        progress,
        handleDataChange,
        handleContinue,
        handleStepClick,
        canNavigateToStep,
        isSubmitting
    } = useOnboarding();

    const StepComponent = currentStep.component;

    return (
        <Flex
            h="100vh"
            bg="gray.50"
            overflow="hidden"
        >
            {/* Left Panel */}
            <Box
                w="320px"
                h="full"
                bg="white"
                borderRight="1px solid"
                borderColor="gray.200"
                p={6}
            >
                <VStack h="full" spacing={6} align="stretch">
                    <VStack spacing={1} align="stretch">
                        <Heading
                            as="h1"
                            size="md"
                        >
                            ¡Bienvenido a Cifrato!
                        </Heading>
                        <Text
                            fontSize="sm"
                            color="gray.500"
                        >
                            Comienza en solo 5 minutos.
                        </Text>
                    </VStack>

                    <VStack spacing={2} align="stretch">
                        {ONBOARDING_STEPS.map((step, index) => (
                            <StepIndicator
                                key={step.id}
                                step={step}
                                isActive={currentStepIndex === index}
                                isCompleted={completedSteps.includes(step.id)}
                                onClick={() => handleStepClick(index)}
                                canNavigate={canNavigateToStep(index)}
                            />
                        ))}
                    </VStack>

                    <Button
                        variant="link"
                        onClick={() => navigate("/")}
                        color="gray.600"
                        mt="auto"
                        alignSelf="flex-start"
                        fontSize="sm"
                    >
                        ← Volver al inicio
                    </Button>
                </VStack>
            </Box>

            {/* Main Content */}
            <Flex
                flex={1}
                direction="column"
                p={8}
                overflow="hidden"
            >
                <Box
                    flex={1}
                    overflow="hidden"
                >
                    <Box
                        h="full"
                        bg="white"
                        borderRadius="lg"
                        border="1px solid"
                        borderColor="gray.200"
                        overflow="hidden"
                    >
                        <StepComponent
                            onDataChange={handleDataChange}
                            stepData={stepsData[currentStep.id]}
                            isValid={currentStepValid}
                        />
                    </Box>
                </Box>

                <VStack spacing={4} mt={6}>
                    <Button
                        colorScheme="blue"
                        size="lg"
                        width="full"
                        maxW="400px"
                        onClick={handleContinue}
                        isDisabled={!currentStepValid && currentStepIndex !== ONBOARDING_STEPS.length - 1}
                        isLoading={isSubmitting}
                        loadingText="Enviando..."
                        rightIcon={<MdArrowForward />}
                        _hover={{
                            transform: 'translateY(-2px)',
                            boxShadow: 'lg',
                        }}
                        transition="all 0.2s"
                    >
                        {currentStepIndex === ONBOARDING_STEPS.length - 1 ? "Terminar mi registro" : "Continuar"}
                    </Button>

                    <Progress
                        value={progress}
                        size="sm"
                        colorScheme="green"
                        borderRadius="full"
                        w="full"
                        maxW="400px"
                    />
                </VStack>
            </Flex>
        </Flex>
    );
}
