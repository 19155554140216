import React from 'react';
import { Box, Flex, Icon, Stack, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaRobot, FaChartLine, FaRegLightbulb, FaFileInvoiceDollar, FaClipboardCheck } from 'react-icons/fa';
import { imageVariants } from './animations';

// Create motion components from Chakra components
const MotionBox = motion(Box);

interface HeroIllustrationProps {
  animate?: any;
}

const HeroIllustration: React.FC<HeroIllustrationProps> = ({ animate }) => {
  return (
    <MotionBox 
      position="relative" 
      w="full" 
      h="full"
      animate={animate}
      variants={imageVariants}
    >
      {/* Main dashboard background */}
      <MotionBox
        position="absolute"
        top="10%"
        left="0%"
        width="100%"
        height="85%"
        bg={useColorModeValue('white', 'gray.800')}
        rounded="lg"
        shadow="xl"
        overflow="hidden"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        {/* Dashboard header */}
        <Box 
          position="absolute" 
          top="0" 
          left="0" 
          right="0" 
          h="8%" 
          bg={useColorModeValue('blue.500', 'blue.600')}
          px={4}
        >
          <Flex h="100%" align="center">
            <MotionBox
              width="30%"
              height="50%"
              bg={useColorModeValue('whiteAlpha.300', 'whiteAlpha.200')}
              rounded="md"
              initial={{ width: "10%" }}
              animate={{ width: "30%" }}
              transition={{ delay: 1.2, duration: 0.8 }}
            />
          </Flex>
        </Box>

        {/* Left sidebar */}
        <Box 
          position="absolute" 
          top="8%" 
          left="0" 
          width="15%" 
          bottom="0"
          bg={useColorModeValue('gray.50', 'gray.700')}
          borderRight="1px solid"
          borderColor={useColorModeValue('gray.200', 'gray.600')}
        >
          {[...Array(5)].map((_, i) => (
            <MotionBox
              key={i}
              mt={i === 0 ? 6 : 4}
              mx={3}
              height="24px"
              bg={useColorModeValue('blue.100', 'blue.800')}
              opacity={i === 0 ? 0.9 : 0.6}
              rounded="md"
              initial={{ width: "40%" }}
              animate={{ width: "80%" }}
              transition={{ delay: 1 + (i * 0.1), duration: 0.5 }}
            />
          ))}
        </Box>

        {/* Main content area with data visualization */}
        <MotionBox
          position="absolute"
          top="8%"
          left="15%"
          right="0"
          bottom="0"
          p={6}
        >
          {/* Analytics header */}
          <Flex justify="space-between" mb={6}>
            <MotionBox
              width="40%"
              height="32px"
              bg={useColorModeValue('gray.200', 'gray.700')}
              rounded="md"
              initial={{ width: "20%" }}
              animate={{ width: "40%" }}
              transition={{ delay: 1.4, duration: 0.6 }}
            />
            <MotionBox
              width="25%"
              height="32px"
              bg={useColorModeValue('blue.100', 'blue.700')}
              rounded="md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.8, duration: 0.6 }}
            />
          </Flex>

          {/* Charts and graphs area */}
          <Flex height="45%" mb={6} gap={4}>
            {/* Main chart */}
            <MotionBox
              flex="1.5"
              bg={useColorModeValue('gray.50', 'gray.700')}
              rounded="lg"
              shadow="sm"
              overflow="hidden"
              position="relative"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 1.5, duration: 0.8 }}
            >
              {/* Chart header */}
              <Box p={4} borderBottom="1px solid" borderColor={useColorModeValue('gray.100', 'gray.600')}>
                <Box width="50%" height="20px" bg={useColorModeValue('gray.200', 'gray.600')} rounded="md" />
              </Box>
              
              {/* Chart visualization */}
              <Box p={4} pt={6} height="calc(100% - 52px)">
                <Flex height="80%" justify="space-between" align="flex-end">
                  {[...Array(7)].map((_, i) => {
                    const random = 0.3 + Math.random() * 0.7; // Random height between 30% and 100%
                    return (
                      <MotionBox
                        key={i}
                        width="10%"
                        height="20%"
                        bg={useColorModeValue('blue.400', 'blue.500')}
                        rounded="sm"
                        initial={{ height: "0%" }}
                        animate={{ height: `${random * 100}%` }}
                        transition={{ delay: 2.2 + (i * 0.1), duration: 0.7 }}
                      />
                    );
                  })}
                </Flex>
                <Flex height="20%" justify="space-between" align="center" mt={2}>
                  {[...Array(7)].map((_, i) => (
                    <Box 
                      key={i}
                      width="10%" 
                      height="6px" 
                      bg={useColorModeValue('gray.200', 'gray.600')} 
                      rounded="sm"
                    />
                  ))}
                </Flex>
              </Box>
            </MotionBox>

            {/* Stats */}
            <MotionBox
              flex="1"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.7, duration: 0.8 }}
            >
              <Stack spacing={4}>
                {[...Array(3)].map((_, i) => (
                  <Box 
                    key={i}
                    p={4}
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded="lg"
                    shadow="sm"
                    borderWidth="1px"
                    borderColor={useColorModeValue('gray.100', 'gray.600')}
                  >
                    <Flex justify="space-between" align="center">
                      <Box>
                        <Box width="80px" height="10px" mb={3} bg={useColorModeValue('gray.200', 'gray.600')} rounded="md" />
                        <MotionBox 
                          width={`${120 - (i * 20)}px`} 
                          height="20px" 
                          bg={i === 0 ? 'green.400' : i === 1 ? 'blue.400' : 'purple.400'} 
                          rounded="md"
                          initial={{ width: "40px" }}
                          animate={{ width: `${120 - (i * 20)}px` }}
                          transition={{ delay: 2 + (i * 0.2), duration: 0.7 }}
                        />
                      </Box>
                      <Icon 
                        as={i === 0 ? FaFileInvoiceDollar : i === 1 ? FaChartLine : FaClipboardCheck} 
                        boxSize={8} 
                        color={i === 0 ? 'green.400' : i === 1 ? 'blue.400' : 'purple.400'} 
                      />
                    </Flex>
                  </Box>
                ))}
              </Stack>
            </MotionBox>
          </Flex>

          {/* Table data */}
          <MotionBox
            height="40%"
            bg={useColorModeValue('white', 'gray.700')}
            rounded="lg"
            shadow="sm"
            overflow="hidden"
            borderWidth="1px"
            borderColor={useColorModeValue('gray.100', 'gray.600')}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.9, duration: 0.8 }}
          >
            {/* Table header */}
            <Flex p={4} bg={useColorModeValue('gray.50', 'gray.600')} borderBottom="1px solid" borderColor={useColorModeValue('gray.100', 'gray.500')}>
              {[...Array(4)].map((_, i) => (
                <Box 
                  key={i} 
                  flex={i === 0 ? 0.5 : 1} 
                  height="16px" 
                  bg={useColorModeValue('gray.300', 'gray.500')} 
                  rounded="md" 
                  mr={i < 3 ? 4 : 0}
                />
              ))}
            </Flex>
            
            {/* Table rows */}
            {[...Array(4)].map((_, i) => (
              <MotionBox
                key={i}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2.2 + (i * 0.15), duration: 0.6 }}
              >
                <Flex 
                  p={4} 
                  borderBottom="1px solid" 
                  borderColor={useColorModeValue('gray.100', 'gray.600')}
                  bg={i === 1 ? useColorModeValue('blue.50', 'blue.900') : 'transparent'}
                >
                  {[...Array(4)].map((_, j) => (
                    <Box 
                      key={j} 
                      flex={j === 0 ? 0.5 : 1} 
                      height="16px" 
                      bg={
                        j === 3 ? 
                          (i === 0 ? 'green.100' : i === 1 ? 'yellow.100' : i === 2 ? 'red.100' : 'blue.100') : 
                          useColorModeValue('gray.200', 'gray.600')
                      }
                      rounded="md" 
                      mr={j < 3 ? 4 : 0}
                    />
                  ))}
                </Flex>
              </MotionBox>
            ))}
          </MotionBox>
        </MotionBox>
      </MotionBox>

      {/* Floating AI elements */}
      <MotionBox
        position="absolute"
        top="-8%"
        right="-5%"
        width="100px"
        height="100px"
        rounded="full"
        bg={useColorModeValue('purple.500', 'purple.400')}
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="4px solid"
        borderColor={useColorModeValue('white', 'gray.800')}
        shadow="lg"
        initial={{ opacity: 0, scale: 0, y: 20 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        transition={{ delay: 0.8, duration: 0.6 }}
      >
        <Icon as={FaRobot} boxSize={10} color="white" />
      </MotionBox>

      <MotionBox
        position="absolute"
        top="35%"
        left="-7%"
        width="70px"
        height="70px"
        rounded="full"
        bg={useColorModeValue('blue.500', 'blue.400')}
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="3px solid"
        borderColor={useColorModeValue('white', 'gray.800')}
        shadow="lg"
        initial={{ opacity: 0, scale: 0, y: 20 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.6 }}
      >
        <Icon as={FaChartLine} boxSize={7} color="white" />
      </MotionBox>

      <MotionBox
        position="absolute"
        bottom="-5%"
        right="20%"
        width="60px"
        height="60px"
        rounded="full"
        bg={useColorModeValue('green.500', 'green.400')}
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="3px solid"
        borderColor={useColorModeValue('white', 'gray.800')}
        shadow="lg"
        initial={{ opacity: 0, scale: 0, y: 20 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        transition={{ delay: 1.2, duration: 0.6 }}
      >
        <Icon as={FaRegLightbulb} boxSize={6} color="white" />
      </MotionBox>

      {/* Connection lines between AI elements and dashboard */}
      <svg
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
      >
        <MotionBox
          as="g"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.4 }}
          transition={{ delay: 1.4, duration: 0.8 }}
        >
          <path
            d="M50,50 L150,120"
            stroke={useColorModeValue('blue.400', 'blue.300')}
            strokeWidth="2"
            strokeDasharray="5,5"
            fill="none"
          />
          <path
            d="M350,40 L250,100"
            stroke={useColorModeValue('purple.400', 'purple.300')}
            strokeWidth="2"
            strokeDasharray="5,5"
            fill="none"
          />
          <path
            d="M320,320 L250,250"
            stroke={useColorModeValue('green.400', 'green.300')}
            strokeWidth="2"
            strokeDasharray="5,5"
            fill="none"
          />
        </MotionBox>
      </svg>

      {/* Animated particles */}
      {[...Array(15)].map((_, i) => (
        <MotionBox
          key={i}
          position="absolute"
          width={`${3 + Math.random() * 4}px`}
          height={`${3 + Math.random() * 4}px`}
          bg={
            i % 3 === 0 
              ? useColorModeValue('blue.400', 'blue.300')
              : i % 3 === 1 
                ? useColorModeValue('purple.400', 'purple.300')
                : useColorModeValue('green.400', 'green.300')
          }
          rounded="full"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          initial={{ opacity: 0 }}
          animate={{
            opacity: [0, 0.8, 0],
            scale: [0, 1, 0],
            y: [0, -20 - Math.random() * 30],
            x: [0, (Math.random() - 0.5) * 20],
          }}
          transition={{
            duration: 2 + Math.random() * 2,
            ease: "easeOut",
            repeat: Infinity,
            delay: Math.random() * 3,
            repeatDelay: Math.random() * 2,
          }}
        />
      ))}
    </MotionBox>
  );
};

export default HeroIllustration; 