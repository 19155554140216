import { useNavigate } from "react-router-dom";
import {
    Flex,
    Heading,
    Button,
    Icon,
    Text,
    HStack,
    Divider,
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Avatar,
    Link
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FiLogOut, FiChevronDown } from "react-icons/fi";
import { signOut, onAuthStateChanged } from "firebase/auth";

import { auth } from "@/config/firebaseConfig";
import { fetchBizName } from "@/services/layout.services";
import Logo from "@/components/landing/Logo";

export default function Navbar({ bizName }: { bizName: string }) {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error("Error logging out: ", error);
        }
    };

    return (
        <Box px="40px" py="20px" bg="white">
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                py={3}
                px={1}
            >
                <Flex align="center">
                    <Link as={RouterLink} to="/dashboard" _hover={{ textDecoration: 'none' }}>
                        <Flex align="center">
                            <Logo height="30px" width="30px" />
                            <Heading
                                ml={2}
                                size="md"
                                color="gray.700"
                                fontWeight="500"
                            >
                                {bizName || "Dashboard"}
                            </Heading>
                        </Flex>
                    </Link>
                </Flex>

                <HStack spacing={4}>
                    <Menu>
                        <MenuButton
                            as={Button}
                            variant="ghost"
                            size="sm"
                            rightIcon={<Icon as={FiChevronDown} />}
                            _hover={{ bg: 'gray.100' }}
                            px={2}
                        >
                            <HStack spacing={2}>
                                <Avatar
                                    size="xs"
                                    name={bizName}
                                    bg="blue.500"
                                />
                                <Text
                                    fontSize="sm"
                                    color="gray.700"
                                    display={{ base: 'none', md: 'block' }}
                                >
                                    {bizName}
                                </Text>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            shadow="md"
                            border="1px solid"
                            borderColor="gray.200"
                            py={1}
                        >
                            <MenuItem
                                icon={<Icon as={FiLogOut} />}
                                onClick={handleLogout}
                                fontSize="sm"
                                color="gray.700"
                                _hover={{
                                    bg: 'gray.50',
                                }}
                            >
                                Cerrar Sesión
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            </Flex>
        </Box>
    );
}