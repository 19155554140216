import {
    Box,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    Icon,
    useToast,
    Tooltip,
    Button
} from "@chakra-ui/react"

import { FaCopy, FaCheck, FaTimes, FaDownload } from "react-icons/fa";
import { useDianContext } from "@/context/dianContext";
import { DianHeader } from "./DianHeader";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { BizServices } from "@/types/invoice.types";
import { DianComparisonResult } from "@/types/dian.types";
import * as XLSX from 'xlsx';



export function ResultsView() {
    const { results } = useDianContext();
    const navigate = useNavigate();
    const toast = useToast();

    const bizServices: BizServices | undefined = results?.services;
    const data = results?.data || [];

    const handleDownloadExcel = () => {
        try {
            // Create a new workbook
            const wb = XLSX.utils.book_new();
            console.log(data)
            
            // Prepare the data for Excel
            const excelData = data.map((result: DianComparisonResult) => ({
                'CUFE/CUDE': result.CUFE_CUDE,
                'ID': result.id,
                'Proveedor': result.Nombre_Emisor,
                'Fecha': result.Fecha_Emisión,
                'Total': result.Total,
                'En Cifrato': result.is_in_db ? 'Sí' : 'No',
                ...(bizServices?.erp === "siigo" && {
                    'En Siigo': result.is_in_siigo ? result.siigo_consecutive : 'No'
                }),
                ...(bizServices?.erp === "helisa" && {
                    'En Helisa': result.helisa_consecutive || 'No'
                }),
                ...(bizServices?.pos === "intuipos" && {
                    'En Intuipos': result.intuipos_consecutive || 'No'
                })
            }));

            // Create a worksheet
            const ws = XLSX.utils.json_to_sheet(excelData);
            
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, "Comparación DIAN");
            
            // Generate Excel file
            XLSX.writeFile(wb, "comparacion_dian.xlsx");

            toast({
                title: "Archivo descargado",
                description: "Los resultados se han descargado como Excel",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "No se pudo descargar el archivo Excel",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <DianHeader />
            <Box bg="white"
                p={2}
                borderRadius="lg"
                boxShadow="sm"
                fontSize="sm"
                border="1px"
                borderColor="gray.200" color={"black"}  overflowY={"auto"}>
                <Box mb={4} display="flex" justifyContent="flex-end">
                    <Button
                        leftIcon={<FaDownload />}
                        colorScheme="blue"
                        size="sm"
                        onClick={handleDownloadExcel}
                    >
                        Descargar Excel
                    </Button>
                </Box>
                <Box>
                    <Table variant="simple" width="100%">
                        <Thead>
                            <Tr>
                                <Th textAlign="center" p={1}>CUFE/CUDE</Th>
                                <Th textAlign="center" p={1}>ID</Th>
                                <Th textAlign="center" p={1}>PROVEEDOR</Th>
                                <Th textAlign="center" p={1}>FECHA</Th>
                                <Th textAlign="center" p={1}>TOTAL</Th>
                                <Th textAlign="center" p={1}>Cifrato</Th>
                                {bizServices?.erp === "siigo" && <Th textAlign="center" p={1}>Siigo</Th>}
                                {bizServices?.erp === "helisa" && <Th textAlign="center" p={1}>Helisa</Th>}
                                {bizServices?.pos === "intuipos" && <Th textAlign="center" p={1}>Intuipos</Th>}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((result: any, index: number) => (
                                <ResultRow key={index} result={result} bizServices={bizServices} navigate={navigate} />
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
        </>
    );
}

function ResultRow({ result, bizServices, navigate }: { result: DianComparisonResult, bizServices: BizServices | undefined, navigate: NavigateFunction }) {
    const toast = useToast();

    function onCopyCUFE(cufe: string) {
        navigator.clipboard.writeText(cufe);
        toast({
            title: "CUFE/CUDE copiado",
            description: "CUFE/CUDE copiado al portapapeles",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    }
    return (
        <Tr cursor={result["is_in_db"] ? "pointer" : "default"}
            _hover={result["is_in_db"] ? { bg: 'gray.50' } : {}}
            onClick={() => result["is_in_db"] && window.open(`/invoice/${result["CUFE_CUDE"]}`, '_blank')}
            transition="background-color 0.2s"
        >
            <Td p={1}>
                <Text display="flex" alignItems="center" justifyContent="center">
                    <Icon
                        as={FaCopy}
                        mr={1}
                        boxSize={5} // Aumentado el tamaño del icono
                        onClick={(e) => { e.stopPropagation(); onCopyCUFE(result["CUFE_CUDE"]); }}
                        cursor="pointer"
                        aria-label="Copiar CUFE/CUDE"
                    />
                    {result["CUFE_CUDE"].slice(0, 10)}...
                </Text>
            </Td>
            <Td p={1}>
                <Text display="flex" alignItems="center" justifyContent="center">{result["id"]}</Text>
            </Td>
            <Td p={1}>
                <Text display="flex" alignItems="center" textAlign="center" justifyContent="center">{result["Nombre_Emisor"]}</Text>
            </Td>
            <Td p={1}>
                <Text display="flex" alignItems="center" textAlign="center" justifyContent="center">{result["Fecha_Emisión"]}</Text>
            </Td>
            <Td p={1}>
                <Text display="flex" alignItems="center" justifyContent="center">
                    {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(result["Total"])}
                </Text>
            </Td>
            <Td p={1}>
                <StatusBadge isActive={result["is_in_db"]} />
            </Td>
            {bizServices?.erp === "siigo" && <SiigoBadge result={result} />}
            {bizServices?.erp === "helisa" && <HelisaBadge result={result} />}
            {bizServices?.pos === "intuipos" && <IntuiposBadge result={result} />}
        </Tr>
    );
}
function StatusBadge({ isActive }: { isActive: boolean }) {
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Tooltip label={isActive ? "Ver en Cifrato" : "No en Cifrato"}>
                <span>
                    {isActive ? <Icon as={FaCheck} color="green.500" boxSize={5} /> : <Icon as={FaTimes} color="red.500" boxSize={5} />}
                </span>
            </Tooltip>
        </Box>
    );
}

function SiigoBadge({ result }: { result: DianComparisonResult }) {
    return (
        <Td p={1}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Tooltip label={result["is_in_siigo"] ? `${result["siigo_consecutive"]}` : "No en Siigo"}>
                    <span>
                        {result["is_in_siigo"] ? (
                            <Icon as={FaCheck} color="green.500" boxSize={5} />
                        ) : (
                            <Icon as={FaTimes} color="red.500" boxSize={5} />
                        )}
                    </span>
                </Tooltip>
            </Box>
        </Td>
    );
}

function HelisaBadge({ result }: { result: DianComparisonResult }) {
    return (
        <Td p={1}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Tooltip label={result["helisa_consecutive"] ? "Click para descargar excel" : "No en Helisa"}>
                    <span>
                        {result["helisa_consecutive"] ? (
                            <Box 
                                as="a" 
                                href={result["helisa_consecutive"]} 
                                target="_blank"
                                rel="noopener noreferrer" 
                                cursor="pointer"
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                            >
                                <Icon as={FaCheck} color="green.500" boxSize={5} />
                            </Box>
                        ) : (
                            <Icon as={FaTimes} color="red.500" boxSize={5} />
                        )}
                    </span>
                </Tooltip>
            </Box>
        </Td>
    );
}

function IntuiposBadge({ result }: { result: DianComparisonResult }) {
    return (
        <Td p={1}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Tooltip label={result["intuipos_consecutive"] ? result["intuipos_consecutive"] : "No en Intuipos"}>
                    <span>
                        {result["intuipos_consecutive"] ? (
                            <>
                                <Icon as={FaCheck} color="green.500" boxSize={5} />
                            </>
                        ) : (
                            <Icon as={FaTimes} color="red.500" boxSize={5} />
                        )}
                    </span>
                </Tooltip>
            </Box>
        </Td>
    );
}
