import { uploadInvoicesIntuipos, uploadInvoicesSiigo, uploadInvoicesHelisa } from "@/services/invoices.services";
import { UploadTargetType } from "@/types/erpShared.types";

export const uploadInvoicesToTarget = async (invoicesToUpload: string[], uploadTarget: UploadTargetType) => {
    // Execute the appropriate upload function based on the target
    let data;
    switch (uploadTarget) {
        case 'intuipos':
            data = await uploadInvoicesIntuipos(invoicesToUpload);
            break;
        case 'siigo':
            data = await uploadInvoicesSiigo(invoicesToUpload);
            break;
        case 'helisa':
            data = await uploadInvoicesHelisa(invoicesToUpload);
            break;
    }
    return data;
}