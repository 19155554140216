import { Invoice } from '@/types/invoice.types';
import { getDianAcknowledgment } from '@/services/invoices.services';
import { UseToastOptions } from '@chakra-ui/react';
import { useInvoicesHandler } from '@/context/shared/invoicesHandlerContext';

/**
 * Handle the DIAN acknowledgment for selected invoices
 */

export const confirmDianAcknowledgment = async (
  toast: (options: UseToastOptions) => void,
  closeModal?: () => void,
): Promise<void> => {

  const { invoices, setInvoices } = useInvoicesHandler();
  try {
    const data = await getDianAcknowledgment(invoices);

    let title = invoices.length === 1
      ? "Acuse de recibo DIAN"
      : "Acuses de recibo DIAN";

    let description;
    if (data.status === 'success') {
      description = invoices.length === 1
        ? "El acuse de recibo ha sido efectuado correctamente"
        : `Se han procesado ${invoices.length} acuses de recibo correctamente`;
    } else {
      description = invoices.length === 1
        ? "Ha ocurrido un error al procesar el acuse de recibo"
        : "Ha ocurrido un error al procesar los acuses de recibo";
    }

    toast({
      position: "top-right",
      title,
      description,
      status: data.status,
      duration: 5000,
      isClosable: true,
    });


    setInvoices(data.invoices);

  } catch (error) {
    toast({
      position: "top-right",
      title: "Error al procesar",
      description: "Ha ocurrido un error al procesar la solicitud",
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  }

  if (closeModal) closeModal();
};

