import React, { useState } from "react";
import { Box, Button, Flex, Input, Text, useToast, VStack } from "@chakra-ui/react";
import { fetchSiigoUploadSales } from "@/services/siigo.services";

const htmlId = `siigo_sales`;

export default function UploadSales() {
    const [file1Name, setFile1Name] = useState("");
    const [file1Uploaded, setFile1Uploaded] = useState(false);
    const toast = useToast();

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (!file) return
            const fileType = file.type;

            if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType === "application/vnd.ms-excel") {
                setFile1Name(file.name);
                setFile1Uploaded(true)
            } else {
                setFile1Name("Tipo de archivo no permitido")
                setFile1Uploaded(false);
            }
        }
    };

    const handleOnClick = () => {
        fetchSiigoUploadSales().then(() => {
            toast({
                position: 'top-right',
                title: 'Archivo subido',
                description: "Enviaremos un correo cuando el proceso haya terminado.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        })
    }

    return (
        <Box height={"100%"}>
            <VStack height={"70%"} justifyContent={"center"}>
                <Text fontSize={"x-large"} fontWeight={"bold"}>
                    Subir facturas de ventas a Siigo
                </Text>
                <Text>
                    Subir la interface de ventas de Intüipos para que cada factura sea
                    subida a Siigo automáticamente.
                </Text>
                <Text mt={10}>Seleccionaste archivo {file1Name}</Text>
                <Flex gap={4}>
                    <Button as="label" htmlFor={htmlId} cursor="pointer">
                        Seleccionar archivo
                    </Button>
                    <Input
                        id={htmlId}
                        type="file"
                        onChange={(event => handleFileUpload(event))}
                        display="none"
                    />
                    <Button colorScheme={file1Uploaded ? "green" : "blue"} isDisabled={!file1Uploaded}
                        onClick={handleOnClick}>
                        Subir archivo
                    </Button>
                </Flex>
            </VStack>
        </Box>
    )
}