import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { Th } from "@chakra-ui/react";
import { useErpForm } from "@/context/shared/erpFormContext";
import { useEffect } from "react";
import { useInvoicesHandler } from "@/context/shared/invoicesHandlerContext";

export const ERPTableHeader: React.FC<{ isHelisa: boolean }> = ({ isHelisa }) => {
    const { invoice } = useInvoiceDetails();
    const { useCostCenterPerItem, services } = useInvoicesHandler();
    const erpService = services?.erp;
    
    return (
        <>
            <Th
                px={4}
                py={3}
                fontSize="xs"
                color="gray.600"
                borderBottomWidth="2px"
                borderBottomColor="gray.200"
            >
                Descripción
            </Th>
            {!isHelisa && (
                <>
                    <Th
                        px={4}
                        py={3}
                        fontSize="xs"
                        color="gray.600"
                        borderBottomWidth="2px"
                        borderBottomColor="gray.200"
                    >
                        Tipo
                    </Th>
                    {invoice.items.some(item => item.erp_type === 'product') && (
                        <Th
                            px={4}
                            py={3}
                            fontSize="xs"
                            color="gray.600"
                            borderBottomWidth="2px"
                        borderBottomColor="gray.200"
                    >
                            Producto
                        </Th>
                    )}
                </>
            )}
            <Th
                px={4}
                py={3}
                fontSize="xs"
                color="gray.600"
                borderBottomWidth="2px"
                borderBottomColor="gray.200"
            >
                Cuenta
            </Th>
            {useCostCenterPerItem && (erpService === 'helisa' || invoice.invoice_type_name === 'CC') && (
                <Th
                    px={4}
                    py={3}
                    fontSize="xs"
                    color="gray.600"
                    borderBottomWidth="2px"
                    borderBottomColor="gray.200"
                >
                    Centro de Costo
                </Th>
            )}
            <Th
                px={4}
                py={3}
                fontSize="xs"
                color="gray.600"
                borderBottomWidth="2px"
                borderBottomColor="gray.200"
                isNumeric
            >
                Cantidad
            </Th>
            <Th
                px={4}
                py={3}
                fontSize="xs"
                color="gray.600"
                borderBottomWidth="2px"
                borderBottomColor="gray.200"
                isNumeric
            >
                Valor Total (COP)
            </Th>
            <Th
                px={4}
                py={3}
                fontSize="xs"
                color="gray.600"
                borderBottomWidth="2px"
                borderBottomColor="gray.200"
            >
                Impuestos
            </Th>
            <Th
                px={4}
                py={3}
                fontSize="xs"
                color="gray.600"
                borderBottomWidth="2px"
                borderBottomColor="gray.200"
            >
                Estado
            </Th>
            {invoice.items.length > 1 && <Th
                px={4}
                py={3}
                fontSize="xs"
                color="gray.600"
                borderBottomWidth="2px"
                borderBottomColor="gray.200"
            >
            </Th>}
            {invoice.items.length <= 1 && <Th
                px={4}
                py={3}
                fontSize="xs"
                color="gray.600"
                borderBottomWidth="2px"
                borderBottomColor="gray.200"
            ></Th>}
        </>
    );
}