import { selectStyles } from '@/components/ui/SelectStyles';
import { useErpForm } from '@/context/shared/erpFormContext';
import { useInvoicesHandler } from '@/context/shared/invoicesHandlerContext';
import { RetentionType } from '@/hooks/erpForm/useRetentions';
import { CostCenterOption, InvoiceTypeOption, PaymentMethodOption, RetentionOption } from '@/types/erpShared.types';
import { AccountOption } from '@/types/invoiceDetail.types';
import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Badge,
  Tooltip,
  Icon,
  Box
} from "@chakra-ui/react";
import { QuestionIcon } from '@chakra-ui/icons';
import { HiSparkles } from "react-icons/hi";
import { CreatableSelect, SingleValue } from "chakra-react-select";
import React from 'react';

export const HelisaForm: React.FC = () => {

  const {
    allInvoicesUploaded,
    retentionsHook,
    invoiceTypesHook,
    paymentMethodsHook,
    costCentersHook,
    accountsHook
  } = useErpForm();

  const { invoices } = useInvoicesHandler();
  const { useCostCenterPerItem, handleUseCostCenterPerItem } = useInvoicesHandler();


  return (
    <Flex direction="row" gap={4} width={"auto"}>
      <Flex direction="column" gap={4} width={"300px"}>
        <FormControl>
          <FormLabel>Tipo de factura:</FormLabel>
          <CreatableSelect
            value={invoiceTypesHook.selectedInvoiceType}
            options={invoiceTypesHook.invoiceTypesOptions.filter(option => {
              if (invoices.every(inv => inv.is_sale)) {
                return option.type === 'FV';
              }
              return option.type !== 'FV';
            })}
            onChange={(selectedOption: SingleValue<InvoiceTypeOption>) => {
              if (selectedOption) {
                invoiceTypesHook.handleInvoiceTypeChange(selectedOption);
              }
            }}
            placeholder="Seleccionar tipo de factura..."
            isLoading={invoiceTypesHook.isLoading}
            isClearable
            isSearchable
            isDisabled={allInvoicesUploaded}
            isInvalid={!invoiceTypesHook.selectedInvoiceType}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />
        </FormControl>

        <FormControl isInvalid={!accountsHook.selectedAccount}>
          <FormLabel>Cuenta Contable del método de pago:</FormLabel>
          <CreatableSelect<AccountOption>
            value={accountsHook.selectedAccount ? {
              label: accountsHook.selectedAccount.label,
              value: accountsHook.selectedAccount.value
            } : null}
            options={accountsHook.accountOptions}
            onChange={(option: SingleValue<AccountOption>) => {
              if (option) {
                accountsHook.handleAccountChange(option);
              } else {
                accountsHook.setSelectedAccount(null as any);
              }
            }}
            onCreateOption={(inputValue: string) => {
              accountsHook.handleAccountChange({
                label: inputValue,
                value: inputValue
              });
            }}
            placeholder="Seleccionar..."
            isLoading={accountsHook.isLoading}
            isClearable
            isSearchable
            formatCreateLabel={(input: string) => `Usar cuenta: ${input}`}
            isDisabled={allInvoicesUploaded}
            isInvalid={!accountsHook.selectedAccount}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />
          {accountsHook.error && (
            <FormErrorMessage>{accountsHook.error}</FormErrorMessage>
          )}
        </FormControl>

        {paymentMethodsHook.paymentMethods && (
          <FormControl>
            <FormLabel>Forma de pago:</FormLabel>
            <CreatableSelect<PaymentMethodOption>
              value={paymentMethodsHook.paymentForm}
              options={paymentMethodsHook.paymentMethods}
              formatOptionLabel={(option) => `${option.id} - ${option.label}`}
              onChange={(option: SingleValue<PaymentMethodOption>) => {
                if (option) {
                  paymentMethodsHook.handlePaymentMethodChange(option);
                } else {
                  paymentMethodsHook.setSelectedPaymentMethod(null as any);
                }
              }}
              placeholder="Seleccionar forma de pago..."
              isLoading={paymentMethodsHook.isLoading}
              isClearable
              isSearchable
              isDisabled={allInvoicesUploaded}
              chakraStyles={selectStyles}
              size="sm"
              errorBorderColor='red.100'
            />
          </FormControl>
        )}

        <FormControl>
          <FormLabel>Centro de costo:</FormLabel>
          <CreatableSelect<AccountOption>
            value={costCentersHook.selectedCostCenter ? {
              label: costCentersHook.selectedCostCenter.label,
              value: String(costCentersHook.selectedCostCenter.value)
            } : null}
            options={costCentersHook.costCenters?.map(option => ({
              label: option.label,
              value: String(option.value)
            }))}
            onChange={(newValue: SingleValue<AccountOption>) => {
              if (newValue) {
                const code = newValue.value.split(' - ')[0];
                const costCenterOption: CostCenterOption = {
                  id: code,
                  label: newValue.label,
                  value: code
                };
                costCentersHook.handleCostCenterChange(costCenterOption);
              } else {
                costCentersHook.setSelectedCostCenter(null as any);
              }
            }}
            onCreateOption={(inputValue: string) => {
              const costCenterOption: CostCenterOption = {
                id: inputValue,
                label: inputValue,
                value: inputValue
              };
              costCentersHook.handleCostCenterChange(costCenterOption);
            }}
            placeholder="Seleccionar centro de costo..."
            isLoading={costCentersHook.isLoading}
            isClearable
            isSearchable
            formatCreateLabel={(input: string) => `Usar centro de costo: ${input}`}
            isDisabled={(allInvoicesUploaded || useCostCenterPerItem)}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />
        </FormControl>
        <FormControl display="flex" alignItems="center" mb={2}>
          <Checkbox
            isChecked={useCostCenterPerItem}
            onChange={(e) => {
              handleUseCostCenterPerItem();
            }}
            size="sm"
          >
            <Text fontSize="sm">Usar centro de costo por ítem</Text>
          </Checkbox>
        </FormControl>
      </Flex>
      <Flex direction="column" gap={4} width={"300px"}>
        <FormControl>
          <FormLabel>
            ReteICA:
            {retentionsHook.retentionsData?.suggested?.reteICA &&
              retentionsHook.selectedReteICA?.id === retentionsHook.retentionsData.suggested.reteICA.id && (
                <Tooltip label="Nuestra IA ha detectado que esta retención se ha usado en facturas previas con el mismo proveedor" hasArrow>
                  <Badge ml={2} colorScheme="purple" fontSize="xs" borderRadius="full" color="purple.700">Autoseleccionado
                    <Box
                      as="span"
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      ml={1}
                    >
                      <HiSparkles style={{
                        display: 'inline',
                        fontSize: '1em',
                        color: '#553C9A',
                        cursor: 'help'
                      }} />
                    </Box>
                  </Badge>
                </Tooltip>
              )}
          </FormLabel>
          <CreatableSelect
            value={retentionsHook.selectedReteICA}
            options={retentionsHook.reteICAOptions}
            onChange={(option) => {
              retentionsHook.handleSingleRetentionChange(option as RetentionOption | null, 'ReteICA' as RetentionType);
            }}
            placeholder="Seleccione ReteICA"
            noOptionsMessage={() => "No hay retenciones disponibles"}
            isLoading={retentionsHook.isLoading}
            isClearable
            isDisabled={allInvoicesUploaded}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            ReteIVA:
            {retentionsHook.retentionsData?.suggested?.reteIVA &&
              retentionsHook.selectedReteIVA?.id === retentionsHook.retentionsData.suggested.reteIVA.id && (
                <Tooltip label="Nuestra IA ha detectado que esta retención se ha usado en facturas previas con el mismo proveedor" hasArrow>
                  <Badge ml={2} colorScheme="purple" fontSize="xs" borderRadius="full" color="purple.700">Autoseleccionado
                    <Box
                      as="span"
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      ml={1}
                    >
                      <HiSparkles style={{
                        display: 'inline',
                        fontSize: '1em',
                        color: '#553C9A',
                        cursor: 'help'
                      }} />
                    </Box>
                  </Badge>
                </Tooltip>
              )}
          </FormLabel>
          <CreatableSelect
            value={retentionsHook.selectedReteIVA}
            options={retentionsHook.reteIVAOptions}
            onChange={(option) => {
              retentionsHook.handleSingleRetentionChange(option as RetentionOption | null, 'ReteIVA' as RetentionType);
            }}
            placeholder="Seleccione ReteIVA"
            noOptionsMessage={() => "No hay retenciones disponibles"}
            isLoading={retentionsHook.isLoading}
            isClearable
            isDisabled={allInvoicesUploaded}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            Retefuente:
            {retentionsHook.retentionsData?.suggested?.retefuente &&
              retentionsHook.selectedRetefuente?.id === retentionsHook.retentionsData.suggested.retefuente.id && (
                <Tooltip label="Nuestra IA ha detectado que esta retención se ha usado en facturas previas con el mismo proveedor" hasArrow>
                  <Badge ml={2} colorScheme="purple" fontSize="xs" borderRadius="full" color="purple.700">Autoseleccionado
                    <Box
                      as="span"
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      ml={1}
                    >
                      <HiSparkles style={{
                        display: 'inline',
                        fontSize: '1em',
                        color: '#553C9A',
                        cursor: 'help'
                      }} />
                    </Box>
                  </Badge>
                </Tooltip>
              )}
          </FormLabel>
          <CreatableSelect
            value={retentionsHook.selectedRetefuente}
            options={retentionsHook.retefuenteOptions}
            onChange={(option) => {
              retentionsHook.handleSingleRetentionChange(option as RetentionOption | null, 'Retefuente' as RetentionType);
            }}
            placeholder="Seleccione Retefuente"
            noOptionsMessage={() => "No hay retenciones disponibles"}
            isLoading={retentionsHook.isLoading}
            isClearable
            isDisabled={allInvoicesUploaded}
            chakraStyles={selectStyles}
            size="sm"
            errorBorderColor='red.100'
          />
        </FormControl>
      </Flex>
    </Flex>
  );
}; 