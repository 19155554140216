import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { Box, Divider, Grid, GridItem } from "@chakra-ui/react";

import Navbar from "@/components/layout/NavBar";
import Sidebar from "@/components/layout/SideBar";
import { auth } from "@/config/firebaseConfig";
import { BussinesAllowAside } from "@/enums/admins";
import { fetchBizName } from "@/services/layout.services";

export default function RootLayout() {
  const [bizName, setBizName] = useState("Dashboard");
  const [showExtra, setShowExtra] = useState<boolean>(false);
  const mounted = useRef(false);

  const getBizName = async () => {
    try {
      const bizNameReq = await fetchBizName();

      if (typeof bizNameReq === "string") {
        setBizName(bizNameReq);
        setShowExtra(BussinesAllowAside.includes(bizNameReq));
      }
    } catch (error) {
      console.error("Error fetching heading name: ", error);
    }
  };

  useEffect(() => {
    mounted.current = true;

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (mounted.current && user) getBizName();
    });

    return () => {
      mounted.current = false;
      unsubscribe();
    };
  }, []);

  
  const layout = "repeat(11, 1fr)";

  return (
    <Grid
      templateColumns={layout}
      bg="gray.50"
      minHeight={{ lg: "100vh" }}
      overflow={"hidden"}
    >
      {
        <GridItem
          as="aside"
          colSpan={{ base: 11, lg: 1, xl: 1 }}
          bg={"blue.400"}
          minHeight={{ lg: "100%" }}
          height={{ lg: "100%" }}
          py={{ base: "35px" }}
          px={{ base: "10px" }}
        >
          <Sidebar showExtra={showExtra} />
        </GridItem>
      }

      {/* main content & navbar */}
      <GridItem
        as="main"
        colSpan={{ base: 11, lg: 10, xl: 10 }}
        minHeight={{ lg: "100%" }}
        height={{ lg: "100%" }}
      >
        <Navbar  bizName={bizName} />
        <Box px="40px">
          <Outlet />
        </Box>
      </GridItem>
    </Grid>
  );
}
