import React from 'react';
import {
    Box,
    Text,
    Grid,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from "@chakra-ui/react";
import { PurchaseOrder } from '@/types/purchaseOrder.types';

interface PurchaseOrderDetailsProps {
    purchaseOrder: PurchaseOrder;
}

export const PurchaseOrderDetails: React.FC<PurchaseOrderDetailsProps> = ({ purchaseOrder }) => {
    return (
        <>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <Box>
                    <Text fontWeight="bold">NIT Proveedor</Text>
                    <Text>{purchaseOrder.supplier_nit}</Text>
                </Box>
                <Box>
                    <Text fontWeight="bold">Fecha de creación de la orden</Text>
                    <Text>{new Date(purchaseOrder.transaction_date).toLocaleString()}</Text>
                </Box>
                <Box>
                    <Text fontWeight="bold">Punto de Venta</Text>
                    <Text>{purchaseOrder.items?.[0]?.warehouse_name || 'No disponible'}</Text>
                </Box>
                <Box>
                    <Text fontWeight="bold">Observaciones</Text>
                    <Text>{purchaseOrder.observations || 'No hay observaciones'}</Text>
                </Box>
            </Grid>

            <Box>
                <Text fontWeight="bold" mb={2}>Items</Text>
                <Table variant="simple" size="sm">
                    <Thead>
                        <Tr>
                            <Th>Descripción</Th>
                            <Th>Cantidad</Th>
                            <Th>Unidad</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {purchaseOrder.items.map((item, itemIndex) => (
                            <Tr key={itemIndex}>
                                <Td>{item.item_description}</Td>
                                <Td>{item.quantity}</Td>
                                <Td>{item.item_measurement_unit}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </>
    );
}; 