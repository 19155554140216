import { ArrowBackIcon } from "@chakra-ui/icons";
import { Button, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { Flex } from "@chakra-ui/react";
import { useDianContext } from "@/context/dianContext";

export function DianHeader() {
    const { setShowResults, setIsLoading, setDianFile, setResults } = useDianContext()
    return (
        <Flex
            mb={5}
            align="center"
            bg="white"
            p={4}
            borderRadius="lg"
            boxShadow="sm"
            border="1px"
            borderColor="gray.200"
        >
            <Button
                leftIcon={<ArrowBackIcon />}
                variant="ghost"
                onClick={() => {
                    setDianFile(null);
                    setResults([]);
                    setShowResults(false);
                    setIsLoading(false);

                }}
            >
                Volver
            </Button>

        </Flex>
    )
}
