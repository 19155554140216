/**
 * Utility functions for invoice validations
 */

/**
 * Validates invoice items and returns errors for each item
 */
export const validateInvoiceItems = (
  items: any[],
  uploadTarget: string
): { description: string; index: number; errors: string[] }[] => {
  return items.reduce((uniqueItems: { description: string; index: number; errors: string[] }[], item: any, itemIdx: number) => {
    // Agrupar items por descripción para evitar duplicados
    if (!uniqueItems.some((ui) => ui.description === item.description)) {
      const errors: string[] = [];

      // Validaciones específicas para Siigo
      if (uploadTarget === 'siigo') {
        if (!item.erp_type) errors.push("falta tipo ERP");
        if (item.erp_type === 'product' && !item.erp_product) errors.push("falta producto ERP");
        if (item.invoice_type === 'FC' && item.erp_type !== 'product') errors.push("debe ser tipo producto para facturas FC");
        if (!item.erp_account) errors.push("falta cuenta ERP");
        if (Object.values(item.taxes || {}).some((tax: any) => !tax.tax_erp_id)) errors.push("falta cuenta de impuesto");
      }
      // Validaciones específicas para Helisa
      else if (uploadTarget === 'helisa') {
        if (!item.erp_account) errors.push("falta cuenta contable");
        if (Object.values(item.taxes || {}).some((tax: any) => !tax.tax_account)) errors.push("falta cuenta de impuesto");
      }
      // Validaciones para otros sistemas
      else {
        if (!item.inventory_item_name) errors.push("falta nombre de inventario");
      }

      // Solo agregar a la lista si tiene errores
      if (errors.length > 0) {
        uniqueItems.push({
          description: item.description,
          index: item.index !== undefined ? item.index : itemIdx,
          errors
        });
      }
    }
    return uniqueItems;
  }, [] as Array<{ description: string; index: number; errors: string[] }>);
}; 