import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { fetchBizErp, getBusinessErpTaxes } from '@/services/invoices.services';
import { ErpTaxOption } from '@/types/erpProducts.types';

export const useErpTaxes = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [erpTaxesOptions, setErpTaxesOptions] = useState<ErpTaxOption[] | undefined>([]);

  useEffect(() => {
    const loadErpTaxes = async () => {
      try {
        // First fetch bizErp to ensure it's available
        const bizErp = await fetchBizErp();

        // Fetch and map taxes
        const taxesData = await getBusinessErpTaxes();

        const taxOptions = taxesData?.taxes
          .filter((tax: any) => tax.active)
          .map((tax: any) => ({
            label: tax.name,
            value: tax.id,
            percentage: tax.percentage
          }));

        if (bizErp?.taxes && taxOptions) {
          for (const typeTaxes in bizErp.taxes) {
            const taxInfo = bizErp.taxes[typeTaxes];
            const option: ErpTaxOption | undefined = taxOptions.find((option: any) => {
              return String(option.value) === String(taxInfo.tax_erp_id);
            });

            if (option) {
              option["account"] = taxInfo.tax_account;
              option["use_as_cost_value"] = taxInfo.use_as_cost_value;
              option["set"] = true;
            }
          }
        }
        
        setErpTaxesOptions(taxOptions);
      } catch (error) {
        console.error("Error loading ERP taxes:", error);
        toast({
          title: "Error al cargar impuestos ERP",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    loadErpTaxes();
  }, []);

  return {
    loading,
    erpTaxesOptions
  };
}; 