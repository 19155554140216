import React from 'react';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Text,
    Badge,
    Box,
    Collapse,
    Button,
    VStack,
    HStack,
    Divider,
    List,
    ListItem,
} from "@chakra-ui/react";
import { useErpForm } from '@/context/shared/erpFormContext';
import { useInvoicesHandler } from '@/context/shared/invoicesHandlerContext';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

export const AlertsSection: React.FC = () => {
    const { allInvoicesUploaded, someInvoicesUploaded } = useErpForm();
    const { uploadTarget, hasErrors, invoiceItemErrors, hasMissingFields, areSuccessfullyUploaded, invoices, missingFormFields } = useInvoicesHandler();
    const [openInvoices, setOpenInvoices] = React.useState<Record<string, boolean>>({});

    // Conteo de facturas con errores
    const invoicesWithErrorsCount = invoiceItemErrors.length;

    const toggleInvoice = (invoiceId: string) => {
        setOpenInvoices(prev => ({
            ...prev,
            [invoiceId]: !prev[invoiceId]
        }));
    };

    // Verificar si hay facturas de gasto
    const hasGeneralPurchases = invoices.some(invoice =>
        invoice.is_general_purchase
    );

    if (hasGeneralPurchases && uploadTarget === 'intuipos') {
        return (
            <>
                <Alert
                    status="error"
                    mb={4}
                >
                    <AlertIcon />
                    <AlertDescription>
                        Se han seleccionado facturas de gasto tipo gasto. Estas no aplican para Intuipos.
                    </AlertDescription>
                </Alert>
            </>
        )
    }
    return (
        <>
            {allInvoicesUploaded && (
                <Alert
                    status="warning"
                    mb={4}
                >
                    <AlertIcon />
                    <AlertDescription>
                        Todas las facturas seleccionadas ya han sido cargadas a {uploadTarget}.
                        No es posible volver a cargarlas.
                    </AlertDescription>
                </Alert>
            )}

            {someInvoicesUploaded && (
                <Alert
                    status="info"
                    mb={4}
                >
                    <AlertIcon />
                    <AlertDescription>
                        Algunas facturas seleccionadas ya han sido cargadas a {uploadTarget}.
                        Solo se cargarán las facturas que no han sido cargadas previamente.
                    </AlertDescription>
                </Alert>
            )}

            {hasErrors && (
                <Alert
                    status="error"
                    mb={4}
                    variant="subtle"
                >
                    <AlertIcon />
                    <AlertDescription display="flex" alignItems="center">
                        <Text>
                            {uploadTarget === 'siigo'
                                && `${invoicesWithErrorsCount} factura${invoicesWithErrorsCount === 1 ? '' : 's'} tienen configuración ERP incompleta. Debes solucionar estos errores antes de subir las facturas.`}
                            {uploadTarget === 'helisa'
                                && `${invoicesWithErrorsCount} factura${invoicesWithErrorsCount === 1 ? '' : 's'} tienen números de cuenta faltantes. Debes solucionar estos errores antes de subir las facturas.`}
                            {uploadTarget === 'intuipos'
                                && `${invoicesWithErrorsCount} factura${invoicesWithErrorsCount === 1 ? '' : 's'} tienen productos sin nombre de inventario. Debes solucionar estos errores antes de subir las facturas.`}
                        </Text>
                    </AlertDescription>
                </Alert>
            )}

            {(!(allInvoicesUploaded || hasErrors || hasMissingFields || areSuccessfullyUploaded) && uploadTarget !== 'intuipos') ? (
                <Alert
                    status="success"
                    mb={4}
                >
                    <AlertIcon />
                    <AlertDescription width="100%">
                        <Text mb={2}>
                            Todas las facturas están listas para ser cargadas a {uploadTarget}.
                        </Text>
                        <VStack align="stretch" width="100%" spacing={0}>
                            {invoices.map(invoice => (
                                <Box
                                    key={invoice.id}
                                    borderWidth="1px"
                                    borderRadius="md"
                                >
                                    <HStack justifyContent="space-between">
                                        <Text fontWeight="bold">
                                            {invoice.invoice_id || invoice.invoice_consecutive || 'Factura sin número'}
                                        </Text>
                                        <Button
                                            size="sm"
                                            variant="ghost"
                                            onClick={() => toggleInvoice(invoice.id)}
                                            rightIcon={openInvoices[invoice.id] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                        >
                                            {openInvoices[invoice.id] ? 'Ocultar detalles' : 'Ver detalles'}
                                        </Button>
                                    </HStack>

                                    <Collapse in={openInvoices[invoice.id]} animateOpacity>
                                        <VStack align="stretch" spacing={0}>


                                            {invoice.invoice_type_name && (
                                                <HStack>
                                                    <Text fontWeight="medium">Tipo de documento:</Text>
                                                    <Text>{invoice.invoice_type_name}</Text>
                                                </HStack>
                                            )}

                                            {invoice.invoice_type && !invoice.invoice_type_name && (
                                                <HStack>
                                                    <Text fontWeight="medium">Tipo de documento:</Text>
                                                    <Text>{invoice.invoice_type}</Text>
                                                </HStack>
                                            )}

                                            {invoice.payment_method && (
                                                <HStack>
                                                    <Text fontWeight="medium">Método de pago:</Text>
                                                    <Text>
                                                        {typeof invoice.payment_method === 'object'
                                                            ? invoice.payment_method.name
                                                            : `ID: ${invoice.payment_method}`}
                                                    </Text>
                                                </HStack>
                                            )}

                                            {invoice.payment_form && (
                                                <HStack>
                                                    <Text fontWeight="medium">Forma de pago:</Text>
                                                    <Text>{invoice.payment_form}</Text>
                                                </HStack>
                                            )}

                                            {invoice.cost_center && (
                                                <HStack>
                                                    <Text fontWeight="medium">Centro de costos:</Text>
                                                    <Text>{invoice.cost_center}</Text>
                                                </HStack>
                                            )}

                                            {invoice.account_number && (
                                                <HStack>
                                                    <Text fontWeight="medium">Número de cuenta:</Text>
                                                    <Text>{invoice.account_number}</Text>
                                                </HStack>
                                            )}

                                            {invoice.is_return && (
                                                <HStack>
                                                    <Text fontWeight="medium">Es devolución:</Text>
                                                    <Text>Sí</Text>
                                                </HStack>
                                            )}

                                            {invoice.retentions && invoice.retentions.length > 0 && (
                                                <Box>
                                                    <Text fontWeight="medium">Retenciones:</Text>
                                                    {invoice.retentions.map((retention, index) => (
                                                        <Text key={index} ml={2} fontSize="sm">
                                                            {retention.name} ({retention.percentage}%)
                                                        </Text>
                                                    ))}
                                                </Box>
                                            )}
                                        </VStack>
                                    </Collapse>
                                </Box>
                            ))}
                        </VStack>
                    </AlertDescription>
                </Alert>
            ) : uploadTarget !== 'intuipos' && hasMissingFields && (
                <Alert
                    status="error"
                    flexDirection="column"
                    alignItems="flex-start"
                    mb={4}
                >
                    <AlertIcon />
                    <AlertTitle mb={2}>
                        Formulario incompleto
                    </AlertTitle>
                    <AlertDescription width="100%">
                        <Text mb={2}>
                            Por favor complete los siguientes campos requeridos en el formulario antes de subir la factura:
                        </Text>
                        <List spacing={2} styleType="disc" pl={4}>
                            {missingFormFields.map((field, index) => (
                                <ListItem key={index}>
                                    <Text color="red.500">{field}</Text>
                                </ListItem>
                            ))}
                        </List>
                    </AlertDescription>
                </Alert>
            )}
        </>
    );
}; 