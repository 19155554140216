import { Invoice } from '@/types/invoice.types';
import { fetchInvoiceByCufe } from '@/services/invoices.services';

/**
 * Reload selected invoices to get the latest data
 */
export const reloadInvoices = async (
  invoices: Invoice[],
  setInvoices: (invoices: Invoice[]) => void
): Promise<Invoice[]> => {
  try {
    // Reload all selected invoices
    const updatedInvoices = [...invoices];
    let hasUpdates = false;
    
    for (let i = 0; i < updatedInvoices.length; i++) {
      try {
        // Only reload if it has CUFE (unique identifier)
        if (updatedInvoices[i].invoice_cufe) {
          const refreshedInvoice = await fetchInvoiceByCufe(updatedInvoices[i].invoice_cufe);
          if (refreshedInvoice) {
            updatedInvoices[i] = refreshedInvoice;
            hasUpdates = true;
          }
        }
      } catch (error) {
        console.error(`Error reloading invoice ${i}:`, error);
      }
    }
    setInvoices(updatedInvoices);
    
    return hasUpdates ? updatedInvoices : invoices;
  } catch (error) {
    console.error('Error reloading invoices:', error);
    return invoices;
  }
}; 