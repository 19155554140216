import { createContext, useContext, useState } from "react";
import { DianComparisonResult } from "@/types/dian.types";

// TODO: change any to the correct type
const DianContext = createContext<{
    showResults: boolean;
    dianFile: File | null;
    comparisonResults: DianComparisonResult[];
    comparisonSelected: string | null;
    results: any;
    isLoading: boolean; // Campo añadido para indicar si está cargando
    setShowResults: (value: boolean) => void;
    setDianFile: (value: File | null) => void;
    setComparisonResults: (value: DianComparisonResult[] | []) => void;
    setComparisonSelected: (value: string | null) => void;
    setResults: (value: any | []) => void;
    setIsLoading: (value: boolean) => void; // Función para actualizar el estado de carga
} | null>(null)

export const DianContextProvider = ({ children }: { children: React.ReactNode; }) => {
    const [comparisonResults, setComparisonResults] = useState<DianComparisonResult[]>([])
    const [comparisonSelected, setComparisonSelected] = useState<string | null>(null)
    const [showResults, setShowResults] = useState<boolean>(false)
    const [dianFile, setDianFile] = useState<File | null>(null)
    const [results, setResults] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false) // Estado de carga añadido

    return (
        <DianContext.Provider value={{ comparisonResults, setComparisonResults, comparisonSelected, setComparisonSelected, showResults, setShowResults, dianFile, setDianFile, results, setResults, isLoading, setIsLoading }} >
            {children}
        </DianContext.Provider>
    )
}

export const useDianContext = () => {
    const context = useContext(DianContext)

    if (!context) {
        throw new Error("Dian Context must be used inside a DianContextProvider")
    }

    return context
} 