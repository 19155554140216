import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Flex, 
  Link, 
  Stack, 
  useColorModeValue,
  useDisclosure,
  IconButton,
  Collapse,
  Text
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { HamburgerIcon, CloseIcon, ExternalLinkIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Logo from './Logo';

const LandingNav = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box 
      position="fixed" 
      w="full" 
      zIndex={10} 
      transition="all 0.3s ease"
      top="16px"
    >
      <Flex
        backdropFilter={scrolled ? "blur(10px)" : "none"}
        backgroundColor={scrolled 
          ? useColorModeValue('rgba(255, 255, 255, 0.8)', 'rgba(26, 32, 44, 0.8)') 
          : useColorModeValue('rgba(255, 255, 255, 0.9)', 'rgba(26, 32, 44, 0.9)')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4, md: 6 }}
        mx="auto"
        maxW="container.xl"
        align={'center'}
        borderRadius="20px"
        boxShadow={scrolled ? "0 4px 20px -5px rgba(0, 0, 0, 0.1)" : "0 8px 25px -5px rgba(0, 0, 0, 0.1)"}
        borderBottom={scrolled ? "1px solid" : "none"}
        borderColor={useColorModeValue('gray.200', 'gray.700')}
        transform={scrolled ? "translateY(0)" : "translateY(0)"}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Link as={RouterLink} to="/">
            <Flex align="center">
              <Logo height="30px" width="30px" />
              <Text
                ml={2}
                fontSize="lg"
                fontWeight="bold"
                color={useColorModeValue('gray.700', 'white')}
              >
                Cifrato
              </Text>
            </Flex>
          </Link>
          
          <Stack
            direction="row"
            display={{ base: 'none', md: 'flex' }}
            alignItems="center"
            spacing={8}
            ml={10}
          >
            <NavLink to="#beneficios">Beneficios</NavLink>
            <NavLink to="#como-funciona">Cómo Funciona</NavLink>
            <NavLink to="#demo-video">Demo</NavLink>
            {/* <NavLink to="#testimonios">Testimonios</NavLink> */}
            {/* <NavLink to="#precios">Precios</NavLink> */}
          </Stack>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={4}
          display={{ base: 'none', md: 'flex' }}
        >
          {/* <Button
            as={RouterLink}
            fontSize={'sm'}
            fontWeight={500}
            variant={'outline'}
            to={'/login'}
            color={useColorModeValue('blue.500', 'blue.300')}
            borderColor={useColorModeValue('blue.500', 'blue.300')}
            _hover={{
              bg: useColorModeValue('blue.50', 'rgba(66, 153, 225, 0.2)'),
              transform: 'translateY(-2px)',
              boxShadow: 'sm',
            }}
            transition="all 0.3s ease"
          >
            Iniciar Sesión
          </Button> */}
          <Button
            as={RouterLink}
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            bg={'linear-gradient(to right, #3182CE, #4299E1)'}
            to={'/login'}
            px={6}
            py={5}
            rightIcon={<ArrowForwardIcon />}
            borderRadius="full"
            _hover={{
              bgGradient: 'linear-gradient(to right, #2B6CB0, #3182CE)',
              transform: 'translateY(-2px)',
              boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            }}
            _active={{
              transform: 'translateY(0)',
              boxShadow: 'none',
            }}
            transition="all 0.3s ease"
          >
            Acceder a la App
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};

const NavLink = ({ children, to }: { children: React.ReactNode, to: string }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    
    // Remove the hash from the link
    const targetId = to.replace('#', '');
    const targetElement = document.getElementById(targetId);
    
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 100, // Offset to account for navbar height
        behavior: 'smooth'
      });
    }
  };

  return (
    <Link
      href={to}
      px={2}
      py={1}
      rounded={'md'}
      position="relative"
      fontWeight="medium"
      color={useColorModeValue('gray.600', 'gray.200')}
      onClick={handleClick}
      _after={{
        content: '""',
        position: 'absolute',
        width: '0%',
        height: '2px',
        bottom: '-2px',
        left: '50%',
        bg: useColorModeValue('blue.500', 'blue.300'),
        transition: 'all 0.3s ease',
        transform: 'translateX(-50%)',
      }}
      _hover={{
        textDecoration: 'none',
        color: useColorModeValue('gray.800', 'white'),
        _after: {
          width: '100%',
        }
      }}
    >
      {children}
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('rgba(255, 255, 255, 0.9)', 'rgba(26, 32, 44, 0.9)')}
      backdropFilter="blur(10px)"
      p={4}
      display={{ md: 'none' }}
      borderRadius="0 0 20px 20px"
      boxShadow="md"
    >
      <Stack spacing={4}>
        <MobileNavItem label="Beneficios" to="#beneficios" />
        <MobileNavItem label="Cómo Funciona" to="#como-funciona" />
        <MobileNavItem label="Demo" to="#demo-video" />
        <MobileNavItem label="Testimonios" to="#testimonios" />
        <MobileNavItem label="Precios" to="#precios" />
      </Stack>
      <Stack
        mt={4}
        pt={4}
        borderTopWidth={1}
        borderColor={useColorModeValue('gray.200', 'gray.700')}
      >
        <Button 
          as={RouterLink} 
          to="/login"
          w="full"
          colorScheme="blue" 
          variant="outline"
          mb={2}
        >
          Iniciar Sesión
        </Button>
        <Button 
          as={RouterLink} 
          to="/login"
          w="full"
          colorScheme="blue"
          rightIcon={<ArrowForwardIcon />}
          borderRadius="full"
          bgGradient="linear(to-r, blue.400, blue.500)"
          _hover={{
            bgGradient: "linear(to-r, blue.500, blue.600)",
            transform: "translateY(-2px)",
            boxShadow: "md"
          }}
        >
          Acceder a la App
        </Button>
      </Stack>
    </Stack>
  );
};

const MobileNavItem = ({ label, to }: { label: string, to: string }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    
    // Remove the hash from the link
    const targetId = to.replace('#', '');
    const targetElement = document.getElementById(targetId);
    
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 100, // Offset to account for navbar height
        behavior: 'smooth'
      });
    }
  };

  return (
    <Stack spacing={4}>
      <Link
        href={to}
        py={2}
        fontWeight={500}
        color={useColorModeValue('gray.600', 'gray.200')}
        onClick={handleClick}
        _hover={{
          textDecoration: 'none',
          color: useColorModeValue('gray.800', 'white'),
        }}
      >
        {label}
      </Link>
    </Stack>
  );
};

export default LandingNav; 