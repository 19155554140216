import { Box, Container, Heading, Text, Flex, useColorModeValue, Image, HStack, VStack, Link } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FC } from 'react';

// Motion components
const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionFlex = motion(Flex);

const BackersSection: FC = () => {
  const bgColor = useColorModeValue('white', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  
  const backers = [
    {
      id: 1,
      name: 'Y Combinator',
      logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Y_Combinator_logo.svg/1200px-Y_Combinator_logo.svg.png',
      url: 'https://www.ycombinator.com/',
    },
    {
      id: 2,
      name: 'Google',
      logoUrl: 'https://storage.googleapis.com/onboarding-videos-cifrato/landing_images/Google%20Logo%20PNG.png',
      url: 'https://startup.google.com/',
    },
  ];
  
  return (
    <Box as="section" py={{ base: 12, md: 16 }} bg={bgColor} width="100%">
      <Container maxW="container.xl" centerContent>
        <MotionBox
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          textAlign="center"
          mb={6}
        >
          <MotionHeading 
            as="h2" 
            fontSize={{ base: '2xl', md: '3xl' }} 
            bgGradient="linear(to-r, blue.500, teal.500)" 
            bgClip="text"
            mb={4}
          >
            Respaldados por líderes en innovación
          </MotionHeading>
          
          <MotionText 
            fontSize={{ base: 'md', md: 'lg' }} 
            color={textColor}
            maxW="800px"
            mb={12}
          >
            Nuestra tecnología cuenta con el respaldo de instituciones líderes en el mundo de la tecnología
          </MotionText>
        </MotionBox>
        
        <HStack 
          spacing={{ base: 12, md: 20 }}
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          justify="center"
        >
          {backers.map((backer) => (
            <MotionFlex
              key={backer.id}
              justify="center"
              align="center"
              p={{ base: 4, md: 6 }}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 * backer.id }}
              whileHover={{ y: -5, scale: 1.05, transition: { duration: 0.2 } }}
            >
              <Link href={backer.url} isExternal _hover={{ textDecoration: 'none' }}>
                <Image
                  src={backer.logoUrl}
                  alt={backer.name}
                  maxH={{ base: "45px", md: "65px" }}
                  maxW={{ base: "130px", md: "160px" }}
                  objectFit="contain"
                />
              </Link>
            </MotionFlex>
          ))}
        </HStack>
      </Container>
    </Box>
  );
};

export default BackersSection; 