import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Button,
    Alert,
    AlertIcon,
    VStack,
    Spinner,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useInvoiceDetails } from '@/context/invoiceDetails/invoiceDetailsContext';
import { PurchaseOrderModal } from './PurchaseOrderModal';
import { PurchaseOrderDetails } from './PurchaseOrderDetails';
import { usePurchaseOrder } from '@/hooks/usePurchaseOrder';

export const PurchaseOrderSection: React.FC = () => {
    const { invoice } = useInvoiceDetails();
    const [expandedPOs, setExpandedPOs] = React.useState<{ [key: string]: boolean }>({});

    const {
        purchaseOrder,
        availablePOs,
        isLoadingPOs,
        isOpen,
        onOpen,
        onClose,
        handleAddPO,
        handleRemovePO,
    } = usePurchaseOrder({
        invoiceCufe: invoice.invoice_cufe,
        supplierNit: invoice.sender_party,
        invoicePo: invoice.purchase_order_matches || [],
    });

    const toggleExpand = (poId: string) => {
        setExpandedPOs(prev => ({
            ...prev,
            [poId]: !prev[poId]
        }));
    };

    return (
        <Box>
            <Flex justify="space-between" align="center" mb={4}>
                <Heading size="md">Orden de Compra</Heading>
                {!purchaseOrder && (
                    <Button
                        colorScheme="blue"
                        onClick={onOpen}
                        isLoading={isLoadingPOs}
                    >
                        Agregar orden de compra
                    </Button>
                )}
            </Flex>

            <PurchaseOrderModal
                isOpen={isOpen}
                onClose={onClose}
                availablePOs={availablePOs}
                onSelectPO={handleAddPO}
                isLoading={isLoadingPOs}
                currentPO={purchaseOrder}
            />

            {(invoice.purchase_order_matches || []).length > 0 && isLoadingPOs ? (
                <Box p={4} borderWidth="1px" borderRadius="lg" mb={4} bg="white">
                    <Flex justify="center" align="center" p={4}>
                        <Spinner size="md" mr={3} />
                        <Heading size="sm">Cargando orden de compra...</Heading>
                    </Flex>
                </Box>
            ) : purchaseOrder ? (
                <Box p={4} borderWidth="1px" borderRadius="lg" mb={4} bg="white">
                    <VStack align="stretch" spacing={4}>
                        <Flex
                            justify="space-between"
                            align="center"
                        >
                            <Flex
                                flex="1"
                                cursor="pointer"
                                onClick={() => toggleExpand(purchaseOrder.id)}
                                _hover={{ bg: 'gray.50' }}
                                p={2}
                                borderRadius="md"
                            >
                                <Heading size="sm">Orden de Compra #{purchaseOrder.transaction_id}</Heading>
                                {expandedPOs[purchaseOrder.id] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </Flex>
                            <Flex gap={2}>
                                <Button
                                    colorScheme="blue"
                                    size="sm"
                                    onClick={onOpen}
                                    isLoading={isLoadingPOs}
                                >
                                    Cambiar
                                </Button>
                                <Button
                                    colorScheme="red"
                                    size="sm"
                                    onClick={handleRemovePO}
                                >
                                    Remover
                                </Button>
                            </Flex>
                        </Flex>

                        {expandedPOs[purchaseOrder.id] && (
                            <PurchaseOrderDetails purchaseOrder={purchaseOrder} />
                        )}
                    </VStack>
                </Box>
            ) : (
                <Alert status="info" borderRadius="lg">
                    <AlertIcon />
                    No hay orden de compra asociada a esta factura.
                </Alert>
            )}
        </Box>
    );
}; 