import { Center, Heading, Modal, ModalBody, ModalContent, ModalOverlay, Progress, VStack, Text } from "@chakra-ui/react";
import { useInvoicesContext } from "@/context/invoices/invoicesContext";

export function DianLoadingModal() {
    const { isLoadingOpen, onLoadingClose } = useInvoicesContext();
    return (
        <Modal isOpen={isLoadingOpen} onClose={onLoadingClose} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent w="400px" p={6} borderRadius="md">
            <ModalBody>
                <VStack spacing={4}>
                    <Center w="full">
                        <Heading size="md" textAlign="center">
                            Enviando token de acceso vía email
                        </Heading>
                    </Center>
                    <Text textAlign="center" color="gray.600">
                        Este proceso puede demorar. Por favor no cierres esta ventana.
                    </Text>
                    <Progress
                        w="full"
                        size="md"
                        isIndeterminate
                        colorScheme="blue"
                        borderRadius="md"
                    />
                </VStack>
            </ModalBody>
        </ModalContent>
    </Modal>
    )
}

