import {
  deleteInvoiceFromSiigo,
  fetchInvoiceByCufe,
} from "@/services/invoices.services";
import { Invoice, InvoiceItem, BizServices } from "@/types/invoice.types";
import { InventoryItem } from "@/types/inventory.types";
import { useToast } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { useInvoicesHandler } from "../shared/invoicesHandlerContext";
import { InvoiceDetailsSkeleton } from "@/components/invoiceDetails/InvoiceDetailsSkeleton";
import { useParams } from "react-router-dom";
import { ErpProductOption, ErpTaxOption } from "@/types/erpProducts.types";
import { useInvoiceOperations } from "@/hooks/invoiceDetails/useInvoiceOperations";
import { useInvoiceItemHandlers } from "@/hooks/invoiceDetails/useInvoiceItemHandlers";
import { useSupplierInvoices } from "@/hooks/invoiceDetails/useSupplierInvoices";
import { useInventoryItems } from "@/hooks/invoiceDetails/useInventoryItems";
import { useErpProducts } from "@/hooks/invoiceDetails/useErpProducts";
import { useErpTaxes } from "@/hooks/invoiceDetails/useErpTaxes";
import { useInvoiceById } from "@/hooks/invoiceDetails/useInvoiceById";

interface InvoiceDetailsContextProps {
  invoice: Invoice;
  setInvoice: (invoice: Invoice) => void;
  modalData: {
    updatedInvoice: Invoice;
    suspiciousItems: InvoiceItem[];
  } | null;
  setModalData: (modalData: {
    updatedInvoice: Invoice;
    suspiciousItems: InvoiceItem[];
  } | null) => void;
  isOpen: boolean;
  handleModalCancel: () => void;
  handleModalConfirm: () => void;
  handleSaveInvoice: () => void;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  services: BizServices | undefined;
  inventoryItems: InventoryItem[];
  itemsOptions: { label: string, value: string }[];
  erpProductsOptions: ErpProductOption[] | undefined;
  erpTaxesOptions: ErpTaxOption[] | undefined;
  handleItemChange: (selectedOption: { label: string, value: string }, index: number) => void;
  handleTaxChange: (
    selectedOption: { label: string, value: string },
    index: number,
    taxIndex: number
  ) => void;
  handleTaxAmountChange: (value: string, index: number, taxName: string) => void;
  processUpdateInvoice: (updatedInvoice: Invoice) => Promise<void>;
  supplierInvoices: Invoice[];
  isLoadingSupplierInvoices: boolean;
  isPDFVisible: boolean;
  setIsPDFVisible: (isPDFVisible: boolean) => void;
  handleDeleteFromSiigoClick: () => void;
  handleDeleteFromSiigo: () => Promise<void>;
  isDeleteErpInvoiceLoading: boolean;
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
  isDeleteSiigoModalOpen: boolean;
  setIsDeleteSiigoModalOpen: (isDeleteSiigoModalOpen: boolean) => void;
}

const InvoiceDetailsContext = createContext<
  InvoiceDetailsContextProps | undefined
>(undefined);

export const InvoiceDetailsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { invoices: selectedInvoices, setInvoices: setSelectedInvoices, setServices, services } = useInvoicesHandler();
  const { id } = useParams(); // Use id from URL
  const [isPDFVisible, setIsPDFVisible] = useState(false);
  // Get invoice from selectedInvoices or fetch by ID
  const invoice = selectedInvoices[0];
  const setInvoice = (invoice: Invoice) => {
    setSelectedInvoices([invoice]);
  };
  const { loading } = useInvoiceById(id, invoice, setInvoice);
  const inventoryItemsHook = useInventoryItems();
  const erpProductsHook = useErpProducts();
  const erpTaxesHook = useErpTaxes();
  const supplierInvoicesHook = useSupplierInvoices(invoice);
  const operationsHook = useInvoiceOperations(invoice, setInvoice, erpTaxesHook.erpTaxesOptions);

  const {
    handleItemChange,
    handleTaxChange,
    handleTaxAmountChange
  } = useInvoiceItemHandlers(invoice, setInvoice, inventoryItemsHook.inventoryItems);


  const [isDeleteSiigoModalOpen, setIsDeleteSiigoModalOpen] = useState(false);
  const [isDeleteErpInvoiceLoading, setIsDeleteErpInvoiceLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const toast = useToast();

  const handleDeleteFromSiigoClick = () => {
    setIsDeleteSiigoModalOpen(true);
  };

  const handleDeleteFromSiigo = async () => {
    if (!invoice) return;

    setIsDeleteErpInvoiceLoading(true);

    try {
      const response = await deleteInvoiceFromSiigo(invoice.invoice_cufe);

      if (response.status === 'success') {
        toast({
          title: "Eliminado de Siigo",
          description: "La factura ha sido eliminada de Siigo correctamente",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });

        // Update the local state to reflect the deletion
        const updatedInvoice = { ...invoice };
        if (updatedInvoice.uploads?.siigo) {
          updatedInvoice.uploads.siigo.uploaded = false;
        }
        setInvoice(updatedInvoice);
      } else {
        toast({
          title: "Error al eliminar",
          description: response.message || "Ha ocurrido un error al eliminar la factura de Siigo",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
      }
    } catch (error) {
      console.error('Error deleting invoice from Siigo:', error);
      toast({
        title: "Error al eliminar",
        description: "Ha ocurrido un error al eliminar la factura de Siigo",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    } finally {
      setIsDeleteSiigoModalOpen(false);
      setIsDeleteErpInvoiceLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log("invoiceInContext", invoice);
  // }, [invoice]);

  if (loading || !invoice) {
    return <InvoiceDetailsSkeleton />;
  }

  return (
    <InvoiceDetailsContext.Provider
      value={{
        invoice,
        isPDFVisible,
        setIsPDFVisible,
        setInvoice,
        modalData: operationsHook.modalData,
        setModalData: operationsHook.setModalData,
        supplierInvoices: supplierInvoicesHook.supplierInvoices,
        isLoadingSupplierInvoices: supplierInvoicesHook.isLoadingSupplierInvoices,
        isOpen: operationsHook.isOpen,
        handleModalCancel: operationsHook.handleModalCancel,
        handleModalConfirm: operationsHook.handleModalConfirm,
        handleSaveInvoice: operationsHook.handleSaveInvoice,
        isSaving: operationsHook.isSaving,
        setIsSaving: operationsHook.setIsSaving,
        processUpdateInvoice: operationsHook.processUpdateInvoice,
        services,
        inventoryItems: inventoryItemsHook.inventoryItems,
        itemsOptions: inventoryItemsHook.itemsOptions,
        erpProductsOptions: erpProductsHook.erpProductsOptions,
        erpTaxesOptions: erpTaxesHook.erpTaxesOptions,
        handleItemChange,
        handleTaxChange,
        handleTaxAmountChange,

        handleDeleteFromSiigoClick,
        handleDeleteFromSiigo,
        isDeleteErpInvoiceLoading,
        activeTab,
        setActiveTab,
        isDeleteSiigoModalOpen,
        setIsDeleteSiigoModalOpen,
      }}
    >
      {children}
    </InvoiceDetailsContext.Provider>
  );
};

export const useInvoiceDetails = () => {
  const context = useContext(InvoiceDetailsContext);
  if (!context) {
    throw new Error(
      "useInvoiceDetails must be used within a InvoiceDetailsProvider"
    );
  }
  return context;
};
