import {
    Heading,
    Flex
} from "@chakra-ui/react";
import { DianLoadingModal } from "../dianSyncModals/DianLoadingTokenModal";
import { DianModal } from "../dianSyncModals/GetDianInvoicesModal";
import { InvoicesHeaderButtons } from "./InvoicesHeaderButtons";

export function InvoicesHeaderSection() {
    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                bg="white"
                p={4}
            >
                <Heading size="md" fontWeight="500" color="gray.700">
                    Facturas
                </Heading>

                <InvoicesHeaderButtons />
            </Flex>

            <DianLoadingModal />
            <DianModal />
        </>
    );
}
