import React from 'react';
import {
    Box,
    Text,
    Link,
    HStack,
    Icon,
    AspectRatio,
    Skeleton,
} from '@chakra-ui/react';
import { MdHelpOutline } from 'react-icons/md';

interface VideoTutorialProps {
    title: string;
    tutorialLink?: string;
    videoUrl: string;
    isLoading: boolean;
    onVideoLoaded: () => void;
}

export const VideoTutorial: React.FC<VideoTutorialProps> = ({
    title,
    tutorialLink,
    videoUrl,
    isLoading,
    onVideoLoaded,
}) => {
    return (
        <Box>
            <Text fontWeight="medium" mb={2}>
                {title}
            </Text>
            {tutorialLink && (
                <Link
                    href={tutorialLink}
                    isExternal
                    color="blue.500"
                    display="block"
                    mb={3}
                >
                <HStack>
                    <Icon as={MdHelpOutline} />
                    <Text>Ver tutorial en {videoUrl ? 'Siigo' : 'Helisa'}</Text>
                    </HStack>
                </Link>
            )}
            {videoUrl && (
                <AspectRatio ratio={16/9} maxH="400px" width="100%">
                    <Box position="relative" borderRadius="md" overflow="hidden" bg="gray.100">
                        <Skeleton
                            isLoaded={!isLoading}
                            fadeDuration={1}
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                        />
                        <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            preload="metadata"
                            style={{ 
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                backgroundColor: 'rgb(243, 244, 246)'
                            }}
                            onLoadedData={onVideoLoaded}
                        >
                            <source src={videoUrl} type="video/mp4" />
                            Tu navegador no soporta la reproducción de videos.
                        </video>
                    </Box>
                </AspectRatio>
            )}
        </Box>
    );
}; 