import { useState, useEffect, useCallback } from 'react';
import { ErpHandler } from '@/services/erp/erpHandler.interface';
import { Invoice } from '@/types/invoice.types';
import { InvoiceTypeOption } from '@/types/erpShared.types';
import { getBizErpType } from '@/services/invoices.services';

export const useInvoiceTypes = (
  erpHandler: ErpHandler | null,
  selectedInvoices: Invoice[],
  setSelectedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>,
) => {
  const [invoiceTypes, setInvoiceTypes] = useState<InvoiceTypeOption[]>([]);
  const [invoiceTypesOptions, setInvoiceTypesOptions] = useState<InvoiceTypeOption[]>([]);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState<InvoiceTypeOption | null>(null);
  const [selectedInvoiceTypeName, setSelectedInvoiceTypeName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [bizErpType, setBizErpType] = useState<string>('');

  const [showRetentionAccountInputs, setShowRetentionAccountInputs] = useState<boolean>(false);

  // Fetch bizErpType when component mounts
  useEffect(() => {
    const fetchErpType = async () => {
      try {
        const response = await getBizErpType();
        if (response && response.type) {
          setBizErpType(response.type);
          console.log('Business ERP Type in useInvoiceTypes:', response.type);
        }
      } catch (error) {
        console.error('Error fetching bizErpType in useInvoiceTypes:', error);
      }
    };

    fetchErpType();
  }, []);

  // Load invoice types
  useEffect(() => {
    const loadInvoiceTypes = async () => {
      if (!erpHandler) return;

      setIsLoading(true);
      setError(null);

      try {
        const types = await erpHandler.getInvoiceTypes(selectedInvoices[0]);
        setInvoiceTypes(types);

        // Also set the options for the selector
        setInvoiceTypesOptions(types);
        // Initialize invoice type if it exists in the invoice
        if (selectedInvoices[0]?.invoice_type && types.length > 0) {
          const currentType = types.find(t =>
            t.id === Number(selectedInvoices[0].invoice_type) ||
            t.id === selectedInvoices[0].invoice_type ||
            t.value === selectedInvoices[0].invoice_type
          );

          if (currentType) {
            setSelectedInvoiceType(currentType);
            // Ensure that the invoice type name is set
            const typeName = currentType.name || '';
            setSelectedInvoiceTypeName(typeName);
          }
        }

        // If it is a credit note (CC), automatically select it
        if (selectedInvoices[0]?.is_return) {
          const creditNoteType = types.find(t => t.type === 'NC' || t.name?.includes('Nota Crédito'));
          if (creditNoteType) {
            setSelectedInvoiceType(creditNoteType);
            // Ensure that the invoice type name is set
            const typeName = creditNoteType.type || creditNoteType.name || '';
            setSelectedInvoiceTypeName(typeName);
          }
        }
      } catch (err) {
        console.error('Error loading invoice types:', err);
        setError('Error loading invoice types');
      } finally {
        setIsLoading(false);
      }
    };

    loadInvoiceTypes();
  }, [erpHandler]);

  // Update the invoice type name when selectedInvoiceType changes
  useEffect(() => {
    if (selectedInvoiceType) {
      const typeName = selectedInvoiceType.type || selectedInvoiceType.name || '';
      setSelectedInvoiceTypeName(typeName);

      // Call the callback if it is available
      updateSelectedInvoices(selectedInvoiceType, typeName);
    }
    if (selectedInvoiceTypeName !== 'FC' || ['pyme', 'contador'].includes(bizErpType)) {
      setShowRetentionAccountInputs(true);
    } else {
      setShowRetentionAccountInputs(false);
    }
  }, [selectedInvoiceTypeName]);

  const updateSelectedInvoices = useCallback((invoiceType: InvoiceTypeOption, typeName: string) => {
    if (selectedInvoices.length > 0) {
		if (!erpHandler) return;
      // Update all the selected invoices
      setSelectedInvoices(prevInvoices => {
        const updatedInvoices = prevInvoices.map(invoice => ({
          ...invoice,
          invoice_type: erpHandler?.erp === 'siigo' ? Number(invoiceType.value) : String(invoiceType.value), // weird
          invoice_type_name: typeName
        }));
        return updatedInvoices;
      });
    }
  }, [selectedInvoices.length, setSelectedInvoices]); // Added dependency on selectedInvoices.length for invoices page (this has to change when we select invoices in the table)

  // Handle invoice type change
  const handleInvoiceTypeChange = useCallback((option: InvoiceTypeOption) => {
    setSelectedInvoiceType(option);

    // Set the invoice type name
    const typeName = option?.name || '';
    setSelectedInvoiceTypeName(typeName);

    // Call the callback if it is available
    if (option) {
      updateSelectedInvoices(option, typeName);
    }
  }, [updateSelectedInvoices]);

  // Modified version to also notify changes
  const updateSelectedInvoiceType = useCallback((option: InvoiceTypeOption | null) => {
    setSelectedInvoiceType(option);

    if (option) {
      // Set the invoice type name
      const typeName = option.name || '';
      setSelectedInvoiceTypeName(typeName);

      updateSelectedInvoices(option, typeName);
    }
  }, [updateSelectedInvoices]);

  return {
    invoiceTypes,
    invoiceTypesOptions,
    selectedInvoiceType,
    selectedInvoiceTypeName,
    isLoading,
    error,
    handleInvoiceTypeChange,
    setSelectedInvoiceType: updateSelectedInvoiceType,
    showRetentionAccountInputs,
    bizErpType
  };
}; 