import { useInvoicesContext } from "@/context/invoices/invoicesContext";
import { UseInvocesTableContext } from "@/context/invoices/invoicesTableContext";
import { Box, Flex, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { CgFileDocument } from "react-icons/cg";
import { MdFilter } from "react-icons/md";

export function InvoicesTableEmpty() {
    const { debouncedProviderFilter, services } = UseInvocesTableContext();
    const { tabIndex } = useInvoicesContext();
    
    // Calculate the correct colSpan dynamically based on all conditions
    let colSpan = 5; // Base columns: Checkbox, ID, Fecha, Estados de carga, Listo
    
    if (tabIndex === 0) {
        colSpan += 2; // Add Proveedor and Tipo columns
    }
    
    if (services?.pos === "intuipos") {
        colSpan += 1; // Add Orden de Compra column
    }
    
    const invoiceTypeText = tabIndex === 0 ? "facturas de compra" : "facturas de venta";

    return (
        <Tr>
            <Td colSpan={colSpan}>
                <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    py={12}
                    px={6}
                    color="gray.600"
                    borderRadius="lg"
                    my={4}
                    boxShadow="sm"
                >
                    <Box mb={6} position="relative">
                        <Icon
                            as={CgFileDocument}
                            boxSize={16}
                            color="blue.500"
                            opacity={0.8}
                        />
                        <Icon
                            as={MdFilter}
                            position="absolute"
                            bottom={-2}
                            right={-2}
                            boxSize={6}
                            color="gray.400"
                        />
                    </Box>

                    <Text fontSize="xl" fontWeight="bold" mb={3} color="gray.700">
                        No se encontraron {invoiceTypeText}
                    </Text>

                    <Text textAlign="center" fontSize="md" maxW="md" mb={6}>
                        {debouncedProviderFilter
                            ? "Intenta usar otros filtros para encontrar lo que necesitas."
                            : `No hay ${invoiceTypeText} con los filtros seleccionados.`}
                    </Text>
                </Flex>
            </Td>
        </Tr>
    )
}