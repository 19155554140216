import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { fetchStoreIds, updateInvoiceStoreId } from "@/services/invoices.services";
import { Alert, AlertIcon, Flex, FormControl, FormLabel, Select, Spinner, Table, Tbody, Td, Th, Thead, Tr, useToast, VStack, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";



export function InvoiceDetailsPOSForm() {
    const { invoice, setInvoice } = useInvoiceDetails();
    const toast = useToast();
    const [storeIds, setStoreIds] = useState<Record<string, string>>({});
    const [storeSelection, setStoreSelection] = useState<string>('');
    const [isLoadingStores, setIsLoadingStores] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isUpdatingStore, setIsUpdatingStore] = useState(false);
    const [hasInvalidItems, setHasInvalidItems] = useState(false);
    const [isPOSModalOpen, setIsPOSModalOpen] = useState(false);
  
    useEffect(() => {
      const loadData = async () => {
        setIsLoadingStores(true);
        try {
          // Obtener IDs de tiendas
          const storeIdsResponse = await fetchStoreIds();
          setStoreIds(storeIdsResponse.store_ids);
  
          // Si hay una sola tienda, autoseleccionar
          if (Object.values(storeIdsResponse.store_ids).length === 1) {
            const onlyStoreName = Object.values(storeIdsResponse.store_ids)[0];
            setStoreSelection(onlyStoreName);
          } else if (invoice.store_id) {
            // Si la factura ya tiene una tienda asignada, usarla
            setStoreSelection(invoice.store_id);
          }
  
          // Verificar si hay items sin inventory_item_name
          const invalidItems = invoice.items.some(item => !item.inventory_item_name);
          setHasInvalidItems(invalidItems);
        } catch (error) {
          console.error('Failed to load initial data:', error);
          toast({
            title: "Error",
            description: "No se pudieron cargar los datos de las tiendas",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } finally {
          setIsLoadingStores(false);
        }
      };
  
      loadData();
    }, [toast]);
  
    const handleStoreSelection = async (storeId: string) => {
      setStoreSelection(storeId);
      
      // Si la tienda seleccionada es diferente a la actual, actualizar en la base de datos
      if (storeId !== invoice.store_id) {
        setIsUpdatingStore(true);
        try {
          // Encontrar el nombre de la tienda a partir del ID
          const storeName = Object.entries(storeIds).find(([_, value]) => value === storeId)?.[0] || '';
          
          // Actualizar el objeto invoice local
          const updatedInvoice = {
            ...invoice,
            store_id: storeId,
            store_name: storeName
          };
          setInvoice(updatedInvoice);
          
          // Actualizar en la base de datos
          const response = await updateInvoiceStoreId(invoice.id, storeId, storeName);
          
          if (response.status === "success") {
            toast({
              title: "Éxito",
              description: "Tienda actualizada correctamente",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Error",
              description: response.message || "Error al actualizar la tienda",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          console.error('Error updating store:', error);
          toast({
            title: "Error",
            description: "Ocurrió un error al actualizar la tienda",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } finally {
          setIsUpdatingStore(false);
        }
      }
    };
  
    const formatStoreName = (storeName: string) => {
      const storeNameParts = storeName.split('_');
      const formattedStoreName = storeNameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
      return formattedStoreName;
    };
  
    const showStoreSelect = Object.keys(storeIds).length > 1;
  
    return (
      <>
        
        {isLoadingStores ? (
          <Flex justify="center" align="center" h="100px">
            <Spinner size="md" color="blue.500" />
            <Text ml={3}>Cargando datos de tiendas...</Text>
          </Flex>
        ) : (
          <>
            {/* {hasInvalidItems && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                <VStack align="start" spacing={1}>
                  <Text fontSize="sm">Algunos productos no tienen nombre de inventario asignado</Text>
                  <Text fontSize="sm">Para cargar a Intuipos, todos los productos deben tener un nombre de inventario</Text>
                </VStack>
              </Alert>
            )} */}
  
            {showStoreSelect && (
              <FormControl mb={4} maxWidth="350px" isInvalid={!storeSelection}>
                <FormLabel fontSize="sm">Seleccionar Tienda</FormLabel>
                <Select
                  value={storeSelection}
                  onChange={(e) => handleStoreSelection(e.target.value)}
                  placeholder="Seleccionar tienda"
                  isRequired
                  isDisabled={isUpdatingStore}
                  size="sm"
                  height="32px"
                  fontSize="0.875rem"
                  maxWidth="350px"
                  errorBorderColor="red.100"
                >
                  {Object.entries(storeIds).map(([id, name]) => (
                    <option key={id} value={name}>
                      {formatStoreName(id)} - {name}
                    </option>
                  ))}
                </Select>
                {isUpdatingStore && (
                  <Text fontSize="sm" color="blue.500" mt={1}>
                    Actualizando tienda...
                  </Text>
                )}
              </FormControl>
            )}
          </>
        )}
      </>
    );
  }