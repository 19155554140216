import { useState, useEffect } from 'react';
import {
    VStack,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    IconButton,
    Text,
    Box,
    Card,
    CardBody,
    Flex,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

interface UserData {
    email: string;
    userName: string;
    password: string;
}

interface UserInformationProps {
    onDataChange: (data: UserData) => void;
    stepData: Partial<UserData>;
    isValid: boolean;
}

export const UserInformation: React.FC<UserInformationProps> = ({
    onDataChange,
    stepData,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState<Partial<UserData>>({});
    const [touchedFields, setTouchedFields] = useState<Set<keyof UserData>>(new Set());
    const [formData, setFormData] = useState<UserData>({
        email: stepData?.email || '',
        userName: stepData?.userName || '',
        password: stepData?.password || '',
    });

    const validateField = (name: keyof UserData, value: string): string => {
        if (!value) return ''; // Don't validate empty fields

        switch (name) {
            case 'email':
                return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'Correo electrónico inválido' : '';
            case 'userName':
                return value.length < 3 ? 'Nombre de usuario debe tener al menos 3 caracteres' : '';
            case 'password':
                return value.length < 8 ? 'La contraseña debe tener al menos 8 caracteres' : '';
            default:
                return '';
        }
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = e.target;
        const newFormData = {
            ...formData,
            [name]: value,
        };
        setFormData(newFormData);

        // Only validate if field has been touched
        if (touchedFields.has(name as keyof UserData)) {
            const error = validateField(name as keyof UserData, value);
            setErrors(prev => ({
                ...prev,
                [name]: error,
            }));
        }

        // Notify parent component
        onDataChange(newFormData);
    };

    const handleBlur = (name: keyof UserData) => {
        // Mark field as touched
        setTouchedFields(prev => new Set(prev).add(name));

        // Validate on blur
        const error = validateField(name, formData[name]);
        setErrors(prev => ({
            ...prev,
            [name]: error,
        }));
    };

    useEffect(() => {
        // Only validate fields that have initial values
        const newErrors: Partial<UserData> = {};
        Object.entries(formData).forEach(([key, value]) => {
            if (value) {
                const fieldName = key as keyof UserData;
                newErrors[fieldName] = validateField(fieldName, value);
                setTouchedFields(prev => new Set(prev).add(fieldName));
            }
        });
        setErrors(newErrors);
    }, []);

    const shouldShowError = (fieldName: keyof UserData) => {
        return touchedFields.has(fieldName) && !!errors[fieldName];
    };

    const inputStyles = {
        borderRadius: 'md',
        borderColor: 'gray.200',
        bg: 'white',
        h: '40px',
        _hover: {
            borderColor: 'gray.300',
        },
        _focus: {
            borderColor: 'blue.500',
            boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)',
        },
    };

    return (
        <Box w="full" h="full" py={4}>
            <Card 
                h="full"
                maxW="800px"
                mx="auto"
                shadow="none"
            >
                <CardBody py={8} px={6}>
                    <VStack align="stretch" spacing={8} h="full">
                        <Box>
                            <Text
                                fontSize="lg"
                                fontWeight="bold"
                                color="gray.700"
                                mb={4}
                            >
                                Información del Usuario Administrador
                            </Text>
                            <Text 
                                fontSize="sm" 
                                color="gray.600"
                                mb={5}
                            >
                                Configura la cuenta del administrador principal para acceder a la plataforma.
                            </Text>

                            <VStack align="stretch" spacing={6}>
                                <FormControl isInvalid={shouldShowError('userName')}>
                                    <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="gray.700"
                                        mb={2}
                                    >
                                        Nombre del Usuario
                                    </FormLabel>
                                    <Input
                                        name="userName"
                                        value={formData.userName}
                                        onChange={handleChange}
                                        onBlur={() => handleBlur('userName')}
                                        placeholder="Ej: Juan Pérez"
                                        {...inputStyles}
                                    />
                                    <FormErrorMessage fontSize="xs">{errors.userName}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={shouldShowError('email')}>
                                    <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="gray.700"
                                        mb={2}
                                    >
                                        Correo Electrónico
                                    </FormLabel>
                                    <Input
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        onBlur={() => handleBlur('email')}
                                        placeholder="correo@gmail.com"
                                        {...inputStyles}
                                    />
                                    <FormErrorMessage fontSize="xs">{errors.email}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={shouldShowError('password')}>
                                    <Flex justify="space-between" align="center">
                                        <FormLabel
                                            fontSize="sm"
                                            fontWeight="medium"
                                            color="gray.700"
                                            mb={2}
                                        >
                                            Contraseña
                                        </FormLabel>
                                        <Text fontSize="xs" color="gray.500">La contraseña debe tener al menos 8 caracteres</Text>
                                    </Flex>

                                    <InputGroup>
                                        <Input
                                            name="password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={formData.password}
                                            onChange={handleChange}
                                            onBlur={() => handleBlur('password')}
                                            placeholder="Ingresa tu contraseña"
                                            {...inputStyles}
                                        />
                                        <InputRightElement h="40px">
                                            <IconButton
                                                aria-label={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                                                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                                onClick={() => setShowPassword(!showPassword)}
                                                variant="ghost"
                                                size="sm"
                                                color="gray.500"
                                                _hover={{ color: 'gray.700' }}
                                            />
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage fontSize="xs">{errors.password}</FormErrorMessage>
                                </FormControl>
                            </VStack>
                        </Box>
                    </VStack>
                </CardBody>
            </Card>
        </Box>
    );
}; 