export const selectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    minHeight: '32px',
    maxWidth: '350px',
    maxHeight: '32px',
    borderColor: state.selectProps.isInvalid ? 'red.100' : base.borderColor,
    _hover: { borderColor: state.selectProps.isInvalid ? 'red.200' : 'gray.300' },
  }),
  valueContainer: (base: any) => ({
    ...base,
    fontSize: '0.82rem',
    padding: '0 5px',
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: '0.82rem',
    maxWidth: '350px',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: '0 3px',
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: '0 0',
  }),
};
