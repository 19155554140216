import { ErpType, RetentionData, InvoiceTypeOption, PaymentMethodOption, CostCenterOption, SellerOption } from "@/types/erpShared.types";
import { fetchBizErp, getBizErpInvoiceTypes, getBizErpPaymentMethods, getBizErpCostCenters, getAccounts, getBusinessErpTaxes, getRetentionsBySupplier } from "../invoices.services";
import { fetchSiigoSellers } from "../siigo.services";
import { ErpHandler } from "./erpHandler.interface";
import { Invoice } from "@/types/invoice.types";

export class SiigoHandler implements ErpHandler {
  private withholdings: Record<string, any> = {};

  constructor() {
    // Load initial data if necessary
    this.fetchWithholdings();
  }
  erp: ErpType = 'siigo';

  private async fetchWithholdings() {
    try {
      const erpData = await fetchBizErp();
      if (erpData?.withholdings) {
        this.withholdings = erpData.withholdings;
      }
    } catch (error) {
      console.error('Error fetching withholdings:', error);
    }
  }

  async getInvoiceTypes(invoice?: Invoice): Promise<InvoiceTypeOption[]> {
    try {
      const response = await getBizErpInvoiceTypes();
      if (!response?.invoice_types) return [];

      // Filter active invoice types
      const activeInvoiceTypes = response.invoice_types.filter((type) => type.active);

      // Format for UI
      return activeInvoiceTypes.map((invoiceType) => ({
        ...invoiceType,
        label: `${invoiceType.type} - ${invoiceType.code} - ${invoiceType.name}`,
        value: invoiceType.id
      }));
    } catch (error) {
      console.error('Error fetching invoice types:', error);
      return [];
    }
  }

  async getPaymentMethods(documentType = 'FC'): Promise<PaymentMethodOption[]> {
    try {
      const response = await getBizErpPaymentMethods(documentType);
      if (!response?.payment_methods) return [];

      return response.payment_methods.map((method: any) => ({
        ...method,
        label: method.name,
        value: method.id
      }));
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      return [];
    }
  }

  async getCostCenters(): Promise<CostCenterOption[]> {
    try {
      const response = await getBizErpCostCenters();
      if (!response?.cost_centers) return [];

      return response.cost_centers
        .filter((center: any) => center.active)
        .map((center: any) => ({
          ...center,
          label: `${center.name} - ${center.code}`,
          value: center.id
        }));
    } catch (error) {
      console.error('Error fetching cost centers:', error);
      return [];
    }
  }

  async getSellers(): Promise<SellerOption[]> {
    try {
      const response = await fetchSiigoSellers();
      if (!response) return [];

      return response.map((seller: any) => ({
        ...seller,
        label: seller.name,
        value: seller.id
      }));
    } catch (error) {
      console.error('Error fetching sellers:', error);
      return [];
    }
  }

  async getAccounts(): Promise<any> {
    try {
      // Convert type to one of the values expected by the API
      const response = await getAccounts();
      return response?.accounts || {};
    } catch (error) {
      console.error(`Error fetching accounts:`, error);
      return {};
    }
  }

  async getRetentions(supplierId?: string): Promise<any> {
    try {
      let suggestedRetentions: RetentionData[] = [];

      if (supplierId) {
        const supplierResponse = await getRetentionsBySupplier(supplierId);
        if (supplierResponse?.retentions) {
          suggestedRetentions = Object.entries(supplierResponse.retentions).map(([name, retention]: [string, any]) => ({
            label: name,
            value: retention.id,
            type: retention.type,
            percentage: retention.percentage,
            account: retention.account || ''
          }));
        }
      }

      const response = await getBusinessErpTaxes(true);
      if (!response?.taxes) return { reteICA: [], reteIVA: [], retefuente: [] };

      const allRetentions = response.taxes.map((tax: any) => {
        const withholding = Object.values(this.withholdings).find((w: any) => w.id === tax.id);
        return {
          label: tax.name,
          value: tax.id,
          type: tax.type,
          percentage: tax.percentage,
          account: withholding?.account || ''
        };
      });

      // Prioritize suggested retentions and remove duplicates
      const uniqueRetentions = allRetentions.filter(retention =>
        !suggestedRetentions.some(suggested => suggested.value === retention.value)
      );

      const retentions = [...suggestedRetentions, ...uniqueRetentions];

      return {
        reteICA: retentions.filter(r => r.type === 'ReteICA'),
        reteIVA: retentions.filter(r => r.type === 'ReteIVA'),
        retefuente: retentions.filter(r => r.type === 'Retefuente'),
        suggested: {
          reteICA: suggestedRetentions.find(r => r.type === 'ReteICA') || null,
          reteIVA: suggestedRetentions.find(r => r.type === 'ReteIVA') || null,
          retefuente: suggestedRetentions.find(r => r.type === 'Retefuente') || null
        }
      };
    } catch (error) {
      console.error('Error fetching retentions:', error);
      return { reteICA: [], reteIVA: [], retefuente: [] };
    }
  }
} 