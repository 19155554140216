export type ERPSystem = '' | 'siigo-contador' | 'siigo-no-contador' | 'helisa' | 'world-office' | 'allegra' | 'siigo-pyme';

export interface ERPCredentials {
    platformUsername?: string;
    platformPassword?: string;
    apiUsername?: string;
    apiKey?: string;
    username?: string;
    password?: string;
    companyId?: string;
    accountsFile?: string;
    costCentersFile?: string;
    invoiceTypesFile?: string;
    paymentMethodsFile?: string;
}

export interface ERPConfig {
    system: ERPSystem;
    credentials: ERPCredentials;
}

export const AVAILABLE_ERPS: ERPSystem[] = ['siigo-contador', 'siigo-no-contador', 'helisa', 'world-office', 'siigo-pyme'];
export const COMING_SOON_ERPS: ERPSystem[] = ['allegra']; 