import React from "react";
import { Badge, Text, Tooltip } from "@chakra-ui/react";

interface StatusBadgeProps {
    isReady: boolean;
    pendingCount?: number;
    pendingItems?: string[];
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ isReady, pendingCount, pendingItems }) => {
    const getColors = () => {
        if (isReady) {
            return {
                bg: 'green.100',
                color: 'green.700',
                icon: '✓'
            };
        }
        return {
            bg: 'orange.100',
            color: 'orange.700',
            icon: '!'
        };
    };

    const { bg, color, icon } = getColors();

    if (!isReady && pendingItems) {
        return (
            <Tooltip
                label={pendingItems.length + " pendientes"}
                hasArrow
                placement="top"
            >
                <Badge
                    px={3}
                    py={1}
                    borderRadius="full"
                    bg={bg}
                    color={color}
                    fontSize="xs"
                    display="inline-flex"
                    textAlign="center"
                    alignItems="center"
                    gap={1}
                >
                    <Text textAlign="center" as="span" fontWeight="bold">{icon}</Text>
                    Falta
                </Badge>
            </Tooltip>
        );
    }

    return (
        <Badge
            px={3}
            py={1}
            borderRadius="full"
            bg={bg}
            color={color}
            fontSize="xs"
            display="inline-flex"
            alignItems="center"
            gap={1}
        >
            <Text as="span" fontWeight="bold">{icon}</Text>
            Listo
        </Badge>
    );
}; 