import React, { useEffect, useState } from "react";
import { FaFileUpload } from "react-icons/fa";
import { Box, Flex, Icon, Input, Text } from "@chakra-ui/react";
import { useDianContext } from "@/context/dianContext";
import { fetchComparison } from "@/services/dian.services";
import { DianComparisonResponse } from "@/types/dian.types";

const UploadDianFile = () => {
    const { setDianFile, setResults, setShowResults, setIsLoading } = useDianContext()

    const [dianFileName, setDianFileName] = useState("");

    const getData = (file: File) => {

        if (file) {
            setIsLoading(true)
            fetchComparison(file).then((data: DianComparisonResponse | undefined) => {
                if (data) {
                    console.log("data", data)
                    setResults(data)
                }
                setIsLoading(false)
            }).catch((error) => {
                console.log("error", error)
                setIsLoading(false)
            })
            setShowResults(true)
        }
    }

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDianFile(null);
        setResults([]);
        setShowResults(false);
        setIsLoading(false);

        if (e.target.files) {
            const file = e.target.files[0];
            if (!file) return
            const fileType = file.type;

            if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType === "application/vnd.ms-excel") {
                setDianFileName(file.name);
                setDianFile(file)
                getData(file)
            } else {
                setDianFileName("Tipo de archivo no permitido")
            }
        }
    };
    return (
        <Flex width={"100%"} justifyContent={"center"}>
            <label style={{ width: "100%" }}>
                <Box
                    border="2px dashed"
                    borderColor="gray.200"
                    bg={"gray.100"}
                    borderRadius="md"
                    p={10} // Altura fija más pequeña
                    width={"100%"}
                    textAlign="center"
                >
                    <Flex direction="column" alignItems="center">
                        <Icon as={FaFileUpload} boxSize="30px" color="gray.400" />
                        <Text mt={4} color={"gray.500"}>Haz click aquí para subir un archivo</Text>
                        <Text fontSize={"xs"} color={"gray.500"}>El archivo debe ser un archivo Excel</Text>
                        <Input
                            type="file"
                            id={"dian"}
                            onChange={handleFileUpload}
                            display="none"
                        />
                    </Flex>
                </Box>
                <Box mt={"5px"} width={"100%"} overflow="hidden" textOverflow="ellipsis" whiteSpace="wrap"
                    textAlign={"center"}>
                    <Text fontSize="sm" fontWeight="bold" color="blue.500">{dianFileName}</Text>
                </Box>
            </label>
        </Flex>
    )
}

export default UploadDianFile;