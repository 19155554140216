import { useInvoicesContext } from "@/context/invoices/invoicesContext";
import { Badge, HStack, Icon, Tab, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";

import { TabList } from "@chakra-ui/react";
import { FaFileInvoiceDollar, FaFileInvoice } from "react-icons/fa";

export function InvoicesTabList() {
    const { tabIndex } = useInvoicesContext();
    return (
        <TabList
            width="100%"
            mb="0"
            gap={6}
            px={4}
            py={2}
            bg="white"
            borderRadius="xl"
            justifyContent="center"
            mx="auto"
            position="relative"
        >
            <Tab
                fontSize="sm"
                fontWeight="bold"
                py={1.5}
                position="relative"
                _selected={{
                    color: "white",
                    bg: "blue.500",
                    transform: "translateY(-2px) scale(1.02)",
                    _hover: { bg: "blue.500" }
                }}
                _hover={{
                    bg: "gray.200",
                    borderColor: "gray.300",
                    transform: "translateY(-1px)",
                    cursor: "pointer"
                }}
                transition="all 0.3s"
                borderRadius="lg"
                px={4}
                width="auto"
                minW="180px"
            >
                <HStack spacing={2} align="center" justify="center">
                    <Icon as={FaFileInvoiceDollar} boxSize={4} />
                    <Text>Facturas de Compra</Text>

                </HStack>
            </Tab>
            <Tab
                fontSize="sm"
                fontWeight="bold"
                py={1.5}
                position="relative"
                bg="gray.100"
                borderWidth="1px"
                borderColor="gray.200"
                color="gray.500"
                boxShadow="sm"
                cursor="not-allowed"
                opacity={0.7}
                _hover={{}}
                transition="all 0.3s"
                borderRadius="lg"
                px={4}
                width="auto"
                minW="180px"
                isDisabled={true}
            >
                <HStack spacing={2} align="center" justify="center">
                    <Icon as={FaFileInvoice} boxSize={4} />
                    <Text>Facturas de Venta</Text>
                    <Badge colorScheme="purple" fontSize="2xs">Próximamente</Badge>
                </HStack>
            </Tab>
        </TabList>
    )
}
