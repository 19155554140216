import { useState, useEffect, useCallback } from 'react';
import { ErpHandler } from '@/services/erp/erpHandler.interface';
import { SellerOption } from '@/types/erpShared.types';
import { Invoice } from '@/types/invoice.types';

export const useSellers = (
  erpService: ErpHandler | null,
  selectedInvoices: Invoice[],
  setSelectedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>
) => {
  const [sellers, setSellers] = useState<SellerOption[]>([]);
  const [selectedSeller, setSelectedSeller] = useState<SellerOption | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Load sellers
  useEffect(() => {
    const loadSellers = async () => {
      if (!erpService) return;

      setIsLoading(true);
      setError(null);

      try {
        const sellersList = await erpService.getSellers();
        setSellers(sellersList);

        // Initialize seller if it exists in the invoice
        if (selectedInvoices[0]?.seller && sellersList.length > 0) {
          const sellerId = typeof selectedInvoices[0]?.seller === 'number'
            ? selectedInvoices[0]?.seller
            : Number(selectedInvoices[0]?.seller);

          const seller = sellersList.find((s: SellerOption) =>
            s.id === sellerId ||
            s.value === sellerId ||
            s.value === selectedInvoices[0]?.seller ||
            String(s.value) === String(selectedInvoices[0]?.seller)
          );

          if (seller) {
            setSelectedSeller(seller);
          } else {
          }
        }
      } catch (err) {
        console.error('Error loading sellers:', err);
        setError('Error loading sellers');
      } finally {
        setIsLoading(false);
      }
    };

    loadSellers();
  }, [erpService]);

  const updateSelectedInvoices = useCallback((seller: SellerOption) => {
    if (selectedInvoices.length > 0) {
      // Update all the selected invoices
      setSelectedInvoices(prevInvoices => {
        const updatedInvoices = prevInvoices.map(invoice => ({
          ...invoice,
          seller: Number(seller.value)
        }));
        return updatedInvoices;
      });
    }
  }, [selectedInvoices.length, setSelectedInvoices]);

  // Handle seller change
  const handleSellerChange = useCallback((option: SellerOption) => {
    setSelectedSeller(option);

    // Call the callback to update the invoice if it is available
    updateSelectedInvoices(option);
    
  }, [updateSelectedInvoices]);

  // Modified version of setSelectedSeller that also notifies changes
  const updateSelectedSeller = useCallback((option: SellerOption | null) => {
    setSelectedSeller(option);

    // If there is a value and the callback exists, notify the change
    if (option) {
      updateSelectedInvoices(option);
    }
  }, [updateSelectedInvoices]);

  return {
    sellers,
    selectedSeller,
    isLoading,
    error,
    handleSellerChange,
    setSelectedSeller: updateSelectedSeller
  };
}; 