import { BizServices } from "@/types/invoice.types";
import { StoreSelection } from "@/types/invoiceDetail.types";
import { useCallback, useEffect, useState } from "react";
import { Invoice } from "@/types/invoice.types";
import { fetchStoreIds, getPurchaseOrderMatches } from "@/services/invoices.services";
import { PurchaseOrder } from "@/types/purchaseOrder.types";

/*
  useStores Hook
  -------------
  This hook manages store-related data and selections for invoices.
  It fetches store IDs and purchase orders for each invoice, then initializes store selections.
  If only one store is available, it auto-selects that store for all invoices.
  It also provides a callback to handle changes in store selection which updates the invoice's store_id.
*/
export const useStores = (services: BizServices | undefined, invoices: Invoice[], setInvoices: (invoices: Invoice[]) => void, setAvailableStores: (stores: number | undefined) => void) => {
    // Initialize state variables: loading state, store IDs, store selections, and purchase orders
    const [isLoadingStores, setIsLoadingStores] = useState(false);
    const [storeIds, setStoreIds] = useState<Record<string, string>>({});
    const [storeSelections, setStoreSelections] = useState<StoreSelection>({});
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);

    // Load store and purchase order data when services or invoices change
    useEffect(() => {
        if (!services?.erp) return;

        const loadData = async () => {
            // Set loading state to true before starting data fetch
            setIsLoadingStores(true);
            try {
                // Fetch store IDs from the server and update state with the received ids
                const storeIdsResponse = await fetchStoreIds();
                setStoreIds(storeIdsResponse.store_ids);
                setAvailableStores(Object.keys(storeIdsResponse.store_ids).length);


                // Fetch purchase orders for each invoice concurrently
                const purchaseOrdersPromises = invoices.map(invoice =>
                    getPurchaseOrderMatches(invoice.invoice_cufe)
                );
                const allPurchaseOrders = await Promise.all(purchaseOrdersPromises);

                // Combine all fetched purchase orders into a map linking invoice IDs to their corresponding purchase order
                const purchaseOrdersMap = allPurchaseOrders.reduce((map, orders, index) => {
                    if (orders && orders.length > 0) {
                        map[invoices[index].id] = orders[0];
                    }
                    return map;
                }, {} as Record<string, any>);

                setPurchaseOrders(Object.values(purchaseOrdersMap));

                // Prepare initial store selections based on available store IDs and purchase order data
                const initialSelections: StoreSelection = {};

                // If there's only one store, automatically select it for all invoices
                if (Object.values(storeIdsResponse.store_ids).length === 1) {
                    const onlyStoreName = Object.values(storeIdsResponse.store_ids)[0];
                    invoices.forEach(invoice => {
                        initialSelections[invoice.id] = onlyStoreName;
                    });
                } else {
                    // For multiple stores, assign store selection: prefer invoice's store_id if exists; otherwise, use store_id from matching purchase order.
                    invoices.forEach(invoice => {
                        // First try to use the invoice's store_id
                        if (invoice.store_id) {
                            initialSelections[invoice.id] = invoice.store_id;
                        }
                        // If no store_id but we have a purchase order match for this invoice, use that
                        else if (purchaseOrdersMap[invoice.id]) {
                            initialSelections[invoice.id] = purchaseOrdersMap[invoice.id].store_id;
                        }
                    });
                }
                setStoreSelections(initialSelections);
            } catch (error) {
                console.error('Failed to load initial data:', error);
            } finally {
                // Reset loading state after data fetching completes
                setIsLoadingStores(false);
            }
        };

        loadData();
    }, [services]);

    // Callback to update store selection for a specific invoice and update the corresponding invoice's store_id
    const handleStoreSelection = useCallback((invoiceId: string, storeId: string) => {
        setStoreSelections(prev => {
            const newSelections = {
                ...prev,
                [invoiceId]: storeId
            };

            // Update the corresponding invoice in the selected invoices list
            const updatedInvoices = invoices.map(invoice => {
                if (invoice.id === invoiceId) {
                    return {
                        ...invoice,
                        store_id: storeId
                    };
                }
                return invoice;
            });
            // Update all selected invoices
            setInvoices(updatedInvoices);

            return newSelections;
        });
    }, [invoices]);

    return { isLoadingStores, storeIds, storeSelections, purchaseOrders, handleStoreSelection };
};