import React from 'react';
import {
    Box,
    Text,
    FormControl,
    FormLabel,
    Input,
    Stack,
    FormHelperText,
} from '@chakra-ui/react';
import { VideoTutorial } from '../VideoTutorial';

interface CredentialField {
    label: string;
    key: string;
    type?: string;
    placeholder?: string;
    isRequired?: boolean;
}

interface CredentialsSectionProps {
    title: string;
    description?: string;
    fields: CredentialField[];
    values: Record<string, string>;
    onChange: (field: string, value: string) => void;
    tutorial?: {
        title: string;
        link: string;
        videoUrl: string;
        isLoading: boolean;
        onVideoLoaded: () => void;
    };
}

export const CredentialsSection: React.FC<CredentialsSectionProps> = ({
    title,
    description,
    fields,
    values,
    onChange,
    tutorial,
}) => {
    return (
        <Box bg="gray.50" p={6} borderRadius="lg">
            <Text fontSize="lg" fontWeight="medium" mb={3}>
                {title}
            </Text>
            {description && (
                <Text fontSize="sm" color="gray.600" mb={4}>
                    {description}
                </Text>
            )}
            <Stack spacing={4}>
                {fields.map((field) => (
                    <FormControl key={field.key} isRequired={field.isRequired}>
                        <FormLabel>{field.label}</FormLabel>
                        <Input
                            type={field.type || 'text'}
                            placeholder={field.placeholder || `${field.label}`}
                            value={values[field.key] || ''}
                            onChange={(e) => onChange(field.key, e.target.value)}
                        />
                        {field.type === 'password' && (
                            <FormHelperText>
                                Esta información se maneja de forma segura y encriptada
                            </FormHelperText>
                        )}
                    </FormControl>
                ))}

                {tutorial && (
                    <VideoTutorial
                        title={tutorial.title}
                        tutorialLink={tutorial.link}
                        videoUrl={tutorial.videoUrl}
                        isLoading={tutorial.isLoading}
                        onVideoLoaded={tutorial.onVideoLoaded}
                    />
                )}
            </Stack>
        </Box>
    );
}; 