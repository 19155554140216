import { useEffect, useState } from 'react';
import {
    VStack,
    Heading,
    Text,
    Box,
    Icon,
    useTheme,
    keyframes,
    Divider,
    Code,
    Alert,
    AlertIcon,
    Flex,
    IconButton,
    useClipboard,
    useToast,
} from '@chakra-ui/react';
import ReactConfetti from 'react-confetti';
import { MdCheck, MdContentCopy } from 'react-icons/md';
import { useOnboarding } from '@/context/onboardingContext';

const pulse = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
`;

interface CongratulationsProps {
    stepData: any;
}

export const Congratulations: React.FC<CongratulationsProps> = ({ stepData }) => {
    // Get all steps data from context
    const { stepsData, currentStep, currentStepIndex } = useOnboarding();
    const theme = useTheme();
    const toast = useToast();
    const [showConfetti, setShowConfetti] = useState(true);
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    
    // For debugging - try to find the user step data
    // Check common possible step IDs and field names
    const possibleStepIds = ['user-details', 'userDetails', 'user_details', 'user'];
    const possibleUserFields = ['email', 'userName', 'username', 'user_name', 'correo', 'nombre', 'password', 'contraseña', 'clave'];
    
    // Log all available data to help debug
    console.log("All steps data:", stepsData);
    console.log("Current prop stepData:", stepData);
    console.log("Current step:", currentStep);
    console.log("Current step index:", currentStepIndex);
    
    // Try to find user data in all possible step IDs
    let foundUserStep = null;
    let userEmail = null;
    let userPassword = null;

    // Find which step contains the user data
    for (const id of possibleStepIds) {
        if (stepsData[id]) {
            console.log(`Found data in step '${id}':`, stepsData[id]);
            foundUserStep = id;
            
            // Try to find email field
            for (const field of possibleUserFields) {
                if (stepsData[id][field] && !userEmail && field.toLowerCase().includes('email')) {
                    userEmail = stepsData[id][field];
                }
                
                // Try to find password field
                if (stepsData[id][field] && !userPassword && 
                   (field.toLowerCase().includes('password') || 
                    field.toLowerCase().includes('contraseña') || 
                    field.toLowerCase().includes('clave'))) {
                    userPassword = stepsData[id][field];
                }
            }
            
            // If we found a step but couldn't identify fields, log all its fields
            if (!userEmail || !userPassword) {
                console.log(`Fields in step '${id}':`, Object.keys(stepsData[id]));
            }
        }
    }
    
    // Fallback to checking all steps for user data fields
    if (!userEmail || !userPassword) {
        console.log("Trying to find user data in all steps...");
        for (const stepId in stepsData) {
            const stepData = stepsData[stepId];
            if (stepData && typeof stepData === 'object') {
                for (const field in stepData) {
                    const value = stepData[field];
                    if (value && typeof value === 'string') {
                        // Try to identify email fields
                        if (field.toLowerCase().includes('email') || 
                            (value.includes('@') && value.includes('.'))) {
                            console.log(`Possible email found in step '${stepId}', field '${field}':`, value);
                            if (!userEmail) userEmail = value;
                        }
                        
                        // Try to identify password fields
                        if (field.toLowerCase().includes('password') || 
                            field.toLowerCase().includes('contraseña') || 
                            field.toLowerCase().includes('clave')) {
                            console.log(`Possible password found in step '${stepId}', field '${field}':`, value);
                            if (!userPassword) userPassword = value;
                        }
                    }
                }
            }
        }
    }
    
    // Final fallback
    userEmail = userEmail || 'No disponible';
    userPassword = userPassword || 'No disponible';

    // Copy functionality
    const handleCopy = (text: string, fieldName: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast({
                    title: "¡Copiado!",
                    description: `${fieldName} copiado al portapapeles`,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                    position: "top",
                });
            })
            .catch(err => {
                console.error('Error al copiar', err);
                toast({
                    title: "Error",
                    description: "No se pudo copiar el texto",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                    position: "top",
                });
            });
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        
        // Stop confetti after 5 seconds
        const timer = setTimeout(() => {
            setShowConfetti(false);
        }, 5000);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timer);
        };
    }, []);

    return (
        <Box 
            position="relative" 
            h="full" 
            w="full" 
            overflow="hidden"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {showConfetti && (
                <ReactConfetti
                    width={windowDimensions.width}
                    height={windowDimensions.height}
                    colors={[
                        theme.colors.green[500],
                        theme.colors.blue[500],
                        theme.colors.purple[500],
                        theme.colors.yellow[500],
                        theme.colors.pink[500],
                    ]}
                    recycle={true}
                    numberOfPieces={200}
                />
            )}

            <VStack
                spacing={8}
                maxW="600px"
                textAlign="center"
                px={6}
            >
                <Box
                    animation={`${pulse} 2s infinite`}
                    bg="blue.100"
                    w="100px"
                    h="100px"
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Icon
                        as={MdCheck}
                        w={12}
                        h={12}
                        color="blue.500"
                    />
                </Box>

                <VStack spacing={4}>
                    <Heading
                        size="2xl"
                        bgGradient="linear(to-r, blue.400, blue.600)"
                        bgClip="text"
                    >
                        ¡Bienvenido a Cifrato!
                    </Heading>
                    <Text
                        fontSize="xl"
                        color="gray.600"
                    >
                        Has completado exitosamente la configuración de tu cuenta
                    </Text>
                </VStack>

                <VStack
                    spacing={6}
                    bg="white"
                    p={8}
                    borderRadius="xl"
                    boxShadow="lg"
                    border="1px solid"
                    borderColor="gray.100"
                    width="100%"
                >
                    <Text color="gray.700" fontWeight="medium">
                        Todo está listo para que comiences a usar Cifrato. Puedes acceder a la plataforma usando:
                    </Text>
                    <VStack
                        spacing={4}
                        bg="gray.50"
                        p={6}
                        borderRadius="md"
                        w="full"
                        align="start"
                    >
                        <Box width="100%">
                            <Text fontSize="sm" color="gray.500" mb={1}>Email:</Text>
                            <Flex width="100%" align="center">
                                <Text fontWeight="bold" color="gray.700" flex="1">{userEmail}</Text>
                                <IconButton
                                    aria-label="Copiar email"
                                    icon={<MdContentCopy />}
                                    size="sm"
                                    colorScheme="blue"
                                    variant="ghost"
                                    onClick={() => handleCopy(userEmail, "Email")}
                                />
                            </Flex>
                        </Box>
                        
                        <Divider borderColor="gray.200" />
                        
                        <Box width="100%">
                            <Text fontSize="sm" color="gray.500" mb={1}>Contraseña:</Text>
                            <Flex width="100%" align="center">
                                <Text fontWeight="bold" color="gray.700" flex="1">{userPassword}</Text>
                                <IconButton
                                    aria-label="Copiar contraseña"
                                    icon={<MdContentCopy />}
                                    size="sm"
                                    colorScheme="blue"
                                    variant="ghost"
                                    onClick={() => handleCopy(userPassword, "Contraseña")}
                                />
                            </Flex>
                        </Box>
                        
                        <Box width="100%" mt={2}>
                            <Text fontWeight="medium" color="gray.600" fontSize="sm">
                                Guarda esta información para acceder a tu cuenta
                            </Text>
                        </Box>
                    </VStack>
                    
                    <Text color="gray.500" fontSize="sm" mt={2}>
                        Se ha enviado un correo de confirmación a tu dirección de email.
                    </Text>
                </VStack>
            </VStack>
        </Box>
    );
};
