import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { fetchBizServices } from '@/services/invoices.services';
import { BizServices } from '@/types/invoice.types';

export const useServices = () => {
  const toast = useToast();
  const [services, setServices] = useState<BizServices | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const servicesData = await fetchBizServices();
        if (servicesData) {
          setServices(servicesData.services);
        }
      } catch (error) {
        console.error("Error loading services:", error);
        toast({
          title: "Error al cargar servicios",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    loadServices();
  }, []);

  return {
    services,
    loading,
    setServices,
  };
}; 