import { ErpType, RetentionData, SellerOption, InvoiceTypeOption, PaymentMethodOption, CostCenterOption } from "@/types/erpShared.types";
import { getBizErpInvoiceTypes, getAccounts, getBizCostCenters, getBizRetentions, getRetentionsBySupplier, getHelisaPaymentMethods } from "../invoices.services";
import { ErpHandler } from "./erpHandler.interface";
import { Invoice } from "@/types/invoice.types";

export class HelisaHandler implements ErpHandler {
  erp: ErpType = 'helisa';


  getSellers(): Promise<SellerOption[]> {
    return Promise.resolve([]);
  }

  async getInvoiceTypes(invoice?: Invoice): Promise<InvoiceTypeOption[]> {
    try {
      const response = await getBizErpInvoiceTypes();
      if (!response?.invoice_types) return [];
      
      // Filter active invoice types and convert object to array
      const activeInvoiceTypes = Object.entries(response.invoice_types)
        .filter(([_, type]: [string, any]) => type.active)
        .map(([key, type]: [string, any]) => ({
          ...type,
          id: key,
          code: key,
          type: type.value || '',
          name: type.name
        }));
      
      // Format for UI similar to Siigo
      return activeInvoiceTypes.map((invoiceType: InvoiceTypeOption, index: number) => ({
        ...invoiceType,
        label: `${invoiceType.type} - ${invoiceType.name}`,
        value: index
      }));
    } catch (error) {
      console.error('Error fetching Helisa invoice types:', error);
      return [];
    }
  }

  async getPaymentMethods(): Promise<PaymentMethodOption[]> {
    try {
      const response = await getHelisaPaymentMethods();
      if (!response) return [];
      
      // Transform the payment methods object into an array of options
      return Object.entries(response).flatMap(([key, paymentMethodsObj]) => {
        if (typeof paymentMethodsObj === 'object') {
          // If it is an object with multiple payment methods
          return Object.entries(paymentMethodsObj).map(([id, name]) => ({
            id,
            value: id,
            label: name,
            name
          }));
        } else {
          // If it is a simple value
          return [{
            id: key,
            value: key,
            label: paymentMethodsObj,
            name: paymentMethodsObj
          }]; // This is bullshit, but it works
        }
      });
    } catch (error) {
      console.error('Error fetching Helisa payment methods:', error);
      return [];
    }
  }

  async getCostCenters(): Promise<CostCenterOption[]> {
    try {
      const response = await getBizCostCenters();
      if (!response?.cost_centers) return [];
      
      return Object.entries(response.cost_centers)
        .map(([code, data]: [string, any]) => ({
          label: `${code} - ${data.name}`,
          value: code,
          ...data
        }));
    } catch (error) {
      console.error('Error fetching Helisa cost centers:', error);
      return [];
    }
  }

  async getAccounts(type?: string): Promise<any> {
    try {
      const response = await getAccounts();
      return response?.accounts || {};
    } catch (error) {
      console.error('Error fetching Helisa accounts:', error);
      return {};
    }
  }

  async getRetentions(supplierId?: string): Promise<any> {
    try {
      let suggestedRetentions: RetentionData[] = [];
      
      if (supplierId) {
        const supplierResponse = await getRetentionsBySupplier(supplierId);
        if (supplierResponse?.retentions) {
          suggestedRetentions = Object.entries(supplierResponse.retentions).map(([name, retention]: [string, any]) => ({
            label: name,
            value: Number(retention.id),
            type: retention.type,
            percentage: retention.percentage,
            account: retention.account || '',
            id: String(retention.id)
          }));
        }
      }
      
      const response = await getBizRetentions();
      if (!response?.retentions) return { reteICA: [], reteIVA: [], retefuente: [] };
      
      const allRetentions = Object.entries(response.retentions).map(([name, data]: [string, any]) => ({
        label: name,
        value: Number(data.id),
        type: data.type,
        percentage: data.percentage,
        account: data.account,
        id: String(data.id)
      }));
      
      // Filter to avoid duplicates, prioritizing the suggested ones
      const uniqueRetentions = allRetentions.filter(retention =>
        !suggestedRetentions.some(suggested => suggested.value === retention.value)
      );
      
      // Combine with the suggested ones first
      const retentions = [...suggestedRetentions, ...uniqueRetentions];
      
      return {
        reteICA: retentions.filter(r => r.type === 'ReteICA'),
        reteIVA: retentions.filter(r => r.type === 'ReteIVA'),
        retefuente: retentions.filter(r => r.type === 'Retefuente'),
        suggested: {
          reteICA: suggestedRetentions.find(r => r.type === 'ReteICA') || null,
          reteIVA: suggestedRetentions.find(r => r.type === 'ReteIVA') || null,
          retefuente: suggestedRetentions.find(r => r.type === 'Retefuente') || null
        }
      };
    } catch (error) {
      console.error('Error fetching Helisa retentions:', error);
      return { reteICA: [], reteIVA: [], retefuente: [] };
    }
  }
} 