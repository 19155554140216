import React, { createContext, useContext, ReactNode, useState, useMemo, useEffect, useCallback } from 'react';
import { BizServices, Invoice, InvoiceError } from '@/types/invoice.types';
import { UploadTargetType } from '@/types/erpShared.types';
import { useToast } from '@chakra-ui/react';
import {
  getCostCenterAsNumber
} from '../../utils/invoiceDetails/utils';
import {
  checkSuccessfullyUploaded,
  calculateMissingFormFields,
  calculateInvoiceItemErrors
} from '../../utils/invoiceDetails/validation';
import { useServices } from '@/hooks/invoiceDetails/useServices';
import { useCostCenterByItem } from '@/hooks/erpForm/useCostCenterByItem';

export interface InvoicesHandlerContextProps {
  // Basic states
  invoices: Invoice[];
  setInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>;
  services: BizServices | undefined;
  setServices: (services: BizServices | undefined) => void;

  // Modal states
  isUploadModalOpen: boolean;
  setIsUploadModalOpen: (isOpen: boolean) => void;
  isPOSModalOpen: boolean;
  setIsPOSModalOpen: (isOpen: boolean) => void;
  isDianModalOpen: boolean;
  setIsDianModalOpen: (isOpen: boolean) => void;
  isErpLoading: boolean;
  setIsErpLoading: (isLoading: boolean) => void;

  // Upload target state
  uploadTarget: UploadTargetType;
  setUploadTarget: (target: UploadTargetType) => void;

  // Calculated states for validation
  invoicesWithErrors: InvoiceError[];
  hasErrors: boolean;
  hasMissingFields: boolean;
  missingFormFields: string[];
  areSuccessfullyUploaded: boolean;
  invoiceItemErrors: InvoiceError[];
  // Functions
  handleUploadClick: (target: 'intuipos' | 'siigo' | 'helisa') => void;
  handleDianClick: () => void;

  getCostCenterAsNumber: (invoice: Invoice) => number | undefined;
  useCostCenterPerItem: boolean;
  handleUseCostCenterPerItem: () => void;

  // Stores
  availableStores: number | undefined;
  setAvailableStores: (stores: number | undefined) => void;
}

const InvoicesHandlerContext = createContext<InvoicesHandlerContextProps | undefined>(undefined);

export const useInvoicesHandler = (): InvoicesHandlerContextProps => {
  const context = useContext(InvoicesHandlerContext);
  if (!context) {
    throw new Error('useInvoicesHandler must be used within an InvoicesHandlerProvider');
  }
  return context;
};

export const InvoicesHandlerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Basic states
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);

  // Modal states
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isPOSModalOpen, setIsPOSModalOpen] = useState(false);
  const [isDianModalOpen, setIsDianModalOpen] = useState(false);
  const [isErpLoading, setIsErpLoading] = useState(false);
  const [availableStores, setAvailableStores] = useState<number | undefined>(undefined);

  // If this is in the erpFormContext, it stops working
  const { useCostCenterPerItem, handleUseCostCenterPerItem } = useCostCenterByItem(selectedInvoices, setSelectedInvoices);

  // Upload target
  const [uploadTarget, setUploadTarget] = useState<UploadTargetType>('');

  // Validation states
  const [invoicesWithErrors, setInvoicesWithErrors] = useState<InvoiceError[]>([]);

  // Toast for notifications
  const toast = useToast();

  // Calculate if the invoices have been uploaded successfully
  const areSuccessfullyUploaded = checkSuccessfullyUploaded(selectedInvoices, uploadTarget);

  // Calculate missing fields with useMemo for all invoices
  const missingFormFields = useMemo(
    () => calculateMissingFormFields(selectedInvoices, uploadTarget, useCostCenterPerItem, availableStores),
    [selectedInvoices, uploadTarget, useCostCenterPerItem, availableStores]
  );

  // Use useMemo to calculate item errors for all selected invoices
  const invoiceItemErrors = useMemo(
    () => calculateInvoiceItemErrors(selectedInvoices, uploadTarget, useCostCenterPerItem),
    [selectedInvoices, uploadTarget, useCostCenterPerItem]
  );

  // Calculate if there are errors or missing fields
  const hasErrors = invoicesWithErrors.length > 0;
  const hasMissingFields = missingFormFields.length > 0;

  // Update the error state only when the calculated errors change
  useEffect(() => {
    setInvoicesWithErrors(invoiceItemErrors);
  }, [invoiceItemErrors]);

  const servicesHook = useServices();

  // Handle click on upload button
  const handleUploadClick = (target: 'intuipos' | 'siigo' | 'helisa') => {
    if (selectedInvoices.length === 0) {
      toast({
        title: "No hay facturas seleccionadas",
        description: "Por favor, seleccione al menos una factura para subir",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
      return;
    }
    setUploadTarget(target);
    if (target === 'intuipos') {
      setIsPOSModalOpen(true);
    } else if (target === 'siigo' || target === 'helisa') {
      setIsUploadModalOpen(true);
    }
  };

  // Handle click on DIAN button
  const handleDianClick = useCallback(() => {
    if (selectedInvoices.length === 0) {
      toast({
        title: "No hay facturas seleccionadas",
        description: "Por favor, seleccione al menos una factura para generar el acuse de recibo",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
      return;
    }
    setIsDianModalOpen(true);
  }, [selectedInvoices, toast]);



  return (
    <InvoicesHandlerContext.Provider
      value={{
        // Basic states
        invoices: selectedInvoices,
        setInvoices: setSelectedInvoices,
        services: servicesHook.services,
        setServices: servicesHook.setServices,
        // Modal states
        isUploadModalOpen,
        setIsUploadModalOpen,
        isPOSModalOpen,
        setIsPOSModalOpen,
        isDianModalOpen,
        setIsDianModalOpen,
        isErpLoading,
        setIsErpLoading,

        // Upload target state
        uploadTarget,
        setUploadTarget,

        // Calculated states for validation
        invoicesWithErrors,
        hasErrors,
        hasMissingFields,
        missingFormFields,
        areSuccessfullyUploaded,
        invoiceItemErrors,
        // Functions
        handleUploadClick,
        handleDianClick,
        getCostCenterAsNumber,
        useCostCenterPerItem,
        handleUseCostCenterPerItem,

        // Stores
        availableStores,
        setAvailableStores
      }}
    >
      {children}
    </InvoicesHandlerContext.Provider>
  );
};

