import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Tr,
    Thead,
    Table,
    Tbody,
} from "@chakra-ui/react";
import { ApplyConfigModal } from '../ApplyConfigModal';
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { ERPTableHeader } from "./erp/ERPTableHeader";
import { POSTableHeader } from "./pos/POSTableHeader";
import { ProductsTableBody } from "./ProductsTableBody";

interface ProductsTableProps {
    activeTab: 'pos' | 'erp';
}

export const ProductsTable: React.FC<ProductsTableProps> = ({ activeTab }) => {
    const [isApplyConfigModalOpen, setIsApplyConfigModalOpen] = useState(false);
    const [sourceItemIndex, setSourceItemIndex] = useState<number>(-1);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [editableAccounts, setEditableAccounts] = useState<{ [key: string]: boolean }>({});
    const topScrollRef = useRef<HTMLDivElement>(null);
    const bottomScrollRef = useRef<HTMLDivElement>(null);

    const {
        invoice,
        setInvoice,
        services,
    } = useInvoiceDetails();

    const handleApplyConfig = (index: number) => {
        setSourceItemIndex(index);
        const allItemsExceptSource = invoice.items
            .map((_, idx) => idx)
            .filter(idx => idx !== index);
        setSelectedItems(allItemsExceptSource);
        setIsApplyConfigModalOpen(true);
    };

    const handleApplyConfigConfirm = () => {
        if (!invoice) return;

        const sourceItem = invoice.items[sourceItemIndex];
        const newItems = [...invoice.items];

        selectedItems.forEach(targetIndex => {
            newItems[targetIndex] = {
                ...newItems[targetIndex],
                erp_type: sourceItem.erp_type,
                erp_product: sourceItem.erp_product,
                erp_account: sourceItem.erp_account,
                exclude_withholding: sourceItem.exclude_withholding,
                taxes: {
                    ...newItems[targetIndex].taxes
                }
            };

            Object.entries(sourceItem.taxes).forEach(([sourceTaxName, sourceTaxInfo]) => {
                if (newItems[targetIndex].taxes[sourceTaxName]) {
                    newItems[targetIndex].taxes[sourceTaxName] = {
                        ...newItems[targetIndex].taxes[sourceTaxName],
                        tax_erp_id: sourceTaxInfo.tax_erp_id,
                        tax_account: sourceTaxInfo.tax_account,
                        use_as_cost_value: sourceTaxInfo.use_as_cost_value
                    };
                }
            });
        });

        setInvoice({ ...invoice, items: newItems });
        setIsApplyConfigModalOpen(false);
    };

    const toggleAccountEditable = (accountKey: string) => {
        setEditableAccounts(prev => ({
            ...prev,
            [accountKey]: !prev[accountKey]
        }));
    };

    useEffect(() => {
        const topScroll = topScrollRef.current;
        const bottomScroll = bottomScrollRef.current;

        if (!topScroll || !bottomScroll) return;

        const handleTopScroll = () => {
            if (bottomScroll) {
                bottomScroll.scrollLeft = topScroll.scrollLeft;
            }
        };

        const handleBottomScroll = () => {
            if (topScroll) {
                topScroll.scrollLeft = bottomScroll.scrollLeft;
            }
        };

        topScroll.addEventListener('scroll', handleTopScroll);
        bottomScroll.addEventListener('scroll', handleBottomScroll);

        return () => {
            topScroll.removeEventListener('scroll', handleTopScroll);
            bottomScroll.removeEventListener('scroll', handleBottomScroll);
        };
    }, []);

    const scrollbarStyles = {
        '&::-webkit-scrollbar': {
            height: '13px',
            width: '10px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#DDD',
            borderRadius: '0px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#BBB',
            borderRadius: '0px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    };

    const isHelisa = services?.erp?.toLowerCase() === 'helisa';

    return (
        <>
            <Box
                overflowX="auto"
                ref={topScrollRef}
                sx={{
                    ...scrollbarStyles,
                    mb: 1
                }}
            >
                <Box width="100%" height="5px" />
            </Box>

            <Box
                borderWidth="1px"
                borderColor="gray.200"
                borderRadius="lg"
                overflow="hidden"
                overflowX="auto"
                bg="white"
                transition="all 0.2s"
                _hover={{
                    boxShadow: "sm"
                }}
                sx={scrollbarStyles}
                ref={bottomScrollRef}
            >
                <Table variant="simple" size="sm">
                    <ProductsTableHeader activeTab={activeTab} isHelisa={isHelisa} />
                    <ProductsTableBody 
                        activeTab={activeTab} 
                        editableAccounts={editableAccounts} 
                        toggleAccountEditable={toggleAccountEditable} 
                        handleApplyConfig={handleApplyConfig}
                    />
                </Table>
            </Box>

            <ApplyConfigModal
                isOpen={isApplyConfigModalOpen}
                onClose={() => setIsApplyConfigModalOpen(false)}
                items={invoice?.items || []}
                sourceItemIndex={sourceItemIndex}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                onApply={handleApplyConfigConfirm}
            />
        </>
    );
};


function ProductsTableHeader({ activeTab, isHelisa }: { activeTab: 'pos' | 'erp', isHelisa: boolean }) {
    return (
        <Thead bg="gray.50">
            <Tr>
                {activeTab === 'erp' ? (
                    <ERPTableHeader isHelisa={isHelisa} />
                ) : (
                    <POSTableHeader />
                )}
            </Tr>
        </Thead>
    );
}

