import { useState, useCallback } from "react";
import { Invoice } from "@/types/invoice.types";

export const useCostCenterByItem = (selectedInvoices: Invoice[], setSelectedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>) => {
    const [useCostCenterPerItem, setUseCostCenterPerItem] = useState(false);

    const handleUseCostCenterPerItem = useCallback(() => {
        setUseCostCenterPerItem(!useCostCenterPerItem);
        // If the checkbox is unchecked, clear the cost_center of all items
        if (useCostCenterPerItem) {
            setSelectedInvoices(prevInvoices => {
                const invoicesWithCostCenterPerItem = prevInvoices.map(invoice => ({
                    ...invoice,
                    items: invoice.items.map(item => ({
                        ...item,
                        cost_center: undefined
                    }))
                }));
                return invoicesWithCostCenterPerItem;
            });
        }
    }, [useCostCenterPerItem, selectedInvoices.length, setSelectedInvoices]);

    return { useCostCenterPerItem, handleUseCostCenterPerItem };
};


