import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

interface LogoProps {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const Logo: React.FC<LogoProps> = ({ width = "40px", height = "40px", color }) => {
  // Use the brand color or fallback to the theme color
  const logoColor = color || useColorModeValue('#3182CE', '#63B3ED'); // Blue primary
  
  return (
    <Box w={width} h={height} display="flex" alignItems="center" justifyContent="center">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Stylized Lock Symbol */}
        <path
          d="M65 45V35C65 21.193 53.807 10 40 10C26.193 10 15 21.193 15 35V45"
          stroke={logoColor}
          strokeWidth="6"
          strokeLinecap="round"
        />
        <rect
          x="25"
          y="45"
          width="50"
          height="40"
          rx="5"
          fill={logoColor}
        />
        <path
          d="M40 65L50 75L60 65"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="75"
          cy="25"
          r="15"
          fill={logoColor}
          opacity="0.7"
        />
      </svg>
    </Box>
  );
};

export default Logo; 