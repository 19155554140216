import { Tbody } from "@chakra-ui/react";
import React from "react";
import { ERPTableRow } from "./erp/ERPTableRow";
import { POSTableRow } from "./pos/POSTableRow";
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { formatCurrency } from "../utils";

interface ProductsTableBodyProps {
    activeTab: 'pos' | 'erp';
    editableAccounts: { [key: string]: boolean };
    toggleAccountEditable: (accountKey: string) => void;
    handleApplyConfig: (index: number) => void;
}

export const ProductsTableBody: React.FC<ProductsTableBodyProps> = ({ activeTab, editableAccounts, toggleAccountEditable, handleApplyConfig }) => {
    const {
        invoice,
        services,
        itemsOptions,
        inventoryItems,
        handleItemChange
    } = useInvoiceDetails();
    return (
        <Tbody>
            {(invoice?.items ?? []).map((item, index) => (
                <React.Fragment key={index}>
                    {activeTab === 'erp' && services?.erp && (
                        <ERPTableRow
                            item={item}
                            index={index}
                            editableAccounts={editableAccounts}
                            toggleAccountEditable={toggleAccountEditable}
                        />
                    )}
                    {activeTab === 'pos' && services?.pos && !invoice.is_return && (
                        <POSTableRow
                            item={item}
                            index={index}
                            itemsOptions={itemsOptions}
                            inventoryItems={inventoryItems}
                            handleItemChange={handleItemChange}
                            formatCurrency={formatCurrency}
                        />
                    )}
                </React.Fragment>
            ))}
        </Tbody>
    )
}