import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  HStack,
  Text,
  Icon,
  Badge,
} from "@chakra-ui/react";
import { FiltersSection } from "@/components/invoices/FiltersSection";
import { TableSection } from "@/components/invoices/invoicesTable/TableSection";
import { PaginationSection } from "@/components/invoices/invoicesTable/PaginationSection";

import { InvoicesHeaderSection } from "@/components/invoices/header/InvoicesHeaderSection";
import { useInvoicesContext } from "@/context/invoices/invoicesContext";
import { InvoicesTabList } from "@/components/invoices/InvoicesTab";

export default function Invoices() {
  const { tabIndex, setTabIndex, handleTabIndexChange } = useInvoicesContext();


  return (
    <Box height="100%" bg="white" borderRadius="xl" borderWidth="1px" borderColor="gray.200" >
      <Flex direction="column" >


        {/* Invoice Type Tabs */}
        <Tabs
          width="100%"
          variant="unstyled"
          index={tabIndex}
          onChange={handleTabIndexChange}
          isLazy
        >
          <InvoicesTabList />

          <TabPanels mt="0">
            {/* Facturas de Venta Panel */}
            <InvoicesTabPanel />

            {/* Facturas de Compra Panel */}
            <InvoicesTabPanel />

          </TabPanels>
        </Tabs>

      </Flex>
    </Box>
  );
}


function InvoicesTabPanel() {
  return (
    <TabPanel p={0} pt={0}>
      <Flex direction="column">
        {/* Header Section */}
        <InvoicesHeaderSection />
        {/* Filters Section */}
        <FiltersSection />
        {/* Table Section */}
        <TableSection />
        {/* Pagination Section */}
        <Box m={6}>
          <PaginationSection />
        </Box>
      </Flex>
    </TabPanel>
  );
}
