import React, { useState } from "react";
import {
    Tr,
    Td,
    Icon,
    Box,
    Badge,
    Text,
    Select,
    Table,
    Thead,
    Tbody,
    Th,
    Collapse,
    VStack,
    Flex,
} from "@chakra-ui/react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Invoice, InvoiceUploadsState } from "@/types/invoice.types";
import { getConvertedQuantity } from "@/utils/conversionFactors";
import { formatCurrency } from "@/utils/currencyFormat";
import { validateInvoiceItems } from "@/utils/invoiceValidations";

interface InvoiceRowProps {
    invoice: Invoice;
    hasErrors: boolean;
    storeIds: Record<string, string>;
    storeSelection?: string;
    onStoreSelect: (invoiceId: string, storeId: string) => void;
    showStoreSelect: boolean;
    uploadTarget: string;
    onToggleErrors?: () => void;
    showErrorDetails?: boolean;
    isErrorExpanded?: boolean;
    errorType: string;
    errorItems: any[];
}

export const InvoiceRow: React.FC<InvoiceRowProps> = ({
    invoice,
    hasErrors,
    storeIds,
    storeSelection,
    onStoreSelect,
    showStoreSelect,
    uploadTarget,
    onToggleErrors,
    errorType,
    errorItems,
    showErrorDetails,
    isErrorExpanded,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    // Check if invoice is already uploaded to the target system
    const isAlreadyUploaded = invoice.uploads &&
        uploadTarget in invoice.uploads &&
        invoice.uploads[uploadTarget as keyof InvoiceUploadsState]?.uploaded;

    const formatStoreName = (storeName: string) => {
        const storeNameParts = storeName.split('_');
        const formattedStoreName = storeNameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
        return formattedStoreName;
    };

    const validatedItems = hasErrors ? validateInvoiceItems(errorItems, uploadTarget) : [];

    // Función para manejar el clic
    const handleClick = () => {
        // Si se debe mostrar los detalles de error y hay un manejador, usarlo
        if (showErrorDetails && onToggleErrors) {
            onToggleErrors();
        } else {
            // De lo contrario, usar el comportamiento normal
            setIsOpen(!isOpen);
        }
    };

    return (
        <>
            <Tr
                cursor="pointer"
                onClick={handleClick}
                _hover={{ bg: "gray.50" }}
                bg={hasErrors ? "red.50" : undefined}
                opacity={isAlreadyUploaded ? 0.5 : 1}
            >
                <Td width="40px">
                    <Icon
                        as={(showErrorDetails && isErrorExpanded) || (!showErrorDetails && isOpen) ? FaChevronDown : FaChevronRight}
                        color="gray.500"
                    />
                </Td>
                <Td>
                    {invoice.invoice_id}
                    {hasErrors && (
                        <Badge ml={2} colorScheme="red">
                            Requiere atención
                        </Badge>
                    )}
                </Td>
                <Td>{invoice.sender_party_name}</Td>
                <Td>{new Date(invoice.issue_date + 'T00:00:00').toLocaleDateString()}</Td>
                <Td>
                    {invoice.uploads && uploadTarget in invoice.uploads ?
                        invoice.uploads[uploadTarget as keyof InvoiceUploadsState]?.uploaded ?
                            <Text color="green.500">Factura subida</Text> :
                            <Text color="red.500">Factura no subida</Text>
                        : <Text color="red.500">Factura no subida</Text>
                    }
                </Td>
                {showStoreSelect && (
                    <Td onClick={e => e.stopPropagation()}>
                        <Select
                            value={storeSelection || ''}
                            onChange={(e) => onStoreSelect(invoice.id, e.target.value)}
                            placeholder="Seleccionar tienda"
                            isRequired
                            size="sm"
                        >
                            {Object.entries(storeIds).map(([id, name]) => (
                                <option key={id} value={name}>
                                    {formatStoreName(id)} - {name}
                                </option>
                            ))}
                        </Select>
                    </Td>
                )}
            </Tr>
            {/* Solo mostrar los detalles de línea si no estamos mostrando errores o si está expandido */}
            {(!showErrorDetails || !isErrorExpanded) && (
                <Tr>
                    <Td colSpan={showStoreSelect ? 6 : 5} p={0}>
                        <Collapse in={isOpen} animateOpacity>
                            <Box
                                p={5}
                                bg="gray.50"
                                borderBottom="1px"
                                borderColor="gray.200"
                                opacity={isAlreadyUploaded ? 0.5 : 1}
                            >
                                <Table size="sm" variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>Producto</Th>
                                            <Th isNumeric>Cantidad</Th>
                                            <Th isNumeric>Precio unitario</Th>
                                            <Th isNumeric>Total</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {invoice.items.map((item, index) => (
                                            <Tr
                                                key={index}
                                                bg={!item.inventory_item_name && uploadTarget === 'intuipos' ? "red.50" : undefined}
                                            >
                                                <Td>
                                                    {item.description}
                                                    {!item.inventory_item_name && uploadTarget === 'intuipos' && (
                                                        <Badge ml={2} colorScheme="red">
                                                            Falta nombre de inventario
                                                        </Badge>
                                                    )}
                                                </Td>
                                                <Td isNumeric>
                                                    {item.converted_quantity || (item.conversion && item.unit ? getConvertedQuantity(item.quantity, item.conversion, item.unit, false) : item.quantity)} {item.unit}
                                                </Td>
                                                <Td isNumeric>
                                                    {formatCurrency(String(item.converted_price || (item.conversion && item.unit ? getConvertedQuantity(item.price, item.conversion, item.unit, true) : item.price_per_unit)))}
                                                </Td>
                                                <Td isNumeric>
                                                    {formatCurrency(String(Number(item.converted_quantity || (item.conversion && item.unit ? getConvertedQuantity(item.quantity, item.conversion, item.unit, false) : item.quantity)) * Number(item.converted_price || (item.conversion && item.unit ? getConvertedQuantity(item.price_per_unit, item.conversion, item.unit, true) : item.price_per_unit))))}
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                            {hasErrors && (
                                        <Flex
                                            flexDirection="column"
                                            borderWidth="1px"
                                            borderColor="red.200"
                                            bg="red.50"
                                            p={3}
                                            m={2}
                                            borderRadius="md"
                                        >
                                            <Flex justifyContent="space-between" alignItems="center" mb={2}>
                                                <Badge colorScheme="red" fontSize="sm">
                                                    {errorType === 'return_without_reference'
                                                        ? 'Devolución sin referencia'
                                                        : `${validatedItems.length} producto${validatedItems.length > 1 ? 's' : ''} con error`}
                                                </Badge>
                                            </Flex>

                                            <Box mt={2} display={isOpen ? 'block' : 'none'} w="100%">
                                                {errorType === 'return_without_reference' ? (
                                                    <Text pl={2} color="red.600" fontStyle="italic">
                                                        Esta factura es una devolución pero no tiene factura de referencia
                                                    </Text>
                                                ) : (
                                                    <VStack align="stretch" spacing={2} w="100%">
                                                        {validatedItems.map((item, idx) => (
                                                            <ProductErrorDetail key={idx} item={item} />
                                                        ))}
                                                    </VStack>
                                                )}
                                            </Box>
                                    </Flex>
                            
                            )}
                        </Collapse>
                    </Td>
                </Tr>
            )}
        </>
    );
}; 

// Componente para mostrar los errores de un producto
const ProductErrorDetail: React.FC<{
    item: { description: string; index: number; errors: string[] };
}> = ({ item }) => {
    return (
        <Box 
            p={2} 
            bg="white" 
            borderRadius="sm"
            borderLeftWidth="3px"
            borderLeftColor="red.400"
            m={1}
            ml={8}
        >
            <Text fontWeight="medium" fontSize="sm">
                Producto: "{item.description}" (ítem {item.index + 1})
            </Text>
            <Text fontSize="sm" color="red.600" mt={1}>
                Errores: {item.errors.join(', ')}
            </Text>
        </Box>
    );
};
