import {
  Box,
  Grid,
  HStack,
  Tag,
  FormControl,
  FormLabel,
  Text,
  Badge,
} from "@chakra-ui/react";
import { useInvoiceDetails } from "@/context/invoiceDetails/invoiceDetailsContext";
import { ReferenceInvoiceSelect } from "./ReferenceInvoiceSelect";

export function InvoiceDetailsSection() {
  const { invoice } = useInvoiceDetails();

  return (
    <Box
      bg="white"
      p={6}
      borderRadius="xl"
      boxShadow="sm"
      borderWidth="1px"
      borderColor="gray.200"
      _hover={{
        borderColor: "gray.300",
      }}
      transition="all 0.2s"
    >
      <Text fontSize="lg" fontWeight="bold" mb={4}>Detalles de la Factura</Text>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        {!invoice.is_sale && (
          <FormControl>
            <FormLabel fontWeight="bold">Proveedor</FormLabel>
            <Text borderRadius="md">
              {invoice?.sender_party_name || "-"}
            </Text>
          </FormControl>
        )}
        {!invoice.is_sale && (
          <FormControl>
            <FormLabel fontWeight="bold">NIT</FormLabel>
            <Text borderRadius="md">
              {invoice?.sender_party || "-"}
            </Text>
          </FormControl>
        )}
        <FormControl>
          <FormLabel fontWeight="bold">Fecha de emisión</FormLabel>
          <Text borderRadius="md">
            {invoice?.issue_date || "-"}
          </Text>
        </FormControl>
        {!invoice.is_sale && (
          <Box>
            <Text fontWeight="bold">Tipo</Text>
            <Badge borderRadius="full" px={2} py={1} colorScheme="blue" fontSize="sm">
              {invoice.is_return
                ? "Nota Crédito"
                : invoice.is_general_purchase
                  ? "Gasto"
                  : "Costo"}
            </Badge>
          </Box>
        )}
        <Box gridColumn="span 2">
          <Text fontWeight="bold">CUFE</Text>
          <Text fontSize="sm" wordBreak="break-all" maxWidth="100%">
            {invoice.invoice_cufe}
          </Text>
        </Box>
        {invoice.is_return && (
          <Box gridColumn="span 2">
            <ReferenceInvoiceSelect />
          </Box>
        )}
        {(invoice.uploads?.siigo?.uploaded ||
          invoice.uploads?.intuipos?.uploaded ||
          invoice.uploads?.helisa?.uploaded) && (
            <Box gridColumn="span 2">
              <Text fontWeight="bold" mb={2}>
                Ha sido cargada a:
              </Text>
              <HStack spacing={2}>
                {invoice.uploads?.siigo?.uploaded && (
                  <Tag
                    colorScheme="green"
                    cursor={invoice.uploads.siigo.url ? "pointer" : "default"}
                    onClick={() => {
                      if (invoice.uploads?.siigo?.url) {
                        window.open(invoice.uploads.siigo.url, "_blank");
                      }
                    }}
                  >
                    Siigo{" "}
                    {invoice.uploads.siigo.url
                      ? "Archivo Plano (Click para ver archivo)"
                      : `- ${invoice.uploads.siigo.consecutive}`}
                  </Tag>
                )}
                {invoice.uploads?.helisa?.uploaded && (
                  <Tag
                    colorScheme="green"
                    cursor={invoice.uploads?.helisa?.url ? "pointer" : "default"}
                    onClick={() => {
                      if (invoice.uploads?.helisa?.url) {
                        window.open(invoice.uploads.helisa.url, "_blank");
                      }
                    }}
                  >
                    Helisa{" "}
                    {invoice.uploads.helisa.url
                      ? "(Click para ver archivo)"
                      : ""}
                  </Tag>
                )}
                {invoice.uploads?.intuipos?.uploaded && (
                  <Tag colorScheme="blue">
                    Intuipos{" "}
                    {invoice.uploads.intuipos.order_id &&
                      invoice.uploads.intuipos.order_id !== "Procesando"
                      ? `- ${invoice.uploads.intuipos.order_id}`
                      : ""}
                  </Tag>
                )}
              </HStack>
            </Box>
          )}
      </Grid>
    </Box>
  );
}
